import { GroupRules } from './team-state.model';

export const modRules: GroupRules[] = [
	{
		name: 'txt_network_role',
		checked: false,
		data: [
			{
				name: 'txt_network_settings_role',
				description: 'txt_this_rule_get_access_for_network_settings',
				checked: false,
				rule: 'NETWORK_MANAGE',
			},
			{
				name: 'txt_user_list_role',
				description: 'txt_this_rule_allow_to_view_users_in_network',
				checked: false,
				rule: 'NETWORK_USERS_LIST',
			},
			{
				name: 'txt_user_create_role',
				description: 'txt_this_rule_allow_invite_user_to_network',
				checked: false,
				rule: 'NETWORK_USERS_CREATE',
			},
			{
				name: 'txt_user_deactivate_role',
				description: 'txt_this_rule_allow_remove_user_from_network',
				checked: false,
				rule: 'NETWORK_USERS_MANAGE',
			},
		],
	},
	{
		name: 'txt_integrations_role',
		checked: false,
		data: [
			{
				name: 'txt_integrations_manage_role',
				description: 'txt_this_rule_allow_to_manage_integrations',
				checked: false,
				rule: 'INTEGRATIONS_MANAGE',
			},
			{
				name: 'txt_mapped_project_list_role',
				description: 'txt_this_rule_allow_to_view_list_of_mapped_projects',
				checked: false,
				rule: 'INTEGRATIONS_MAPPED_PROJECTS_LIST',
			},
		],
	},
	{
		name: 'Manage features',
		checked: false,
		data: [
			{
				name: 'Manage features',
				description: 'Allow user to manage intergration',
				checked: false,
				rule: 'TEST_CASE_FEATURES_MANAGE',
			},
		],
	},
	{
		name: 'txt_requirements_role',
		checked: false,
		data: [
			{
				name: 'txt_requirements_manage_role',
				description: 'txt_this_rule_allow_to_manage_requirements',
				checked: false,
				rule: 'TEST_REQUIREMENTS_MANAGE',
			},
			{
				name: 'txt_requirements_view_role',
				description: 'txt_this_rule_allow_to_view_requirements',
				checked: false,
				rule: 'TEST_REQUIREMENTS_LIST',
			},
		],
	},
	{
		name: 'txt_roles_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_role_list_role',
				description: 'txt_this_rule_allow_to_view_networks_roles',
				checked: false,
				rule: 'NETWORK_ROLES_LIST',
			},
			{
				name: 'txt_create_role_role',
				description: 'txt_this_rule_allow_to_create_network_role',
				checked: false,
				rule: 'NETWORK_ROLES_CREATE',
			},
			{
				name: 'txt_update_role_role',
				description: 'txt_this_rule_allow_to_update_network_role',
				checked: false,
				rule: 'NETWORK_ROLES_UPDATE',
			},
			{
				name: 'txt_delete_role_role',
				description: 'txt_this_rule_allow_to_delete_network_role',
				checked: false,
				rule: 'NETWORK_ROLES_DELETE',
			},
			{
				name: 'txt_assign_role_to_user_role',
				description: 'txt_this_rule_allow_to_assign_role_to_users_in_network',
				checked: false,
				rule: 'NETWORK_ROLES_ASSIGN',
			},
		],
	},
	{
		name: 'txt_projects_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_project_create_role',
				description: 'txt_this_rule_allow_to_create_project',
				checked: false,
				rule: 'PROJECTS_CREATE',
			},
			{
				name: 'txt_project_update_role',
				description: 'txt_this_rule_allow_to_update_project',
				checked: false,
				rule: 'PROJECTS_UPDATE',
			},
			{
				name: 'txt_project_delete_role',
				description: 'txt_this_rule_allow_to_delete_project',
				checked: false,
				rule: 'PROJECTS_DELETE',
			},
			{
				name: 'txt_project_users_role',
				description: 'txt_this_rule_allow_to_get_users_of_project',
				checked: false,
				rule: 'PROJECTS_USERS_MANAGE',
			},
		],
	},
	{
		name: 'txt_tests_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_test_create_role',
				description: 'txt_this_rule_allow_to_create_tests',
				checked: false,
				rule: 'TESTS_CREATE',
			},
			{
				name: 'txt_test_update_role',
				description: 'txt_this_rule_allow_to_update_tests',
				checked: false,
				rule: 'TESTS_UPDATE',
			},
			{
				name: 'txt_test_delete_role',
				description: 'txt_this_rule_allow_to_delete_tests',
				checked: false,
				rule: 'TESTS_DELETE',
			},
			{
				name: 'txt_test_assign_role',
				description: 'txt_this_rule_allow_assign_test_to_user',
				checked: false,
				rule: 'TESTS_ASSIGN',
			},
		],
	},
	{
		name: 'txt_profile_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_user_profile_role',
				description: 'txt_this_rule_allow_to_manage_user_profile',
				checked: false,
				rule: 'USER_PROFILE_MANAGE_OWN',
			},
		],
	},
	{
		name: 'txt_project_configs_roles_manage',
		checked: false,
		data: [
			{
				name: 'txt_project_configs_manage_role',
				description: 'txt_this_rule_allow_to_manage_project_configs',
				checked: false,
				rule: 'PROJECT_CONFIGS_MANAGE',
			},
		],
	},
	{
		name: 'txt_sections_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_tests_section_create_role',
				description: 'txt_this_rule_allow_to_create_test_sections',
				checked: false,
				rule: 'TEST_SECTIONS_CREATE',
			},
			{
				name: 'txt_tests_section_update_role',
				description: 'txt_this_rule_allow_to_update_test_sections',
				checked: false,
				rule: 'TEST_SECTIONS_UPDATE',
			},
			{
				name: 'txt_tests_section_delete_role',
				description: 'txt_this_rule_allow_to_delete_test_sections',
				checked: false,
				rule: 'TEST_SECTIONS_DELETE',
			},
		],
	},
	{
		name: 'txt_test_cases_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_test_case_create_role',
				description: 'txt_this_rule_allow_to_create_test_cases',
				checked: false,
				rule: 'TEST_CASES_CREATE',
			},
			{
				name: 'txt_test_case_update_role',
				description: 'txt_this_rule_allow_to_update_test_cases',
				checked: false,
				rule: 'TEST_CASES_UPDATE',
			},
			{
				name: 'txt_test_case_delete_role',
				description: 'txt_this_rule_allow_to_delete_test_cases',
				checked: false,
				rule: 'TEST_CASES_DELETE',
			},
			{
				name: 'txt_test_case_tags_manage_role',
				description: 'txt_this_rule_allow_to_manage_test_cases_tags',
				checked: false,
				rule: 'TEST_CASE_TAGS_MANAGE',
			},
		],
	},
	{
		name: 'txt_test_run_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_test_run_create_role',
				description: 'txt_this_rule_allow_to_create_test_runs_',
				checked: false,
				rule: 'TEST_RUNS_CREATE',
			},
			{
				name: 'txt_test_run_update_role',
				description: 'txt_this_rule_allow_to_update_test_runs_',
				checked: false,
				rule: 'TEST_RUNS_UPDATE',
			},
			{
				name: 'txt_test_run_delete_role',
				description: 'txt_this_rule_allow_to_delete_test_runs',
				checked: false,
				rule: 'TEST_RUNS_DELETE',
			},
		],
	},
	{
		name: 'txt_test_result_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_test_result_list_role',
				description: 'txt_this_rule_allow_to_view_test_result_list',
				checked: false,
				rule: 'TEST_RESULTS_LIST',
			},
			{
				name: 'txt_test_results_create_role',
				description: 'txt_this_rule_allow_to_create_test_results',
				checked: false,
				rule: 'TEST_RESULTS_CREATE',
			},
		],
	},
	{
		name: 'txt_comments_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_comment_list_role',
				description: 'txt_this_rule_allow_to_view_comments',
				checked: false,
				rule: 'COMMENTS_LIST',
			},
			{
				name: 'txt_comment_create_role',
				description: 'txt_this_rule_allow_to_create_comments',
				checked: false,
				rule: 'COMMENTS_CREATE',
			},
			{
				name: 'txt_comment_update_role',
				description: 'txt_this_rule_allow_to_update_comments',
				checked: false,
				rule: 'COMMENTS_UPDATE',
			},
			{
				name: 'txt_comment_delete_role',
				description: 'txt_this_rule_allow_to_remove_comments',
				checked: false,
				rule: 'COMMENTS_DELETE',
			},
		],
	},
	{
		name: 'txt_attachments_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_attachment_list_role',
				description: 'txt_this_rule_allow_to_view_attachments_list',
				checked: false,
				rule: 'ATTACHMENTS_LIST',
			},
			{
				name: 'txt_attachment_upload_role',
				description: 'txt_this_rule_allow_to_upload_attachments',
				checked: false,
				rule: 'ATTACHMENTS_CREATE',
			},
			{
				name: 'txt_attachment_delete_role',
				description: 'txt_this_rule_allow_to_delete_attachments',
				checked: false,
				rule: 'ATTACHMENTS_DELETE',
			},
		],
	},
	{
		name: 'txt_sprints_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_sprint_create_role',
				description: 'txt_this_rule_allow_to_create_sprints',
				checked: false,
				rule: 'RELEASES_CREATE',
			},
			{
				name: 'txt_sprint_start_role',
				description: 'txt_this_rule_allow_to_start_sprints',
				checked: false,
				rule: 'RELEASES_START',
			},
			{
				name: 'txt_sprint_update_role',
				description: 'txt_this_rule_allow_to_update_sprints',
				checked: false,
				rule: 'RELEASES_UPDATE',
			},
			{
				name: 'txt_sprint_delete_role',
				description: 'txt_this_rule_allow_to_delete_sprints',
				checked: false,
				rule: 'RELEASES_DELETE',
			},
			{
				name: 'txt_sprint_close_role',
				description: 'txt_this_rule_allow_to_close_sprints',
				checked: false,
				rule: 'RELEASES_CLOSE',
			},
		],
	},
	{
		name: 'txt_import_export_role',
		checked: false,
		data: [
			{
				name: 'txt_import_cases_role',
				description: 'txt_this_rule_allow_to_import_cases',
				checked: false,
				rule: 'IMPORT_CREATE',
			},
		],
	},
	{
		name: 'txt_activities_manage_role',
		checked: false,
		data: [
			{
				name: 'txt_activity_list_role',
				description: 'txt_this_rule_allow_to_view_activities',
				checked: false,
				rule: 'ACTIVITIES_LIST',
			},
		],
	},
	{
		name: 'txt_billing',
		checked: false,
		data: [
			{
				name: 'txt_apply_plans',
				checked: false,
				rule: 'BILLING_PLAN_APPLY',
				description: 'txt_this_rule_allow_to_change_payment_plans',
			},
		],
	},
];
