import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RootComponent } from './root.component';
import { SvgDefinitionsModule } from '@shared/ui/components/svg-definitions/svg-definitions.module';
import { PortalHostModule } from '@shared/ui/components/portal-host/portal-host.module';
import { TuiScrollControlsModule } from '@shared/ui/elements/scroll-controls';
import { EqaDialogHostModule } from '@shared/ui/components/dialog-host';
import { TuiHintsHostModule } from '@shared/ui/components/hints-host';
import { EqaDropdownHostModule } from '@shared/ui/components/dropdown-host';

@NgModule({
	declarations: [RootComponent],
	exports: [RootComponent],
	imports: [
		CommonModule,
		SvgDefinitionsModule,
		PortalHostModule,
		TuiScrollControlsModule,
		EqaDialogHostModule,
		TuiHintsHostModule,
		EqaDropdownHostModule,
	],
})
export class RootModule {}
