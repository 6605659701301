import { ChangeDetectorRef, ElementRef, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { focusVisibleObservable, watch } from '@shared/domain/observables';
import { DestroyService } from '@shared/domain/services/destroy.service';

/**
 * Service to imitate :focus-visible
 * (https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible)
 * in browsers that do not support it
 * @dynamic
 */
@Injectable()
export class EqaFocusVisibleService extends Observable<boolean> {
	private readonly focusVisible$: Observable<boolean>;

	constructor(
		@Inject(ElementRef) { nativeElement }: ElementRef<Element>,
		@Inject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef,
		@Inject(DestroyService) destroy$: Observable<void>,
	) {
		super((subscriber) => this.focusVisible$.subscribe(subscriber));

		this.focusVisible$ = focusVisibleObservable(nativeElement).pipe(watch(changeDetectorRef), takeUntil(destroy$));
	}
}
