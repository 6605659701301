import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';

import { TuiHintBoxModule } from './hint-box/hint-box.module';
import { EqaHintsHostComponent } from './hints-host.component';
import { ActiveZoneModule } from '@shared/ui/directives/active-zone/active-zone.module';
import { EqaHoveredModule } from '@shared/ui/directives/hovered';

@NgModule({
    imports: [EqaHoveredModule, ActiveZoneModule, CommonModule, PolymorpheusModule, TuiHintBoxModule],
    declarations: [EqaHintsHostComponent],
    exports: [EqaHintsHostComponent]
})
export class TuiHintsHostModule {}
