export class HideLoader {
	static readonly type = '[Show Loader] Hide Loader';

	constructor() {}
}

export class ShowLoader {
	static readonly type = '[Show Loader] Show Loader';

	constructor() {}
}
