import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { CustomField, CustomFieldType } from "@features/case/case-fields/domain";

@Injectable({ providedIn: 'root' })
export class CustomFieldApiService {
	types = CustomFieldType;
	private baseUrl = ServerUrl.HOME_URL;

	constructor(private http: HttpClient) {}

	url(projectId: string) {
		return `${this.baseUrl}/projects/${projectId}/fields`;
	}

	getCustomFields(projectId) {
		return this.http.get<CustomField[]>(this.url(projectId));
	}

	deleteCustomField(projectId: string, fieldId: string) {
		return this.http.delete<CustomField[]>(`${this.url(projectId)}/${fieldId}`);
	}

	updateCustomField(projectId: string, fieldId: string, field) {
		return this.http.put<CustomField[]>(`${this.url(projectId)}/${fieldId}`, field);
	}

	createCustomField(projectId: string, field: CustomField) {
		return this.http.post(this.url(projectId), field);
	}
}
