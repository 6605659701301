import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TestCaseApiService } from "@features/case/case-repository/domain/services/test.case.api.service";
import { DefectList } from "@features/defects/domain/interfaces/defect.list";
import { DefectModel } from "@features/defects/domain/interfaces/defect.model";
import { Severity } from "@features/defects/domain/interfaces/severity";
import { DefectsApiService } from "@features/defects/domain/services/defect.api.service";
import { RunsApiService } from "@features/run/domain/services/api/runs-api.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { insertItem, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { format, parseISO } from "date-fns";
import { EMPTY, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
	CloseDefect,
	CreateDefect,
	DeleteDefect,
	GetDefect,
	GetDefectsByProject,
	GetDefectsByRun,
	GetDefectsBySharedRun,
	GetDefectsByTestCase,
	GetSeverities,
	ReopenDefect,
	UpdateDefect
} from "./defects.actions";

export class DefectsStateModel {
	public defects: DefectModel[];
	public defectsLoaded: boolean;
	public defectsIfNotFound: boolean;
	public defect: DefectModel;
	public severities: Severity[];
}

const defaults = {
	defects: [],
	defect: null,
	defectsLoaded: true,
	defectsIfNotFound: false,
	severities: [],
};

@State<DefectsStateModel>({
	name: 'defects',
	defaults,
})
@Injectable()
export class DefectsState {

	@Selector()
	static groupedDefects(defectsStateModel: DefectsStateModel): DefectList {
		return defectsStateModel.defects.reduce((acc, obj) => {
			const key = format(parseISO(obj.createdAt), 'yyyy-MM-dd');
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(obj);
			return acc;
		}, {});
	}

	@Selector()
	static defectsLoaded(defectsStateModel: DefectsStateModel): boolean {
		return defectsStateModel.defectsLoaded;
	}

	@Selector()
	static defectsIfNotFound(defectsStateModel: DefectsStateModel): boolean {
		return defectsStateModel.defectsIfNotFound;
	}

	@Selector()
	static defects(defectsStateModel: DefectsStateModel): DefectModel[] {
		return defectsStateModel.defects;
	}

	@Selector()
	static defect(defectsStateModel: DefectsStateModel): DefectModel {
		return defectsStateModel.defect;
	}

	@Selector()
	static severities(defectsStateModel: DefectsStateModel): Severity[] {
		return defectsStateModel.severities;
	}

	@Selector()
	static defectsCount(defectsStateModel: DefectsStateModel): number {
		return defectsStateModel.defects.length;
	}

	constructor(
		private defectsApiService: DefectsApiService,
		private testRunApiService: RunsApiService,
		private testCaseApiService: TestCaseApiService,
	) {}

	@Action(CreateDefect)
	createDefect({ getState, setState }: StateContext<DefectsStateModel>, { projectId, defect: d }: CreateDefect) {
		return this.defectsApiService.createDefect(projectId, d).pipe(
			tap((defect) => {
				setState(patch({ defect, defects: insertItem(defect, 0) }));
			}),
		);
	}

	@Action(GetDefect)
	getDefect({ getState, patchState }: StateContext<DefectsStateModel>, { projectId, defectId }: GetDefect) {
		patchState({
			defectsLoaded: false,
			defectsIfNotFound: false,
		});
		return this.defectsApiService.getDefect(projectId, defectId).pipe(
			tap((defect) => {
				patchState({ defect, defectsLoaded: true, defectsIfNotFound: false });
			}),
			catchError((e: HttpErrorResponse) => {
				if (e.status === 404) {
					patchState({ defectsIfNotFound: true });
					return EMPTY;
				}
				return throwError(e);
			}),
		);
	}

	@Action(GetDefectsByProject)
	getDefectsByProject({ getState, patchState }: StateContext<DefectsStateModel>, { projectId }: GetDefectsByProject) {
		patchState({
			defectsLoaded: false,
		});
		return this.defectsApiService.getDefectsByProject(projectId).pipe(
			tap((defects) => {
				patchState({
					defects,
					defectsLoaded: true,
				});
			}),
		);
	}

	@Action(GetDefectsByRun)
	getDefectsByRun({ getState, patchState }: StateContext<DefectsStateModel>, { runId }: GetDefectsByRun) {
		patchState({
			defectsLoaded: false,
		});
		return this.testRunApiService.getDefectsByRun(runId).pipe(
			tap((defects) => {
				patchState({
					defects,
					defectsLoaded: true,
				});
			}),
		);
	}

	@Action(GetDefectsBySharedRun)
	getDefectsBySharedRun(
		{ getState, patchState }: StateContext<DefectsStateModel>,
		{ runId, token }: GetDefectsBySharedRun,
	) {
		patchState({
			defectsLoaded: false,
		});
		return this.testRunApiService.getDefectsByRun(runId, token).pipe(
			tap((defects) => {
				patchState({
					defects,
					defectsLoaded: true,
				});
			}),
		);
	}

	@Action(GetDefectsByTestCase)
	getDefectsByTestCase(
		{ getState, patchState }: StateContext<DefectsStateModel>,
		{ testCaseId }: GetDefectsByTestCase,
	) {
		patchState({
			defectsLoaded: false,
		});
		return this.testCaseApiService.getTestCaseDefects(testCaseId).pipe(
			tap((defects) => {
				patchState({
					defects,
					defectsLoaded: true,
				});
			}),
		);
	}

	@Action(GetSeverities)
	getSeverities({ patchState }: StateContext<DefectsStateModel>) {
		patchState({
			defectsLoaded: false,
		});
		return this.defectsApiService.defectSeverities().pipe(
			tap((severities: Severity[]) => {
				patchState({
					severities,
					defectsLoaded: true,
				});
			}),
		);
	}

	@Action(CloseDefect)
	closeDefect({ getState, setState }: StateContext<DefectsStateModel>, { projectId, defectId }: CloseDefect) {
		setState(
			patch({
				defectsLoaded: false,
			}),
		);
		return this.defectsApiService.closeDefect(projectId, defectId).pipe(
			tap((defect) => {
				setState(
					patch({
						defects: updateItem<DefectModel>((d) => d.id === defectId, defect),
						defect,
						defectsLoaded: true,
					}),
				);
			}),
		);
	}

	@Action(ReopenDefect)
	reopenDefect({ getState, setState }: StateContext<DefectsStateModel>, { projectId, defectId }: ReopenDefect) {
		setState(
			patch({
				defectsLoaded: false,
			}),
		);
		return this.defectsApiService.reopenDefect(projectId, defectId).pipe(
			tap((defect) => {
				setState(
					patch({
						defects: updateItem<DefectModel>((d) => d.id === defectId, defect),
						defect,
						defectsLoaded: true,
					}),
				);
			}),
		);
	}

	@Action(UpdateDefect)
	updateDefect(
		{ getState, setState }: StateContext<DefectsStateModel>,
		{ projectId, defectId, updateDefectDto }: UpdateDefect,
	) {
		setState(
			patch({
				defectsLoaded: false,
			}),
		);
		return this.defectsApiService.updateDefect(projectId, defectId, updateDefectDto).pipe(
			tap((defect) => {
				setState(
					patch({
						defects: updateItem<DefectModel>((d) => d.id === defectId, defect),
						defect,
						defectsLoaded: true,
					}),
				);
			}),
		);
	}

	@Action(DeleteDefect)
	deleteDefect({ getState, setState }: StateContext<DefectsStateModel>, { projectId, defectId }: DeleteDefect) {
		setState(
			patch({
				defectsLoaded: false,
			}),
		);
		return this.defectsApiService.deleteDefect(projectId, defectId).pipe(
			tap(() => {
				setState(
					patch({
						defects: removeItem<DefectModel>((d) => d.id === defectId),
						defect: null,
						defectsLoaded: true,
					}),
				);
			}),
		);
	}
}
