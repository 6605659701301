export class SetLanguage {
	static readonly type = '[Language] Set Language';

	constructor(public payload: string) {}
}

export class GetLanguage {
	static readonly type = '[Language] Get Language';
}

export class GetLanguageList {
	static readonly type = '[Language] Get All Languages List';

	constructor() {}
}

export class LoadLanguages {
	static readonly type = '[Language] Get Codes';

	constructor() {}
}

export class GetCurrencyList {
	static readonly type = '[Currency] GetRange';

	constructor() {}
}

export class GetCurrency {
	static readonly type = '[Currency] Update';

	constructor() {}
}

export class SetCurrency {
	static readonly type = '[Currency] GetUpdated';

	constructor(public payload: string) {}
}
