import { Section } from '@features/case/case-repository/domain/interfaces/section';
import { Test } from '@features/run/domain/interfaces/test/test';
import { Testcase } from '@features/case/case-repository/domain/interfaces/testcase';
import { ContentOperations } from '@features/case/case-repository/domain/contants/content.operations';

export class SetCheckedNodeList {
	static readonly type = '[Tree] Set Checked Node List';

	constructor(public nodesIds: string[], public entitiesIds: string[]) {}
}

export class BuildTree {
	static readonly type = '[Tree] Build Tree';

	constructor(public sections: Section[]) {}
}

export class FilterTree {
	static readonly type = '[Tree] Filter Tree';

	constructor(public filters: any = {}, public searchPhrase = '') {}
}

export class RemoveNode {
	static readonly type = '[Tree] Remove Node';

	constructor(public node: Section) {}
}

export class AddRootNode {
	static readonly type = '[Tree] Add Root Node';

	constructor(public node: Section) {}
}

export class AddChildNode {
	static readonly type = '[Tree] Add Child Node';

	constructor(public node: Section) {}
}

export class RemoveNodeList {
	static readonly type = '[Tree] Remove Node List';

	constructor(public projectId: string) {}
}

export class UpdateNode {
	static readonly type = '[Tree] Update Node';

	constructor(public node: Section) {}
}

export class MoveNode {
	static readonly type = '[Tree] Move Tree Node';

	constructor(public section: Section, public newParentId: string) {}
}

export class SetMoveTarget {
	static readonly type = '[Tree] Set Move Destination';

	constructor(public node: Section) {}
}

export class ActivateNode {
	static readonly type = '[Tree] Activate Node';

	constructor(public nodeId: string) {}
}

export class ToggleNode {
	static readonly type = '[Tree] Toggle Node';

	constructor(public node: Section) {}
}

export class SetExpandedIds {
	static readonly type = '[Tree] Set Expanded Ids';

	constructor(public expandedNodesIds: string[]) {}
}

export class ExpandAll {
	static readonly type = '[Tree] Expand All';
}

export class CollapseAll {
	static readonly type = '[Tree] Collapse All';
}

export class SelectNode {
	static readonly type = '[Tree] Select Node';

	constructor(public node: Section, public checked: boolean) {}
}

export class ClearTree {
	static readonly type = '[Tree] Clear Tree';
}

export class RemoveSelectedNodeContent {
	static readonly type = '[Tree] Remove Selected Node Content';
}

export class ClearSelectedNodeContent {
	static readonly type = '[Tree] Clear Selected Node Content';
}

export class UpdateContent {
	static readonly type = '[Tree] Update Content';

	constructor(public field: string, public entitiesIds: string[], public value: any) {}
}

export class SelectNodeEntities {
	static readonly type = '[Tree] Select Node Entities';

	constructor(public entities: Array<Test | Testcase>, public checked: boolean) {}
}

export class UpdateNodeContent {
	static readonly type = '[Tree] Update Node Content';

	constructor(public entities: any[], public node: Section, public operation: ContentOperations) {}
}

export class AddNodeContent {
	static readonly type = '[Tree] Add Node Content';

	constructor(public entities: any[], public node: Section, public operation: ContentOperations) {}
}

export class CopyNodeContent {
	static readonly type = '[Tree] Copy Node Content';

	constructor(public destination: Section) {}
}

export class MoveNodeContent {
	static readonly type = '[Tree] Move Node Content';

	constructor(public destination: Section, public nodeEntities?: Testcase[]) {}
}
