import { InjectionToken } from '@angular/core';
import * as icons from '../../icons';

export const EQA_ICONS = new InjectionToken<Record<string, string>>(
	'A key/value dictionary of icon names and src to be defined with SvgService',
	{
		factory: () => {
			const iconsObject = {};
			Object.values(icons).forEach((icon) => {
				iconsObject[icon.name] = icon.data;
			});
			return iconsObject;
		},
	},
);
