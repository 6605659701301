import { Directive, ElementRef, Inject, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { EqaHoveredService } from '@shared/domain';

@Directive({
	selector: '[eqaHoveredChange]',
})
export class EqaHoveredDirective {
	@Output()
	readonly eqaHoveredChange: Observable<boolean>;

	constructor(
		@Inject(ElementRef) { nativeElement }: ElementRef<Element>,
		@Inject(EqaHoveredService) hoveredService: EqaHoveredService,
	) {
		this.eqaHoveredChange = hoveredService.createHovered$(nativeElement);
	}
}
