import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { pluck, tap } from 'rxjs/operators';
import { StatusService } from '@features/run/domain/services/api/test-execution/status.service';
import { Status } from '@features/run/domain/interfaces/test/status';
import { CreateStatus, LoadStatuses, RemoveStatus, SetStatuses, UpdateStatus } from './status.actions';

export class StatusStateModel {
	statuses: Status[];
	loading: boolean;
}

@State<StatusStateModel>({
	name: 'statusState',
	defaults: {
		statuses: [],
		loading: false,
	},
})
@Injectable()
export class StatusState {
	constructor(private statusService: StatusService, private translateService: TranslateService) {}

	@Selector()
	static statuses(m: StatusStateModel): Status[] {
		return m.statuses;
	}

	@Selector()
	static loading(m: StatusStateModel): boolean {
		return m.loading;
	}

	@Action(LoadStatuses)
	getStatuses({ patchState }: StateContext<StatusStateModel>, {}: LoadStatuses) {
		return this.statusService.getStatusesByProjectId().pipe(
			pluck('data'),
			tap((statuses: Status[]) => {
				statuses.forEach((s) => {
					s.slug = this.translateService.instant(s.name);
				});
				patchState({
					statuses: statuses,
					loading: false,
				});
			}),
		);
	}

	@Action(SetStatuses)
	setStatuses({ patchState }: StateContext<StatusStateModel>, { statuses }: SetStatuses) {
		patchState({
			statuses,
			loading: false,
		});
	}

	@Action(CreateStatus)
	createStatus({ getState, patchState }: StateContext<StatusStateModel>, { status }: CreateStatus) {
		const state = getState();
		const statuses = _.cloneDeep(state.statuses);
		return this.statusService.createStatus(status).pipe(
			pluck('data'),
			tap((createdStatus: Status) => {
				createdStatus.slug = createdStatus.name;
				statuses.unshift(createdStatus);
				patchState({
					statuses: statuses,
				});
			}),
		);
	}

	@Action(UpdateStatus)
	updateStatus({ getState, patchState }: StateContext<StatusStateModel>, { status }: UpdateStatus) {
		const state = getState();
		const statuses = _.cloneDeep(state.statuses);
		const index = statuses.findIndex((s) => s.id === status.id);
		return this.statusService.updateStatus(status).pipe(
			pluck('data'),
			tap((updatedStatus: Status) => {
				updatedStatus.slug = updatedStatus.name;
				statuses[index] = updatedStatus;
				patchState({
					statuses,
				});
			}),
		);
	}

	@Action(RemoveStatus)
	removeStatus({ getState, patchState }: StateContext<StatusStateModel>, { statusId }: RemoveStatus) {
		const state = getState();
		const statuses = _.cloneDeep(state.statuses);
		const index = statuses.findIndex((s) => s.id === statusId);
		return this.statusService.removeStatus(statusId).pipe(
			tap(() => {
				statuses.splice(index, 1);
				patchState({
					statuses,
				});
			}),
		);
	}
}
