import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { FormControlModel } from '@shared/ui/forms/ui/components/dynaminc-fields/form.control.model';
import { SelectedFilter } from '@shared/ui/components/filters/domain/interfaces/selected.filter';
import {
	ClearFilters,
	FulfillFilters,
	RemoveFilter,
	SaveFiltersFormState,
	SetExpanded,
	SetFilter,
	SetFilterInProgress,
	SetFiltersControls,
} from './filter.actions';
import { FilterStateModel } from './filter.state.model';

@State<FilterStateModel>({
	name: 'filterState',
	defaults: {
		filters: {},
		filtersControls: [],
		currentFilters: {
			searchPhrase: '',
			filters: {},
		},
		expandedFilters: [],
		allowAddFilter: true,
		filterInProgress: false,
	},
})
@Injectable()
export class FilterState {

	@Selector()
	static filters(m: FilterStateModel): { filters: any, searchPhrase: string } {
		return m.currentFilters;
	}

	@Selector()
	static expandedFilters(m: FilterStateModel): string[] {
		return m.expandedFilters;
	}

	@Selector()
	static filtersControls(m: FilterStateModel): FormControlModel<any>[] {
		return m.filtersControls;
	}

	@Selector()
	static filteringInProgress(m: FilterStateModel): boolean {
		return m.filterInProgress;
	}

	@Selector()
	static allowAddFilter(m: FilterStateModel): boolean {
		return m.allowAddFilter;
	}

	@Selector()
	static selectedFilters(m: FilterStateModel): SelectedFilter {
		return m.currentFilters.filters;
	}

	@Selector()
	static searchPhrase(m: FilterStateModel): string {
		return m.currentFilters.searchPhrase;
	}

	constructor() {}

	@Action(FulfillFilters)
	fulfillFilters({ patchState }: StateContext<FilterStateModel>, { filters }: FulfillFilters) {}

	@Action(SaveFiltersFormState)
	saveFiltersFormState({ patchState }: StateContext<FilterStateModel>, { filters }: SaveFiltersFormState) {
		patchState({
			filters: filters,
			allowAddFilter: true,
		});
	}

	@Action(SetFilterInProgress)
	setFilterInProgress({ patchState }: StateContext<FilterStateModel>, { flag }: SetFilterInProgress) {
		patchState({
			filterInProgress: flag,
		});
	}

	@Action(SetFilter)
	setFilter({ getState, patchState }: StateContext<FilterStateModel>, { filters, searchPhrase }: SetFilter) {
		const currentFilters = _.cloneDeep(getState().currentFilters);
		currentFilters.filters = filters;
		currentFilters.searchPhrase = searchPhrase;
		patchState({
			currentFilters,
			filterInProgress: true,
		});
	}

	@Action(ClearFilters)
	clearFilters({ patchState }: StateContext<FilterStateModel>) {
		patchState({
			currentFilters: {
				searchPhrase: '',
				filters: {},
			},
			filterInProgress: false,
		});
	}

	@Action(SetExpanded)
	setExpanded({ getState, patchState }: StateContext<FilterStateModel>, { expandedFilters }: SetExpanded) {
		patchState({ expandedFilters });
	}

	@Action(SetFiltersControls)
	setFiltersControls({ getState, patchState }: StateContext<FilterStateModel>, { controls }: SetFiltersControls) {
		patchState({
			filterInProgress: true,
			filtersControls: controls,
		});
	}

	@Action(RemoveFilter)
	removeFilter({ getState, patchState }: StateContext<FilterStateModel>, { filter }: RemoveFilter) {
		const currentFilters = _.cloneDeep(getState().currentFilters);
		const filters = currentFilters.filters;
		delete filters[filter];
		patchState({
			currentFilters,
			expandedFilters: getState().expandedFilters.filter((f) => f !== filter),
			filterInProgress: false,
		});
	}
}
