<eqa-hint-box
	*ngFor="let hint of hints$ | async"
	role="tooltip"
	[attr.id]="hint.id"
	[hint]="hint"
	[@eqaFadeIn]="animation"
	[eqaActiveZoneParent]="hint.activeZone"
	(eqaHoveredChange)="onHovered($event, hint)"
>
	<ng-container *polymorpheusOutlet="hint.content as text">
		<div>
			<span class="t-text">{{ text }}</span>
		</div>
	</ng-container>
</eqa-hint-box>
