import { TestrunModel } from '../interfaces/testrun.model';
import { QueryParams } from '@core/interfaces/http/QueryParams';
import { Sprint } from '../../../sprint/domain/interfaces/sprint';

export class SetActiveSprint {
	static readonly type = 'Set active sprint';

	constructor(public sprint: Sprint = null) {}
}

export class RemoveTestRun {
	static readonly type = '[API] Remove Test Run';

	constructor(public runId: string) {}
}

export class CloseRun {
	static readonly type = '[API] Close Test Run';

	constructor() {}
}

export class GetTestRun {
	static readonly type = '[API] Get Test Run';

	constructor(public id: string) {}
}

export class GetTestRunProgress {
	static readonly type = '[API] Get Test Run Progress';

	constructor(public id: string, public groupBy: string) {}
}

export class GetSharedTestRunProgress {
	static readonly type = '[API] Get Shared Test Run Progress';

	constructor(public id: string, public groupBy: string, public token: string) {}
}

export class SelectRun {
	static readonly type = '[API] Select Test Run';

	constructor(public run: TestrunModel) {}
}

export class ClearState {
	static readonly type = '[API] Clear Test Run State';

	constructor() {}
}

export class ShareRun {
	static readonly type = '[Result] Share run';

	constructor(public runId: string) {}
}

export class ClearShareLink {
	static readonly type = '[Result] Clear Share Link';
}

export class GetSharedRun {
	static readonly type = '[Result] Get Shared run';

	constructor(public token: string, public runId: string) {}
}

export class GetRunListByProject {
	static readonly type = '[RUN Api] Get Run List By Project';

	constructor(public projectId: string, public params?: QueryParams) {}
}

export class GetRunListBySprint {
	static readonly type = '[RUN Api] Get Run List By Sprint';

	constructor(public sprintId: string) {}
}

export class CreateTestRun {
	static readonly type = '[RUN Api] Create Run';

	constructor(public run: TestrunModel) {}
}

export class DeleteRun {
	static readonly type = '[RUN Api] Delete Run';

	constructor(public run: TestrunModel) {}
}

export class UpdateRun {
	static readonly type = '[RUN Api] Update Run';

	constructor(public run: TestrunModel) {}
}

export class GetRunsConfig {
	static readonly type = '[RUN Api] Get Run Config';

	constructor(public runId: string) {}
}

export class GetSharedRunConfig {
	static readonly type = '[RUN Api] Get Shared Run Config';

	constructor(public runId: string, public token: string) {}
}
