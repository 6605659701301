import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EqaPortalHostComponent } from './portal-host.component';

@NgModule({
	declarations: [EqaPortalHostComponent],
	imports: [CommonModule],
	exports: [EqaPortalHostComponent],
})
export class PortalHostModule {}
