import { InjectionToken, ValueProvider } from '@angular/core';

export interface EqaLoaderOptions {
	inheritColor: boolean;
	overlay: boolean;
}

export const EQA_LOADER_DEFAULT_OPTIONS: EqaLoaderOptions = {
	inheritColor: false,
	overlay: false,
};

export const EQA_LOADER_OPTIONS = new InjectionToken<EqaLoaderOptions>('A token for eqa loader options', {
	factory: () => EQA_LOADER_DEFAULT_OPTIONS,
});

export const eqLoaderOptionsProvider: (options: Partial<EqaLoaderOptions>) => ValueProvider = (
	options: Partial<EqaLoaderOptions>,
) => ({
	provide: EQA_LOADER_OPTIONS,
	useValue: { ...EQA_LOADER_DEFAULT_OPTIONS, ...options },
});
