import { Injectable } from "@angular/core";
import { Testcase } from "@features/case/case-repository/domain/interfaces/testcase";
import { DefaultFields } from "@features/case/case-review/domain/constants/default.fields";
import { Priority } from "@features/case/case-review/domain/constants/tescase.priorities";
import { ProjectsState } from "@features/projects/+state/project/project.state";
import { CustomFieldsState } from "@features/projects/+state/settings/custom-fields/custom-fields.state";
import { Store } from "@ngxs/store";
import { TreeState } from "@store/tree/tree.state";

@Injectable({ providedIn: 'root' })
export class EmptyCaseFactoryService {
	constructor(private store: Store) {}

	public create(): Testcase {
		const c = {
			name: '',
			fields: this.store.selectSnapshot(CustomFieldsState.customFieldsList).map((f) => f.toDefaultField()),
			tags: [],
			sectionId: this.store.selectSnapshot(TreeState.activeSectionIds)[0],
			priority: Priority.MEDIUM,
			estimate: 0,
			automated: false,
			steps: [],
			attachments: [],
			expectedResult: null,
			innerId: undefined,
			preconditions: null,
			isApproved: false,
			projectId: this.store.selectSnapshot(ProjectsState.selectedProject).id,
		};

		c.fields.find((f) => f.slug === DefaultFields.PRIORITY).value.value = Priority.MEDIUM;
		c.fields.find((f) => f.slug === DefaultFields.PRECONDITIONS).value.value = '';
		c.fields.find((f) => f.slug === DefaultFields.ESTIMATE).value.value = 0;

		return c;
	}
}
