import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LocalStorageService } from '@core/services/local-storage/local.storage.service';
import { SetUser } from './user.actions';
import { UserStateModel } from './user.state.model';

@State<UserStateModel>({
	name: 'userState',
	defaults: {
		user: null,
	},
})
@Injectable()
export class UserState {
	constructor(private localStorageService: LocalStorageService) {}

	@Selector()
	static user(m: UserStateModel): any {
		return m.user;
	}

	@Selector()
	static network(m: UserStateModel): any {
		return m.user.network;
	}

	@Action(SetUser)
	setUser({ patchState }: StateContext<UserStateModel>, { user }: SetUser) {
		patchState({ user });
	}
}
