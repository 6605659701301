import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";

@Injectable({ providedIn: 'root' })
export class SprintApiService {
	private baseUrl = ServerUrl.HOME_URL;
	private sprintUrl = this.baseUrl + '/sprints';

	constructor(private http: HttpClient) {}

	getSprintsList(projectId, limit = null, offset = null) {
		let httpParams = new HttpParams();
		httpParams = httpParams.append('offset', offset);
		httpParams = httpParams.append('limit', limit);
		return this.http.get(`${this.sprintUrl}/list/${projectId}`, { params: httpParams });
	}

	getPaginatedSprintsList(projectId, limit = null, offset = null, status) {
		let httpParams = new HttpParams();
		httpParams = httpParams.append('offset', offset);
		httpParams = httpParams.append('limit', limit);
		httpParams = httpParams.append('statuses', status);
		return this.http.get(`${this.sprintUrl}/paginated/list/${projectId}`, { params: httpParams });
	}

	getSprint(id: string) {
		return this.http.get(this.sprintUrl + '/' + id);
	}

	startSprint(sprintId: string) {
		return this.http.post(`${this.sprintUrl}/start/${sprintId}`, { sprintId });
	}

	closeSprint(sprintId: string) {
		return this.http.post(`${this.sprintUrl}/close/${sprintId}`, { sprintId });
	}

	createSprint(sprint) {
		return this.http.post(this.sprintUrl, sprint);
	}

	updateSprint(sprint: any) {
		return this.http.post(`${this.sprintUrl}/${sprint.id}`, sprint);
	}

	deleteSprint(id: string) {
		return this.http.delete(this.sprintUrl + '/' + id);
	}
}
