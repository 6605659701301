export enum PlanActionsEnum {
	GET_PLAN_LIST = '[Plans Api] Get Plan List',
	SELECT_PLAN = '[Plans Api] Select Plan',
	SET_PERIOD = '[Plans Api] Set Plan Period',
	INCREASE_FEATURE_COUNT = '[Plan Features] Increase count',
	DECREASE_FEATURE_COUNT = '[Plan Features] Decrease count',
	CALCULATE_PRICE = '[Plan] Calculate price',
	UPGRADE_PLAN = '[Plan] Upgrade Plan',
	SAVE_SELECTED_PLAN = '[Plan] Save selected plan',
	APPLY_PLAN = '[Plan] Apply Plan',
	GET_COST = '[Plan] Get Cost',
}
