import { TestAction } from '@features/run/domain/interfaces/test/test.action';
import { ArrayUtils } from '@core/utils/array.utils';
import { StatusState } from '../../../../../store/status/status.state';
import { TeamEffort } from '../../interfaces/TeamEffort';
import { RunsApiService } from '../../services/api/runs-api.service';
import { CalculateTeamEffortBurndown, ChangeSortDirection, LoadTeamEffort } from './team-effort.actions';
import { calculateTeamEffort, calculateTeamEffortChartStats, StatusKeyVal } from './team-effort.helpers';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { pluck, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { ExecutionState } from '../execution/exectuion.state';
import { RunState } from '../runs.state';

type LineChatStats = {
	labels: Array<string>;
	data: Array<Object>;
};

export class TeamEffortStateModel {
	public teamEffort: TeamEffort[];
	public flatEffortList: TestAction[];
	public teamEffortChartStats: LineChatStats;
	public sort: {
		field: string;
		direction: string;
	};
}

const defaults = {
	flatEffortList: [],
	teamEffort: [],
	teamEffortChartStats: {
		labels: [],
		data: [],
	},
	sort: {
		field: 'total',
		direction: 'asc',
	},
};

@State<TeamEffortStateModel>({
	name: 'teamEffort',
	defaults,
})
@Injectable()
export class TeamEffortState {
	constructor(private store: Store, private runApiService: RunsApiService) {}

	@Selector()
	static teamEffort(m: TeamEffortStateModel): TeamEffort[] {
		if (m.sort.field && m.sort.direction !== '') {
			const teamEffort = cloneDeep(m.teamEffort);
			return teamEffort.sort((a, b) => {
				const aValue = (a as TeamEffort)[m.sort.field];
				const bValue = (b as TeamEffort)[m.sort.field];
				return (aValue < bValue ? -1 : 1) * (m.sort.direction === 'asc' ? 1 : -1);
			});
		} else {
			return m.teamEffort;
		}
	}

	@Selector()
	static teamEffortChartStats(m: TeamEffortStateModel): LineChatStats {
		return m.teamEffortChartStats;
	}

	@Selector()
	static flatEffortList(m: TeamEffortStateModel): TestAction[] {
		return m.flatEffortList;
	}

	@Action(LoadTeamEffort)
	loadTeamEffort({ getState, patchState }: StateContext<TeamEffortStateModel>, { runId }: LoadTeamEffort) {
		return this.runApiService.getRunProgress(runId).pipe(
			pluck('data'),
			tap((runProgress: TestAction[]) => {
				const groupedProgressByUser = ArrayUtils.groupByKey(
					runProgress,
					(action: TestAction) => action.creator.email,
				);
				const statuses = this.store.selectSnapshot(StatusState.statuses);
				const statusKeyVal = <StatusKeyVal>ArrayUtils.convertArrayOfObjectsToKeyValue(
					statuses,
					(s) => s.name,
					(s) => {
						return {
							name: s.name,
							count: 0,
							percentage: 0,
							color: s.color,
						};
					},
				);
				const teamEffort: TeamEffort[] = calculateTeamEffort(groupedProgressByUser, statusKeyVal);
				patchState({
					flatEffortList: runProgress,
					teamEffort,
				});
			}),
		);
	}

	@Action(CalculateTeamEffortBurndown)
	calculateTeamEffortBurndownChart({ getState, patchState }: StateContext<TeamEffortStateModel>) {
		const actions = cloneDeep(getState().flatEffortList);
		const tests = this.store.selectSnapshot(ExecutionState.tests);
		const run = this.store.selectSnapshot(RunState.testRun);
		const teamEffortChartStats = calculateTeamEffortChartStats(run.createdAt, tests.length, actions);
		patchState({
			teamEffortChartStats,
		});
	}

	@Action(ChangeSortDirection)
	changeSortDirection({ getState, patchState }: StateContext<TeamEffortStateModel>, { sort }: ChangeSortDirection) {
		patchState({
			sort,
		});
	}
}
