import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { ImpersonateComponent } from '@features/authentication/ui/pages/impersonate/impersonate.component';
import { SubscriptionExpiredComponent } from '@features/network/components/billing/expired/subscription.expired.component';
import { NetworkPrepareComponent } from '@features/network/components/prepare/network-prepare.component';
import { EqaWorkspaceLayoutComponent } from '@layout/ui/pages/workspace-layout/workspace-layout.component';
import { NotFoundPageComponent } from '@shared/ui/not-found-page/not-found-page.component';
import { MainComponent } from './layouts/main/main.component';

const appRoutes: Routes = [
	{
		path: '',
		component: MainComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'projects',
				loadChildren: () => import('@features/projects/projects.module').then((m) => m.ProjectsModule),
			},
			{
				path: 'workspace',
				component: EqaWorkspaceLayoutComponent,
				loadChildren: () => import('@features/network/network.module').then((m) => m.NetworkModule),
			},
			{
				path: 'user',
				loadChildren: () => import('@features/user-settings/user-settings.module').then((m) => m.EqaUserSettingsModule),
			},
			{
				path: 'expired',
				component: SubscriptionExpiredComponent,
			},
		],
	},
	{
		path: '',
		loadChildren: () => import('@features/authentication/authentication.module').then((m) => m.AuthenticationModule),
	},
	{ path: 'impersonate', component: ImpersonateComponent },
	{
		path: 'editor',
		loadChildren: () => import('@features/editor/editor.module').then((m) => m.EditorModule),
	},
	{
		path: 'prepare',
		component: NetworkPrepareComponent,
	},
	{ path: '**', component: NotFoundPageComponent, pathMatch: 'full' },
];

@NgModule({
	imports: [
		BrowserModule,
		RouterModule.forRoot(appRoutes, {
			enableTracing: false,
			relativeLinkResolution: 'legacy',
		}),
	],
	exports: [RouterModule],
})
export class ApplicationRoutingModule {}
