import { Injectable } from '@angular/core';
import { ServerUrl } from '@core/constants/server-url';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { TestAction } from '@features/run/domain/interfaces/test/test.action';

@Injectable({ providedIn: 'root' })
export class TestActionsApiService {
	private baseUrl = ServerUrl.HOME_URL;
	private actionUrl = `${this.baseUrl}/action`;

	constructor(private http: HttpClient, private store: Store) {}

	getActionsByTest(id: string): Observable<TestAction[]> {
		return this.http.get<TestAction[]>(this.actionUrl + '/list/' + id);
	}
}
