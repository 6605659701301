import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgDefinitionsComponent } from './svg-definitions.component';

@NgModule({
	declarations: [SvgDefinitionsComponent],
	exports: [SvgDefinitionsComponent],
	imports: [CommonModule],
})
export class SvgDefinitionsModule {}
