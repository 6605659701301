import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { Testcase } from "@features/case/case-repository/domain/interfaces/testcase";
import { UpdateManyCasesDto } from "@features/case/case-repository/domain/interfaces/updateManyCasesDto";
import { DefectModel } from "@features/defects/domain/interfaces/defect.model";
import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class TestCaseApiService {
	private baseUrl = ServerUrl.HOME_URL;
	private testCaseUrls = this.baseUrl + '/cases';

	constructor(private http: HttpClient) {}

	getTestCase(id: string) {
		return this.http.get(this.testCaseUrls + '/' + id);
	}

	getTestCaseDefects(id: string) {
		return this.http.get(this.testCaseUrls + '/' + id + '/defects').pipe<DefectModel[]>(pluck('data'));
	}

	getCasesBySectionId(sectionId: string) {
		return this.http.get(this.testCaseUrls + '/list/' + sectionId, {
			params: {
				limit: '0',
			},
		});
	}

	getCasesByProjectId(projectId: string) {
		return this.http.get(this.testCaseUrls + '/list/project/' + projectId, {
			params: {
				limit: '0',
			},
		});
	}

	createTestCase(testCase): Observable<Testcase> {
		return this.http.post(this.testCaseUrls + '/create', testCase).pipe(pluck('data'));
	}

	getHistory(testCaseId) {
		return this.http.get(this.testCaseUrls + '/history/cases/' + testCaseId);
	}

	getTestcaseChangelog(testCaseId) {
		return this.http.get(this.testCaseUrls + '/' + testCaseId + '/history');
	}

	updateManyTestCases(ids, testCase) {
		return this.http.post(this.testCaseUrls + '/update', { ids: [ids], ...testCase });
	}

	bulkUpdateCases(updateManyCasesDto: UpdateManyCasesDto): Observable<Testcase[]> {
		return this.http.post(this.testCaseUrls + '/update', updateManyCasesDto).pipe(pluck('data'));
	}

	updateTestCase(id, testCase): Observable<Testcase> {
		return this.http.put(this.testCaseUrls + '/' + id, testCase).pipe(pluck('data'));
	}

	moveTestCases(testCasesInfo: { caseId: string, initialSectionId: string }[], sectionId: string) {
		return this.http.post(this.testCaseUrls + '/move', { testCases: testCasesInfo, sectionId });
	}

	removeTestCaseList(ids: string[]) {
		return this.http.post(this.testCaseUrls + '/delete/list', { ids });
	}

	copyTestCaseList(caseIds: string[], sectionId: string) {
		return this.http.post(this.testCaseUrls + '/copy/list', { caseIds, sectionId });
	}

	removeTestCase(testCaseId: string) {
		return this.http.delete(`${this.testCaseUrls}/delete/${testCaseId}`);
	}
}
