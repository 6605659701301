import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerUrl } from '@core/constants/server-url';
import { RefundRequestModel } from '@features/network/components/billing/domain/models/redund.request.model';

@Injectable({ providedIn: 'root' })
export class RefundRequestApiService {
	private baseUrl = ServerUrl.HOME_URL;
	private billingUrl = this.baseUrl + '/billing/refund';

	constructor(private http: HttpClient) {}

	postRefundRequest(refundRequestModel: RefundRequestModel) {
		return this.http.post(this.billingUrl, refundRequestModel);
	}
}
