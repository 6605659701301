import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { containsOrAfter, getClosestFocusable, getNativeFocused, setNativeFocused } from '@shared/domain/utils';
import { blurNativeFocused } from '@shared/domain/utils/focus';
// @dynamic
@Directive({
	selector: '[eqaFocusTrap]',
	host: {
		tabIndex: '0',
	},
})
export class EqaFocusTrapDirective implements OnDestroy {
	private readonly activeElement = getNativeFocused(this.documentRef);

	constructor(
		@Inject(DOCUMENT) private readonly documentRef: Document,
		@Inject(ElementRef)
		private readonly elementRef: ElementRef<HTMLElement>,
		@Inject(Renderer2) private readonly renderer: Renderer2,
	) {
		Promise.resolve().then(() => {
			setNativeFocused(this.elementRef.nativeElement);
		});
	}

	@HostListener('blur')
	onBlur() {
		this.renderer.removeAttribute(this.elementRef.nativeElement, 'tabIndex');
	}

	@HostListener('window:focusin.silent', ['$event.target'])
	onFocusIn(node: Node) {
		if (containsOrAfter(this.elementRef.nativeElement, node)) {
			return;
		}

		const focusable = getClosestFocusable(this.elementRef.nativeElement, false, this.elementRef.nativeElement);

		if (focusable) {
			setNativeFocused(focusable);
		}
	}

	ngOnDestroy() {
		blurNativeFocused(this.documentRef);

		Promise.resolve().then(() => {
			if (this.activeElement instanceof HTMLElement) {
				setNativeFocused(this.activeElement);
			}
		});
	}
}
