import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '@core/services/http/auth/account.service';
import { Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { SetCurrency } from '../../../../../core/store/localisation/localisation.actions';
import { GetSubscription } from '@features/network/+state/subscription/subscrtiption.actions';
import { SubscriptionState } from '@features/network/+state/subscription/subscrtiption.state';

@Component({
	selector: 'eqa-impersonate',
	templateUrl: './impersonate.component.html',
})
export class ImpersonateComponent implements OnInit {
	constructor(private accountService: AccountService, private store: Store, private router: Router, private route: ActivatedRoute) {}

	getSubscription(): void {
		this.store.dispatch(new GetSubscription()).subscribe(() => {
			const isExpired = this.store.selectSnapshot(SubscriptionState.subscription).isExpired;
			if (isExpired) {
				this.navigateToExpiredScreen();
				return;
			}
			this.navigateToDashboard();
		});
	}

	setLocalisationInfo(response) {
		this.store.dispatch(new SetCurrency(response.user.network.currency));
	}

	setToken(response) {
		this.accountService.storeUserData(response.user, response.token['accessToken']);
	}

	navigateToDashboard() {
		this.router.navigate(['/projects']);
	}

	navigateToExpiredScreen() {
		this.router.navigate(['/expired']);
	}

	ngOnInit() {
		localStorage.clear();
		this.accountService
			.impersonate(this.route.snapshot.queryParams.token)
			.pipe(
				tap((res: any) => {
					this.setToken(res);
					this.setLocalisationInfo(res);
					this.getSubscription();
				}),
				catchError(() => this.router.navigate(['/signin'])),
			)
			.subscribe();
	}
}
