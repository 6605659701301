import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { CreateTestCaseTag } from "@features/case/case-tags/domain/interfaces/CreateTestCaseTag";
import { TestCaseTag } from "@features/case/case-tags/domain/interfaces/TestCaseTag";
import { ProjectsState } from "@features/projects/+state/project/project.state";
import { Store } from "@ngxs/store";

@Injectable({ providedIn: 'root' })
export class CaseTagsApiService {
	private baseUrl = ServerUrl.HOME_URL;

	constructor(private store: Store, private http: HttpClient) {}

	get tagsUrl() {
		return `${this.baseUrl}/projects/${this.store.selectSnapshot(ProjectsState.selectedProject).id}/tags`;
	}

	public getTagsByProject() {
		return this.http.get(this.tagsUrl);
	}

	public getTag(tagId: string) {
		return this.http.get(this.tagsUrl + '/' + tagId);
	}

	public removeTag(tagId: string) {
		return this.http.delete(this.tagsUrl + '/' + tagId);
	}

	public createTag(tag: CreateTestCaseTag) {
		return this.http.post(this.tagsUrl, tag);
	}

	public updateTag(tag: Partial<TestCaseTag>) {
		return this.http.put(this.tagsUrl + '/' + tag.id, tag);
	}
}
