import { ProjectConfig } from 'src/app/features/projects/interfaces';

enum ProjectConfigsActions {
	GET_CONFIGS = '[Project Configs Api] Get Project Configs',
	GET_CONFIG_BY_ID = '[Project Configs Api] Get Project Config By Id',
	CREATE_CONFIGS = '[Project Configs Api] Create Project Config',
	REMOVE_CONFIGS = '[Project Configs Api] Remove Project Config',
	CLEAR_ACTIVE_CONFIG = '[Project Configs Api] Clear Active Config',
	UPDATE_CONFIGS = '[Project Configs Api] Update Project Config',
}

export class GetProjectConfigs {
	static readonly type = ProjectConfigsActions.GET_CONFIGS;

	constructor(public projectId: string) {}
}

export class GetProjectConfigById {
	static readonly type = ProjectConfigsActions.GET_CONFIG_BY_ID;

	constructor(public projectId: string, public id: string) {}
}

export class RemoveProjectConfig {
	static readonly type = ProjectConfigsActions.REMOVE_CONFIGS;

	constructor(public projectId: string, public configId: string) {}
}

export class ClearActiveProjectConfig {
	static readonly type = ProjectConfigsActions.CLEAR_ACTIVE_CONFIG;

	constructor() {}
}

export class UpdateProjectConfig {
	static readonly type = ProjectConfigsActions.UPDATE_CONFIGS;

	constructor(public projectId: string, public configId: string, public config: ProjectConfig) {}
}

export class CreateProjectConfig {
	static readonly type = ProjectConfigsActions.CREATE_CONFIGS;

	constructor(public projectId: string, public config: ProjectConfig) {}
}
