import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowBillableFeaturesDirective } from './show-billable-features.directive';

@NgModule({
	declarations: [ShowBillableFeaturesDirective],
	exports: [ShowBillableFeaturesDirective],
	imports: [CommonModule],
})
export class BillableFeatureModule {}
