import { ChangeDetectionStrategy, Component, ElementRef, Inject } from '@angular/core';
import { EqaHintService } from '@shared/domain/services/hint-service';
import { AbstractEqaHint } from '@shared/domain/abstracts';
import { EqaHintDirective } from '@shared/ui/directives/hint';
import { EQA_ANIMATION_OPTIONS } from '@shared/tokens/animation';
import { AnimationOptions } from '@angular/animations';
import { eqaFadeIn } from '@shared/domain/animations';

@Component({
	selector: 'eqa-hints-host',
	templateUrl: './hints-host.template.html',
	styleUrls: ['./hints-host.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [eqaFadeIn],
	host: {
		'aria-live': 'polite',
	},
})
export class EqaHintsHostComponent {
	readonly animation = {
		value: '',
		...this.options,
	} as const;

	constructor(
		@Inject(EQA_ANIMATION_OPTIONS) private readonly options: AnimationOptions,
		@Inject(ElementRef) private readonly elementRef: ElementRef<HTMLElement>,
		@Inject(EqaHintService) readonly hints$: EqaHintService,
	) {}

	get clientRect(): ClientRect {
		return this.elementRef.nativeElement.getBoundingClientRect();
	}

	onHovered(hovered: boolean, directive: AbstractEqaHint) {
		if (directive instanceof EqaHintDirective) {
			directive.componentHovered$.next(hovered);
		}
	}
}
