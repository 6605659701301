import { UserModel } from '@core/interfaces/user/UserModel';
import { Role } from '@core/interfaces/user/Roles';
import { GroupRules, Rule } from './team-state.model';

export class GetTeamUsers {
	static readonly type = '[Users API] Get Users from network';
}

export class InviteUser {
	static readonly type = '[Users API] Invite user to  network';

	constructor(public user: Partial<UserModel>) {}
}

export class DeactivateUser {
	static readonly type = '[Users API] Deactivate user from network';

	constructor(public id: string) {}
}

export class AssignUser {
	static readonly type = '[Access Control API] Assign role to user';

	constructor(public roleId: string, public userId: string) {}
}

export class GetRoles {
	static readonly type = '[Access Control API] Get roles';
}

export class GetRole {
	static readonly type = '[Access Control API] Get role by ID';

	constructor(public id: string) {}
}

export class CreateRole {
	static readonly type = '[Access Control API] Create role';

	constructor(public role: Partial<Role>) {}
}

export class UpdateRule {
	static readonly type = 'Update rule of role';

	constructor(public rule: Rule) {}
}

export class UpdateGroupRules {
	static readonly type = 'Update group rules';

	constructor(public group: GroupRules) {}
}

export class UpdateRole {
	static readonly type = '[Access Control API] Updated role by ID';

	constructor(public id: string, public role: Role) {}
}

export class FullUpdateRole {
	static readonly type = '[Access Control API] Full Updated role by ID with new rules';

	constructor(public id: string) {}
}

export class DeleteRole {
	static readonly type = '[Access Control API] Delete role';

	constructor(public id: string) {}
}

export class GetGroups {
	static readonly type = '[Groups API] Get groups';
}

export class DeleteGroup {
	static readonly type = '[Groups API] Delete group';

	constructor(public id: string) {}
}
