import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { ProjectModel, ProjectSettingsModel } from "@features/projects/interfaces";

@Injectable({ providedIn: 'root' })
export class ProjectApiService {
	private baseUrl = ServerUrl.HOME_URL;
	private projectsUrl = this.baseUrl + '/projects';

	constructor(private http: HttpClient) {}

	fetchProjects() {
		return this.http.get<ProjectModel[]>(`${this.projectsUrl}/list`);
	}

	getProjectsByUser() {
		return this.http.get(this.baseUrl + '/user/projects');
	}

	saveProjectUsers(id: string, userIds: string[]) {
		return this.http.post(this.projectsUrl + '/' + id + '/users', { userIds });
	}

	fetchProjectByNetwork() {
		return this.http.get<ProjectModel[]>(this.projectsUrl + '/list');
	}

	fetchProject(id: string) {
		return this.http.get<ProjectModel>(this.projectsUrl + '/' + id);
	}

	addProject(project: ProjectModel) {
		return this.http.post<ProjectModel>(this.projectsUrl, project);
	}

	updateProject(id, project: ProjectModel) {
		return this.http.post<ProjectModel>(this.projectsUrl + '/' + id, project);
	}

	updateProjectSettings(id, setting: ProjectSettingsModel) {
		return this.http.put<{ data: ProjectModel }>(`${this.projectsUrl}/${id}/settings`, setting);
	}

	deleteProject(id: string) {
		return this.http.delete<ProjectModel>(this.projectsUrl + '/' + id);
	}

	addProjectToFavorites(id: string) {
		return this.http.put<void>(this.projectsUrl + '/' + id + '/' + 'favorite', {});
	}

	deleteProjectFromFavorites(id: string) {
		return this.http.delete<void>(this.projectsUrl + '/' + id + '/' + 'favorite');
	}

	getProjectsStatistic() {
		return this.http.get(this.projectsUrl + '/stats', { params: { limit: '30', interval: '1' } });
	}
}
