import { InjectionToken, ValueProvider } from '@angular/core';
import { EQA_ABSTRACT_HINT_DEFAULT_OPTIONS, EqaAbstractHintOptions } from '@shared/domain/abstracts';

export type EqaManualHintOptions = EqaAbstractHintOptions;

export const EQA_MANUAL_HINT_DEFAULT_OPTIONS: EqaManualHintOptions = {
	...EQA_ABSTRACT_HINT_DEFAULT_OPTIONS,
};

export const EQA_MANUAL_HINT_OPTIONS = new InjectionToken<EqaManualHintOptions>(
	'Default parameters for manual hint directive',
	{
		factory: () => EQA_MANUAL_HINT_DEFAULT_OPTIONS,
	},
);

export const eqaManualHintOptionsProvider: (options: Partial<EqaManualHintOptions>) => ValueProvider = (
	options: Partial<EqaManualHintOptions>,
) => ({
	provide: EQA_MANUAL_HINT_OPTIONS,
	useValue: { ...EQA_MANUAL_HINT_DEFAULT_OPTIONS, ...options },
});
