import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractEqaPortalService } from '@shared/domain/abstracts/portal-service';
import { EqaPortalService } from '@shared/domain';
import { AbstractEqaPortalHostComponent } from '@shared/domain/abstracts';

const BLANK_CLIENT_RECT = {
	bottom: 0,
	height: 0,
	left: 0,
	right: 0,
	top: 0,
	width: 0,
};

@Component({
	selector: 'eqa-dropdown-host',
	templateUrl: './dropdown-host.template.html',
	styleUrls: ['./dropdown-host.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: AbstractEqaPortalService, useExisting: EqaPortalService }],
})
export class EqaDropdownHostComponent extends AbstractEqaPortalHostComponent {
	@ViewChild('positionFixedOffset')
	private readonly positionFixedOffsetRef?: ElementRef<HTMLDivElement>;

	fixedPositionOffset() {
		return this.positionFixedOffsetRef?.nativeElement.getBoundingClientRect() || BLANK_CLIENT_RECT;
	}
}
