import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { cloneDeep, isEqual, orderBy } from "lodash";
import { throwError } from "rxjs";
import { catchError, pluck, tap } from "rxjs/operators";
import { CustomFieldsState } from "@features/projects/+state/settings/custom-fields/custom-fields.state";
import { ReviewStatus } from "../constants/review-status";
import { CaseReviewDto } from "../interfaces/case-review.dto";
import { CaseReviewApiService } from "../services/api/case-review-api.service";
import { CaseReviewStateModel } from "./case-review-state.model";
import {
	ChangeCaseReviewStatus,
	CreateCaseReview,
	FilterReviews,
	LoadCaseReview,
	LoadCaseReviews,
	SortReviews,
	UpdateCaseReview
} from "./case-review.actions";
import { ChangeLog } from "../../../case-repository/domain/interfaces/change-log";

@State<CaseReviewStateModel>({
	name: 'caseReviewState',
	defaults: {
		reviews: [],
		review: null,
		count: 0,
	},
})
@Injectable()
export class CaseReviewState {
	@Selector()
	static reviews(m: CaseReviewStateModel) {
		return m.reviews;
	}

	@Selector()
	static count(m: CaseReviewStateModel) {
		return m.count;
	}

	@Selector()
	static inProgressCount(m: CaseReviewStateModel) {
		return m.reviews.filter(({ status }) => status === ReviewStatus.IN_PROGRESS).length;
	}

	@Selector()
	static review(m: CaseReviewStateModel) {
		return m.review;
	}

	constructor(private store: Store, private caseReviewApiService: CaseReviewApiService) {}

	@Action(LoadCaseReview)
	loadCaseReview({ getState, patchState }: StateContext<CaseReviewStateModel>, { caseReviewId }: LoadCaseReview) {
		return this.caseReviewApiService.loadCaseReviewById(caseReviewId).pipe(
			pluck('data'),
			tap((caseReview: CaseReviewDto) => {
				const caseReviewCopy = cloneDeep(caseReview);
				patchState({
					review: caseReviewCopy,
				});
			}),
		);
	}

	@Action(CreateCaseReview)
	createCaseReview({ getState, patchState }: StateContext<CaseReviewStateModel>, { caseReview }: CreateCaseReview) {
		return this.caseReviewApiService.createCaseReview(caseReview).pipe(
			pluck('data'),
			tap((createdCaseReview: CaseReviewDto) => {
				const reviews = cloneDeep(getState().reviews);
				reviews.push(createdCaseReview);
				patchState({
					reviews: reviews,
				});
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
		);
	}

	@Action(UpdateCaseReview)
	updateCaseReview({ getState, patchState }: StateContext<CaseReviewStateModel>, { caseReview }: UpdateCaseReview) {
		return this.caseReviewApiService.updateCaseReview(caseReview.id, caseReview).pipe(
			pluck('data'),
			tap((updatedCaseReview: CaseReviewDto) => {
				const reviews = cloneDeep(getState().reviews);
				const review = cloneDeep(getState().review);
				const reviewIndex = reviews.findIndex(({ id }) => id === updatedCaseReview.id);
				if (reviewIndex >= 0) {
					reviews[reviewIndex].name = updatedCaseReview.name;
					reviews[reviewIndex].reviewers = updatedCaseReview.reviewers;
				}
				if (review) {
					review.name = updatedCaseReview.name;
					review.reviewers = updatedCaseReview.reviewers;
				}
				patchState({
					reviews,
					review,
				});
			}),
		);
	}

	@Action(ChangeCaseReviewStatus)
	changeCaseReviewStatus(
		{ getState, patchState }: StateContext<CaseReviewStateModel>,
		{ caseReviewId, status }: ChangeCaseReviewStatus,
	) {
		return this.caseReviewApiService
			.changeReviewStatus(caseReviewId, {
				status: status,
			})
			.pipe(
				pluck('data'),
				tap((updatedCaseReview: CaseReviewDto) => {
					const reviews = cloneDeep(getState().reviews);
					const review = cloneDeep(getState().review);
					const reviewIndex = reviews.findIndex(({ id }) => id === updatedCaseReview.id);
					if (reviewIndex >= 0) {
						reviews[reviewIndex].status = updatedCaseReview.status;
					}
					if (review) {
						review.status = updatedCaseReview.status;
					}
					patchState({
						reviews,
						review,
					});
				}),
			);
	}

	@Action(LoadCaseReviews)
	loadCaseReviews({ patchState }: StateContext<CaseReviewStateModel>, { projectId, status }: LoadCaseReviews) {
		return this.caseReviewApiService.loadProjectCaseReviews(projectId, status).pipe(
			tap((reviewResponse: any) => {
				patchState({
					reviews: reviewResponse.data,
					count: reviewResponse.meta.total,
				});
			}),
		);
	}

	@Action(SortReviews)
	sortReviews({ patchState, getState }: StateContext<CaseReviewStateModel>, { sortExpression }: SortReviews) {
		const reviews = cloneDeep(getState().reviews);
		let sortedReviews = [];
		switch (sortExpression.field) {
			case 'createdAt':
				sortedReviews = orderBy(reviews, [(obj) => new Date(obj[sortExpression.field])], [sortExpression.name]);
				break;
			case 'status':
				sortedReviews = orderBy(reviews, [(obj) => obj[sortExpression.field]], [sortExpression.name]);
				break;
			default:
				sortedReviews = orderBy(reviews, [(obj) => obj['name']], ['asc']);
				break;
		}
		patchState({
			reviews: sortedReviews,
		});
	}

	@Action(FilterReviews)
	filterReviews({ patchState, getState }: StateContext<CaseReviewStateModel>, { filters }: FilterReviews) {}
}
