import { PeriodInterval } from '@features/network/components/billing/domain/constants/period-interval';
import { PriceModel } from './price.model';

export class PlanPeriodModel {
	interval: PeriodInterval;

	value: number;

	prices: PriceModel[];

	constructor(model: PlanPeriodModel) {
		this.interval = model.interval;
		this.value = model.value;
		if (model.prices) {
			this.prices = model.prices.map((price) => new PriceModel(price));
		}
	}
}
