import { DefectSeverities } from "@features/defects/domain/interfaces/defect.severity";

export const severities = [
	{
		color: '#FF6252',
		name: DefectSeverities.BLOCKER,
		type: DefectSeverities.BLOCKER,
	},
	{
		color: '#FFC23C',
		name: DefectSeverities.CRITICAL,
		type: DefectSeverities.CRITICAL,
	},
	{
		color: '#3879BE',
		name: DefectSeverities.HIGH,
		type: DefectSeverities.HIGH,
	},
	{
		color: '#05a081',
		name: DefectSeverities.MEDIUM,
		type: DefectSeverities.MEDIUM,
	},
	{
		color: '#A4AAB0',
		name: DefectSeverities.LOW,
		type: DefectSeverities.LOW,
	},
	{
		color: '#DEE2E6',
		name: DefectSeverities.MINOR,
		type: DefectSeverities.MINOR,
	},
];
