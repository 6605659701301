import { CreateDefectDto } from "@features/defects/domain/interfaces/create.defect";
import { UpdateDefectDto } from "@features/defects/domain/interfaces/update.defect";

export class GetDefectsByProject {
	static readonly type = '[Defects] Get Defects By Project';

	constructor(public projectId: string) {}
}

export class GetDefectsBySharedRun {
	static readonly type = '[Defects] Get Defects By Shared Run';

	constructor(public runId: string, public token: string) {}
}

export class GetDefectsByRun {
	static readonly type = '[Defects] Get Defects By Run';

	constructor(public runId: string) {}
}

export class GetDefectsByTestCase {
	static readonly type = '[Defects] Get Defects By Test Case';

	constructor(public testCaseId: string) {}
}

export class GetSeverities {
	static readonly type = '[Defects] Get Severities';
}

export class CreateDefect {
	static readonly type = '[Defects] Create Defect';

	constructor(public projectId: string, public defect: CreateDefectDto) {}
}

export class GetDefect {
	static readonly type = '[Defects] Get Defect';

	constructor(public projectId: string, public defectId: string) {}
}

export class UpdateDefect {
	static readonly type = '[Defects] Update Defect';

	constructor(public projectId: string, public defectId: string, public updateDefectDto: UpdateDefectDto) {}
}

export class CloseDefect {
	static readonly type = '[Defects] Close Defect';

	constructor(public projectId: string, public defectId: string) {}
}

export class ReopenDefect {
	static readonly type = '[Defects] Reopen Defect';

	constructor(public projectId: string, public defectId: string) {}
}

export class DeleteDefect {
	static readonly type = '[Defects] Delete Defect';

	constructor(public projectId: string, public defectId: string) {}
}
