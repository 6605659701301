import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'eqa-heading-h4',
	templateUrl: './heading-h4.component.html',
	styleUrls: ['./heading-h4.component.scss'],
})
export class HeadingH4Component implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
