import { Status } from '@features/run/domain/interfaces/test/status';
import { StatusCreate } from '@features/run/domain/interfaces/test/status-create';

export class LoadStatuses {
	static readonly type = '[Status] Get List';

	constructor() {}
}

export class CreateStatus {
	static readonly type = '[Status] Create Status';

	constructor(public status: StatusCreate) {}
}

export class SetStatuses {
	static readonly type = '[Status] Set Statuses';

	constructor(public statuses: Status[]) {}
}

export class UpdateStatus {
	static readonly type = '[Status] Update Status';

	constructor(public status: Status) {}
}

export class RemoveStatus {
	static readonly type = '[Status] Remove Status';

	constructor(public statusId: string) {}
}
