import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AbstractHttpService } from "@core/abstracts";
import { API_URL } from "@core/tokens/api-url.token";
import { TestCaseExecution } from "@features/executions/testcase-execution/domain/interfaces";
import { Test } from "@features/run/domain/interfaces/test/test";
import { TestResultRequestModel } from "@features/run/domain/interfaces/test/test.result.request.model";
import { ToastrService } from "ngx-toastr";
import { from, Observable } from "rxjs";
import { catchError, pluck } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class HttpTestcaseExecutionsService extends AbstractHttpService {
	private testCaseExecutionsUrl = this.baseUrl + '/test';

	constructor(@Inject(API_URL) private baseUrl: string, notifications: ToastrService, private http: HttpClient) {
		super(notifications);
	}

	public getTestById(id: string): Observable<Test> {
		return this.http.get<Test>(this.testCaseExecutionsUrl + '/' + id);
	}

	public getTestStatisticsByProjectId(projectId: string) {
		return this.http.get(this.testCaseExecutionsUrl + '/statistics/' + projectId);
	}

	public createTest(runId: string, testsIds: string[]) {
		let httpParams = new HttpParams();
		httpParams = httpParams.append('deleteExcluded', String(true));
		return this.http.post(this.testCaseExecutionsUrl, { runId, caseIds: testsIds }, { params: httpParams });
	}

	public createTestsForAllTestCases(runId: string, projectId: string) {
		return this.http.post(`${this.testCaseExecutionsUrl}/list`, { runId, projectId });
	}

	public saveTestsResults(resultRequest: TestResultRequestModel) {
		return this.http.post(this.testCaseExecutionsUrl + '/results', resultRequest);
	}

	public assignTests(testRunId, testsIds, assignTo) {
		return this.http.post(this.testCaseExecutionsUrl + '/assign', {
			runId: testRunId,
			testIds: testsIds,
			assignTo,
		});
	}

	public getTodosByProject(projectId) {
		return this.http.get(this.testCaseExecutionsUrl + '/assigned/project/' + projectId);
	}

	public getHistoryByTestCaseId(testCaseId: string) {
		return this.http.get(this.testCaseExecutionsUrl + '/list/case/' + testCaseId);
	}

	public getTestExecutionsByRunId(runId: string): Observable<TestCaseExecution[]> {
		return this.http.get<TestCaseExecution[]>(`${this.testCaseExecutionsUrl}/list/run/${runId}`).pipe(
			pluck('data'),
			catchError((err) => {
				this.handleError(err);
				return from([]);
			}),
		);
	}
}
