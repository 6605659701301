import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {

	Sentry.init({
		dsn: 'https://996dbff22afd4623aef11a646e276d60@o434017.ingest.sentry.io/5793259',
		integrations: [
			new Integrations.BrowserTracing({
				tracingOrigins: ['https://app.everyqa.io'],
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
		],
		tracesSampleRate: 1.0,
	});

	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.log(err));
