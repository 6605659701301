import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BillableFeaturesComponent } from '@features/network/components/billing/billable-features/billable-features.component';
import { Router } from '@angular/router';
import { SubscriptionState } from '@features/network/+state/subscription/subscrtiption.state';
import { Store } from '@ngxs/store';
import { ModalService } from '@shared/ui/components/modal/domain/services/modal/modal.service';

@Directive({
	selector: '[eqaFeatureSubscription]',
})
export class ShowBillableFeaturesDirective {
	@Input('feature') feature: string;
	@Input('maxFeatureCount') maxFeatureCount: number;
	@Output() clickEvent = new EventEmitter();

	constructor(private store: Store, private router: Router, private modalService: ModalService) {}

	private showBillableFeaturesWindow(): void {
		const ref = this.modalService.init(BillableFeaturesComponent, {}, {}, 'center');
		ref.instance.buttonClick.subscribe((value) => {
			if (value) {
				this.router.navigate(['billing']);
			} else {
				this.modalService.destroy();
			}
		});
	}

	@HostListener('click', ['$event, $event.target'])
	onClick(event, targetElement) {
		if (this.feature === 'ANY' || !this.feature) {
			this.clickEvent.emit();
			return;
		}
		event.stopPropagation();
		event.preventDefault();
		event.cancelBuble = true;
		event.stopImmediatePropagation();
		const feat = this.getFeature();
		if (!feat) {
			this.showBillableFeaturesWindow();
			return;
		} else {
			if (feat) {
				if (this.maxFeatureCount >= feat && feat > 0) {
					this.showBillableFeaturesWindow();
					return;
				} else {
					this.clickEvent.emit();
				}
			}
		}
	}

	getFeature() {
		const subscription = this.store.selectSnapshot(SubscriptionState.subscription);
		const features = subscription.features;
		return features[this.feature];
	}
}
