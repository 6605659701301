import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerUrl } from '@core/constants/server-url';
import { Balance } from '@features/network/components/billing/domain/interfaces/balance';
import { RequestInvoiceModel } from '@features/network/components/billing/domain/interfaces/invoice.request';

@Injectable({ providedIn: 'root' })
export class PaymentApiService {
	private baseUrl = ServerUrl.HOME_URL;
	private billingUrl = this.baseUrl + '/billing/payments';

	constructor(private http: HttpClient) {}

	getPayments() {
		return this.http.get(this.billingUrl);
	}

	yandexPayment(amount, currency) {
		return this.http.post(`${this.billingUrl}/yandex`, { amount, currency });
	}

	requestInvoice(invoice) {
		return this.http.post<RequestInvoiceModel>(`${this.billingUrl}/invoice`, invoice);
	}

	getBalance() {
		return this.http.get<Balance>(`${this.billingUrl}/balance`);
	}
}
