import { Section } from "@features/case/case-repository/domain/interfaces/section";
import { SelectedFilter } from "@shared/ui/components/filters/domain/interfaces/selected.filter";

export class GetTree {
	static readonly type = '[Section] Get Sections';

	constructor(public projectId: string) {}
}

export class AddChild {
	static readonly type = '[Section] Append Subsection';

	constructor(public sectionModel: Section) {}
}

export class AddRootItem {
	static readonly type = '[Section] Add Root Item';

	constructor(public sectionModel: Section) {}
}

export class RemoveTreeNode {
	static readonly type = '[Section] Remove';

	constructor(public item: Section) {}
}

export class RemoveTreeNodeList {
	static readonly type = '[Section] Remove Tree Node List';

	constructor() {}
}

export class ChangeSectionParent {
	static readonly type = '[Section] Change Section Parent';

	constructor() {}
}

export class SetActiveItem {
	static readonly type = '[Section] Set';

	constructor(public item: Section) {}
}

export class CheckItem {
	static readonly type = '[Section] Check Item';

	constructor(public item: Section) {}
}

export class ToggleItem {
	static readonly type = '[Section] Toggle Item';

	constructor(public item: Section) {}
}

export class SetMoveTarget {
	static readonly type = '[Section] Set Move Target';

	constructor(public item: Section) {}
}

export class UpdateSelectedItemName {
	static readonly type = '[Section] Update';

	constructor(public item: Section, public newName: string) {}
}

export class ClearManageCasesState {
	static readonly type = '[CLEAR] Clear manage cases team-effort';

	constructor() {}
}

export class FilterTree {
	static readonly type = '[TREE] Filter tree';

	constructor(public filters: SelectedFilter) {}
}

export class ResetFilters {
	static readonly type = '[TREE] Reset Filters';

	constructor() {}
}
