import { CustomFieldType } from "@features/case/case-fields/domain/custom-field.type";
import { FormControlModel } from "@shared/ui/forms/ui/components/dynaminc-fields/form.control.model";

// TODO GOD SAVE US ALL PLS
export class CustomField {
	id: string;
	slug: string;
	name: string;
	type: CustomFieldType;
	defaultValue: any;
	order: number;
	data: any;
	required: boolean;
	value?: { value: any } | any;
	multi?: boolean;

	constructor(data) {
		this.id = data?.id;
		this.slug = data.slug;
		this.name = data.name;
		this.type = data.type;
		this.defaultValue = data.type === CustomFieldType.BOOLEAN ? this.getBool(data.defaultValue) : data.defaultValue;
		this.value = data?.value?.value;
		this.order = data.order;
		this.data = data.data;
		this.multi = data?.multi;
		this.required = data.required;
	}

	toFormControl() {
		const control = this.mapFieldTypeToControlType(this.type);
		return new FormControlModel(control);
	}

	toFiltersControl(cssClass = 'custom-input') {
		const control = this.mapFieldTypeToFiltersControlType(this.type, cssClass);
		return new FormControlModel(control);
	}

	toDefaultField(): CustomField {
		return {
			...this,
			value: { value: this.defaultValue },
		} as CustomField;
	}

	mapFieldTypeToControlType(type: CustomFieldType) {
		switch (type) {
			case CustomFieldType.BOOLEAN:
				return {
					value: this.getBool(this.value !== undefined ? this.value : this.defaultValue),
					key: this.slug,
					label: this.name,
					order: this.order,
					controlType: 'checkbox',
					required: this.required,
				};
			case CustomFieldType.ENUM:
				return {
					value: this.value ? this.value : this.defaultValue,
					key: this.slug,
					label: this.name,
					order: this.order,
					required: this.required,
					controlType: 'select',
					options: this.data.values.map((value) => {
						if (typeof value === 'object') {
							return { id: value.id, name: value.name };
						}
						return { id: value, name: value };
					}),
				};
			case CustomFieldType.STRING:
				return {
					value: this.value ? this.value : this.defaultValue,
					key: this.slug,
					label: this.name,
					required: this.required,
					order: this.order,
					controlType: 'input',
				};
		}
	}

	mapFieldTypeToFiltersControlType(type: CustomFieldType, cssClass) {
		switch (type) {
			case CustomFieldType.BOOLEAN:
				return {
					value: null,
					cssClass: cssClass,
					key: this.slug,
					label: this.name,
					order: this.order,
					controlType: 'select',
					required: this.required,
					multi: false,
					options: [
						{ id: true, name: 'txt_checked' },
						{ id: false, name: 'txt_unchecked' },
						{ id: null, name: 'txt_any' },
					],
				};
			case CustomFieldType.ENUM:
				return {
					cssClass: cssClass,
					value: null,
					key: this.slug,
					label: this.name,
					order: this.order,
					required: this.required,
					multi: true,
					controlType: 'select',
					options: this.data.values.map((value) => {
						if (typeof value === 'object') {
							return { id: value.id, name: value.name };
						}
						return { id: value, name: value };
					}),
				};
			case CustomFieldType.STRING:
				return {
					cssClass: cssClass,
					value: '',
					key: this.slug,
					label: this.name,
					required: this.required,
					order: this.order,
					controlType: 'input',
				};
		}
	}

	getBool(val) {
		return String(val).toLowerCase() === 'true' || val === true;
	}

	getValueType(value) {
		if (typeof value === 'object' && Object.values(value).length > 0) {
			return [{ id: value.id, name: value.name }];
		}
		if (typeof value !== 'object') {
			return [{ id: value, name: value }];
		}
	}
}
