import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalContentDirective } from './modal-content.directive';

@NgModule({
	declarations: [ModalContentDirective],
	exports: [ModalContentDirective],
	imports: [CommonModule],
})
export class ModalContentModule {}
