import { Injectable } from '@angular/core';
import { ServerUrl } from '@core/constants/server-url';
import { HttpClient } from '@angular/common/http';
import { Status } from '@features/run/domain/interfaces/test/status';
import { Store } from '@ngxs/store';
import { ProjectsState } from '@features/projects/+state/project/project.state';
import { StatusCreate } from '@features/run/domain/interfaces/test/status-create';

@Injectable({ providedIn: 'root' })
export class StatusService {
	private baseUrl = ServerUrl.HOME_URL;

	constructor(private http: HttpClient, private store: Store) {}

	get statusesUrl() {
		return `${this.baseUrl}/projects/${this.store.selectSnapshot(ProjectsState.selectedProject).id}/statuses`;
	}

	getStatusesByProjectId() {
		return this.http.get(this.statusesUrl);
	}

	createStatus(status: StatusCreate) {
		return this.http.post(this.statusesUrl, status);
	}

	updateStatus(status: Status) {
		return this.http.put(this.statusesUrl + '/' + status.id, status);
	}

	removeStatus(id: string) {
		return this.http.delete(this.statusesUrl + '/' + id);
	}
}
