import { InjectionToken, ValueProvider } from '@angular/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { EQA_ABSTRACT_HINT_DEFAULT_OPTIONS, EqaAbstractHintOptions } from '@shared/domain/abstracts';

export interface EqaHintOptions extends EqaAbstractHintOptions {
	readonly eqaHintShowDelay: number;
	readonly eqaHintHideDelay: number;
	readonly eqaTooltipIcon: PolymorpheusContent;
}

export const EQA_TOOLTIP_ICON = 'tuiIconTooltipLarge';

export const EQA_HINT_DEFAULT_OPTIONS: EqaHintOptions = {
	...EQA_ABSTRACT_HINT_DEFAULT_OPTIONS,
	eqaHintShowDelay: 25,
	eqaHintHideDelay: 0,
	eqaTooltipIcon: EQA_TOOLTIP_ICON,
};

export const EQA_HINT_OPTIONS = new InjectionToken<EqaHintOptions>('Default parameters for hint directive', {
	factory: () => EQA_HINT_DEFAULT_OPTIONS,
});

export const eqaHintOptionsProvider: (options: Partial<EqaHintOptions>) => ValueProvider = (
	options: Partial<EqaHintOptions>,
) => ({
	provide: EQA_HINT_OPTIONS,
	useValue: { ...EQA_HINT_DEFAULT_OPTIONS, ...options },
});
