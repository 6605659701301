import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	forwardRef,
	Input,
	HostBinding,
	ElementRef,
	Inject,
} from '@angular/core';
import { EQA_FOCUSABLE_ITEM_ACCESSOR } from '@shared/tokens';
import { DestroyService, EqaFocusVisibleService } from '@shared/domain';
import {
	EqaFocusableElementAccessor,
	EqaNativeFocusableElement,
} from '@shared/domain/interfaces/focusable-element-accessor';
import { isNativeFocused } from '@shared/domain/utils/focus';
import { merge } from 'rxjs';
import { typedFromEvent } from '@shared/domain/observables';
import { mapTo } from 'rxjs/operators';

@Component({
	selector: 'a[eqaWebLink]',
	templateUrl: './link.component.html',
	styleUrls: ['./link.style.scss'],
	providers: [
		{
			provide: EQA_FOCUSABLE_ITEM_ACCESSOR,
			useExisting: forwardRef(() => EqaLinkComponent),
		},
		EqaFocusVisibleService,
		DestroyService,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'eqaWebLink',
})
export class EqaLinkComponent implements EqaFocusableElementAccessor {
	@Input()
	@HostBinding('attr.data-host-mode')
	mode: 'positive' | 'negative' | null = 'positive';

	@HostBinding('class._focus-visible')
	focusVisible = false;

	readonly focusedChange = merge(
		typedFromEvent(this.elementRef.nativeElement, 'focusin').pipe(mapTo(true)),
		typedFromEvent(this.elementRef.nativeElement, 'focusout').pipe(mapTo(false)),
	);

	constructor(
		@Inject(ElementRef)
		private readonly elementRef: ElementRef<EqaNativeFocusableElement>,
		@Inject(EqaFocusVisibleService)
		focusVisible$: EqaFocusVisibleService,
	) {
		focusVisible$.subscribe((visible) => {
			this.focusVisible = visible;
		});
	}

	get nativeFocusableElement(): EqaNativeFocusableElement {
		return this.elementRef.nativeElement;
	}

	get focused(): boolean {
		return isNativeFocused(this.nativeFocusableElement);
	}
}
