import { NgModule } from '@angular/core';
import { OverlayComponent } from '@shared/ui/components/modal/ui/components/overlay/overlay.component';
import { ConfirmComponent } from '@shared/ui/components/modal/ui/components/confirm/confirm.component';
import { ModalContentModule } from '@shared/ui/directives/modal-content/modal-content.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@shared/ui';

@NgModule({
	imports: [ModalContentModule, CommonModule, TranslateModule, ButtonModule],
	declarations: [OverlayComponent, ConfirmComponent],
	exports: [OverlayComponent, ConfirmComponent],
	providers: [],
})
export class ModalWindowModule {}
