import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DestroyService extends Observable<void> implements OnDestroy {
	private readonly destroy$ = new Subject();

	constructor() {
		super((subscriber) => this.destroy$.subscribe(subscriber));
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
