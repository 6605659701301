import { HideLoader, ShowLoader } from './eqa-loader.actions';
import { EqaLoaderModel } from './eqa-loader.model';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

@State<EqaLoaderModel>({
	name: 'loaderState',
	defaults: {
		show: false,
	},
})
@Injectable()
export class EqaLoaderState {
	@Selector()
	static loading(state: EqaLoaderModel): boolean {
		return state.show;
	}

	@Action(HideLoader)
	hideLoader({ patchState }: StateContext<EqaLoaderModel>, {}: HideLoader) {
		patchState({
			show: false,
		});
	}

	@Action(ShowLoader)
	showLoader({ patchState }: StateContext<EqaLoaderModel>, {}: HideLoader) {
		patchState({
			show: true,
		});
	}
}
