import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router, RouterLinkWithHref } from '@angular/router';
import { Select } from '@ngxs/store';
import { AccountState } from '@features/user-settings/store/account.state';
import { Observable } from 'rxjs';
import { AccountService } from '@core/services/http/auth/account.service';
import { UserModel } from '@core/interfaces/user/UserModel';
import { UserAvatarModule } from '@shared/ui/components/user-avatar/user-avatar.module';
import { CommonModule } from '@angular/common';
import { ActiveZoneModule } from '@shared/ui/directives/active-zone/active-zone.module';
import { TranslateModule } from '@ngx-translate/core';
import { EqaManualHintModule } from '@shared/ui/directives/manual-hint';
import { EqaLinkModule } from '@shared/ui/elements/link';
import { BillableFeatureModule } from '@shared/ui/directives/billable-feature/billable-feature.module';
import { HasPermissionModule } from '@core/directives/has-permission/has-permission.module';

@Component({
	selector: 'eqa-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		UserAvatarModule,
		ActiveZoneModule,
		CommonModule,
		TranslateModule,
		EqaManualHintModule,
		RouterLinkWithHref,
		EqaLinkModule,
		BillableFeatureModule,
		HasPermissionModule,
	],
})
export class EqaProfileComponent {
	@Input() userId: string;
	@Input() isDemoVersion: boolean;
	@Select(AccountState.userBio) user$: Observable<UserModel>;
	profileOptionsVisible: boolean = false;

	constructor(public accountService: AccountService) {}

	public logout(): void {
		this.accountService.logout();
	}

	public toggle(): void {
		this.profileOptionsVisible = !this.profileOptionsVisible;
	}

	public hide() {
		this.profileOptionsVisible = false;
	}
}
