import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, filter, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { DestroyService, EqaHoveredService } from '@shared/domain';
import { AbstractEqaHint } from '@shared/domain/abstracts';
import { EqaHintService } from '@shared/domain/services/hint-service';
import { typedFromEvent } from '@shared/domain/observables';
import {
	EQA_POINTER_HINT_OPTIONS,
	EqaPointerHintOptions,
} from '@shared/ui/directives/pointer-hint/pointer-hint-options';

@Directive({
	selector: '[eqaPointerHint]:not(ng-container)',
	providers: [DestroyService],
})
export class EqaPointerHintDirective extends AbstractEqaHint {
	private currentMouseRect = this.mousePositionToClientRect();

	@Input()
	tuiHintShowDelay: EqaPointerHintOptions['eqaHintShowDelay'] = this.options.eqaHintShowDelay;

	@Input()
	tuiHintHideDelay: EqaPointerHintOptions['eqaHintHideDelay'] = this.options.eqaHintHideDelay;

	@Input()
	set tuiPointerHint(value: PolymorpheusContent | null) {
		if (!value) {
			this.hideTooltip();
			this.content = '';

			return;
		}

		this.content = value;
	}

	content: PolymorpheusContent = '';

	constructor(
		@Inject(ElementRef) elementRef: ElementRef<HTMLElement>,
		@Inject(EqaHintService) hintService: EqaHintService,
		@Inject(DestroyService)
		private readonly destroy$: DestroyService,
		@Inject(EqaHoveredService) hoveredService: EqaHoveredService,
		@Inject(EQA_POINTER_HINT_OPTIONS)
		protected readonly options: EqaPointerHintOptions,
	) {
		super(elementRef, hintService, null, options);

		const hint$ = hoveredService.createHovered$(this.elementRef.nativeElement).pipe(
			filter(() => !!this.content),
			startWith(false),
			distinctUntilChanged(),
		);

		hint$
			.pipe(
				switchMap((visible) =>
					of(visible).pipe(delay(visible ? this.tuiHintShowDelay : this.tuiHintHideDelay)),
				),
				takeUntil(destroy$),
			)
			.subscribe({
				next: (visible) => {
					if (visible) {
						this.showTooltip();
					} else {
						this.hideTooltip();
					}
				},
				complete: () => {
					this.hideTooltip();
				},
			});

		this.initMouseMoveSubscription();
	}

	getElementClientRect() {
		return this.currentMouseRect;
	}

	private initMouseMoveSubscription() {
		const mouseMove$: Observable<MouseEvent> = typedFromEvent(this.elementRef.nativeElement, 'mousemove');

		mouseMove$.pipe(takeUntil(this.destroy$)).subscribe(({ clientX, clientY }) => {
			this.currentMouseRect = this.mousePositionToClientRect(clientX, clientY);
		});
	}

	private mousePositionToClientRect(x: number = 0, y: number = 0) {
		return {
			left: x,
			right: x,
			top: y,
			bottom: y,
			width: 0,
			height: 0,
		};
	}
}
