import { Section } from '@features/case/case-repository/domain/interfaces/section';
import { PartialResult } from '@features/run/domain/interfaces/test/partial.result';
import { Test } from '@features/run/domain/interfaces/test/test';
import { UserModel } from '@core/interfaces/user/UserModel';
import { FlatTreeActionsEnum } from './flat.tree.actions.enum';
import { Filter } from '@shared/ui/components/filters/domain/interfaces/new.filter';

export class BuildFlatTree {
	static readonly type = FlatTreeActionsEnum.BUILD_TREE;

	constructor(public sections: Section[]) {}
}

export class ActivateSection {
	static readonly type = FlatTreeActionsEnum.ACTIVATE_SECTION;

	constructor(public section: Section) {}
}

export class SetTestsCount {
	static readonly type = FlatTreeActionsEnum.SET_TESTS_COUNT;

	constructor(public tests: Test[]) {}
}

export class SetActiveTestId {
	static readonly type = FlatTreeActionsEnum.SET_ACTIVE_IDS;

	constructor(public testId: string) {}
}

export class SelectSection {
	static readonly type = FlatTreeActionsEnum.SELECT;

	constructor(public node: Section, public checked: boolean) {}
}

export class SelectSectionContentEntity {
	static readonly type = FlatTreeActionsEnum.SELECT_CONTENT;

	constructor(public node: Section, public entity: any, public checked: boolean) {}
}

export class SetStepStatus {
	static readonly type = FlatTreeActionsEnum.SET_STEP_STATUS;

	constructor(public event: any) {}
}

export class ActivateTest {
	static readonly type = FlatTreeActionsEnum.ACTIVATE_TEST;

	constructor(public testId: string) {}
}

/*
export class SetActiveIds {
	static readonly type = FlatTreeActionsEnum.ACTIVATE_TEST;

	constructor(public test: Test) {}
}
*/

export class SetResult {
	static readonly type = FlatTreeActionsEnum.SET_RESULT;

	constructor(public projectId: string, public test: any, public result: PartialResult) {}
}

export class ToggleSection {
	static readonly type = FlatTreeActionsEnum.TOGGLE;

	constructor(public section: Section) {}
}

export class LoadNext {
	static readonly type = FlatTreeActionsEnum.LOAD_NEXT;

	constructor() {}
}

export class SetLoadingStatus {
	static readonly type = FlatTreeActionsEnum.SET_LOADING_STATUS;

	constructor() {}
}

export class LoadPrevious {
	static readonly type = FlatTreeActionsEnum.LOAD_PREVIOUS;

	constructor() {}
}

export class AssignTest {
	static readonly type = FlatTreeActionsEnum.ASSIGN_TEST;

	constructor(public assignee: { test: Test; user: UserModel }) {}
}

export class AssignTests {
	static readonly type = FlatTreeActionsEnum.ASSIGN_TESTS;

	constructor(public assignee: { tests: string[]; user: UserModel; runId: string }) {}
}

export class SetShowed {
	static readonly type = FlatTreeActionsEnum.SET_SHOWED;

	constructor(public showed: boolean) {}
}

export class SetFilters {
	static readonly type = FlatTreeActionsEnum.SET_FILTERS;

	constructor(public filters: Filter<any>, public search: string) {}
}

export class RemoveFilterByKey {
	static readonly type = FlatTreeActionsEnum.REMOVE_FILTER;

	constructor(public filterKey: string) {}
}

export class UpdateSearch {
	static readonly type = FlatTreeActionsEnum.SEARCH_UPDATED;

	constructor(public search: string) {}
}

export class UncheckAll {
	static readonly type = FlatTreeActionsEnum.UNCHECK_ALL;
}
