import { Injectable } from "@angular/core";
import { UserService } from "@core/services/http/user/user.service";
import { IntegrationProviders } from "@features/integrations/domain/constants";
import { Action, createSelector, Selector, State, StateContext } from "@ngxs/store";
import { append, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { AccountStateModel } from "./account-state.model";
import {
	DeleteUserIntegration,
	GetAccountData,
	GetUsersIntegrations,
	LoadAvatar,
	SaveAccountData,
	SetUserIntegration
} from "./account.actions";

@State<AccountStateModel>({
	name: 'accountState',
	defaults: {
		userBio: null,
		avatar: '',
		integrations: [],
		integrationsLoaded: false,
	},
})
@Injectable()
export class AccountState {
	@Selector()
	static avatar(m: AccountStateModel) {
		return m.avatar;
	}

	@Selector()
	static userBio(m: AccountStateModel) {
		return m.userBio;
	}

	@Selector()
	static integrationsLoaded(m: AccountStateModel) {
		return m.integrationsLoaded;
	}

	static integration(provider: IntegrationProviders) {
		return createSelector([AccountState], (m: AccountStateModel) =>
			m.integrations.find((i) => i.provider === provider),
		);
	}

	@Selector()
	static integrations(m: AccountStateModel) {
		return m.integrations;
	}

	constructor(private userService: UserService) {}

	@Action(GetAccountData)
	getAccountData(ctx: StateContext<AccountStateModel>) {
		return this.userService.getUserProfile().pipe(
			tap((account) => {
				ctx.patchState({
					userBio: account,
					avatar: account.avatar,
				});
			}),
		);
	}

	@Action(SaveAccountData)
	saveAccountData(ctx: StateContext<AccountStateModel>, { payload }: SaveAccountData) {
		return this.userService.updateUserProfile(payload).pipe(
			tap((account) => {
				ctx.patchState({
					userBio: account,
				});
			}),
		);
	}

	@Action(LoadAvatar)
	loadAvatar({ patchState }: StateContext<AccountStateModel>, { formData }: LoadAvatar) {
		return this.userService.loadAvatar(formData).pipe(
			tap((user) => {
				patchState({
					userBio: user,
					avatar: user.avatar,
				});
			}),
		);
	}

	@Action(GetUsersIntegrations)
	getUsersIntegrations({ patchState }: StateContext<AccountStateModel>) {
		patchState({ integrationsLoaded: false });
		return this.userService.listIntegrationMembersByUser().pipe(
			tap(
				(integrations) => {
					patchState({ integrations, integrationsLoaded: true });
				},
				() => patchState({ integrationsLoaded: true }),
			),
		);
	}

	@Action(SetUserIntegration)
	setUserIntegration(
		{ setState, getState }: StateContext<AccountStateModel>,
		{ provider, data }: SetUserIntegration,
	) {
		const index = getState().integrations.findIndex((i) => i.provider === provider);
		if (index > -1) {
			setState(
				patch({
					integrations: updateItem(index, { data, provider }),
				}),
			);
		} else {
			setState(
				patch({
					integrations: append([{ data, provider }]),
				}),
			);
		}
		return of(null);
	}

	@Action(DeleteUserIntegration)
	deleteUserIntegration(
		{ setState, getState }: StateContext<AccountStateModel>,
		{ provider }: DeleteUserIntegration,
	) {
		const index = getState().integrations.findIndex((i) => i.provider === provider);
		if (index > -1) {
			setState(
				patch({
					integrations: removeItem(index),
				}),
			);
		}
		return of(null);
	}
}
