import { Injectable } from "@angular/core";
import { Attachment } from "@features/attachments/domain/interfaces/attachment";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { append, patch, removeItem } from "@ngxs/store/operators";
import * as _ from "lodash";
import { cloneDeep, orderBy } from "lodash";
import { forkJoin, of } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { AttachmentsHttpService } from "../services/http/attachments-http.service";
import {
	ClearAttachmentState,
	ClearSelectedAttachments,
	ClearTrashAttachments,
	GetAttachments,
	LoadAttachmentsByNetwork,
	PushAttachments,
	RemoveAttachment,
	RemoveAttachmentsList,
	RemoveTrashAttachments,
	SaveAttachments,
	SelectAttachments,
	SetAttachments,
	SortAttachmentsList,
	TrashAttachment,
	UploadAttachments
} from "./attachments.actions";
import { AttachmentsStateModel } from "./attachments.state.model";

@State<AttachmentsStateModel>({
	name: 'attachmentsState',
	defaults: {
		selectedAttachments: [],
		attachments: [],
		trash: [],
		attachmentsSize: 0,
		totalAttachments: 0,
		loading: false,
		executingOperation: false,
	},
})
@Injectable()
export class AttachmentsState {
	constructor(private attachmentsHttpService: AttachmentsHttpService) { }

	@Selector()
	static attachments(attachmentsStateModel: AttachmentsStateModel): Array<Attachment> {
		return attachmentsStateModel.attachments;
	}

	@Selector()
	static attachmentsSize(attachmentsStateModel: AttachmentsStateModel): number {
		return attachmentsStateModel.attachmentsSize;
	}

	@Selector()
	static selectedAttachments(attachmentsStateModel: AttachmentsStateModel): Array<Attachment> {
		return attachmentsStateModel.selectedAttachments;
	}

	@Selector()
	static loadingAttachments(attachmentsStateModel: AttachmentsStateModel): boolean {
		return attachmentsStateModel.loading;
	}

	@Action(GetAttachments)
	getAttachments({ getState, patchState }: StateContext<AttachmentsStateModel>, { entityId }: GetAttachments) {
		const state = getState();
		patchState({
			loading: true,
		});
		if (entityId) {
			return this.attachmentsHttpService.loadAttachmentsByEntityId(entityId).pipe(
				tap((att: Attachment[]) => {
					const total = att.length;
					let currentAttachments = _.cloneDeep(state.attachments);
					if (att.length) {
						currentAttachments = this.removeDuplicatesBy(att, 'id');
					}
					patchState({
						loading: false,
						attachments: currentAttachments,
						totalAttachments: total,
						attachmentsSize: 0,
					});
				}),
			);
		} else {
			patchState({
				attachments: [],
				loading: false,
			});
		}
	}

	@Action(PushAttachments)
	pushAttachments({ getState, patchState }: StateContext<AttachmentsStateModel>, { attachments }: PushAttachments) {
		const selectedAttachments = _.cloneDeep(getState().selectedAttachments);
		selectedAttachments.push(...attachments);
		patchState({
			selectedAttachments,
			totalAttachments: selectedAttachments.length,
			attachmentsSize: 0,
			loading: false,
		});
	}

	@Action(SetAttachments)
	setAttachments({ patchState }: StateContext<AttachmentsStateModel>, { attachments }: SetAttachments) {
		patchState({
			attachments,
			loading: false,
		});
	}

	@Action(LoadAttachmentsByNetwork)
	loadAttachmentsByNetwork({ patchState }: StateContext<AttachmentsStateModel>, { }: LoadAttachmentsByNetwork) {
		patchState({
			loading: true,
		});
		return this.attachmentsHttpService.loadAttachmentsByNetwork().pipe(
			tap((response: { data: Array<Attachment>; meta: { total: number; size: number } }) => {
				patchState({
					attachments: response.data,
					totalAttachments: response.meta.total,
					attachmentsSize: response.meta.size,
					selectedAttachments: [],
					loading: false,
				});
			}),
		);
	}

	// save and assign
	@Action(SaveAttachments)
	saveAttachments({ getState, patchState }: StateContext<AttachmentsStateModel>, { entityIds, type }: SaveAttachments) {
		const state = getState();
		let selectedAttachments = _.cloneDeep(state.selectedAttachments);
		let currentAttachments = _.cloneDeep(state.attachments);

		const attachmentsToUpload = [];

		for (let index = 0; index < selectedAttachments.length; index++) {
			const a = selectedAttachments[index];
			if (a.fileData) {
				attachmentsToUpload.push(a);
				selectedAttachments.splice(index, 1);
				index--;
			}
		}
		if (attachmentsToUpload.length) {
			return this.uploadFiles(attachmentsToUpload).pipe(
				switchMap((attachments) => {
					currentAttachments.push(...attachments);
					return this.attachmentsHttpService.assignAttachment(attachments, entityIds, type);
				}),
				tap((response) => {
					patchState({
						selectedAttachments: [],
						attachments: currentAttachments,
						totalAttachments: selectedAttachments.length,
						attachmentsSize: 0,
						loading: false,
					});
				}),
			);
		}
	}

	// only upload
	@Action(UploadAttachments)
	uploadAttachments({ getState, patchState }: StateContext<AttachmentsStateModel>, { }: UploadAttachments) {
		const state = getState();
		let currentAttachments = _.cloneDeep(state.selectedAttachments);
		return this.uploadFiles(currentAttachments).pipe(
			tap((response) => {
				patchState({
					attachments: response,
					totalAttachments: currentAttachments.length,
					attachmentsSize: 0,
					loading: false,
				});
			}),
		);
	}

	@Action(RemoveTrashAttachments)
	removeStepAttachments({ getState }: StateContext<AttachmentsStateModel>) {
		const ids = getState().trash?.map((a) => a.id);
		if (!ids?.length) {
			return of(null);
		}
		const obss = ids.map((id) => this.attachmentsHttpService.removeAttachment(id));
		return forkJoin(obss).pipe(catchError(() => of(null)));
	}

	@Action(RemoveAttachmentsList)
	removeAttachmentsList(
		{ getState, patchState }: StateContext<AttachmentsStateModel>,
		{ attachments }: RemoveAttachmentsList,
	) {
		patchState({
			executingOperation: true,
		});
		const attachmentsForDelete = attachments ? attachments : getState().selectedAttachments;
		const ids = attachmentsForDelete.map(({ id }) => id);
		return this.attachmentsHttpService.removeAttachmentsList(ids).pipe(
			tap(
				() => {
					const newAttachmentsList = cloneDeep(getState().attachments).filter(({ id }) => !new Set(ids).has(id));
					const newSelectedList = attachments
						? cloneDeep(getState().selectedAttachments).filter(({ id }) => !new Set(ids).has(id))
						: [];
					const size = newAttachmentsList.reduce((acc, e) => (acc += e.size), 0);

					patchState({
						executingOperation: false,
						attachments: newAttachmentsList,
						attachmentsSize: size,
						totalAttachments: newAttachmentsList.length,
						selectedAttachments: newSelectedList,
					});
				},
				() => {
					patchState({
						executingOperation: false,
					});
				},
			),
		);
	}

	@Action(ClearTrashAttachments)
	clearTrash({ patchState }: StateContext<AttachmentsStateModel>) {
		patchState({
			trash: [],
		});
	}

	@Action(RemoveAttachment)
	removeAttachment(
		{ getState, patchState, setState }: StateContext<AttachmentsStateModel>,
		{ attachment, index }: RemoveAttachment,
	) {
		const state = getState();
		let attachments = _.cloneDeep(state.attachments);
		let selectedAttachments = _.cloneDeep(state.selectedAttachments);
		if (attachment.id) {
			setState(
				patch({
					trash: append([attachment]),
					attachments: removeItem<Readonly<Attachment>>((a) => a.id === attachment.id),
				}),
			);
		} else {
			attachments = attachments.filter((_, idx) => idx !== index);
			selectedAttachments = selectedAttachments.filter((_, idx) => idx !== index);
			patchState({
				attachments,
				selectedAttachments,
				totalAttachments: attachments.length,
				attachmentsSize: 0,
				loading: false,
			});
		}
	}

	@Action(SelectAttachments)
	selectAttachments(
		{ getState, patchState }: StateContext<AttachmentsStateModel>,
		{ attachments, selected }: SelectAttachments,
	) {
		if (!attachments) {
			patchState({
				selectedAttachments: selected ? cloneDeep(getState().attachments) : [],
			});
			return;
		}
		const selectedAttachments = cloneDeep(getState().selectedAttachments);
		if (selected) {
			selectedAttachments.push(...attachments);
			patchState({
				selectedAttachments: Array.from(new Set(selectedAttachments)),
			});
		} else {
			const attachmentsSet = new Set(attachments.map(({ id }) => id));
			patchState({
				selectedAttachments: selectedAttachments.filter(({ id }) => !attachmentsSet.has(id)),
			});
		}
	}

	@Action(TrashAttachment)
	trashAttachment({ getState, patchState }: StateContext<AttachmentsStateModel>, { attachment }: TrashAttachment) {
		const state = getState();
		let attachments = _.cloneDeep(state.attachments);
		let selectedAttachments = _.cloneDeep(state.selectedAttachments);
		let trash = _.cloneDeep(state.trash);
		if (attachment.id) {
			const index = attachments.findIndex((a) => a.id === attachment.id);
			if (index !== -1) {
				trash.push(attachments.splice(index, 1)[0]);
			}
			patchState({
				attachments,
				trash,
				totalAttachments: attachments.length,
				attachmentsSize: 0,
				loading: false,
			});
		} else {
			selectedAttachments = selectedAttachments.filter((a) => {
				return a.link !== attachment.link;
			});
			patchState({
				selectedAttachments,
				totalAttachments: attachments.length,
				attachmentsSize: 0,
				loading: false,
			});
		}
	}

	@Action(ClearAttachmentState)
	clearAttachmentState({ patchState }: StateContext<AttachmentsStateModel>, { }: ClearAttachmentState) {
		patchState({
			attachments: [],
			selectedAttachments: [],
			trash: [],
			totalAttachments: 0,
			attachmentsSize: 0,
			loading: false,
		});
	}

	@Action(SortAttachmentsList)
	sortAttachmentsList({ getState, patchState }: StateContext<AttachmentsStateModel>, { sort }: SortAttachmentsList) {
		const attachments = cloneDeep(getState().attachments);
		let sortedAttachments = [];
		switch (sort.field) {
			case 'createdAt':
				sortedAttachments = orderBy(attachments, [(obj) => new Date(obj[sort.field])], [sort.name]);
				break;
			case 'name':
				sortedAttachments = orderBy(attachments, [(obj) => obj[sort.field]], [sort.name]);
				break;
			case 'size':
				sortedAttachments = orderBy(attachments, [(obj) => obj[sort.field]], [sort.name]);
				break;
			default:
				sortedAttachments = orderBy(attachments, [(obj) => obj['name']], ['asc']);
				break;
		}
		patchState({
			attachments: sortedAttachments,
		});
	}

	@Action(ClearSelectedAttachments)
	clearSelectedAttachments({ patchState }: StateContext<AttachmentsStateModel>, { }: ClearSelectedAttachments) {
		patchState({
			selectedAttachments: [],
		});
	}

	uploadFiles(files) {
		const formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append('attachments[]', files[i].fileData, files[i].fileData['name']);
		}
		return this.attachmentsHttpService.createAttachment(formData);
	}

	removeDuplicatesBy(arr: any[], key: string) {
		const values = {};
		return arr.filter((item) => {
			const val = item[key];
			const exists = values[val];
			values[val] = true;
			return !exists;
		});
	}
}
