import { AnimationOptions } from '@angular/animations';
import { inject, InjectionToken } from '@angular/core';

import { EQA_ANIMATIONS_DURATION } from './animations-duration';

export const EQA_ANIMATION_OPTIONS = new InjectionToken<AnimationOptions>('Options for animations', {
	factory: () => ({
		params: {
			duration: inject(EQA_ANIMATIONS_DURATION),
		},
	}),
});
