import { ChatIntegrationProviders } from "./chats";
import { CiIntegrationProviders } from "./ci";
import { TrackerIntegrationProviders } from "./trackers";

export const IntegrationProviders = {
	...TrackerIntegrationProviders,
	...CiIntegrationProviders,
	...ChatIntegrationProviders,
};
export type IntegrationProviders = keyof typeof IntegrationProviders;
