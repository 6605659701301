import { ChangeDetectionStrategy, Component, HostListener, Inject, ViewEncapsulation } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { EqaDialog } from '@shared/types/dialog';
import { eqaSlideInTop } from '@shared/domain/animations';

@Component({
	selector: 'eqa-preview-dialog',
	templateUrl: './preview-dialog.template.html',
	styleUrls: ['./preview-dialog.style.scss'],
	animations: [eqaSlideInTop],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EqaPreviewDialogComponent {
	constructor(
		@Inject(POLYMORPHEUS_CONTEXT)
		readonly context: EqaDialog<void, void>,
	) {}

	@HostListener('document:keydown.esc')
	onKeyDownEsc() {
		this.context.$implicit.complete();
	}
}
