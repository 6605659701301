import { Directive, HostBinding, Inject, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { EQA_DESCRIBED_BY_PROVIDERS, EQA_DESCRIBED_BY_SHOW } from './described-by.providers';
import { EqaHintService } from '@shared/domain/services/hint-service';

export const DESCRIBED_BY = '_described-by';

@Directive({
	selector: '[eqaDescribedBy]:not(ng-container)',
	providers: EQA_DESCRIBED_BY_PROVIDERS,
})
export class EqaDescribedByDirective {
	@Input()
	eqaDescribedBy = '';

	constructor(
		@Inject(EqaHintService) hintService: EqaHintService,
		@Inject(EQA_DESCRIBED_BY_SHOW) visibility$: Observable<boolean>,
	) {
		visibility$.subscribe((visible) => {
			if (!this.eqaDescribedBy) {
				return;
			}

			if (visible) {
				hintService.showHintForId(this.eqaDescribedBy);
			} else {
				hintService.hideHintForId(this.eqaDescribedBy);
			}
		});
	}

	@HostBinding('attr.aria-describedby')
	get computedDescribedBy(): string | null {
		return this.eqaDescribedBy ? this.eqaDescribedBy + DESCRIBED_BY : null;
	}
}
