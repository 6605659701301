import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from '@shared/ui/components/breadcrumbs/breadcrumbs.component';
import { ContentModule } from '@layout/ui/components/content';
import { LoadingComponent } from '@shared/ui/components/loading-screen/loading/loading.component';
import { RouterOutlet } from '@angular/router';
import { SidenavModule } from '@layout/ui/components/sidenav/sidenav.module';
import { EqaTopPanelComponent } from '@shared/ui/components/top-panel/top-panel.component';
import { SidenavContextService } from '@shared/domain/services/sidenav-context.service';
import { Observable } from 'rxjs';
import { Context } from '@layout/domain/store/sidenav/context';

@Component({
	selector: 'eqa-main',
	standalone: true,
	imports: [CommonModule, BreadcrumbsComponent, ContentModule, LoadingComponent, RouterOutlet, SidenavModule, EqaTopPanelComponent],
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent {
	public navContext$: Observable<Context> = this.sideNavContext.sidenavContext;
	constructor(private sideNavContext: SidenavContextService) {}
}
