import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { CreateReportDto } from "@features/project-reports/domain/models/create-report.dto";
import { Report } from "@features/project-reports/domain/models/report";
import { pluck } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ReportsApiService {
	private baseUrl = ServerUrl.HOME_URL;

	constructor(private http: HttpClient) {}

	private reportsUrl(id: string) {
		return `${this.baseUrl}/projects/${id}/reports`;
	};

	getDefectsStatistics(projectId: string) {
		return this.http.get(`${this.reportsUrl(projectId)}/defects/statistics`);
	}

	getDefectsMatrix(projectId: string) {
		return this.http.get(`${this.reportsUrl(projectId)}/defects/matrix`);
	}

	getActivities(projectId: string) {
		return this.http.get(`${this.reportsUrl(projectId)}/activities`);
	}

	getResultsMatrix(projectId: string) {
		return this.http.get(`${this.reportsUrl(projectId)}/results/matrix`);
	}

	getAll(projectId: string) {
		return this.http.get(`${this.reportsUrl(projectId)}`)
			.pipe<Report[]>(pluck('data'));
	}

	getOne(projectId: string, id: string) {
		return this.http.get(`${this.reportsUrl(projectId)}/${id}`)
			.pipe<Report>(pluck('data'));
	}

	create(dto: CreateReportDto) {
		return this.http.post(`${this.reportsUrl(dto.projectId)}`, dto)
			.pipe<Report>(pluck('data'));
	}

	delete(projectId: string, id: string) {
		return this.http.delete(`${this.reportsUrl(projectId)}/${id}`);
	}
}
