import { DefectModel } from "@features/defects/domain/interfaces/defect.model";
import { ProjectModel } from "src/app/features/projects/interfaces";
import { IntegrationProviders, TrackerIntegrationProviders } from "../constants";
import { CompleteOauthParams, IntegrationUserCredentials, MapProjectDto, UpsertIntegrationDto } from "../types";

export namespace Integrations {
	export class Summary {
		static readonly type = '[Integrations State] Summary';
	}

	export class GetOne {
		static readonly type = '[Integrations State] Get One';

		constructor(public provider: IntegrationProviders) {}
	}

	export class Upsert {
		static readonly type = '[Integrations State] Upsert';

		constructor(public provider: IntegrationProviders, public dto: UpsertIntegrationDto) {}
	}

	export class Delete {
		static readonly type = '[Integrations State] Delete';

		constructor(public provider: IntegrationProviders) {}
	}

	export class Enable {
		static readonly type = '[Integrations State] Enable';

		constructor(public provider: IntegrationProviders) {}
	}

	export class Disable {
		static readonly type = '[Integrations State] Disable';

		constructor(public providers: IntegrationProviders[]) {}
	}

	export class GetForeignProjects {
		static readonly type = '[Integrations State] Get Foreign Projects';

		constructor(public provider: IntegrationProviders) {}
	}

	export class GetMappedProjects {
		static readonly type = '[Integrations State] Get Mapped Projects';

		constructor(public provider: IntegrationProviders) {}
	}

	export class GetMappedProjectById {
		static readonly type = '[Integrations State] Get Mapped Project By Id';

		constructor(public provider: IntegrationProviders, public projectId: string) {}
	}

	export class MapProject {
		static readonly type = '[Integrations State] Map Projects';

		constructor(public provider: IntegrationProviders, public project: ProjectModel, public dto: MapProjectDto) {}
	}

	export class UnmapProject {
		static readonly type = '[Integrations State] Unmap Projects';

		constructor(public provider: IntegrationProviders, public projectId: string) {}
	}

	export class MapUser {
		static readonly type = '[Integrations State] Map User';

		constructor(public provider: IntegrationProviders, public dto: IntegrationUserCredentials) {}
	}

	export class UnmapUser {
		static readonly type = '[Integrations State] Unmap User';

		constructor(public provider: IntegrationProviders) {}
	}

	export class CompleteOAuth {
		static readonly type = '[Integrations State] Complete OAuth';

		constructor(public params: CompleteOauthParams) {}
	}

	export class StoreDefect {
		static readonly type = '[Integrations State] Store Defect';

		constructor(public provider: IntegrationProviders, public value: any) {}
	}

	export class GetPriorities {
		static readonly type = '[Integrations State] Get Priorities';

		constructor(public provider: TrackerIntegrationProviders, public projectId: string) {}
	}

	export class GetUsers {
		static readonly type = '[Integrations State] Get Users';

		constructor(public provider: TrackerIntegrationProviders, public projectId: string) {}
	}

	export class AddWebHook {
		static readonly type = '[Integrations State] Add Webhook';

		constructor(public provider: TrackerIntegrationProviders, public projectId: string) {}
	}

	export class DeleteWebHook {
		static readonly type = '[Integrations State] Delete Webhook';

		constructor(public provider: TrackerIntegrationProviders, public projectId: string) {}
	}

	export class CreateIssue {
		static readonly type = '[Integrations State] Create Defect';

		constructor(
			public provider: TrackerIntegrationProviders,
			public projectId: string,
			public defect: DefectModel,
			public formValue: any,
		) {}
	}

	export class ClearDefectFormValues {
		static readonly type = '[Integrations State] Clear Defect Form Values';
	}
}
