<eqa-wrapper
	class="eqa-wrapper"
	[appearance]="appearance"
	[hovered]="computedHovered"
	[pressed]="computedPressed"
	[disabled]="computedDisabled"
	[focused]="computedFocusVisible"
>
	<span class="eqa-content">
		<span *ngIf="iconLeft" class="eqa-left">
			<ng-container *polymorpheusOutlet="iconLeft">
				<eqa-svg class="eqa-icon" [src]="iconLeft"></eqa-svg>
			</ng-container>
		</span>
		<span *ngIf="icon">
			<ng-container *polymorpheusOutlet="icon">
				<eqa-svg class="eqa-icon" [src]="icon"></eqa-svg>
			</ng-container>
		</span>
		<ng-content></ng-content>
		<span *ngIf="iconRight" class="eqa-right">
			<ng-container *polymorpheusOutlet="iconRight">
				<eqa-svg class="eqa-icon" [src]="iconRight"></eqa-svg>
			</ng-container>
		</span>
	</span>
	<eqa-loader *ngIf="showLoader" class="eqa-loader" [inheritColor]="true"></eqa-loader>
</eqa-wrapper>
