import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalFirstPipe } from '@shared/ui/pipes/string-pipes/capital.first.pipe';
import { FirstLetterPipe } from '@shared/ui/pipes/string-pipes/first-letter.pipe';
import { FormatEstimatePipe } from '@shared/ui/pipes/string-pipes/format-estimate.pipe';
import { PricePipe } from '@shared/ui/pipes/string-pipes/price.pipe';
import { TruncatePipe } from '@shared/ui/pipes/string-pipes/trim.string.pipe';



@NgModule({
  declarations: [CapitalFirstPipe, FirstLetterPipe, FormatEstimatePipe, PricePipe, TruncatePipe],
  exports: [CapitalFirstPipe, FirstLetterPipe, FormatEstimatePipe, PricePipe, TruncatePipe],
  imports: [
    CommonModule
  ]
})
export class StringPipesModule { }
