import { AppLocale } from '@core/interfaces/localisation';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage/local.storage.service';

@Injectable({ providedIn: 'root' })
export class LocalisationService {
	private langs = [
		{
			code: 'en-Us',
			shortCode: 'en',
			languageName: 'English',
		},
		{
			code: 'ru-Ru',
			shortCode: 'ru',
			languageName: 'Русский',
		},
	];

	constructor(private localStorageService: LocalStorageService) {}

	getLocales(): AppLocale[] {
		return this.langs;
	}

	getLocaleCodes(): string[] {
		return this.langs.map((language) => language.code);
	}

	setLocale(locale: string) {
		const langObject = this.langs.find((x) => x.code === locale);
		this.localStorageService.set('locale', JSON.stringify(langObject));
		return langObject;
	}

	getCurrentLocale(): AppLocale {
		// try to get from storage
		try {
			return this.localStorageService.getParsedByKey('locale');
		} catch (e) {
			return null;
		}
	}

	getDefaultLocale(): AppLocale {
		const locale =
			navigator.language === 'ru'
				? {
						code: 'ru-Ru',
						shortCode: 'ru',
						languageName: 'Русский',
				  }
				: {
						code: 'en-Us',
						shortCode: 'en',
						languageName: 'English',
				  };

		return locale;
	}
}
