import { NgModule } from '@angular/core';
import { HasPermissionDirective } from '@core/directives/has-permission/has-permission.directive';


@NgModule({
	imports: [],
	exports: [HasPermissionDirective],
	declarations: [HasPermissionDirective],
	providers: [],
})
export class HasPermissionModule {
}
