import { ProjectConfig } from 'src/app/features/projects/interfaces';
import { RunProgressStats } from '@features/run/domain/interfaces/run.progress.stats';
import { Utils } from '@core/utils/utils';
import { ProgressTypes } from '../constants/progress.types';
import { RunStatus } from '../constants/runs.status';
import { TestrunModel } from '../interfaces/testrun.model';
import { RunsApiService } from '../services/api/runs-api.service';
import { RunsStateModel } from './runs-state.model';
import {
	ClearShareLink,
	ClearState,
	CloseRun,
	CreateTestRun,
	DeleteRun,
	GetRunListByProject,
	GetRunListBySprint,
	GetRunsConfig,
	GetSharedRun,
	GetSharedTestRunProgress,
	GetTestRun,
	GetTestRunProgress,
	RemoveTestRun,
	SelectRun,
	SetActiveSprint,
	ShareRun,
	UpdateRun,
} from './runs.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as _ from 'lodash';
import { fromPairs, groupBy as lGroupBy, sortBy, toPairs } from 'lodash';
import { EMPTY, throwError } from 'rxjs';
import { catchError, pluck, tap } from 'rxjs/operators';
import {PieStats} from "@shared/ui/components/charts/interfaces/pie.stats";

@State<RunsStateModel>({
	name: 'runState',
	defaults: {
		activeSprint: null,
		testRun: null,
		projectId: null,
		shareLink: '',
		flatRunsList: [],
		runProgress: null,
		runProgressStats: null,
		runList: {
			activeRuns: [],
			closedRuns: {},
			completedRunsCount: 0,
		},
		totalRunsCount: 0,
		loading: false,
		saving: false,
		testRunStatistics: null,
		selectedRun: null,
		progressLoading: true,
		testRunConfig: null,
		runIsNotFound: false,
	},
})
@Injectable()
export class RunState {
	@Selector()
	static totalRunsCount(testRunStateModel: RunsStateModel): number {
		return testRunStateModel.totalRunsCount;
	}

	@Selector()
	static progressLoading(testRunStateModel: RunsStateModel): boolean {
		return testRunStateModel.progressLoading;
	}

	@Selector()
	static selectedRun(testRunStateModel: RunsStateModel): TestrunModel {
		return testRunStateModel.selectedRun;
	}

	@Selector()
	static runsConfig(testRunStateModel: RunsStateModel): ProjectConfig {
		return testRunStateModel.testRunConfig;
	}

	@Selector()
	static stats(testRunStateModel: RunsStateModel): PieStats {
		return testRunStateModel.testRunStatistics;
	}

	@Selector()
	static progressStats(testRunStateModel: RunsStateModel): RunProgressStats {
		return testRunStateModel.runProgressStats;
	}

	@Selector()
	static saving(testRunStateModel: RunsStateModel): boolean {
		return testRunStateModel.saving;
	}

	@Selector()
	static currentProjectId(testRunStateModel: RunsStateModel): string {
		return testRunStateModel.projectId;
	}

	@Selector()
	static testRun(testRunStateModel: RunsStateModel): TestrunModel {
		return testRunStateModel.testRun;
	}

	@Selector()
	static shareLink(testRunStateModel: RunsStateModel): string {
		return testRunStateModel.shareLink;
	}

	@Selector()
	static runList(testRunStateModel: RunsStateModel): Object {
		return testRunStateModel.runList;
	}

	@Selector()
	static flatRunsList(testRunStateModel: RunsStateModel): TestrunModel[] {
		return testRunStateModel.flatRunsList;
	}

	@Selector()
	static shortActiveRunsList(testRunStateModel: RunsStateModel): TestrunModel[] {
		return testRunStateModel.runList?.activeRuns?.slice(0, 3) || [];
	}

	@Selector()
	static activeRuns(testRunStateModel: RunsStateModel): TestrunModel[] {
		return testRunStateModel.runList?.activeRuns || [];
	}

	@Selector()
	static loading(testRunStateModel: RunsStateModel): boolean {
		return testRunStateModel.loading;
	}

	@Selector()
	static runIsNotFound(testRunStateModel: RunsStateModel): boolean {
		return testRunStateModel.runIsNotFound;
	}

	@Selector()
	static runProgress(testRunStateModel: RunsStateModel): {} {
		return testRunStateModel.runProgress;
	}

	constructor(private testRunApiService: RunsApiService, private utils: Utils) {}

	@Action(GetTestRun)
	getTestRun({ patchState }: StateContext<RunsStateModel>, { id }: GetTestRun) {
		patchState({ loading: true, runIsNotFound: false });
		return this.testRunApiService.getTestRunById(id).pipe(
			pluck('data'),
			tap((runResponse: TestrunModel) => {
				patchState({
					projectId: runResponse.projectId,
					testRun: runResponse,
					testRunConfig: runResponse.config,
					testRunStatistics: this.calculateRunStats([runResponse]),
					loading: false,
					runIsNotFound: false,
				});
			}),
			catchError((e: HttpErrorResponse) => {
				if (e.status === 404) {
					patchState({ runIsNotFound: true });
					return EMPTY;
				}
				return throwError(e);
			}),
		);
	}

	@Action(GetRunsConfig)
	getRunsConfig({ patchState }: StateContext<RunsStateModel>, { runId }: GetRunsConfig) {
		patchState({ loading: true });
		return this.testRunApiService.getRunsConfig(runId).pipe(
			tap((testRunConfig: ProjectConfig) => {
				patchState({ testRunConfig, loading: false });
			}),
		);
	}

	@Action(GetTestRunProgress)
	getTestRunProgress({ getState, patchState }: StateContext<RunsStateModel>, { id, groupBy }: GetTestRunProgress) {
		patchState({ progressLoading: true });
		return this.testRunApiService.getRunProgress(id, groupBy).pipe(
			pluck('data'),
			tap((runProgress: any[]) => {
				const { progress, stats } = this.parseRunProgress(runProgress, groupBy, getState().testRun);
				patchState({
					runProgress: progress,
					runProgressStats: stats,
					progressLoading: false,
				});
			}),
		);
	}

	@Action(GetSharedTestRunProgress)
	getSharedTestRunProgress(
		{ getState, patchState }: StateContext<RunsStateModel>,
		{ id, groupBy, token }: GetSharedTestRunProgress,
	) {
		patchState({ progressLoading: true });
		return this.testRunApiService.getRunProgress(id, groupBy, token).pipe(
			pluck('data'),
			tap((runProgress: any[]) => {
				const { progress, stats } = this.parseRunProgress(runProgress, groupBy, getState().testRun);
				patchState({
					runProgress: progress,
					runProgressStats: stats,
					progressLoading: false,
				});
			}),
		);
	}

	@Action(SetActiveSprint)
	setActiveSprint({ patchState }: StateContext<RunsStateModel>, { sprint }: SetActiveSprint) {
		patchState({ activeSprint: sprint });
	}

	@Action(RemoveTestRun)
	removeTestRun({ getState, setState }: StateContext<RunsStateModel>, { runId }: RemoveTestRun) {
		return this.testRunApiService.deleteTestRun(runId).pipe(
			tap((res) => {
				setState(patch<RunsStateModel>({ testRun: null }));
			}),
		);
	}

	@Action(CloseRun)
	closeRun(ctx: StateContext<RunsStateModel>) {
		const run = _.cloneDeep(ctx.getState().testRun);
		run.status = RunStatus.CLOSED;
		run.completedAt = new Date();
		run.assigned = run.assigned ? run.assigned.id : null;
		return this.updateRun(ctx, { run });
	}

	@Action(GetRunListByProject)
	getRunListByProject(
		{ getState, patchState }: StateContext<RunsStateModel>,
		{ projectId, params }: GetRunListByProject,
	) {
		patchState({
			loading: true,
		});
		return this.testRunApiService.getTestRunList(projectId, params).pipe(
			pluck('data'),
			tap((testRunList: TestrunModel[]) => {
				const testRunsCount = testRunList.length;
				const runs = this.groupRunsByStatus(testRunList);
				const stats = this.calculateRunStats(runs.activeRuns);
				testRunList.forEach((run) => {
					run.projectId = projectId;
					this.calculateRunStatistic(run)
				});
				patchState({
					totalRunsCount: testRunsCount,
					flatRunsList: testRunList,
					testRunStatistics: stats,
					runList: runs,
					loading: false,
				});
			}),
		);
	}

	@Action(GetRunListBySprint)
	getRunListBySprint({ getState, patchState }: StateContext<RunsStateModel>, { sprintId }: GetRunListBySprint) {
		patchState({
			loading: true,
		});
		return this.testRunApiService.getTestRunBysprintId(sprintId).pipe(
			pluck('data'),
			tap((testRunList: TestrunModel[]) => {
				const testRunsCount = testRunList.length;
				const runs = this.groupRunsByStatus(testRunList);
				const stats = this.calculateRunStats(testRunList);
				testRunList.forEach((run) => this.calculateRunStatistic(run));
				patchState({
					totalRunsCount: testRunsCount,
					testRunStatistics: stats,
					flatRunsList: testRunList,
					runList: runs,
					loading: false,
				});
			}),
		);
	}

	@Action(CreateTestRun)
	createTestRun({ getState, patchState }: StateContext<RunsStateModel>, { run }: CreateTestRun) {
		return this.testRunApiService.createTestRun(run).pipe(
			pluck('data'),
			tap((createdRun: TestrunModel) => {
				const runList = _.cloneDeep(getState().flatRunsList);
				const stats = getState().testRunStatistics;
				createdRun.calculatedStats = {};
				createdRun.tests = [];
				createdRun.stats = {};
				if (stats) {
					Object.keys(stats).forEach((s) => {
						const st = stats[s];
						createdRun.stats[s] = {
							status: {
								name: s,
								color: st.color,
							},
							count: 0,
						};
					});
				}
				runList.unshift(createdRun);
				const groupedList = this.groupRunsByStatus(runList);
				patchState({
					totalRunsCount: runList.length,
					flatRunsList: runList,
					runList: groupedList,
					loading: false,
				});
			}),
		);
	}

	@Action(UpdateRun)
	updateRun({ getState, patchState }: StateContext<RunsStateModel>, { run }: UpdateRun) {
		const runToUpdate = _.cloneDeep(run);
		const sprint = getState().activeSprint;
		runToUpdate.assigned = runToUpdate.assigned ?? null;
		if (!runToUpdate.sprintId) {
			runToUpdate.sprintId = typeof runToUpdate.sprint === 'string' ? runToUpdate.sprint : runToUpdate.sprint.id;
		}
		return this.testRunApiService.updateTestRun(runToUpdate, run.id).pipe(
			pluck('data'),
			tap((updatedRun: TestrunModel) => {
				const flatRunsList = _.cloneDeep(getState().flatRunsList);
				const runIndex = flatRunsList.findIndex((r) => r.id === updatedRun.id);
				flatRunsList.splice(runIndex, 1, updatedRun);
				const filteredList = !sprint
					? flatRunsList
					: flatRunsList.filter((r) =>
						typeof r.sprint === 'string' ? r.sprint === sprint.id : r.sprint.id === sprint.id,
					);
				const groupedList = this.groupRunsByStatus(filteredList);
				const stats = this.calculateRunStats(filteredList);
				patchState({
					testRunStatistics: stats,
					flatRunsList: filteredList,
					runList: groupedList,
					testRun: updatedRun,
					loading: false,
				});
			}),
		);
	}

	@Action(DeleteRun)
	deleteRun({ getState, patchState }: StateContext<RunsStateModel>, { run }: DeleteRun) {
		return this.testRunApiService.deleteTestRun(run.id).pipe(
			tap(() => {
				const flatRunList = getState().flatRunsList.filter((r) => r.id !== run.id);
				const groupedList = this.groupRunsByStatus(flatRunList);
				patchState({
					totalRunsCount: flatRunList.length,
					flatRunsList: flatRunList,
					runList: groupedList,
					loading: false,
				});
			}),
		);
	}

	@Action(SelectRun)
	selectRun({ getState, patchState }: StateContext<RunsStateModel>, { run }: SelectRun) {
		patchState({ selectedRun: run });
	}

	@Action(ClearState)
	clearState({ getState, patchState }: StateContext<RunsStateModel>, {}: ClearState) {
		patchState({
			shareLink: '',
			flatRunsList: [],
			runList: {
				activeRuns: [],
				closedRuns: {},
				completedRunsCount: 0,
			},
			testRun: null,
			totalRunsCount: 0,
			loading: false,
			projectId: '',
			saving: false,
			testRunStatistics: null,
		});
	}

	@Action(ShareRun)
	shareRun({ getState, patchState }: StateContext<RunsStateModel>, { runId }: ShareRun) {
		return this.testRunApiService.shareRun(runId).pipe(
			tap((token: any) => {
				patchState({
					shareLink: `${window.origin}/reports/run/${runId}?token=${token.token}`,
				});
			}),
		);
	}

	@Action(ClearShareLink)
	clearShareLink({ patchState }: StateContext<RunsStateModel>) {
		patchState({
			shareLink: '',
		});
	}

	@Action(GetSharedRun)
	getSharedRun({ getState, patchState }: StateContext<RunsStateModel>, { token, runId }: GetSharedRun) {
		return this.testRunApiService.getSharedRun(token, runId).pipe(
			pluck('data'),
			tap((run: TestrunModel) => {
				patchState({
					testRun: run,
				});
			}),
		);
	}

	groupRunsByStatus(runList: any[]) {
		const activeRuns = runList.filter((x) => x.status === RunStatus.OPEN);
		let completedRuns = runList.filter((x) => x.status === RunStatus.CLOSED);

		const completedRunsCount = completedRuns.length;
		let closedRuns = null;
		if (completedRunsCount > 0) {
			const options = { year: 'numeric', month: 'short', day: 'numeric' };
			closedRuns = fromPairs(
				sortBy(
					toPairs(
						lGroupBy(completedRuns, (run) =>
							new Date(run.completedAt).toLocaleDateString('en-US', options as Intl.DateTimeFormatOptions),
						),
					),
					([date, runs]) => -new Date(date).valueOf(),
				),
			);
		}

		return { closedRuns, activeRuns, completedRunsCount };
	}

	groupArrayByMonth(runs: TestrunModel[], property = 'completedAt') {
		return runs.reduce((acc, obj) => {
			const key = Utils.getMonthFromStringDate(obj[property]);
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(obj);
			return acc;
		}, {});
	}

	groupArrayByDate(runs: TestrunModel[], property = 'completedAt') {
		return runs.reduce((acc, obj) => {
			const key = this.utils.dateToMedium(obj[property]);
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(obj);
			return acc;
		}, {});
	}

	groupArrayByCreator(runs: TestrunModel[]) {
		return runs.reduce((acc, obj) => {
			const key = obj['creator']['email'];
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(...obj['test']['actions']);
			return acc;
		}, {});
	}

	groupArrayByTestName(runs: TestrunModel[]) {
		return runs.reduce((acc, obj) => {
			const key = obj['test']['name'];
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(...obj['test']['actions']);
			return acc;
		}, {});
	}

	calculateRunStats(activeTestRuns: TestrunModel[]) {
		let total = 0;
		activeTestRuns.forEach((run) => {
			run.stats.forEach((stat) => {
				total += +stat.count;
			});
		});
		const stats = {};
		activeTestRuns.forEach((run) => {
			run.stats.forEach((stat) => {
				const key = stat.status.name;
				if (!stats[key]) {
					stats[key] = {
						color: stat.status.color,
						name: stat.status.name,
						count: 0,
						percentage: 0,
					};
				}
				stats[key].count += parseInt(stat.count, 10);
				stats[key].percentage = ((stats[key].count / total || 0) * 100).toFixed();
			});
		});
		return stats;
	}

	calculateRunProgress(actionList) {
		const tests = {};
		actionList.reduce((acc, item) => {
			const key = item.test.id;
			if (tests[key]) {
				if (item['status']) {
					tests[key]['actions'].push(item);
				} else {
					tests[key]['actions'].push(...item['test']['actions']);
				}
			} else {
				tests[key] = {};
				tests[key]['actions'] = [];
				if (item['status']) {
					tests[key]['actions'].push(item);
				} else {
					tests[key]['actions'].push(...item['test']['actions']);
				}
			}
		}, {});

		let testedCount = 0;
		let notTestedCount = 0;
		Object.keys(tests).forEach((key) => {
			const test = tests[key];
			if (test.actions.length === 0) {
				notTestedCount++;
			}
			if (test.actions.length === 1 && test.actions[0].name === 'not_tested') {
				notTestedCount++;
			} else {
				test.actions.sort(function (a, b) {
					let c = new Date(a.createdAt);
					let d = new Date(b.createdAt);
					if (c < d) {
						return 1;
					}
					if (c > d) {
						return -1;
					}
				});
				const action = test.actions[0];
				if (action.status.name === 'not_tested') {
					notTestedCount++;
				} else {
					testedCount++;
				}
			}
		});
		return testedCount;
	}

	parseRunProgress(runProgress: any[], groupBy: string, run: TestrunModel) {
		const stats = {} as RunProgressStats;
		const testedCount = runProgress.length ? this.calculateRunProgress(runProgress) : 0;
		const notTestedCount = run.testsCount - testedCount;
		stats['tested'] = {
			count: testedCount,
			percentage: Math.floor((testedCount / run.testsCount) * 100),
		};
		stats['not_tested'] = {
			count: notTestedCount,
			percentage: Math.floor((notTestedCount / run.testsCount) * 100),
		};

		let progress: {};
		switch (groupBy) {
			case ProgressTypes.DATES:
				progress = this.groupArrayByDate(runProgress, 'createdAt');
				break;
			case ProgressTypes.ACTION:
				progress = this.groupArrayByTestName(runProgress);
				break;
			default:
				progress = this.groupArrayByDate(runProgress, 'createdAt');
				break;
		}
		return {
			progress,
			stats,
		};
	}

	private calculateRunStatistic(run: TestrunModel): void {
		const total = run.testsCount;
		run.calculatedStats = run.stats.reduce((acc: {}, obj: any) => {
			const key = obj.status.name;
			if (!acc[key]) {
				acc[key] = {
					color: obj.status.color,
					name: obj.status.name,
					count: parseInt(obj.count, 10),
					percentage: 0,
				};
			}
			acc[key].percentage = ((acc[key].count / total) * 100).toFixed();
			return acc;
		}, {});
	}
}
