import { ComponentFactory, ComponentRef, EmbeddedViewRef, Injectable, Injector, TemplateRef } from '@angular/core';
import { AbstractEqaPortalHostComponent } from '@shared/domain/abstracts/abstract-portal-host';

/**
 * Abstract service for displaying portals
 */
@Injectable()
export abstract class AbstractEqaPortalService {
	protected host?: AbstractEqaPortalHostComponent;

	protected get safeHost(): AbstractEqaPortalHostComponent {
		if (!this.host) {
			throw new Error('No host');
		}

		return this.host;
	}

	attach(host: AbstractEqaPortalHostComponent) {
		this.host = host;
	}

	add<C>(componentFactory: ComponentFactory<C>, injector: Injector): ComponentRef<C> {
		return this.safeHost.addComponentChild(componentFactory, injector);
	}

	remove<C>({ hostView }: ComponentRef<C>) {
		hostView.destroy();
	}

	addTemplate<C>(templateRef: TemplateRef<C>, context?: C): EmbeddedViewRef<C> {
		return this.safeHost.addTemplateChild(templateRef, context);
	}

	removeTemplate<C>(viewRef: EmbeddedViewRef<C>) {
		viewRef.destroy();
	}
}
