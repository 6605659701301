import { ModuleWithProviders, NgModule } from '@angular/core';
import { NGXS_PLUGINS } from '@ngxs/store';
import { EqaNgxsFormPlugin } from './form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { FormDirective } from './directive';

@NgModule({
	imports: [ReactiveFormsModule],
	declarations: [FormDirective],
	exports: [FormDirective],
})
export class EqaNgxsFormPluginModule {
	static forRoot(): ModuleWithProviders<EqaNgxsFormPluginModule> {
		return {
			ngModule: EqaNgxsFormPluginModule,
			providers: [
				{
					provide: NGXS_PLUGINS,
					useClass: EqaNgxsFormPlugin,
					multi: true,
				},
			],
		};
	}
}
