import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { UserIntegration, UserModel } from '@core/interfaces/user/UserModel';
import { ServerUrl } from '@core/constants/server-url';

@Injectable({ providedIn: 'root' })
export class UserService {
	private header: HttpHeaders;
	private baseUrl = ServerUrl.HOME_URL;
	private userUrl = this.baseUrl + '/user';

	constructor(private http: HttpClient) {
		this.header = new HttpHeaders();
		this.header.append('Content-Type', 'application/json');
	}

	registerUser(user: Partial<UserModel>) {
		return this.http.post(this.userUrl + '/register', user, {
			headers: this.header,
		});
	}

	createDemoUser() {
		const demoToken = localStorage.getItem('demo_token');
		const params: any = {};
		if (demoToken) {
			params.demo_token = demoToken;
		}
		return this.http.get(this.userUrl + '/demo', { params });
	}

	getUserProfile() {
		return this.http.get<UserModel>(this.userUrl + '/profile');
	}

	updateUserProfile(user: Partial<UserModel>) {
		return this.http.put(this.userUrl + '/profile', user);
	}

	getUsersByNetwork() {
		return this.http.get<any[]>(this.userUrl + '/list', {
			headers: this.header,
		});
	}

	fetchUsersByProject(id: string) {
		return this.http.get<UserModel[]>(this.baseUrl + '/projects/' + id + '/users', {
			headers: this.header,
		});
	}

	updateUserPassword(user: { password: string; confirmPassword: string }) {
		return this.http.post(this.userUrl + '/account', user);
	}

	inviteUser(user: Partial<UserModel>) {
		return this.http.post(this.userUrl + '/invite', user);
	}

	deactivateUser(id: string) {
		return this.http.delete(`${this.userUrl}/${id}`);
	}

	hasPermission(rulesString: string) {
		const rules = rulesString.split(',');
		const user = JSON.parse(localStorage.getItem('userState.user'));
		return rules.some((rule) => user?.['role']?.rules?.includes?.(rule.trim()));
	}

	getUsers() {
		return this.http.get(this.userUrl + '/list', { headers: this.header });
	}

	loadAvatar(body: FormData) {
		return this.http.post<UserModel>(this.userUrl + '/profile/avatar', body);
	}

	listIntegrationMembersByUser(): Observable<UserIntegration[]> {
		return this.http.get<any>(`${this.userUrl}/integrations`).pipe(
			pluck('data'),
			map((res) => res.map(({ data, integration: { provider } }) => ({ data, provider }))),
		);
	}
}
