import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { USER_AGENT, WINDOW } from '@ng-web-apis/common';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SvgService } from '@shared/domain/services/svg.service';
import { EqaStringHandler } from '@shared/types/handler';
import { eqaAssert } from '@shared/domain/classes/assert';
import { EQA_ICONS_PATH, EQA_SVG_SRC_PROCESSOR } from '@shared/tokens';
import { StaticRequestService } from '@shared/domain/services/static-request.service';
import { getDocumentOrShadowRoot, isPresumedHTMLString } from '@shared/domain/utils';

const UNDEFINED_NAMED_ICON = 'Attempted to use undefined named icon';
const MISSING_EXTERNAL_ICON = 'External icon is missing on the given URL';

@Component({
	selector: 'eqa-svg',
	templateUrl: './svg.component.html',
	styleUrls: ['./svg.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgComponent {
	private readonly src$ = new ReplaySubject<void>(1);
	private icon = '';
	readonly innerHTML$: Observable<SafeHtml>;

	@Input()
	set src(src: string) {
		this.icon = this.processor(src);
		this.src$.next();
	}

	constructor(
		@Inject(DOCUMENT) private readonly documentRef: Document,
		@Inject(WINDOW) private readonly windowRef: Window,
		@Inject(EQA_ICONS_PATH) private readonly iconsPath: EqaStringHandler<string>,
		@Inject(SvgService) private readonly svgService: SvgService,
		@Inject(StaticRequestService)
		private readonly staticRequestService: StaticRequestService,
		@Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
		@Inject(ElementRef) private readonly elementRef: ElementRef<Element>,
		@Inject(USER_AGENT) private readonly userAgent: string,
		@Inject(EQA_SVG_SRC_PROCESSOR)
		private readonly processor: EqaStringHandler<string>,
	) {
		this.innerHTML$ = this.src$.pipe(
			switchMap(() => of(this.getSafeHtml(this.icon))),
			startWith(''),
		);
	}

	get src(): string {
		return this.icon;
	}

	get use(): string {
		return this.icon.includes('.svg#') ? this.icon : this.resolveName(this.icon, this.iconsPath);
	}

	get isInnerHTML(): boolean {
		return this.isSrc || this.isExternal || (this.isName && this.isShadowDOM);
	}

	private get isShadowDOM(): boolean {
		return getDocumentOrShadowRoot(this.elementRef.nativeElement) !== this.documentRef;
	}

	private get isUse(): boolean {
		return this.use.includes('.svg#');
	}

	private get isExternal(): boolean {
		return this.isUrl || this.isUse || this.isCrossDomain;
	}

	private get isUrl(): boolean {
		return this.icon.endsWith('.svg');
	}

	private get isSrc(): boolean {
		return isPresumedHTMLString(this.icon);
	}

	private get isName(): boolean {
		return !this.isUrl && !this.isUse && !this.isSrc;
	}

	private get isCrossDomain(): boolean {
		const { use, isUse, windowRef } = this;

		return isUse && use.startsWith('http') && !!windowRef.origin && !use.startsWith(windowRef.origin);
	}

	onError(message: string = MISSING_EXTERNAL_ICON) {
		const { icon } = this;

		eqaAssert.assert(false, message, icon);
	}

	private resolveName(name: string, iconsPath: EqaStringHandler<string>): string {
		return iconsPath(name);
	}

	private getSafeHtml(src: string): SafeHtml {
		return this.process(src);
	}

	private process(src: string): SafeHtml {
		const icon = this.svgService.getOriginal(src);

		if (this.isName && !icon && !!src) {
			this.onError(UNDEFINED_NAMED_ICON);
		}

		return !this.isShadowDOM || !this.isName ? '' : this.sanitize(icon || '');
	}

	private sanitize(src: string): SafeHtml | string {
		return this.sanitizer.bypassSecurityTrustHtml(src);
	}
}
