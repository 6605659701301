import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { severities } from "@features/defects/domain/constants/severities";
import { CreateDefectDto } from "@features/defects/domain/interfaces/create.defect";
import { DefectModel } from "@features/defects/domain/interfaces/defect.model";
import { Severity } from "@features/defects/domain/interfaces/severity";
import { UpdateDefectDto } from "@features/defects/domain/interfaces/update.defect";
import { Observable, of } from "rxjs";

import { pluck } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class DefectsApiService {
	private baseUrl = ServerUrl.HOME_URL;

	constructor(private http: HttpClient) {}

	defectsUrl(projectId) {
		return `${this.baseUrl}/projects/${projectId}/defects`;
	}

	createDefect(projectId, defect: CreateDefectDto): Observable<DefectModel> {
		return this.http.post(this.defectsUrl(projectId), defect).pipe(pluck('data'));
	}

	getDefectsByProject(projectId: string): Observable<DefectModel[]> {
		return this.http.get(this.defectsUrl(projectId)).pipe(pluck('data'));
	}

	getDefect(projectId: string, defectId: string): Observable<DefectModel> {
		return this.http.get(`${this.defectsUrl(projectId)}/${defectId}`).pipe(pluck('data'));
	}

	updateDefect(projectId: string, defectId: string, updateDefectDto: UpdateDefectDto): Observable<DefectModel> {
		return this.http.put(`${this.defectsUrl(projectId)}/${defectId}`, updateDefectDto).pipe(pluck('data'));
	}

	closeDefect(projectId: string, defectId: string): Observable<DefectModel> {
		return this.http.post(`${this.defectsUrl(projectId)}/${defectId}/close`, {}).pipe(pluck('data'));
	}

	reopenDefect(projectId: string, defectId: string): Observable<DefectModel> {
		return this.http.post(`${this.defectsUrl(projectId)}/${defectId}/reopen`, {}).pipe(pluck('data'));
	}

	deleteDefect(projectId: string, defectId: string): Observable<void> {
		return this.http.delete<void>(`${this.defectsUrl(projectId)}/${defectId}`);
	}

	defectSeverities(): Observable<Severity[]> {
		return of(severities);
	}
}
