import { CreateTestCaseTag } from "@features/case/case-tags/domain/interfaces/CreateTestCaseTag";
import { TestCaseTag } from "@features/case/case-tags/domain/interfaces/TestCaseTag";

enum CaseTagsActions {
	getCaseTagsListByProject = '[CaseTagModel] Get Case Tags List By Project',
	getCaseTagsListByCase = '[CaseTagModel] Get Case Tags List By Case',
	getCaseTag = '[CaseTagModel] Get Case Tag',
	createCaseTag = '[CaseTagModel] Create Case Tag',
	updateCaseTag = '[CaseTagModel] Update Case Tag',
	removeCaseTag = '[CaseTagModel] Remove Case Tag',
}

export class LoadCaseTagsListByProject {
	static readonly type = CaseTagsActions.getCaseTagsListByProject;

	constructor(public orderById = false) {}
}

export class GetCaseTagsListByCase {
	static readonly type = CaseTagsActions.getCaseTagsListByCase;

	constructor() {}
}

export class GetCaseTag {
	static readonly type = CaseTagsActions.getCaseTag;

	constructor(public caseTagId: string) {}
}

export class CreateCaseTag {
	static readonly type = CaseTagsActions.createCaseTag;

	constructor(public createTag: CreateTestCaseTag) {}
}

export class UpdateCaseTag {
	static readonly type = CaseTagsActions.updateCaseTag;

	constructor(public caseTag: TestCaseTag) {}
}

export class RemoveCaseTag {
	static readonly type = CaseTagsActions.removeCaseTag;

	constructor(public caseTagId: string) {}
}
