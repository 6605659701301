import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {append, patch} from '@ngxs/store/operators';
import * as _ from 'lodash';
import {cloneDeep} from 'lodash';
import {Section} from '@features/case/case-repository/domain/interfaces/section';
import {Test} from '@features/run/domain/interfaces/test/test';
import {Testcase} from '@features/case/case-repository/domain/interfaces/testcase';
import {ContentOperations} from '@features/case/case-repository/domain/contants/content.operations';
import {
	ROOT_CASES_SECTION_NAME,
	VIRTUAL_SECTION_ID
} from '@features/case/case-repository/domain/contants/section-constants';
import {TestcaseState} from '@features/case/case-repository/domain/store/cases/testcase.state';
import {SectionsState} from '@features/case/case-repository/domain/store/sections/sections.state';
import {
	ActivateNode,
	AddChildNode,
	AddNodeContent,
	AddRootNode,
	BuildTree,
	ClearSelectedNodeContent,
	ClearTree,
	CollapseAll,
	CopyNodeContent,
	ExpandAll,
	FilterTree,
	MoveNode,
	MoveNodeContent,
	RemoveNode,
	RemoveNodeList,
	RemoveSelectedNodeContent,
	SelectNode,
	SelectNodeEntities,
	SetCheckedNodeList,
	SetExpandedIds,
	ToggleNode,
	UpdateContent,
	UpdateNode,
	UpdateNodeContent,
} from './tree.state.actions';
import {TreeStateModel} from './tree.state.model';
import {TreeUtils} from './tree.utils';
import {Utils} from '../../core/utils/utils';

@State<TreeStateModel>({
	name: 'treeState',
	defaults: {
		tree: [],
		rootCases: [],
		activeItem: null,
		moveTargetItem: null,
		loading: true,
		contentLoading: false,
		checkedEntities: [],
		totalSelected: 0,
		activeSectionIds: [],
		expandedNodesIds: [],
		checkedNodesIds: [],
		selectedNodesIds: [],
		selectedNodesEntitiesIds: [],
	},
})
@Injectable()
export class TreeState {

	@Selector()
	static tree(treeStateModel: TreeStateModel): Section[] {
		return cloneDeep(treeStateModel.tree);
	}

	@Selector()
	static rootCases(treeStateModel: TreeStateModel): Testcase[] | Test[] {
		return treeStateModel.rootCases;
	}

	@Selector()
	static bulkActionsEnabled(treeStateModel: TreeStateModel): boolean {
		return treeStateModel.selectedNodesEntitiesIds.length > 0;
	}

	@Selector()
	static checkedEntities(treeStateModel: TreeStateModel) {
		return treeStateModel.checkedEntities;
	}

	@Selector()
	static totalSelected(treeStateModel: TreeStateModel): number {
		return treeStateModel.selectedNodesEntitiesIds.length;
	}

	@Selector()
	static selectedNodesIds(treeStateModel: TreeStateModel): string[] {
		return treeStateModel.selectedNodesIds;
	}

	@Selector()
	static selectedNodesEntitiesIds(treeStateModel: TreeStateModel): string[] {
		return treeStateModel.selectedNodesEntitiesIds;
	}

	@Selector()
	static activeSectionIds(treeStateModel: TreeStateModel): string[] {
		return treeStateModel.activeSectionIds;
	}

	@Selector()
	static expandedSectionIds(treeStateModel: TreeStateModel): string[] {
		return treeStateModel.expandedNodesIds;
	}

	@Selector()
	static checkedNodesIds(treeStateModel: TreeStateModel): string[] {
		return treeStateModel.checkedNodesIds;
	}

	@Selector()
	static loading(treeStateModel: TreeStateModel): boolean {
		return treeStateModel.loading;
	}

	@Selector()
	static selectedItem(treeStateModel: TreeStateModel): Section {
		return treeStateModel.activeItem;
	}

	@Selector()
	static moveTargetItem(treeStateModel: TreeStateModel): Section {
		return treeStateModel.moveTargetItem;
	}

	@Selector()
	static isTreeEmpty(treeStateModel: TreeStateModel): boolean {
		return treeStateModel.tree.length === 0;
	}

	constructor(private store: Store) {}

	@Action(BuildTree)
	buildTree({ patchState }: StateContext<TreeStateModel>, { sections }: BuildTree) {
		patchState({
			loading: true,
		});

		const _sections = _.cloneDeep(sections);
		const tree = TreeUtils.buildTree(_sections);
		patchState({
			tree,
			checkedEntities: [],
			totalSelected: 0,
			loading: false,
		});
	}

	@Action(SetCheckedNodeList)
	setCheckedNodeList(
		{ getState, setState, patchState }: StateContext<TreeStateModel>,
		{ nodesIds, entitiesIds }: SetCheckedNodeList,
	) {
		patchState({
			selectedNodesEntitiesIds: entitiesIds,
			selectedNodesIds: nodesIds,
		});
	}

	@Action(AddRootNode)
	addRootItem({ patchState, setState }: StateContext<TreeStateModel>, { node }: AddRootNode) {
		const _node = _.cloneDeep(node);
		_node.path = [];
		setState(
			patch<TreeStateModel>({
				tree: append([node]),
				activeSectionIds: [_node.id],
				expandedNodesIds: append([node.id]),
			}),
		);
	}

	@Action(AddChildNode)
	addChild({ getState, patchState }: StateContext<TreeStateModel>, { node }: AddChildNode) {
		const state = getState();
		const _tree = _.cloneDeep(state.tree);
		const _node = _.cloneDeep(node);
		const parentNode = TreeUtils.getNodeById(_tree, _node.parent?.id ?? _node.parentId);
		_node.path = [...parentNode.path, parentNode.id];
		parentNode.children.push(_node);
		patchState({
			tree: _tree,
			expandedNodesIds: [...state.expandedNodesIds, _node.id],
			activeSectionIds: [_node.id],
		});
	}

	@Action(UpdateNode)
	updateSelectedItemName({ getState, patchState }: StateContext<TreeStateModel>, { node }: UpdateNode) {
		const state = getState();
		const _node = _.clone(node);
		const tree = _.cloneDeep(state.tree) as Array<Section>;
		const treeNode = <Section>TreeUtils.getNodeById(tree, _node.id);

		for (const prop in _node) {
			treeNode[prop] = _node[prop];
		}

		patchState({ tree, activeItem: treeNode });
	}

	@Action(RemoveNode)
	removeNode({ getState, patchState, dispatch }: StateContext<TreeStateModel>, { node }: RemoveNode) {
		const state = getState();
		const tree = _.cloneDeep(state.tree);
		const _node = _.cloneDeep(node);
		if (!_node.parent) {
			let rootNodeIndex = tree.findIndex((s) => s.id === node.id);
			tree.splice(rootNodeIndex, 1);
			rootNodeIndex = rootNodeIndex > 0 ? rootNodeIndex - 1 : rootNodeIndex;
			const newActiveNode = tree[rootNodeIndex] ? tree[rootNodeIndex] : null;
			patchState({
				tree,
				activeSectionIds: newActiveNode ? [newActiveNode.id] : [],
			});
		} else {
			const nodesParent = TreeUtils.getNodeById(tree, _node.parent.id);
			let nodesIndex = nodesParent.children.findIndex((c) => c.id === _node.id);
			nodesParent.children.splice(nodesIndex, 1);
			nodesIndex = nodesIndex > 0 ? nodesIndex - 1 : nodesIndex;
			const newActiveNode = nodesParent.children[nodesIndex] ? nodesParent.children[nodesIndex] : nodesParent;
			patchState({ tree, activeSectionIds: [newActiveNode.id] });
		}
	}

	@Action(RemoveNodeList)
	removeNodeList({ getState, patchState }: StateContext<TreeStateModel>) {
		const state = getState();
		const activeItemCopy = _.cloneDeep(state.activeItem) as Section;
		const checkedItems = activeItemCopy.children.filter((x) => x.checked === true);
		const tree = _.cloneDeep(state.tree) as Array<Section>;
		activeItemCopy.children = activeItemCopy.children.filter((x) => x.checked === false);
		activeItemCopy.checked = false;
		checkedItems.forEach((item) => {
			TreeUtils.removeNodeFromTree(item, tree);
		});
		patchState({ tree });
	}

	@Action(UpdateContent)
	updateContent({ getState, patchState }: StateContext<TreeStateModel>, { field, entitiesIds, value }: UpdateContent) {
		const state = getState();
		const tree = _.cloneDeep(state.tree);
		const entitiesForUpdate = TreeUtils.getTestsFromTree(tree, entitiesIds);
		entitiesForUpdate.forEach((t) => {
			t[field] = value;
		});
		patchState({
			tree,
			totalSelected: 0,
			checkedEntities: [],
			selectedNodesEntitiesIds: [],
			selectedNodesIds: [],
		});
	}

	@Action(UpdateNodeContent)
	updateNodeContent(
		{ getState, patchState }: StateContext<TreeStateModel>,
		{ entities, node, operation }: UpdateNodeContent,
	) {
		const state = getState();
		const tree = _.cloneDeep(state.tree);
		const _node = TreeUtils.getNodeById(tree, node?.id ?? VIRTUAL_SECTION_ID);
		const ids = entities.map(e => e.id);

		if (operation === ContentOperations.ADD) {
			_node.cases.push(...entities);
			_node.casesOrder.push(...entities.map(e => e.id))
		}

		if (operation === ContentOperations.UPDATE) {
			_node.cases = Utils.sortCasesByOrder(
				_node.cases
					.filter(c => !entities.find(e => e.id === c.id))
					.concat(entities),
				_node.casesOrder
			);
		}

		if (operation === ContentOperations.MOVE || operation === ContentOperations.REFRESH) {
			_node.casesOrder = ids;
			_node.cases = Utils.sortCasesByOrder(entities, _node.casesOrder);
		}

		if (operation === ContentOperations.REMOVE) {
			_node.casesOrder = _node.casesOrder?.filter(id => !ids.includes(id));
			_node.cases = Utils.sortCasesByOrder(_node.cases?.filter(c => !ids.includes(c.id)), _node.casesOrder);
		}

		patchState({ tree });
	}

	@Action(RemoveSelectedNodeContent)
	removeSelectedNodeContent({ getState, patchState }: StateContext<TreeStateModel>, {}: RemoveSelectedNodeContent) {
		const state = getState();
		const _tree = _.cloneDeep(state.tree);
		const selectedNodesEntitiesIds = _.cloneDeep(state.selectedNodesEntitiesIds);
		const _nodes = TreeUtils.getNodesByCasesIds(_tree, selectedNodesEntitiesIds);
		const nodeCount = _nodes.length;
		for (let nodeIndex = 0; nodeIndex < nodeCount; nodeIndex++) {
			const node = _nodes[nodeIndex];
			node.cases = node.cases.filter((tc) => !selectedNodesEntitiesIds.includes(tc.id));
		}
		patchState({
			tree: _tree,
			selectedNodesIds: [],
			selectedNodesEntitiesIds: [],
		});
	}

	@Action(ClearSelectedNodeContent)
	clearSelectedNodeContent({ patchState }: StateContext<TreeStateModel>, {}: ClearSelectedNodeContent) {
		patchState({
			checkedEntities: [],
			selectedNodesIds: [],
			selectedNodesEntitiesIds: [],
		});
	}

	@Action(SetExpandedIds)
	setExpandedIds({ patchState }: StateContext<TreeStateModel>, { expandedNodesIds }: SetExpandedIds) {
		patchState({ expandedNodesIds });
	}

	@Action(AddNodeContent)
	addNodeContent(
		{ getState, patchState }: StateContext<TreeStateModel>,
		{ entities, node, operation }: AddNodeContent,
	) {
		const state = getState();
		const tree = _.cloneDeep(state.tree);
		const _node = TreeUtils.getNodeById(tree, node.id ?? VIRTUAL_SECTION_ID);
		_node.cases.push(...entities);
		patchState({ tree });
	}

	@Action(ActivateNode)
	setActiveItem({ getState, patchState }: StateContext<TreeStateModel>, { nodeId }: ActivateNode) {
		patchState({
			activeSectionIds: [nodeId ?? VIRTUAL_SECTION_ID],
		});
	}

	@Action(SelectNode)
	selectNode({ getState, patchState }: StateContext<TreeStateModel>, { node, checked }: SelectNode) {
		const state = getState();
		const _tree = _.cloneDeep(state.tree);
		let _selectedNodesIds = _.clone(state.selectedNodesIds);
		let _selectedTestsIds = _.clone(state.selectedNodesEntitiesIds);
		const _node = TreeUtils.getNodeById(_tree, node.id);
		const _nodesForCheck = TreeUtils.getNodeChildrenRecursive(_node);
		_nodesForCheck.push(_node);
		const _nodesCount = _nodesForCheck.length;
		for (let nodeIndex = 0; nodeIndex < _nodesCount; nodeIndex++) {
			const currentNode = _nodesForCheck[nodeIndex];
			if (checked) {
				_selectedNodesIds.push(currentNode.id);
				currentNode.cases.forEach((c) => {
					const entityIndex = _selectedTestsIds.findIndex((id) => id === c.id);
					if (entityIndex < 0) {
						_selectedTestsIds.push(c.id);
					}
				});
			} else {
				_selectedNodesIds = _selectedNodesIds.filter((nodeId) => nodeId !== currentNode.id);
				const testIds = currentNode.cases.map((tCase) => tCase.id);
				_selectedTestsIds = _selectedTestsIds.filter((caseId) => !testIds.includes(caseId));
			}
		}
		patchState({
			activeItem: _node,
			tree: _tree,
			selectedNodesIds: _selectedNodesIds,
			selectedNodesEntitiesIds: _selectedTestsIds,
		});
	}

	@Action(FilterTree)
	filterTree({ patchState }: StateContext<TreeStateModel>, { filters, searchPhrase }: FilterTree) {
		if ((!filters || !Object.keys(filters).length) && !searchPhrase) {
			return;
		}
		patchState({ loading: true });
		const flatTree = _.cloneDeep(this.store.selectSnapshot(SectionsState.sections));
		const rootCases = _.cloneDeep(this.store.selectSnapshot(TestcaseState.rootCases));
		flatTree.unshift({
			id: VIRTUAL_SECTION_ID,
			name: ROOT_CASES_SECTION_NAME,
			children: [],
			cases: rootCases,
			path: [],
			casesOrder: rootCases.map(c => c.id),
		});
		let filtrationResult = {
			paths: [],
			filtered: flatTree,
		};
		filtrationResult = TreeUtils.filterTreeByContentFilters(filtrationResult.filtered, filters, searchPhrase);
		const paths = filtrationResult.paths;
		const filteredSectionsFlatTree = filtrationResult.filtered;
		const sectionsForBuild = _.cloneDeep(
			filteredSectionsFlatTree.filter((s) => paths.findIndex((p) => p === s.id) >= 0),
		);
		const filteredTree = TreeUtils.buildTree(sectionsForBuild);
		patchState({
			tree: filteredTree,
			loading: false
		});
	}

	@Action(SelectNodeEntities)
	selectNodeItemContent(
		{ getState, patchState }: StateContext<TreeStateModel>,
		{ entities, checked }: SelectNodeEntities,
	) {
		const state = getState();
		const _tree = _.cloneDeep(state.tree);
		let _node;
		let selectedTestsIds = _.clone(state.selectedNodesEntitiesIds);
		let selectedNodesIds = _.clone(state.selectedNodesIds);
		entities.forEach(entity => {
			if (_node?.id !== entity.sectionId) {
				_node = <Section>TreeUtils.getNodeById(_tree, entity.sectionId ?? VIRTUAL_SECTION_ID);
			}
			const entityIndex = selectedTestsIds.findIndex((id) => id === entity.id);

			if (checked) {
				if (entityIndex < 0) {
					selectedTestsIds.push(entity.id);
				}
			} else {
				selectedTestsIds.splice(entityIndex, 1);
			}
			const nodeCasesIds = _node.cases.map((x) => x.id);
			const totalSelectedInNode = selectedTestsIds.filter((id) => nodeCasesIds.includes(id));
			const _nodeIndexInSelected = selectedNodesIds.findIndex((id) => id === _node.id);
			if (nodeCasesIds.length === totalSelectedInNode.length) {
				if (_nodeIndexInSelected < 0) {
					selectedNodesIds.push(_node.id);
				}
			} else {
				if (_nodeIndexInSelected >= 0) {
					selectedNodesIds.splice(_nodeIndexInSelected, 1);
				}
			}
		});
		patchState({
			selectedNodesIds: selectedNodesIds,
			selectedNodesEntitiesIds: selectedTestsIds,
		});
	}

	@Action(ToggleNode)
	toggleItem({ getState, patchState }: StateContext<TreeStateModel>, { node }: ToggleNode) {
		const state = getState();
		const expandedNodes = _.cloneDeep(state.expandedNodesIds);
		const index = expandedNodes.findIndex((id) => id === node.id);
		if (index >= 0) {
			expandedNodes.splice(index, 1);
		} else {
			expandedNodes.push(node.id);
		}
		patchState({
			expandedNodesIds: expandedNodes,
		});
	}

	@Action(ExpandAll)
	expandAll({ getState, patchState }: StateContext<TreeStateModel>) {
		const stack = cloneDeep(getState().tree);
		const expandedNodesIds: string[] = [];
		while (stack.length) {
			const node = stack.shift();
			expandedNodesIds.push(node.id);
			if (node?.children?.length) {
				stack.push(...node.children);
			}
		}
		patchState({ expandedNodesIds });
	}

	@Action(CollapseAll)
	collapseAll({ patchState }: StateContext<TreeStateModel>) {
		patchState({ expandedNodesIds: [] });
	}

	@Action(MoveNode)
	changeSectionParent(
		{ getState, setState, patchState }: StateContext<TreeStateModel>,
		{ section, newParentId }: MoveNode,
	) {
		if ((section.parent?.id ?? null) === (newParentId ?? VIRTUAL_SECTION_ID)) {
			return;
		}
		const state = getState();
		const _tree = _.cloneDeep(state.tree) as Array<Section>;
		const _moveDestinationNode = TreeUtils.getNodeById(_tree, newParentId);
		let _nodeForMove = TreeUtils.getNodeById(_tree, section.id);
		if (section.parent) {
			const _nodeForMoveCurrentParent = TreeUtils.getNodeById(_tree, section.parent.id);
			const _movedIndex = _nodeForMoveCurrentParent.children.findIndex((m) => m.id === _nodeForMove.id);
			_nodeForMoveCurrentParent.children.splice(_movedIndex, 1);
		} else {
			const rootSectionIndex = _tree.findIndex((m) => m.id === _nodeForMove.id);
			_tree.splice(rootSectionIndex, 1);
		}
		_nodeForMove.parent = _moveDestinationNode;
		_nodeForMove.path = [..._nodeForMove.parent?.path ?? [], _moveDestinationNode.id];
		TreeUtils.recalculatePathInNode(_nodeForMove);
		_moveDestinationNode.children.push(_nodeForMove);
		patchState({
			tree: _tree,
		});
	}

	@Action(ClearTree)
	clearTree({ patchState }: StateContext<TreeStateModel>, {}: ClearTree) {
		patchState({
			tree: [],
			loading: true,
		});
	}

	@Action(CopyNodeContent)
	copyNodeContent({ getState, patchState }: StateContext<TreeStateModel>, { destination }: CopyNodeContent) {
		const state = getState();
		const tree = _.cloneDeep(state.tree);
		const selectedTestsIds = [...state.selectedNodesEntitiesIds];
		const destinationNode = TreeUtils.getNodeById(tree, destination.id);
		const nodes = TreeUtils.treeToFlatList(tree);
		const nodesCount = nodes.length;
		const nodesContent = [];
		for (let nodeIndex = 0; nodeIndex < nodesCount; nodeIndex++) {
			const currentNode = nodes[nodeIndex];
			nodesContent.push(
				...currentNode.cases
					.filter((cs) => selectedTestsIds.includes(cs.id))
					.map((c) => {
						const tCase = c;
						tCase.sectionId = destinationNode.id;
						return tCase;
					}),
			);
		}
		destinationNode.cases.push(...nodesContent);
		patchState({
			tree: tree,
			selectedNodesEntitiesIds: [],
			selectedNodesIds: [],
		});
	}

	@Action(MoveNodeContent)
	moveNodeContent({ getState, patchState }: StateContext<TreeStateModel>, { destination, nodeEntities }: MoveNodeContent) {
		const state = getState();
		const tree = _.cloneDeep(state.tree);
		const selectedTestsIds = nodeEntities ? nodeEntities.map(e => e.id) : [...state.selectedNodesEntitiesIds];
		const destinationNode = TreeUtils.getNodeById(tree, destination.id);
		const nodesContent = [];

		const checkNodesCases = (nodes: Section[]) => {
			nodes.forEach((node) => {
				nodesContent.push(
					...node.cases
						.filter((cs) => selectedTestsIds.includes(cs.id))
						.map((c) => (c.sectionId = destinationNode.id, c)),
				);

				node.cases = node.cases.filter((cs) => !selectedTestsIds.includes(cs.id));
				node.casesOrder = node.cases.map((cs) => cs.id);

				if (node.children?.length) {
					checkNodesCases(node.children);
				}
			});
		};

		checkNodesCases(tree);

		destinationNode.cases.push(...nodesContent);
		destinationNode.casesOrder.push(...nodesContent.map(c => c.id))

		patchState({
			tree,
			selectedNodesEntitiesIds: [],
			selectedNodesIds: [],
		});
	}
}
