import { Currency } from '@core/constants';
import { PeriodInterval } from '@features/network/components/billing/domain/constants/period-interval';
import { PlanModel } from '@features/network/components/billing/domain/models/plan.model';
import { SubscriptionModel } from '@features/network/components/billing/domain/models/subscription.model';
import { PlanActionsEnum } from './plan.actions.enum';

export class GetPlans {
	static readonly type = PlanActionsEnum.GET_PLAN_LIST;

	constructor(public period: PeriodInterval, public currency: string) {}
}

export class SelectPlan {
	static readonly type = PlanActionsEnum.SELECT_PLAN;

	constructor(public plan: PlanModel, public currency: Currency, public period: PeriodInterval) {}
}

export class SetPeriod {
	static readonly type = PlanActionsEnum.SET_PERIOD;

	constructor(public period: PeriodInterval) {}
}

export class IncreaseFeatureCount {
	static readonly type = PlanActionsEnum.INCREASE_FEATURE_COUNT;

	constructor() {}
}

export class DecreaseFeatureCount {
	static readonly type = PlanActionsEnum.DECREASE_FEATURE_COUNT;

	constructor() {}
}

export class SaveSelectedPlan {
	static readonly type = PlanActionsEnum.SAVE_SELECTED_PLAN;

	constructor(public savedPlan: any) {}
}

export class CalculatePlanPrice {
	static readonly type = PlanActionsEnum.CALCULATE_PRICE;

	constructor(public currency: Currency, public period: PeriodInterval, public currentValue: number) {}
}

export class ApplyPlan {
	static readonly type = PlanActionsEnum.APPLY_PLAN;

	constructor() {}
}

export class GetPlanCost {
	static readonly type = PlanActionsEnum.GET_COST;

	constructor() {}
}

export class UpgradePlan {
	static readonly type = PlanActionsEnum.UPGRADE_PLAN;

	constructor(public plan: PlanModel, public period: PeriodInterval, public subscription: SubscriptionModel, public currency: Currency) {}
}
