import { Inject, Injectable, Optional, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { EQA_ICONS } from '@shared/tokens';
import { eqaAssert } from '@shared/domain/classes/assert';
import { processIcon } from '@shared/domain/utils';

@Injectable({
	providedIn: 'root',
})
export class SvgService {
	private originals: Record<string, string> = {};

	readonly items$ = new BehaviorSubject<Map<string, SafeHtml>>(new Map());

	constructor(
		@Optional()
		@Inject(DomSanitizer)
		private readonly sanitizer: DomSanitizer,
		@Inject(EQA_ICONS) icons: Record<string, string>,
	) {
		this.define(icons);
	}

	define(icons: Record<string, string>) {
		const { value } = this.items$;

		Object.keys(icons).forEach((key) => {
			this.defineIcon(key, icons[key], value);
		});

		this.items$.next(value);
	}

	getOriginal(name: string): string | null {
		return this.originals[name] || null;
	}

	private defineIcon(name: string, src: string, map: Map<string, SafeHtml>) {
		if (map.has(name)) {
			return;
		}

		const parsed = this.parseSrc(name, src);

		if (!parsed) {
			eqaAssert.assert(false, 'Unable to parse given SVG src');
			return;
		}

		map.set(name, parsed);
		this.originals = {
			...this.originals,
			[name]: src,
		};
	}

	private parseSrc(name: string, src: string): SafeHtml {
		return this.sanitize(processIcon(src, name));
	}

	private sanitize(src: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(src);
	}
}
