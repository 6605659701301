import { EqaLetDirective } from './let.directive';

export interface EqaContextWithImplicit<T> {
	$implicit: T;
}

/**
 * @internal
 */
export class EqaLetContext<T> implements EqaContextWithImplicit<T> {
	constructor(private readonly internalDirectiveInstance: EqaLetDirective<T>) {}

	get $implicit(): T {
		return this.internalDirectiveInstance.eqaLet;
	}

	get eqaLet(): T {
		return this.internalDirectiveInstance.eqaLet;
	}
}
