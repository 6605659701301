import { eqaAssert } from '@shared/domain/classes/assert';

export function clamp(value: number, min: number, max: number): number {
	eqaAssert.assert(!isNaN(value));
	eqaAssert.assert(!isNaN(min));
	eqaAssert.assert(!isNaN(max));
	eqaAssert.assert(max >= min);

	return Math.min(max, Math.max(min, value));
}
