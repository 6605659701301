import { Injectable } from '@angular/core';
import { Payment } from '@features/network/components/billing/domain/interfaces/payment';
import { PaymentApiService } from '@features/network/components/billing/domain/services/payment/payment.api.service';
import { PlansApiService } from '@features/network/components/billing/domain/services/plans/plans.api.service';
import { RefundRequestApiService } from '@features/network/components/billing/domain/services/refund/refund.request.api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { pluck, tap } from 'rxjs/operators';
import { PaymentStateModel } from './payment-state.model';
import { GetPayments, PayByYandex, RefundRequest, RequestInvoice } from './payment.actions';

@State<PaymentStateModel>({
	name: 'paymentState',
	defaults: {
		lastPayment: null,
		payments: [],
		yandexPayment: null,
		paymentUrl: '',
	},
})
@Injectable()
export class PaymentState {
	constructor(
		private plansApiService: PlansApiService,
		private paymentsService: PaymentApiService,
		private refundRequestApiService: RefundRequestApiService,
	) {}

	@Selector()
	static payments(m: PaymentStateModel) {
		return m.payments;
	}

	@Selector()
	static lastPayment(m: PaymentStateModel) {
		return m.lastPayment;
	}

	@Selector()
	static paymentUrl(m: PaymentStateModel) {
		return m.paymentUrl;
	}

	@Action(RequestInvoice)
	requestInvoice({ getState, patchState }: StateContext<PaymentStateModel>, { requestInvoiceModel }: RequestInvoice) {
		const state = getState();
		const planId = 0;
		return this.paymentsService.requestInvoice(requestInvoiceModel);
	}

	@Action(GetPayments)
	getPayments({ patchState }: StateContext<PaymentStateModel>, {}: GetPayments) {
		return this.paymentsService.getPayments().pipe(
			pluck('data'),
			tap((payments: Payment[]) => {
				if (payments.length) {
					const [lastPayment] = payments;
					patchState({
						lastPayment: lastPayment,
						payments: payments,
					});
				}
			}),
		);
	}

	@Action(RefundRequest)
	refundRequest({ getState, patchState }: StateContext<PaymentStateModel>, { refundRequestModel }: RefundRequest) {
		return this.refundRequestApiService.postRefundRequest(refundRequestModel);
	}

	@Action(PayByYandex)
	payByYandex({ patchState }: StateContext<PaymentStateModel>, { amount, currency }: PayByYandex) {
		return this.paymentsService.yandexPayment(amount, currency).pipe(
			pluck('data'),
			tap((payment: Payment) => {
				const redirectUrl = payment.data.redirect;
				patchState({
					paymentUrl: redirectUrl,
				});
			}),
		);
	}
}
