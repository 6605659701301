import { CreateTrackerDescription } from "./create-tracker-description";

export class CreateCommonMarkdownDescriptionStrategy implements CreateTrackerDescription {
	create(data) {
		let descriptionString = '';
		if (data.preconditions) {
			descriptionString += `**Preconditions:**\n${data.preconditions}\n\n`;
		}
		if (data.steps?.length) {
			descriptionString += `| Step | Expected Results |\n| --------|---------|\n`;
			data.steps.forEach((step, index) => {
				descriptionString += `| ${index + 1}. ${step.desc} | ${step.expectedResult}|\n`;
			});
			descriptionString += '\n';
		}
		descriptionString += `**Actual result:** \n ${data.actualResult}`;
		return descriptionString;
	}
}
