import { Injectable } from '@angular/core';
import { EqaIcon } from '@shared/domain/interfaces/EqaIcon';

@Injectable({ providedIn: 'root' })
export class SvgService {
	private svgRegistry = new Map<string, string>();

	public registerSvgIcons(icons: EqaIcon[]): void {
		icons.forEach((icon: EqaIcon) => {
			this.svgRegistry.set(icon.name, icon.data);
		});
	}

	public getSvgIcon(iconName: string): string | undefined {
		if (!this.svgRegistry.has(iconName)) {
			console.warn(`Svg not found ${iconName}`);
		}

		return this.svgRegistry.get(iconName);
	}
}
