import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
	ChatIntegrationProviders,
	IntegrationProviders,
	TrackerIntegrationProviders
} from "@features/integrations/domain/constants";
import { ChatIntegrationService } from "./chats";
import { CommonIntegrationService } from "./common-integration.service";
import {
	CreateCommonMarkdownDescriptionStrategy
} from "./trackers/strategies/create-common-markdown-description.strategy";
import { CreateJiraDescriptionStrategy } from "./trackers/strategies/create-jira-description.strategy";
import { CreateTrelloDescriptionStrategy } from "./trackers/strategies/create-trello-description-strategy";
import { TrackerIntegrationService } from "./trackers/tracker-integration.service";

@Injectable({ providedIn: 'root' })
export class IntegrationServicesContainer {
	constructor(private readonly http: HttpClient) {}

	getCommon<T>(provider: IntegrationProviders) {
		switch (provider) {
			case IntegrationProviders.JIRA_HOSTED:
			case IntegrationProviders.JIRA_CLOUD:
			case IntegrationProviders.TRELLO:
			case IntegrationProviders.BITBUCKET_CLOUD:
			case IntegrationProviders.GITHUB:
			case IntegrationProviders.GITLAB:
			case IntegrationProviders.SLACK:
			case IntegrationProviders.TELEGRAM_BOT:
				return new CommonIntegrationService<T>(provider, this.http);
			default:
				console.log(`No service for common provider: ${provider}`);
		}
	}

	getTracker(provider: TrackerIntegrationProviders) {
		switch (provider) {
			case TrackerIntegrationProviders.JIRA_HOSTED:
			case TrackerIntegrationProviders.JIRA_CLOUD:
				return new TrackerIntegrationService(provider, this.http, new CreateJiraDescriptionStrategy());
			case TrackerIntegrationProviders.TRELLO:
				return new TrackerIntegrationService(provider, this.http, new CreateTrelloDescriptionStrategy());
			case TrackerIntegrationProviders.BITBUCKET_CLOUD:
			case TrackerIntegrationProviders.GITHUB:
			case TrackerIntegrationProviders.GITLAB:
				return new TrackerIntegrationService(provider, this.http, new CreateCommonMarkdownDescriptionStrategy());
			default:
				console.log(`No service for tracker provider: ${provider}`);
		}
	}

	getChat(provider: ChatIntegrationProviders) {
		switch (provider) {
			case ChatIntegrationProviders.SLACK:
			case ChatIntegrationProviders.TELEGRAM_BOT:
				return new ChatIntegrationService(provider, this.http);
			default:
				console.log(`No service for chat provider: ${provider}`);
		}
	}
}
