import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AbstractHttpService } from "@core/abstracts/abstract-http-service";
import { API_URL } from "@core/tokens/api-url.token";
import { RunExecution } from "@features/executions/run-execution/domain/interfaces";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { catchError, pluck } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class HttpRunExecutionService extends AbstractHttpService {
	private runsUrl = this.baseUrl + '/runs';

	constructor(
		notificationService: ToastrService,
		private http: HttpClient,
		@Inject(API_URL) private baseUrl: string,
	) {
		super(notificationService);
	}

	/**
	 * Load test run execution entity by id
	 * @param {string} runId
	 * */
	public loadRunExecutionById(runId: string) {
		return this.http.get<RunExecution>(`${this.runsUrl}/${runId}`).pipe(
			pluck('data'),
			catchError((error) => {
				this.handleError(error);
				return of(error);
			}),
		);
	}
}
