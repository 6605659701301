import { Injectable } from '@angular/core';

const EQA = 'eqa_';

@Injectable({
	providedIn: 'root',
})
export class EqaIdService {
	private static autoId = 0;

	generate(): string {
		return `${EQA}${EqaIdService.autoId++}${Date.now()}`;
	}
}
