import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { UserState } from "@core/store/user/user.state";
import { Store } from "@ngxs/store";

@Component({
	selector: 'eqa-subscription-expired-component',
	templateUrl: './subscription.expired.component.html',
	styleUrls: ['./subscription.expired.styles.scss'],
})
export class SubscriptionExpiredComponent {
	constructor(private store: Store, private router: Router) {}

	renew() {
		const user = this.store.selectSnapshot(UserState.user);
		const netwrokId = user.network.id;
		this.router.navigate([`/workspace/${netwrokId}/billing`]);
	}

	contact() {}
}
