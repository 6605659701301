import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { DestroyService } from '@shared/domain';
import { SafeHtml } from '@angular/platform-browser';
import { innerHTML } from '@shared/domain/utils/polyfills';
import { SvgService } from '@shared/domain/services/svg.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'eqa-svg-definitions',
	templateUrl: './svg-definitions.component.html',
	styleUrls: ['./svg-definitions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class SvgDefinitionsComponent implements OnInit {
	items!: IterableIterator<SafeHtml>;
	isBrowser = true;

	constructor(
		@Inject(ChangeDetectorRef) private readonly changeDetectorRef: ChangeDetectorRef,
		@Inject(DestroyService) private readonly destroy$: DestroyService,
		@Inject(DOCUMENT) documentRef: Document,
		@Inject(SvgService) private readonly svgService: SvgService,
		@Inject(PLATFORM_ID) platformId: Object,
	) {
		this.isBrowser = !isPlatformServer(platformId);
		innerHTML(documentRef);
	}

	ngOnInit() {
		this.svgService.items$.pipe(takeUntil(this.destroy$)).subscribe((defsMap) => {
			this.items = defsMap.values();
			this.changeDetectorRef.detectChanges();
		});
	}
}
