import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EqaInteractiveState } from '@shared/domain/enums/interactive';

@Component({
	selector: 'eqa-wrapper',
	template: '<ng-content></ng-content>',
	styleUrls: ['./wrapper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EqaWrapperComponent {
	@Input()
	disabled = false;

	@Input()
	readOnly = false;

	@Input()
	hovered = false;

	@Input()
	pressed = false;

	@Input()
	focused = false;

	@Input()
	invalid = false;

	@Input()
	@HostBinding('attr.data-appearance')
	appearance = '';

	constructor() {}

	@HostBinding('class._invalid')
	get computedInvalid(): boolean {
		return !this.disabled && !this.readOnly && this.invalid;
	}

	@HostBinding('class._focused')
	get computedFocused(): boolean {
		return this.focused && !this.disabled;
	}

	@HostBinding('attr.data-state')
	get interactiveState(): EqaInteractiveState | null {
		if (this.disabled) {
			return EqaInteractiveState.Disabled;
		}

		if (this.readOnly) {
			return EqaInteractiveState.Readonly;
		}

		if (this.pressed) {
			return EqaInteractiveState.Pressed;
		}

		if (this.hovered) {
			return EqaInteractiveState.Hovered;
		}

		return null;
	}
}
