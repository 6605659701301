import { CustomFieldType } from "@features/case/case-fields/domain";

export class SharedStep {
	id: string;
	name?: string;
	action: string;
	desc: string;
	expectedResult: string;
	type: CustomFieldType;
	value?: { value: any };
	defaultValue: any;
	order: number;
	data: any;
	required: boolean;
	multi?: boolean;

	constructor(data) {
		this.id = data?.id;
		this.action = data.action;
		this.desc = data.desc;
		this.expectedResult = data.expectedResult;
		this.type = data.type;
		this.value = data?.value?.value;
		this.defaultValue = data.defaultValue;
		this.order = data.order;
		this.data = data.data;
		this.multi = data?.multi;
		this.name = data?.name;
		this.required = data.required;
	}
}
