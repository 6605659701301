import { Currency } from '@core/constants';
import { PeriodInterval } from '@features/network/components/billing/domain/constants/period-interval';
import { PricableFeature } from '@features/network/components/billing/domain/constants/pricable.feature';
import { SubscriptionType } from '@features/network/components/billing/domain/constants/subscription.type';
import { PlanFeatureModel } from './plan.feature.model';
import { PlanModel } from './plan.model';
import { PlanPeriodModel } from './plan.period.model';

export class SubscriptionModel {
	type: SubscriptionType;
	activeFrom: Date;
	activeTill: Date;
	isExpired: boolean;
	plan: PlanModel;
	features: PlanFeatureModel;
	isTrial: boolean;
	price: any;
	period: PlanPeriodModel;

	constructor(subscription: SubscriptionModel) {
		this.type = subscription.type;
		this.activeFrom = subscription.activeFrom;
		this.activeTill = subscription.activeTill;
		this.isExpired = subscription.isExpired;
		this.plan = new PlanModel(subscription.plan);
		this.features = subscription.features;
		this.isTrial = this.plan.isTrial;
		this.period = subscription.period;
	}

	get subscribedPlan() {
		return this.plan;
	}

	get subscriptionPeriod() {
		return this.period;
	}

	calculateSubscriptionPrice(currency: Currency, period: PeriodInterval): any {
		const feature = this.plan.getPurchasableFeatures(PricableFeature.USERS);
		feature.setPriceByCurrencyAndInterval(currency, period);
		const value = this.features[feature.slug];
		const free = feature.free;
		const totalPrice = value * feature.price.amount - free * feature.price.amount;
		this.price = { amount: totalPrice, currency: currency };
	}
}
