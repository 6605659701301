import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav.component';
import { SvgModule } from '@shared/ui/elements/svg/svg.module';
import { EqaHintModule } from '@shared/ui/directives/hint';
import { StringPipesModule } from '@shared/ui/pipes/string-pipes/string-pipes.module';
import { HasPermissionModule } from '@core/directives/has-permission/has-permission.module';
import { BillableFeatureModule } from '@shared/ui/directives/billable-feature/billable-feature.module';
import { EqaLinkModule } from '@shared/ui/components/link';
import { EqaLinkPipesModule } from '@shared/ui/pipes/link';
import { RouterModule } from '@angular/router';
import { ScrollbarModule } from '@shared/ui/elements/scrollbar';
import { LetModule } from '@shared/ui/directives/let';
import { EqaFlyOutDirective } from '@layout/ui/components/sidenav/fly-out.directive';

@NgModule({
	declarations: [EqaFlyOutDirective, SidenavComponent],
	exports: [SidenavComponent],
	imports: [
		CommonModule,
		SvgModule,
		EqaHintModule,
		StringPipesModule,
		HasPermissionModule,
		BillableFeatureModule,
		EqaLinkModule,
		RouterModule,
		EqaLinkPipesModule,
		ScrollbarModule,
		LetModule,
	],
})
export class SidenavModule {}
