import { Directive, ElementRef } from '@angular/core';
import { EQA_ELEMENT_REF } from '@shared/tokens';

@Directive({
	selector: '[eqaScrollbarWrapper]',
	providers: [
		{
			provide: EQA_ELEMENT_REF,
			useExisting: ElementRef,
		},
	],
})
export class EqaScrollbarWrapperDirective {}
