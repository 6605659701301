import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EqaFocusTrapDirective } from './focus-trap.directive';

@NgModule({
	declarations: [EqaFocusTrapDirective],
	exports: [EqaFocusTrapDirective],
	imports: [CommonModule],
})
export class FocusTrapModule {}
