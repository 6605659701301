import {Component, OnInit, ChangeDetectionStrategy, Inject, Optional} from '@angular/core';
import { merge, Observable } from 'rxjs';
import { EQA_DIALOGS } from '@shared/tokens/dialogs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'eqa-host',
	templateUrl: './root.component.html',
	styleUrls: ['./root.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootComponent implements OnInit {
	readonly scrollbars$: Observable<boolean> = merge(...this.dialogs).pipe(map(({ length }) => !length));

	constructor(
		@Optional()
		@Inject(EQA_DIALOGS)
		readonly dialogs: readonly Observable<readonly unknown[]>[] | null,
	) {}

	ngOnInit(): void {}
}
