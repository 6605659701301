import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerUrl } from '@core/constants/server-url';
import { Role, Roles } from '@core/interfaces/user/Roles';

@Injectable({ providedIn: 'root' })
export class AccessControlService {
	private baseUrl = ServerUrl.HOME_URL;
	private accessUrl = this.baseUrl + '/network/roles';

	constructor(private http: HttpClient) {}

	getRoles() {
		return this.http.get<Roles>(`${this.accessUrl}/`);
	}

	getRole(id: string) {
		return this.http.get<Role>(`${this.accessUrl}/${id}`);
	}

	createRole(role: Partial<Role>) {
		return this.http.post<Role>(`${this.accessUrl}/`, role);
	}

	updateRole(id: string, role: Partial<Role>) {
		return this.http.patch<Role>(`${this.accessUrl}/${id}`, role);
	}

	deleteRole(id: string) {
		return this.http.delete(`${this.accessUrl}/${id}`);
	}

	assignRole(roleId: string, userId: string) {
		return this.http.put(`${this.accessUrl}/${roleId}/assign`, { userId });
	}
}
