import { Injectable } from "@angular/core";
import { CaseFeature } from "@features/case/case-features/domain/interfaces/CaseFeature";
import { CaseFeaturesApiService } from "@features/case/case-features/domain/services/case-features.api.service";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { HideLoader, ShowLoader } from "@shared/domain/store/eqa-loader/eqa-loader.actions";
import produce from "immer";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { catchError, finalize, pluck, tap } from "rxjs/operators";
import {
	CreateCaseFeature,
	LoadProjectCaseFeatures,
	RemoveCaseFeature,
	UpdateCaseFeature
} from "./case-features.actions";

export interface CaseFeaturesStateModel {
	featuresList: CaseFeature[];
}

@State<CaseFeaturesStateModel>({
	name: 'caseFeaturesState',
	defaults: {
		featuresList: [],
	},
})
@Injectable()
export class CaseFeaturesState {
	constructor(
		private toast: ToastrService,
		private store: Store,
		private caseFeaturesApiService: CaseFeaturesApiService,
	) {}

	@Selector()
	static featuresList(state: CaseFeaturesStateModel): CaseFeature[] {
		return state.featuresList;
	}

	@Action(LoadProjectCaseFeatures)
	loadProjectCaseFeatures(
		{ patchState, getState }: StateContext<CaseFeaturesStateModel>,
		{}: LoadProjectCaseFeatures,
	) {
		this.store.dispatch(new ShowLoader());
		const currentState = getState();
		return this.caseFeaturesApiService.loadProjectCaseFeatures().pipe(
			pluck('data'),
			tap((featuresList: CaseFeature[]) => {
				const newState = produce(currentState, (draft: CaseFeaturesStateModel) => {
					draft.featuresList = featuresList;
				});
				patchState(newState);
			}),
			catchError((err) => {
				this.toast.error(err.message);
				patchState(currentState);
				return of([]);
			}),
			finalize(() => this.store.dispatch(new HideLoader())),
		);
	}

	@Action(CreateCaseFeature)
	createCaseFeature({ getState, patchState }: StateContext<CaseFeaturesStateModel>, { feature }: CreateCaseFeature) {
		const currentState = getState();
		return this.caseFeaturesApiService.createCaseFeature(feature).pipe(
			pluck('data'),
			tap((newFeature: CaseFeature) => {
				const newState = produce(currentState, (draft: CaseFeaturesStateModel) => {
					draft.featuresList.push(newFeature);
				});
				patchState(newState);
			}),
			catchError((err) => {
				patchState(currentState);
				this.toast.error(err.message);
				return of({});
			}),
		);
	}

	@Action(UpdateCaseFeature)
	updateCaseFeature({ getState, patchState }: StateContext<CaseFeaturesStateModel>, { feature }: UpdateCaseFeature) {
		const currentState = getState();
		return this.caseFeaturesApiService.updateCaseFeature(feature).pipe(
			pluck('data'),
			tap((updatedFeature: CaseFeature) => {
				const newState = produce(currentState, (draft: CaseFeaturesStateModel) => {
					const featureIndex = draft.featuresList.findIndex((t) => t.id === feature.id);
					draft.featuresList[featureIndex] = updatedFeature;
				});
				patchState(newState);
			}),
			catchError((err) => {
				patchState(currentState);
				this.toast.error(err.message);
				return of({});
			}),
		);
	}

	@Action(RemoveCaseFeature)
	removeCaseFeature(
		{ getState, patchState }: StateContext<CaseFeaturesStateModel>,
		{ featureId }: RemoveCaseFeature,
	) {
		const currentState = getState();
		return this.caseFeaturesApiService.removeCaseFeature(featureId).pipe(
			pluck('data'),
			tap(() => {
				const newState = produce(currentState, (draft: CaseFeaturesStateModel) => {
					const { featuresList } = draft;
					draft.featuresList = featuresList.filter((t) => t.id !== featureId);
				});
				patchState(newState);
			}),
			catchError((err) => {
				patchState(currentState);
				this.toast.error(err.message);
				return of({});
			}),
		);
	}
}
