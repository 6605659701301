import { AppComponent } from './app.component';
import { ApplicationRoutingModule } from './app.routes';
import { CoreModule } from '@core/core.module';
import { LocalisationService } from '@core/services/localisation/localisation.service';
import { ActivityModule } from '@features/activity/activity.module';
import { CasesRepositoryModule } from '@features/case/case-repository/cases.repository.module';
import { DefectsModule } from '@features/defects/defects.module';
import { NetworkModule } from '@features/network/network.module';
import { TeamModule } from '@features/network/components/team/team.module';
import { RequirementsModule } from '@features/requirements/requirements.module';
import { RunModule } from '@features/run/run.module';
import { TemplatesModule } from '@features/templates/templates.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthenticationModule } from '@features/authentication/authentication.module';
import { SvgService } from '@shared/domain/services/dom/svg/svg.service';
import { registeredIcons } from '@shared/domain/services/dom/svg/everyqa.registered.icons';
import { RootModule } from '@shared/ui/components/root/root.module';
import { EQA_ICONS_PATH } from '@shared/tokens';
import { DEFAULT_ICONS_PATH } from '@shared/domain/constants';
import { SplashScreenModule } from '@shared/ui/components/splash-screen/splash-screen.module';
import localeRu from '@angular/common/locales/ru';
import { QuillModule } from 'ngx-quill';

registerLocaleData(localeRu);

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		CommonModule,
		CoreModule.forRoot(),
		DragDropModule,
		ApplicationRoutingModule,
		RootModule,
		QuillModule.forRoot({
			modules: {
				syntax: false,
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],        // toggled buttons
					['blockquote', 'code-block'],
					[{ 'header': 1 }, { 'header': 2 }],               // custom button values
					[{ 'list': 'ordered'}, { 'list': 'bullet' }],
					[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
					[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
					[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
					[{ 'align': [] }],
					['link', 'image'],                         // link and image, video
					['clean'],                                         // remove formatting button                       // link and image, video
				  ]
			}
		  }),
	],
	providers: [
		/*{
            provide: HTTP_INTERCEPTORS,
            useClass: NetworkInterceptor,
            multi: true,
        },*/
		{
			provide: EQA_ICONS_PATH,
			useValue: DEFAULT_ICONS_PATH,
		},
		LocalisationService,
	],
	exports: [],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private svgService: SvgService) {
		this.svgService.registerSvgIcons(registeredIcons);
	}
}
