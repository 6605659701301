<eqa-scroll-controls *ngIf="scrollbars$ | async" class="scrollbar"></eqa-scroll-controls>
<eqa-svg-definitions></eqa-svg-definitions>
<eqa-dropdown-host>
	<div class="host-content">
		<ng-content></ng-content>
	</div>
	<ng-content select="eqaOverContent"></ng-content>
	<eqa-dialog-host></eqa-dialog-host>
	<ng-content select="eqaOverDialogs"></ng-content>
</eqa-dropdown-host>
<eqa-hints-host></eqa-hints-host>
