import { SharedStep } from "@features/case/case-steps-shared/domain";

enum SharedStepsActions {
	GET_STEPS = '[Shared Steps Api] Get Shared Steps',
	CREATE_STEP = '[Shared Steps Api] Create Shared Step',
	REMOVE_STEP = '[Shared Steps Api] Remove Shared Step',
	UPDATE_STEP = '[Shared Steps Api] Update Shared Step',
	GET_DEFAULT_STEPS = '[Shared Steps Api] Get Default Steps',
}

export class GetSharedSteps {
	static readonly type = SharedStepsActions.GET_STEPS;

	constructor(public projectId: string) {}
}

export class GetDefaultSteps {
	static readonly type = SharedStepsActions.GET_DEFAULT_STEPS;

	constructor() {}
}

export class RemoveSharedStep {
	static readonly type = SharedStepsActions.REMOVE_STEP;

	constructor(public projectId: string, public stepId: string) {}
}

export class UpdateSharedStep {
	static readonly type = SharedStepsActions.UPDATE_STEP;

	constructor(public projectId: string, public stepId: string, public step: SharedStep) {}
}

export class CreateSharedStep {
	static readonly type = SharedStepsActions.CREATE_STEP;

	constructor(public projectId: string, public step: SharedStep) {}
}
