import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { ProjectTestProjectConfigsApiService } from '@features/projects/services/project-test-run-configs.api.service';
import { ProjectConfig } from 'src/app/features/projects/interfaces';
import {
	ClearActiveProjectConfig,
	CreateProjectConfig,
	GetProjectConfigById,
	GetProjectConfigs,
	RemoveProjectConfig,
	UpdateProjectConfig,
} from './project-configs.actions';

export interface ProjectConfigsStateModel {
	projectConfigs: ProjectConfig[];
	activeConfig: ProjectConfig;
	loading: boolean;
}

@State<ProjectConfigsStateModel>({
	name: 'projectConfigsState',
	defaults: {
		projectConfigs: [],
		activeConfig: null,
		loading: false,
	},
})
@Injectable()
export class ProjectConfigsState {
	@Selector()
	static projectConfigs(m: ProjectConfigsStateModel): ProjectConfig[] {
		return m.projectConfigs;
	}

	@Selector()
	static activeConfig(m: ProjectConfigsStateModel): ProjectConfig {
		return m.activeConfig;
	}

	@Selector()
	static loading(m: ProjectConfigsStateModel): boolean {
		return m.loading;
	}

	constructor(private projectConfigsApiService: ProjectTestProjectConfigsApiService) {}

	@Action(GetProjectConfigs)
	getConfigs({ getState, patchState }: StateContext<ProjectConfigsStateModel>, { projectId }: GetProjectConfigs) {
		patchState({ loading: true });
		return this.projectConfigsApiService.getProjectConfigs(projectId).pipe(
			tap((projectConfigs: ProjectConfig[]) => {
				patchState({
					projectConfigs,
					loading: false,
				});
			}),
		);
	}

	@Action(GetProjectConfigById)
	getConfigById(
		{ getState, patchState }: StateContext<ProjectConfigsStateModel>,
		{ projectId, id }: GetProjectConfigById,
	) {
		patchState({ loading: true });
		return this.projectConfigsApiService.getOne(projectId, id).pipe(
			tap((activeConfig: ProjectConfig) => {
				patchState({
					activeConfig,
					loading: false,
				});
			}),
		);
	}

	@Action(CreateProjectConfig)
	createProjectConfig(
		{ getState, patchState }: StateContext<ProjectConfigsStateModel>,
		{ projectId, config }: CreateProjectConfig,
	) {
		patchState({ loading: true });
		return this.projectConfigsApiService.createProjectConfig(projectId, config).pipe(
			tap((createdConfig: ProjectConfig) => {
				const projectConfigs = cloneDeep(getState().projectConfigs);
				projectConfigs.push(createdConfig);
				patchState({
					projectConfigs,
					loading: false,
				});
			}),
		);
	}

	@Action(UpdateProjectConfig)
	updateProjectConfig(
		{ getState, patchState }: StateContext<ProjectConfigsStateModel>,
		{ projectId, configId, config }: UpdateProjectConfig,
	) {
		return this.projectConfigsApiService.updateProjectConfig(projectId, configId, config).pipe(
			tap((updatedConfig) => {
				const projectConfigs = cloneDeep(getState().projectConfigs);
				const index = projectConfigs.findIndex((f) => f.id === configId);
				projectConfigs[index] = updatedConfig;
				patchState({
					projectConfigs,
					loading: false,
				});
			}),
		);
	}

	@Action(RemoveProjectConfig)
	removeProjectConfig(
		{ getState, patchState }: StateContext<ProjectConfigsStateModel>,
		{ projectId, configId }: RemoveProjectConfig,
	) {
		return this.projectConfigsApiService.deleteProjectConfig(projectId, configId).pipe(
			tap(() => {
				const projectConfigs = cloneDeep(getState().projectConfigs);
				const index = projectConfigs.findIndex((f) => f.id === configId);
				projectConfigs.splice(index, 1);
				patchState({
					projectConfigs,
					loading: false,
				});
			}),
		);
	}

	@Action(ClearActiveProjectConfig)
	clearActiveProjectConfig({ patchState }: StateContext<ProjectConfigsStateModel>) {
		patchState({
			activeConfig: null,
		});
	}
}
