import { ChangeDetectionStrategy, Component, Inject, inject, InjectionToken } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HISTORY, WINDOW } from '@ng-web-apis/common';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EQA_DIALOGS } from '@shared/tokens/dialogs';
import { EqaDialog } from '@shared/types/dialog';

export function isInsideIframe(windowRef: Window): boolean {
	return windowRef.parent !== windowRef;
}

export const EQA_DIALOG_CLOSES_ON_BACK = new InjectionToken<Observable<boolean>>(
	'Is closing dialog on browser backward navigation enabled',
	{
		factory: () => of(!isInsideIframe(inject(WINDOW))),
	},
);

export const FAKE_HISTORY_STATE = { label: 'ignoreMe' } as const;

export const isFakeHistoryState = (historyState: Record<string, unknown>): historyState is typeof FAKE_HISTORY_STATE =>
	historyState?.label === FAKE_HISTORY_STATE.label;

@Component({
	selector: 'eqa-dialog-host',
	templateUrl: './dialog-host.template.html',
	styleUrls: ['./dialog-host.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EqaDialogHostComponent<T extends EqaDialog<unknown, unknown>> {
	readonly dialogs$ = combineLatest(this.dialogsByType).pipe(
		map((allTypesDialogs) => new Array<T>().concat(...allTypesDialogs).sort((a, b) => a.createdAt - b.createdAt)),
	);

	constructor(
		@Inject(EQA_DIALOG_CLOSES_ON_BACK)
		readonly isDialogClosesOnBack$: Observable<boolean>,
		@Inject(EQA_DIALOGS) private readonly dialogsByType: Observable<readonly T[]>[],
		@Inject(HISTORY) private readonly historyRef: History,
		@Inject(Title) private readonly titleService: Title,
	) {}

	closeLast(dialogs: readonly T[], isDialogClosesOnBack: boolean) {
		if (!isDialogClosesOnBack) {
			return;
		}

		const [last] = dialogs.slice(-1);

		if (!last) {
			return;
		}

		if (dialogs.length > 1) {
			this.historyRef.pushState(FAKE_HISTORY_STATE, this.titleService.getTitle());
		}

		last.$implicit.complete();
	}

	onDialog({ propertyName }: TransitionEvent, popupOpened: boolean, isDialogClosesOnBack: boolean) {
		if (!isDialogClosesOnBack || propertyName !== 'letter-spacing') {
			return;
		}

		if (popupOpened) {
			this.historyRef.pushState(FAKE_HISTORY_STATE, this.titleService.getTitle());
		} else if (isFakeHistoryState(this.historyRef.state)) {
			this.historyRef.back();
		}
	}
}
