export class InitActivity {
	static readonly type = '[Activity API] InitActivity';

	constructor(public projectId: string) {}
}

export class GetNewActivity {
	static readonly type = '[Activity API] GetNewActivity';

	constructor(public projectId: string, public page: number) {}
}

export class GetActivity {
	static readonly type = '[Activity API] GetActivity';
}

export class SortActivity {
	static readonly type = '[Activity API] SortActivity';
}
