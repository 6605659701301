import { CustomField } from "@features/case/case-fields/domain";

enum CustomFieldsActions {
	GET_FIELDS = '[Custom Fields Api] Get Custom Fields',
	CREATE_FIELD = '[Custom Fields Api] Create Custom Field',
	REMOVE_FIELD = '[Custom Fields Api] Remove Custom Field',
	UPDATE_FIELD = '[Custom Fields Api] Update Custom Field',
}

export class LoadCustomFields {
	static readonly type = CustomFieldsActions.GET_FIELDS;

	constructor(public projectId: string) {}
}

export class RemoveCustomField {
	static readonly type = CustomFieldsActions.REMOVE_FIELD;

	constructor(public projectId: string, public fieldId: string) {}
}

export class UpdateCustomField {
	static readonly type = CustomFieldsActions.UPDATE_FIELD;

	constructor(public projectId: string, public fieldId: string, public field: CustomField) {}
}

export class CreateCustomField {
	static readonly type = CustomFieldsActions.CREATE_FIELD;

	constructor(public projectId: string, public field: CustomField) {}
}
