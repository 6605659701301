import { IntegrationProviders } from "../constants";
import { ForeignProjectInfo } from "./foreign-project-info";
import { ProjectMapping } from "./project-mapping";

export class CommonIntegration<T> {
	enabled: boolean;
	automatic: boolean;
	provider: IntegrationProviders;
	data: T;
	foreignProjects?: ForeignProjectInfo[];
	mappedProjects?: ProjectMapping[];
	currentMappedProject?: ProjectMapping;
	defectFormValue?: any;

	constructor(data: CommonIntegration<T>) {
		this.automatic = data.automatic;
		this.enabled = data.enabled;
		this.provider = data.provider;
		this.data = data?.data ?? undefined;
		this.foreignProjects = data?.foreignProjects ?? [];
		this.mappedProjects = data?.mappedProjects ?? [];
		this.currentMappedProject = data?.currentMappedProject ?? null;
		this.defectFormValue = data?.defectFormValue ?? null;
	}
}
