import { EqaDirection } from '@shared/domain/types';
import { EqaHintMode } from '@shared/domain/constants/hint-mode';

export interface EqaAbstractHintOptions {
	readonly direction: EqaDirection;
	readonly mode: EqaHintMode;
}

export const EQA_ABSTRACT_HINT_DEFAULT_OPTIONS: EqaAbstractHintOptions = {
	direction: 'bottom-left',
	mode: null,
};
