// core/core.module.ts
import { LayoutModule } from '@layout/layout.module';
import { CustomRouterStateSerializer } from '../store/router/router-state.serializer';
import { AuthGuard } from './guards/auth.guard';
import { throwIfAlreadyLoaded } from './guards/ensureModuleLoadedOnceGuard';
import { SubscriptionGuard } from './guards/subscription.guard';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { AccountService } from './services/http/auth/account.service';
import { UserService } from './services/http/user/user.service';
import { DomService } from '@shared/ui/components/modal/domain/services/modal/dom.service';
import { LocalStorageService } from './services/local-storage/local.storage.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { ColorPickerModule } from 'ngx-color-picker';
import { ToastrModule } from 'ngx-toastr';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { EqaNgxsFormPluginModule } from '@shared/plugins/form-plugin';
import { API_URL_PROVIDER } from '@core/providers/api-url.provider';
import { StoreModule } from '@core/store.module';
import { ActivityModule } from '@features/activity/activity.module';
import { TemplatesModule } from '@features/templates/templates.module';
import { NetworkModule } from '@features/network/network.module';
import { TeamModule } from '@features/network/components/team/team.module';
import { CasesRepositoryModule } from '@features/case/case-repository/cases.repository.module';
import { RequirementsModule } from '@features/requirements/requirements.module';
import { RunModule } from '@features/run/run.module';
import { DefectsModule } from '@features/defects/defects.module';
import { AuthenticationModule } from '@features/authentication/authentication.module';
import { SplashScreenModule } from '@shared/ui/components/splash-screen/splash-screen.module';
import { RootModule } from '@shared/ui/components/root/root.module';
import { UploadAttachmentsModule } from '@features/attachments/ui/components/upload-attachments/upload-attachments.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports: [
		NgSelectModule,
		UploadAttachmentsModule,
		StoreModule,
		HttpClientModule,
		JwtModule.forRoot({
			config: {
				tokenGetter,
			},
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpTranslateLoader,
				deps: [HttpClient],
			},
		}),
		ToastrModule.forRoot({
			timeOut: 1500,
			positionClass: 'toast-bottom-right',
			preventDuplicates: true,
			maxOpened: 1,
		}),
		EqaNgxsFormPluginModule.forRoot(),
		ColorPickerModule,
	],
	declarations: [],
	exports: [
		JwtModule,
		TranslateModule,
		NgxsModule,
		NgxsResetPluginModule,
		NgxsStoragePluginModule,
		NgxsRouterPluginModule,
		NgxsLoggerPluginModule,
		NgxsReduxDevtoolsPluginModule,
	],
	providers: [],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				API_URL_PROVIDER,
				AuthGuard,
				SubscriptionGuard,
				/*	{
					provide: ErrorHandler,
					useValue: Sentry.createErrorHandler({
						showDialog: false,
					}),
				},
				{
					provide: Sentry.TraceService,
					deps: [Router],
				},
				{
					provide: APP_INITIALIZER,
					useFactory: () => () => {},
					deps: [Sentry.TraceService],
					multi: true,
				},*/
				{
					provide: HTTP_INTERCEPTORS,
					useClass: JwtInterceptor,
					multi: true,
				},
				{
					provide: RouterStateSerializer,
					useClass: CustomRouterStateSerializer,
				},
				DomService,
				AccountService,
				UserService,
				LocalStorageService,
			],
		};
	}

	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}
}

export function httpTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
	return localStorage.getItem('token');
}
