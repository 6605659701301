import { Currency } from "@core/constants";
import { PlanPeriodModel } from "./plan.period.model";

export class PriceModel {
	currency: Currency;
	amount: number;
	period: PlanPeriodModel;

	constructor(model: PriceModel) {
		this.currency = model.currency;
		this.amount = model.amount;
		this.period = model.period;
	}

	getPriceObject() {
		return {
			currency: this.currency,
			amount: 0,
		};
	}
}
