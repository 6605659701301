import { EqaAppearance } from '@shared/domain/enums';
import { InjectionToken, ValueProvider } from '@angular/core';

export interface EqaButtonOptions {
	readonly appearance: EqaAppearance | string;
	readonly shape: 'square' | 'rounded' | null;
	readonly size: 's' | 'm' | 'l' | null;
}

export const EQA_BUTTON_DEFAULT_OPTIONS: EqaButtonOptions = {
	appearance: EqaAppearance.Primary,
	shape: null,
	size: 'm',
};

export const EQA_BUTTON_OPTIONS = new InjectionToken<EqaButtonOptions>('Default button params', {
	factory: () => EQA_BUTTON_DEFAULT_OPTIONS,
});

export const eqaButtonOptionsProvider: (options: Partial<EqaButtonOptions>) => ValueProvider = (
	options: Partial<EqaButtonOptions>,
) => ({
	provide: EQA_BUTTON_OPTIONS,
	useValue: { ...EQA_BUTTON_DEFAULT_OPTIONS, ...options },
});
