import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { Observable } from "rxjs";
import { ReviewStatus } from "../../constants/review-status";
import { CaseReviewServiceInterface } from "../../interfaces/case-review-service";
import { CaseReviewDto } from "../../interfaces/case-review.dto";
import { CreateCaseReviewDto } from "../../interfaces/create-case-review.dto";

@Injectable({ providedIn: 'root' })
export class CaseReviewApiService implements CaseReviewServiceInterface {
	private apiUrl = ServerUrl.HOME_URL;
	private reviewUrls = {
		listByProject: '/case-reviews/list/project/',
		create: '/case-reviews/create',
		one: '/case-reviews/',
		update: '/case-reviews/',
		changeStatus: '/case-reviews/',
	};

	constructor(private httpClient: HttpClient) {}

	public changeReviewStatus(reviewId: string, changeStatusDto: { status: ReviewStatus }): Observable<CaseReviewDto> {
		return this.httpClient.put<CaseReviewDto>(
			`${this.apiUrl}${this.reviewUrls.changeStatus}${reviewId}/status`,
			changeStatusDto,
		);
	}

	public createCaseReview(caseReview: Partial<CreateCaseReviewDto>): Observable<any> {
		return this.httpClient.post(`${this.apiUrl}${this.reviewUrls.create}`, caseReview);
	}

	public loadCaseReviewById(caseReviewId: string): Observable<CaseReviewDto> {
		return this.httpClient.get<CaseReviewDto>(`${this.apiUrl}${this.reviewUrls.one}${caseReviewId}`);
	}

	public loadProjectCaseReviews(projectId: string, status?: ReviewStatus): Observable<CaseReviewDto[]> {
		let url = `${this.apiUrl}${this.reviewUrls.listByProject}${projectId}`;
		if (status) {
			url += `?status=${status}`;
		}
		return this.httpClient.get<CaseReviewDto[]>(url);
	}

	public removeCaseReview(reviewId: string): Observable<CaseReviewDto> {
		return undefined;
	}

	public updateCaseReview(reviewId: string, caseReview: Partial<CaseReviewDto>): Observable<CaseReviewDto> {
		return this.httpClient.put<CaseReviewDto>(`${this.apiUrl}${this.reviewUrls.update}${reviewId}`, caseReview);
	}
}
