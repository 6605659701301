import { ComponentFactory, ComponentRef, EmbeddedViewRef, Injectable, Injector, TemplateRef } from '@angular/core';
import { EqaNoHostException } from '@shared/domain/exceptions';
import { EqaPortalHostComponent } from '@shared/ui/components/portal-host/portal-host.component';

@Injectable({
	providedIn: 'root',
})
export class EqaPortalService {
	private host?: EqaPortalHostComponent;

	private get safeHost(): EqaPortalHostComponent {
		if (!this.host) {
			throw new EqaNoHostException();
		}

		return this.host;
	}

	attach(host: EqaPortalHostComponent): void {
		this.host = host;
	}

	add<C>(factory: ComponentFactory<C>, injector: Injector): ComponentRef<C> {
		return this.safeHost.addComponentChild(factory, injector);
	}

	remove<C>({ hostView }: ComponentRef<C>) {
		hostView.destroy();
	}

	addTemplate<C>(templateRef: TemplateRef<C>, context?: C): EmbeddedViewRef<C> {
		return this.safeHost.addTemplateChild(templateRef, context);
	}

	removeTemplate<C>(viewRef: EmbeddedViewRef<C>) {
		viewRef.destroy();
	}
}
