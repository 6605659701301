import { Injectable } from "@angular/core";
import { TestCaseTag } from "@features/case/case-tags/domain/interfaces/TestCaseTag";
import { CaseTagsApiService } from "@features/case/case-tags/domain/services/case-tags-api.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { cloneDeep } from "lodash";
import { pluck, tap } from "rxjs/operators";
import { CreateCaseTag, LoadCaseTagsListByProject, RemoveCaseTag, UpdateCaseTag } from "./case-tags.actions";

export interface CaseTagsStateModel {
	tagList: TestCaseTag[];
	tagsLoading: boolean;
}

@State<CaseTagsStateModel>({
	name: 'caseTagsState',
	defaults: {
		tagList: [],
		tagsLoading: true,
	},
})
@Injectable()
export class CaseTagsState {
	constructor(private tagsApiService: CaseTagsApiService) {}

	@Selector()
	static tagsLoading(testCaseStateModel: CaseTagsStateModel): boolean {
		return testCaseStateModel.tagsLoading;
	}

	@Selector()
	static tagList(testCaseStateModel: CaseTagsStateModel): TestCaseTag[] {
		return testCaseStateModel.tagList;
	}

	@Action(LoadCaseTagsListByProject)
	getTagsListByProject({ patchState }: StateContext<CaseTagsStateModel>, { orderById }: LoadCaseTagsListByProject) {
		patchState({ tagsLoading: true });
		return this.tagsApiService.getTagsByProject().pipe(
			pluck('data'),
			tap((tagList: TestCaseTag[]) => {
				const orderedTagList = orderById ? tagList.sort((a, b) => +b.id - +a.id) : tagList;
				patchState({ tagList: orderedTagList, tagsLoading: false });
			}),
		);
	}

	@Action(CreateCaseTag)
	createCaseTag({ getState, patchState }: StateContext<CaseTagsStateModel>, { createTag }: CreateCaseTag) {
		const state = getState();
		const tagList: TestCaseTag[] = cloneDeep(state.tagList);
		return this.tagsApiService.createTag(createTag).pipe(
			pluck('data'),
			tap((tags: TestCaseTag[]) => {
				tagList.unshift(...tags);
				patchState({ tagList });
			}),
		);
	}

	@Action(UpdateCaseTag)
	updateCaseTag({ getState, patchState }: StateContext<CaseTagsStateModel>, { caseTag }: UpdateCaseTag) {
		const state = getState();
		const tagList: TestCaseTag[] = cloneDeep(state.tagList);
		const index = tagList.findIndex((t) => t.id === caseTag.id);
		return this.tagsApiService.updateTag(caseTag).pipe(
			pluck('data'),
			tap((tag: TestCaseTag) => {
				tagList[index] = tag;
				patchState({ tagList });
			}),
		);
	}

	@Action(RemoveCaseTag)
	removeCaseTag({ getState, patchState }: StateContext<CaseTagsStateModel>, { caseTagId }: RemoveCaseTag) {
		const state = getState();
		const tagList: TestCaseTag[] = cloneDeep(state.tagList);
		return this.tagsApiService.removeTag(caseTagId).pipe(
			tap(() => {
				patchState({
					tagList: tagList.filter((t) => t.id !== caseTagId),
				});
			}),
		);
	}
}
