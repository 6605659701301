import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem } from '@layout/domain/interfaces/MenuItem';

@Pipe({
	name: 'eqaComputeLinkUrl',
})
export class EqaComputeLinkUrlPipe implements PipeTransform {
	transform(menuItem: MenuItem): string {
		return menuItem.link?.url.join('/');
	}
}
