import { Observable } from 'rxjs';
import { filter, mapTo, startWith, switchMapTo, take } from 'rxjs/operators';
import { typedFromEvent } from './typed-from-event';
import { EqaOwnerDocumentException } from '@shared/domain/exceptions/owner-document.exception';
import { mouseDragFinishFrom } from './mouse-drag-finish-from';

export interface EqaPressedObservableOptions {
	onlyTrusted: boolean;
}

export function pressedObservable(
	element: Element,
	{ onlyTrusted }: EqaPressedObservableOptions = { onlyTrusted: true },
): Observable<boolean> {
	const { ownerDocument } = element;

	if (!ownerDocument) {
		throw new EqaOwnerDocumentException();
	}

	return typedFromEvent(element, 'mousedown').pipe(
		filter(({ isTrusted }) => isTrusted || !onlyTrusted),
		switchMapTo(mouseDragFinishFrom(ownerDocument).pipe(mapTo(false), take(1), startWith(true))),
	);
}
