import { animate, style, transition, trigger } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from "@core/services/config-service";
import { AccountService } from "@core/services/http/auth/account.service";
import { UserService } from "@core/services/http/user/user.service";

@Component({
	selector: 'eqa-network-prepare-component',
	templateUrl: './network-prepare.component.html',
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [
				style({ height: 0, opacity: 0 }),
				animate('50ms ease-out', style({ height: 84, opacity: 1 })),
			]),
			transition(':leave', [
				style({ height: 84, opacity: 1 }),
				animate('50ms ease-in', style({ height: 0, opacity: 0 })),
			]),
		]),
	],
})
export class NetworkPrepareComponent implements OnInit {
	constructor(
		private router: Router,
		private userService: UserService,
		private accountService: AccountService,
		private configService: ConfigService,
	) {}

	ngOnInit() {
		if (this.configService.isDemoVersion) {
			this.userService.createDemoUser().subscribe(({ user, token: { accessToken } }: any) => {
				this.accountService.storeUserData(user, accessToken);
				this.router.navigate(['/', 'projects']);
			});
		} else {
			this.router.navigate(['/', 'projects']);
		}
	}
}
