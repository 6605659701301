import { CommentEntityType } from "../constants/comment-entity-type";
import { Comment } from "../models/comment";
import { CommentsActionsEnum } from "./comments.actions.enum";

export class GetComments {
	static readonly type = CommentsActionsEnum.GET_COMMENTS;

	constructor(public entityId: string, public entityType: CommentEntityType) {}
}

export class RemoveComment {
	static readonly type = CommentsActionsEnum.REMOVE_COMMENT;

	constructor(public commentId: string) {}
}

export class AddComment {
	static readonly type = CommentsActionsEnum.ADD_COMMENT;

	constructor(public comment: Comment) {}
}

export class UpdateComment {
	static readonly type = CommentsActionsEnum.UPDATE_COMMENT;

	constructor(public comment: Comment) {}
}
