import { CommentEntityType } from "./comment-entity-type";

export const COMMENT_ENTITY_TYPE_URL_MAPPINGS = {
	[CommentEntityType.TEST]: 'tests',
	[CommentEntityType.DEFECT]: 'defects',
	[CommentEntityType.CASE_REVIEW]: 'reviews',
};

export const URL_COMMENT_ENTITY_TYPE_MAPPINGS = {
	tests: CommentEntityType.TEST,
	defects: CommentEntityType.DEFECT,
};
