import { getNativeFocused } from './get-native-focused';
import { setNativeFocused } from '@shared/domain/utils';

export function blurNativeFocused(documentRef: Document) {
	const activeElement = getNativeFocused(documentRef);

	if (activeElement instanceof HTMLElement) {
		setNativeFocused(activeElement, false);
	}
}
