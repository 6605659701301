
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServerUrl } from '@core/constants/server-url';
import { TestResultRequestModel } from '@features/run/domain/interfaces/test/test.result.request.model';
import { Observable } from 'rxjs';
import { Test } from '@features/run/domain/interfaces/test/test';

@Injectable({ providedIn: 'root' })
export class TestApiService {
	private baseUrl = ServerUrl.HOME_URL;
	private testUrl = this.baseUrl + '/test';

	constructor(private http: HttpClient) {}

	getTestById(id: string): Observable<Test> {
		return this.http.get<Test>(this.testUrl + '/' + id);
	}

	getTestStatisticsByProjectId(projectId: string) {
		return this.http.get(this.testUrl + '/statistics/' + projectId);
	}

	createTest(runId: string, testsIds: string[]) {
		let httpParams = new HttpParams();
		httpParams = httpParams.append('deleteExcluded', String(true));
		return this.http.post(this.testUrl, { runId, caseIds: testsIds }, { params: httpParams });
	}

	createTestsForAllTestCases(runId: string, projectId: string) {
		return this.http.post(`${this.testUrl}/list`, { runId, projectId });
	}

	saveTestsResults(resultRequest: TestResultRequestModel) {
		return this.http.post(this.testUrl + '/results', resultRequest);
	}

	assignTests(testRunId, testsIds, assignTo) {
		return this.http.post(this.testUrl + '/assign', { runId: testRunId, testIds: testsIds, assignTo });
	}

	getTodosByProject(projectId) {
		return this.http.get(this.testUrl + '/assigned/project/' + projectId);
	}

	getHistoryByTestCaseId(testCaseId: string) {
		return this.http.get(this.testUrl + '/list/case/' + testCaseId);
	}

	getMappedTests(runId: string) {
		return this.http.get(`${this.testUrl}/list/run/${runId}`);
	}
}
