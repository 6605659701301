<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<ng-container *ngIf="isBrowser; else server">
		<defs *ngFor="let def of items" [innerHTML]="def"></defs>
	</ng-container>
	<ng-template #server>
		<defs *ngFor="let def of items">
			<svg [outerHTML]="def"></svg>
		</defs>
	</ng-template>
</svg>
