import { ApiToken } from '@features/user-settings/interfaces/api.token';

enum TokensActions {
	GET_TOKENS = '[Tokens Api] Get Tokens',
	CREATE_TOKEN = '[Tokens Api] Create Token',
	REMOVE_TOKEN = '[Tokens Api] Remove Token',
}

export class GetTokens {
	static readonly type = TokensActions.GET_TOKENS;

	constructor() {}
}

export class RemoveToken {
	static readonly type = TokensActions.REMOVE_TOKEN;

	constructor(public tokenId: string) {}
}

export class CreateToken {
	static readonly type = TokensActions.CREATE_TOKEN;

	constructor(public token: ApiToken) {}
}
