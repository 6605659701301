import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
	selector: 'eqa-legacy-loading',
	templateUrl: './loader.component.html',
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
	@Input() margins = 0;

	constructor() {}

	ngOnInit() {}

	ngOnDestroy() {}
}
