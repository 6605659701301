import { ElementRef, Inject, Injectable, NgZone, Self } from '@angular/core';
import { ANIMATION_FRAME, WINDOW } from '@ng-web-apis/common';
import { fromEvent, merge, Observable } from 'rxjs';
import { delay, distinctUntilChanged, map, startWith, takeUntil, throttleTime } from 'rxjs/operators';
import { DestroyService, EqaParentsScrollService } from '.';
import { eqaZoneOptimized } from '@shared/domain/observables/zone-free';
import { getElementObscurers } from '../utils/dom/get-element-obscures';

export const POLLING_TIME = 1000 / 15;

@Injectable()
export class EqaObscuredService extends Observable<null | ReadonlyArray<Element>> {
	private readonly obscured$: Observable<null | ReadonlyArray<Element>>;

	constructor(
		@Inject(EqaParentsScrollService)
		@Self()
		parentsScroll$: EqaParentsScrollService,
		@Inject(ElementRef) { nativeElement }: ElementRef<Element>,
		@Inject(NgZone) ngZone: NgZone,
		@Inject(WINDOW) windowRef: Window,
		@Inject(DestroyService) destroy$: Observable<void>,
		@Inject(ANIMATION_FRAME) animationFrame$: Observable<number>,
	) {
		super((subscriber) => this.obscured$.subscribe(subscriber));

		this.obscured$ = merge(
			merge(parentsScroll$, fromEvent(windowRef, 'resize')).pipe(delay(0)),
			animationFrame$.pipe(throttleTime(POLLING_TIME)),
		).pipe(
			map(() => getElementObscurers(nativeElement)),
			startWith(null),
			distinctUntilChanged(),
			eqaZoneOptimized(ngZone),
			takeUntil(destroy$),
		);
	}
}
