import { ChatIntegrationProviders } from '@features/integrations/domain/constants';
import { Currency } from '../constants';
import { Injectable } from '@angular/core';
import { compareAsc, format, parseISO } from 'date-fns';
import { Testcase } from '@features/case/case-repository/domain/interfaces/testcase';

const SECOND = 1;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

@Injectable({ providedIn: 'root' })
export class Utils {
	constructor() {}

	static compareDates(dateStart, dateRight) {
		return compareAsc(parseISO(dateStart), parseISO(dateRight));
	}

	static formatDate(date, toFormat) {
		return format(date, toFormat);
	}

	static parseStringToDate(date, toFormat) {
		return format(parseISO(date), toFormat);
	}

	static getMonthFromStringDate(date) {
		return Utils.parseStringToDate(date, 'MMMM');
	}

	static getZeroPrice(currency: Currency) {
		return {
			currency,
			amount: 0,
		};
	}

	static getEstimatePartsFromTimestamp(value: number, { hoursInDay = 8, daysInWeek = 7 } = {}): string[] {
		const DAY = hoursInDay * HOUR;

		const units = [DAY, HOUR, MINUTE, SECOND];

		let tail = value;
		let curValue;

		return units.reduce((acc, cur) => {
			curValue = tail;
			tail = curValue % cur;
			return [...acc, (curValue - tail) / cur + ''];
		}, []);
	}

	static parseEstimate(value: string | number, { hoursInDay = 8, daysInWeek = 7 } = {}): number {
		const DAY = hoursInDay * HOUR;
		const WEEK = daysInWeek * DAY;

		if (value === null || value === undefined || value === '' || value === 0) {
			// eslint-disable-line
			return 0;
		}
		if (typeof value === 'number') {
			return value;
		}

		const values = value
			.replace(/([\d\.]+)\s*([^\d\s]+)/g, '$1$2')
			.split(' ')
			.map((s) => s.trim())
			.filter(Boolean);
		if (values.length > 1) {
			return values.map((v) => Utils.parseEstimate(v, { hoursInDay, daysInWeek })).reduce((a, b) => a + b, 0);
		}

		const result = value.match(
			/^(?<value>[\d\.]+)\s*(?<modifier>seconds|second|sec|s|minutes|minute|min|m|hours|hour|hrs|hr|h|days|day|d|weeks|week|w)?$/,
		);
		const group = result ? result['groups'] : { value: '0' };

		const c = parseFloat(group.value) || 0;
		switch (group.modifier) {
			case 's':
			case 'sec':
			case 'second':
			case 'seconds':
				return Math.round(c * SECOND);
			case 'm':
			case 'min':
			case 'minute':
			case 'minutes':
				return Math.round(c * MINUTE);
			case 'h':
			case 'hr':
			case 'hrs':
			case 'hour':
			case 'hours':
				return Math.round(c * HOUR);
			case 'days':
			case 'day':
			case 'd':
				return Math.round(c * DAY);
			case 'weeks':
			case 'week':
			case 'w':
				return Math.round(c * WEEK);
			default:
				return Math.round(c * SECOND);
		}
	}

	static formatEstimate(value: number, { hoursInDay = 8, daysInWeek = 5 } = {}): string {
		const DAY = HOUR * hoursInDay;
		const WEEK = DAY * daysInWeek;
		const weeks = Math.floor(value / WEEK);
		const days = Math.floor((value - weeks * WEEK) / DAY);
		const hours = Math.floor((value - weeks * WEEK - days * DAY) / HOUR);
		const minutes = Math.floor((value - weeks * WEEK - days * DAY - hours * HOUR) / MINUTE);
		const seconds = value - weeks * WEEK - days * DAY - hours * HOUR - minutes * MINUTE;

		let result = '';
		if (weeks > 0) {
			result += `${weeks}w`;
		}
		if (days > 0) {
			result += ` ${days}d`;
		}
		if (hours > 0) {
			result += ` ${hours}h`;
		}
		if (minutes > 0) {
			result += ` ${minutes}m`;
		}
		if (seconds > 0) {
			result += ` ${seconds}s`;
		}

		return result.trim() || '0';
	}

	static resolveChatUrlProviderParam(provider: ChatIntegrationProviders) {
		switch (provider) {
			case ChatIntegrationProviders.SLACK:
				return 'slack';
			case ChatIntegrationProviders.TELEGRAM_BOT:
				return 'telegram-bot';
		}
	}

	static resolveChatMessageProvider(provider: ChatIntegrationProviders) {
		switch (provider) {
			case ChatIntegrationProviders.SLACK:
				return 'txt_slack_report_sent';
			case ChatIntegrationProviders.TELEGRAM_BOT:
				return 'txt_telegram_report_sent';
		}
	}

	static sortCasesByOrder(cases: Testcase[], casesOrder: string[]): Testcase[] {
		if (!casesOrder.length || casesOrder.length !== cases.length) {
			return cases;
		}

		cases.sort(function (a, b) {
			return casesOrder.indexOf(a.id) - casesOrder.indexOf(b.id)
		})

		return cases;
	}

	dateToMedium(dateString: string) {
		return Utils.parseStringToDate(dateString, 'yyyy-MM-dd');
	}
}
