import { Injectable } from "@angular/core";
import { MoveRequirementsDto } from "@features/requirements/domain/interfaces/requirement/move.requirement.dto";
import { RemoveRequirementsDto } from "@features/requirements/domain/interfaces/requirement/remove.requirements.dto";
import { RequirementDto } from "@features/requirements/domain/interfaces/requirement/requirement.dto";
import { RequirementApiService } from "@features/requirements/domain/services/requirement.api.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { cloneDeep } from "lodash";
import { pluck, tap } from "rxjs/operators";
import {
	CopyRequirements,
	CreateRequirement,
	GetLinkedCases,
	GetRequirement,
	GetRequirementsByGroup,
	LinkCases,
	MoveRequirements,
	RemoveRequirement,
	RemoveRequirements,
	SelectAllRequirements,
	SelectRequirement,
	SetCurrentRequirement,
	UpdateRequirement
} from "./requirement.actions";
import { RequirementStateModel } from "./requirement.state.model";

@State<RequirementStateModel>({
	name: 'requirementState',
	defaults: {
		requirementLoading: false,
		requirementsLoading: false,
		requirementsList: [],
		selectedRequirementsIds: [],
		currentRequirement: null,
	},
})
@Injectable()
export class RequirementState {
	constructor(private requirementApiService: RequirementApiService) {}

	@Selector()
	static requirements(m: RequirementStateModel): RequirementDto[] {
		return m.requirementsList;
	}

	@Selector()
	static bulkActionsEnabled(m: RequirementStateModel): boolean {
		return m.selectedRequirementsIds.length > 0;
	}

	@Selector()
	static selectedRequirementsIds(m: RequirementStateModel): string[] {
		return m.selectedRequirementsIds;
	}

	@Selector()
	static requirement(m: RequirementStateModel): RequirementDto {
		return m.currentRequirement;
	}

	@Selector()
	static empty(m: RequirementStateModel): boolean {
		return m.requirementsList.length === 0;
	}

	@Selector()
	static requirementLoading(m: RequirementStateModel): boolean {
		return m.requirementLoading;
	}

	@Action(GetRequirementsByGroup)
	getRequirementByGroup(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ projectId, groupId }: GetRequirementsByGroup,
	) {
		patchState({
			requirementsLoading: true,
		});
		return this.requirementApiService.getByGroup(projectId, groupId).pipe(
			pluck('data'),
			tap((requirementsDtos: RequirementDto[]) => {
				patchState({
					requirementsLoading: false,
					requirementsList: requirementsDtos,
				});
			}),
		);
	}

	@Action(GetRequirement)
	getRequirement(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ projectId, groupId, requirementId }: GetRequirement,
	) {
		patchState({
			requirementLoading: true,
		});
		return this.requirementApiService.getOneById(projectId, groupId, requirementId).pipe(
			pluck('data'),
			tap((requirementDto: RequirementDto) => {
				patchState({
					requirementLoading: false,
					currentRequirement: requirementDto,
				});
			}),
		);
	}

	@Action(SetCurrentRequirement)
	setCurrentRequirement(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ requirementDto }: SetCurrentRequirement,
	) {
		patchState({
			currentRequirement: requirementDto,
		});
	}

	@Action(CreateRequirement)
	createRequirement(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ projectId, requirement }: CreateRequirement,
	) {
		patchState({
			requirementsLoading: true,
		});
		return this.requirementApiService.createRequirement(projectId, requirement).pipe(
			pluck('data'),
			tap((requirementDto: RequirementDto) => {
				patchState({
					requirementsLoading: false,
					currentRequirement: requirementDto,
				});
			}),
		);
	}

	@Action(UpdateRequirement)
	updateRequirement(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ projectId, requirement, requirementId }: UpdateRequirement,
	) {
		return this.requirementApiService.updateRequirement(projectId, requirement, requirementId).pipe(
			pluck('data'),
			tap((requirementDto: RequirementDto) => {
				patchState({
					currentRequirement: requirementDto,
				});
			}),
		);
	}

	@Action(RemoveRequirement)
	removeRequirement(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ projectId, requirement }: RemoveRequirement,
	) {
		return this.requirementApiService.removeRequirement(projectId, requirement.groupId, requirement.id).pipe(
			tap(() => {
				const reqList = cloneDeep(getState().requirementsList);
				const index = reqList.findIndex((r) => r.id === requirement.id);
				reqList.splice(index, 1);
				patchState({
					requirementsList: reqList,
				});
			}),
		);
	}

	@Action(CopyRequirements)
	copyRequirements(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ projectId, groupId }: CopyRequirements,
	) {
		const selectedIds = getState().selectedRequirementsIds;
		const moveRequirementsDto = {
			group: groupId,
			ids: selectedIds,
		} as MoveRequirementsDto;

		return this.requirementApiService.copyRequirements(projectId, moveRequirementsDto).pipe(
			tap(() => {
				patchState({
					selectedRequirementsIds: [],
				});
			}),
		);
	}

	@Action(MoveRequirements)
	moveRequirements(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ projectId, groupId }: MoveRequirements,
	) {
		const selectedIds = getState().selectedRequirementsIds;
		const moveRequirementsDto = {
			group: groupId,
			ids: selectedIds,
		} as MoveRequirementsDto;

		return this.requirementApiService.moveRequirements(projectId, moveRequirementsDto).pipe(
			tap(() => {
				let reqList = cloneDeep(getState().requirementsList);
				reqList = reqList.filter((r) => !selectedIds.includes(r.id));
				patchState({
					requirementsList: reqList,
					selectedRequirementsIds: [],
				});
			}),
		);
	}

	@Action(RemoveRequirements)
	removeRequirements(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ projectId, groupId }: RemoveRequirements,
	) {
		const selectedIds = getState().selectedRequirementsIds;
		const removeRequirementsDto = {
			ids: selectedIds,
		} as RemoveRequirementsDto;

		return this.requirementApiService.removeRequirements(projectId, groupId, removeRequirementsDto).pipe(
			tap(() => {
				let reqList = cloneDeep(getState().requirementsList);
				reqList = reqList.filter((r) => !selectedIds.includes(r.id));
				patchState({
					requirementsList: reqList,
					selectedRequirementsIds: [],
				});
			}),
		);
	}

	@Action(SelectRequirement)
	selectRequirement(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ requirement, flag }: SelectRequirement,
	) {
		const selectedRequirementsIds = cloneDeep(getState().selectedRequirementsIds);
		const reqIndex = selectedRequirementsIds.findIndex((id) => id === requirement.id);
		if (reqIndex < 0 && flag) {
			selectedRequirementsIds.push(requirement.id);
		}
		if (!flag) {
			selectedRequirementsIds.splice(reqIndex, 1);
		}
		patchState({
			selectedRequirementsIds: selectedRequirementsIds,
		});
	}

	@Action(SelectAllRequirements)
	selectAllRequirements(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ flag }: SelectAllRequirements,
	) {
		const requirements = cloneDeep(getState().requirementsList);
		if (flag) {
			patchState({
				selectedRequirementsIds: requirements.map((r) => r.id),
			});
		} else {
			patchState({
				selectedRequirementsIds: [],
			});
		}
	}

	@Action(LinkCases)
	linkCases(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ projectId, requirement, casesIds }: LinkCases,
	) {
		return this.requirementApiService.linkCases(projectId, requirement.groupId, requirement.id, casesIds).pipe(
			pluck('data'),
			tap((requirementDto: RequirementDto) => {
				patchState({
					currentRequirement: requirementDto,
				});
			}),
		);
	}

	@Action(GetLinkedCases)
	getLinkedCases(
		{ getState, setState, patchState }: StateContext<RequirementStateModel>,
		{ projectId, groupId, requirementId }: GetLinkedCases,
	) {
		return this.requirementApiService.getLinkedCases(projectId, groupId, requirementId).pipe(
			pluck('data'),
			tap((linkedCases: any[]) => {
				const currentReq = cloneDeep(getState().currentRequirement);
				currentReq.linkedCases = linkedCases;
				patchState({
					currentRequirement: currentReq,
				});
			}),
		);
	}
}
