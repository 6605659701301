import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef,
	HostBinding,
	HostListener,
	Inject,
	Input,
} from '@angular/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { EQA_FOCUSABLE_ITEM_ACCESSOR } from "../../../tokens";
import { AbstractEqaInteractive } from "../../../domain/abstracts";
import { EqaFocusableElementAccessor } from '../../../domain/interfaces/focusable-element-accessor';
import { DestroyService, EqaFocusVisibleService, EqaHoveredService } from '../../../domain';
import { Observable } from 'rxjs';
import { EQA_TAKE_ONLY_TRUSTED_EVENTS } from "../../../tokens";
import { watch } from '../../../domain/observables';
import { pressedObservable } from '../../../domain/observables/pressed-observable';
import { takeUntil } from 'rxjs/operators';
import { isNativeFocused } from "../../../domain/utils/focus";
import { EqaAppearance } from '../../../domain/enums';
import { EQA_BUTTON_OPTIONS, EqaButtonOptions } from './button.options';

@Component({
	selector: 'button[eqaButton], button[eqaIconButton], a[eqaButton], a[eqaIconButton]',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: EQA_FOCUSABLE_ITEM_ACCESSOR,
			useExisting: forwardRef(() => EqaButtonComponent),
		},
		DestroyService,
		EqaFocusVisibleService,
	],
})
export class EqaButtonComponent
	extends AbstractEqaInteractive
	implements EqaFocusableElementAccessor, EqaButtonOptions
{
	@Input()
	@HostBinding('attr.data-appearance')
	appearance: EqaAppearance | string = this.options.appearance;

	@Input()
	@HostBinding('attr.data-size')
	size = this.options.size;

	@Input()
	disabled = false;

	@Input()
	tooltip = '';

	@Input()
	icon: PolymorpheusContent = '';

	@Input()
	iconRight: PolymorpheusContent = '';

	@Input()
	iconLeft: PolymorpheusContent = '';

	@Input()
	@HostBinding('attr.data-shape')
	shape = this.options.shape;

	@Input()
	@HostBinding('class._loading')
	showLoader = false;

	constructor(
		@Inject(ElementRef) private readonly elementRef: ElementRef<HTMLElement>,
		@Inject(EqaFocusVisibleService) focusVisible$: EqaFocusVisibleService,
		@Inject(EqaHoveredService) hoveredService: EqaHoveredService,
		@Inject(DestroyService) destroy$: Observable<void>,
		@Inject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef,
		@Inject(EQA_TAKE_ONLY_TRUSTED_EVENTS)
		private readonly takeOnlyTrustedEvents: boolean,
		@Inject(EQA_BUTTON_OPTIONS) private readonly options: EqaButtonOptions,
	) {
		super();

		hoveredService
			.createHovered$(elementRef.nativeElement)
			.pipe(watch(changeDetectorRef), takeUntil(destroy$))
			.subscribe((hovered) => {
				this.updateHovered(hovered);
			});
		pressedObservable(elementRef.nativeElement, {
			onlyTrusted: this.takeOnlyTrustedEvents,
		})
			.pipe(watch(changeDetectorRef), takeUntil(destroy$))
			.subscribe((pressed) => {
				this.updatePressed(pressed);
			});
		focusVisible$.subscribe((focusVisible) => {
			this.updateFocusVisible(focusVisible);
		});
	}

	get nativeFocusableElement(): HTMLElement | null {
		return this.nativeDisabled ? null : this.elementRef.nativeElement;
	}

	get focused(): boolean {
		return !this.showLoader && isNativeFocused(this.elementRef.nativeElement);
	}

	@HostBinding('attr.disabled')
	get nativeDisabled(): '' | null {
		return this.computedDisabled || this.showLoader ? '' : null;
	}

	@HostBinding('tabIndex')
	get tabIndex(): number {
		return this.focusable ? 0 : -1;
	}

	@HostListener('focusin', ['true'])
	@HostListener('focusout', ['false'])
	onFocused(focused: boolean) {
		this.updateFocused(focused);
	}
}
