import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { pluck, tap } from 'rxjs/operators';
import { TestApiService } from '@features/run/domain/services/api/test-execution/test.api.service';
import { Filter } from '@shared/ui/components/filters/domain/interfaces/new.filter';
import { Section } from '@features/case/case-repository/domain/interfaces/section';
import { Test } from '@features/run/domain/interfaces/test/test';
import { TestResult } from '@features/run/domain/interfaces/test/test.result';
import { Testcase } from '@features/case/case-repository/domain/interfaces/testcase';
import {
	ActivateSection,
	ActivateTest,
	AssignTest,
	AssignTests,
	BuildFlatTree,
	LoadNext,
	LoadPrevious,
	RemoveFilterByKey,
	SelectSection,
	SelectSectionContentEntity,
	SetActiveTestId,
	SetFilters,
	SetResult,
	SetShowed,
	SetStepStatus,
	SetTestsCount,
	ToggleSection,
	UncheckAll,
	UpdateSearch,
} from './flat.tree.actions';
import { FlatTreeStateModel } from './flat.tree.state.model';
import { HttpSectionsService } from '@features/case/case-repository/domain/services/http-sections.service';
import produce from 'immer';
import { getFirstElement } from '@shared/domain/utils/array';

@State<FlatTreeStateModel>({
	name: 'flatTreeState',
	defaults: {
		testTree: null,
		search: '',
		filters: {},
		activeSection: null,
		activeSectionsIds: [],
		activeTestsIds: [],
		expandedSectionsIds: [],
		selectedSectionsIds: [],
		selectedTestsIds: [],
		activeTest: null,
		loading: true,
		savingResult: false,
		result: null,
		testsCount: 0,
		showed: false,
	},
})
@Injectable()
export class FlatTreeState {
	@Selector()
	static tree(model: FlatTreeStateModel): Section[] {
		const filters =
			Object.entries(model.filters)?.map(([key, v]) => ({
				key,
				value: v.value.map((val) => val.name),
			})) ?? [];
		const search = model.search;

		const conditions: Array<(c: Testcase | Test) => boolean> = [];

		if (!filters?.length && !search) {
			return model.testTree;
		}

		if (filters?.length) {
			conditions.push((c: Testcase | Test) =>
				filters.some(({ key, value }) => {
					switch (key) {
						case 'assignedTo': {
							return (<Test>c).assignedTo === null
								? value.includes('None')
								: value.includes((<Test>c).assignedTo);
						}
						case 'status': {
							return value.includes(c.status.name);
						}
						case 'tags': {
							return c.tags.some((t) => value.includes(t.name));
						}
						default:
							return false;
					}
				}),
			);
		}
		if (search) {
			conditions.push((c: Testcase | Test) => c.name.toLowerCase().includes(search.toLowerCase()));
		}

		const filtered: Section[] = [];
		model.testTree.forEach((s) => {
			const newSec = cloneDeep(s);
			newSec.cases = newSec.cases?.filter((c) => conditions.every((cond) => cond(c))) ?? [];
			if (newSec.cases?.length) {
				filtered.push(newSec);
			}
		});
		return filtered;
	}

	@Selector()
	static showed(m: FlatTreeStateModel): boolean {
		return m.showed;
	}

	@Selector()
	static filters(m: FlatTreeStateModel): Filter<any> {
		return m.filters;
	}

	@Selector()
	static search(m: FlatTreeStateModel): string {
		return m.search;
	}

	@Selector()
	static testsCount(model: FlatTreeStateModel): number {
		return model.testsCount;
	}

	@Selector()
	static result(model: FlatTreeStateModel): TestResult {
		return model.result;
	}

	@Selector()
	static loading(model: FlatTreeStateModel): boolean {
		return model.loading;
	}

	@Selector()
	static savingResult(model: FlatTreeStateModel): boolean {
		return model.savingResult;
	}

	@Selector()
	static activeSection(model: FlatTreeStateModel): Section {
		return model.activeSection;
	}

	@Selector()
	static activeSectionsIds(model: FlatTreeStateModel): string[] {
		return model.activeSectionsIds;
	}

	@Selector()
	static selectedSectionsIds(model: FlatTreeStateModel): string[] {
		return model.selectedSectionsIds;
	}

	@Selector()
	static selectedTestsIds(model: FlatTreeStateModel): string[] {
		return model.selectedTestsIds;
	}

	@Selector()
	static expandedSectionsIds(model: FlatTreeStateModel): string[] {
		return model.expandedSectionsIds;
	}

	@Selector()
	static activeTestsIds(model: FlatTreeStateModel): string[] {
		return model.activeTestsIds;
	}

	@Selector()
	static activeTest(model: FlatTreeStateModel): any {
		return model.activeTest;
	}

	constructor(private sectionApiService: HttpSectionsService, private testApiService: TestApiService) {}

	@Action(BuildFlatTree)
	buildFlatTree({ getState, patchState }: StateContext<FlatTreeStateModel>, { sections }: BuildFlatTree) {
		const activeTestIds = _.cloneDeep(getState().activeTestsIds);
		const _sections = _.cloneDeep(sections);
		_sections.forEach((section) => {
			const tests = section.cases;
			const total = tests.length;
			section['stats'] = tests.reduce((acc: {}, obj: Testcase) => {
				const key = obj.status.name;
				if (!acc[key]) {
					acc[key] = {
						color: obj.status.color,
						name: obj.status.name,
						count: 0,
						percentage: 0,
					};
				}
				acc[key].count++;
				acc[key].percentage = ((acc[key].count / total) * 100).toFixed();
				return acc;
			}, {});
		});

		const findActiveSection = (ss: Section[]) => {
			for (const section of ss) {
				if (section.cases.length) {
					return section;
				}
			}
			return null;
		};

		let activeSection;
		let activeTest;
		if (!activeTestIds.length) {
			activeTest = getState().activeTest ?? activeSection?.cases[0] ?? null;
		} else {
			activeTest = this.searchTest(_sections, activeTestIds[0]);
		}

		if (activeTest) {
			activeSection = activeTest.testCase.section;
		} else {
			activeSection = findActiveSection(_sections);
		}

		patchState({
			activeTest,
			activeSection,
			testTree: _sections,
			activeSectionsIds: activeSection ? [activeSection.id] : [],
			expandedSectionsIds: _sections.map((s) => s.id),
			activeTestsIds: activeTest ? [activeTest.id] : [],
		});
	}

	@Action(SetShowed)
	setShowed({ patchState }: StateContext<FlatTreeStateModel>, { showed }: SetShowed) {
		patchState({ showed });
	}

	@Action(ActivateTest)
	setActiveTest({ getState, patchState }: StateContext<FlatTreeStateModel>, { testId }: ActivateTest) {
		const tree = getState().testTree;
		let testIdForSearch = testId ? testId : this.getFirstFulfilledSection(tree)?.cases[0]?.id;
		const test = this.searchTest(tree, testIdForSearch);
		patchState({
			activeTest: test,
			activeSection: getState().testTree.find((s) => s.id === test?.testCase?.sectionId),
			activeSectionsIds: test?.testCase?.sectionId ? [test.testCase.sectionId] : [],
			activeTestsIds: test?.id ? [test.id] : [],
		});
	}

	/*	@Action(SetActiveIds)
	setActiveIds({ getState, patchState }: StateContext<FlatTreeStateModel>, { test }: ActivateTest) {
		patchState({
			activeSection: getState().testTree.find((s) => s.id === test?.testCase?.sectionId),
			activeSectionsIds: test?.testCase?.sectionId ? [test.testCase.sectionId] : [],
			activeTestsIds: test?.id ? [test.id] : [],
		});
	}*/

	@Action(SetStepStatus)
	setStepStatus({ getState, patchState }: StateContext<FlatTreeStateModel>, { event }: SetStepStatus) {
		const _sections = _.cloneDeep(getState().testTree);
		const _activeTest = _.cloneDeep(getState().activeTest);
		_activeTest.steps[event.step.index].status = event.status;

		patchState({
			testTree: _sections,
			activeTest: _activeTest,
			loading: false,
		});
	}

	@Action(LoadNext)
	getNextTest({ getState, patchState }: StateContext<FlatTreeStateModel>, {}: LoadNext) {
		const state = getState();

		let newActiveTest = null;
		let newActiveSection = null;

		const _sections = _.cloneDeep(state.testTree);
		const _activeTest = _.cloneDeep(state.activeTest);
		const _activeSection = _.cloneDeep(state.activeSection);

		const findNextNotEmptySection = (startIndex: number) => {
			for (let i = startIndex, j = _sections.length; i < j; i++) {
				if (_sections[i]?.cases?.length) {
					return _sections[i];
				}
			}
			return _sections[startIndex - 1];
		};

		const currentActiveSectionPosition = _sections.findIndex((s) => s.id === _activeSection.id);
		const currentActiveTestPosition = _activeSection.cases.findIndex((c) => c.id === _activeTest.id);

		if (currentActiveTestPosition < _activeSection.cases.length - 1) {
			newActiveTest = _activeSection.cases[currentActiveTestPosition + 1];
			newActiveSection = _activeSection;
		} else {
			if (currentActiveSectionPosition < _sections.length - 1) {
				newActiveSection = findNextNotEmptySection(currentActiveSectionPosition + 1);
				newActiveTest = newActiveSection.cases[0];
			}
		}
		patchState({
			activeSection: newActiveSection ? newActiveSection : _activeSection,
			activeTest: newActiveTest ? newActiveTest : _activeTest,
			activeSectionsIds: newActiveSection ? [newActiveSection.id] : [_activeSection.id],
			activeTestsIds: newActiveTest ? [newActiveTest.id] : [_activeTest.id],
		});
	}

	@Action(LoadPrevious)
	getPreviousTest({ getState, patchState }: StateContext<FlatTreeStateModel>, {}: LoadPrevious) {}

	@Action(ToggleSection)
	toggleItem({ getState, patchState }: StateContext<FlatTreeStateModel>, { section }: ToggleSection) {
		const state = getState();
		const expandedSectionsIds = _.clone(state.expandedSectionsIds);
		const index = expandedSectionsIds.findIndex((id) => id === section.id);
		if (index >= 0) {
			expandedSectionsIds.splice(index, 1);
		} else {
			expandedSectionsIds.push(section.id);
		}
		patchState({
			expandedSectionsIds: expandedSectionsIds,
		});
	}

	@Action(AssignTest)
	assignTest({ getState, patchState }: StateContext<FlatTreeStateModel>, { assignee }: AssignTest) {
		return this.testApiService.assignTests(assignee.test.runId, [assignee.test.id], assignee.user.id).pipe(
			tap(() => {
				const tree = cloneDeep(getState().testTree);
				const section = tree.find((s) => s.id === (assignee.test.testCase?.section?.id ?? null));
				const testIndex = section.cases.findIndex((c) => c.id === assignee.test.id);
				section.cases[testIndex]['assignedTo'] = assignee.user?.id ? assignee.user : null;
				patchState({
					testTree: tree,
				});
			}),
		);
	}

	@Action(AssignTests)
	assignTests({ getState, patchState }: StateContext<FlatTreeStateModel>, { assignee }: AssignTests) {
		return this.testApiService.assignTests(assignee.runId, assignee.tests, assignee.user.id).pipe(
			tap(() => {
				const tree = cloneDeep(getState().testTree);
				tree.forEach((s) => {
					s.cases.forEach((c) => {
						if (!assignee.tests.includes(c.id)) {
							return;
						}
						c['assignedTo'] = assignee.user?.id ? assignee.user : null;
					});
				});
				patchState({
					testTree: tree,
					selectedSectionsIds: [],
					selectedTestsIds: [],
				});
			}),
		);
	}

	@Action(ActivateSection)
	setActiveSection({ patchState, getState }: StateContext<FlatTreeStateModel>, { section }: ActivateSection) {
		const oldState = getState();
		const newState = produce(oldState, (draft) => {
			draft.activeSectionsIds = [section.id];
			draft.activeSection = section;
			const test = getFirstElement(section.cases);
			if (test) {
				draft.activeTestsIds = [test.id];
				draft.activeTest = test;
			}
		});
		patchState(newState);
	}

	@Action(SelectSection)
	selectSection({ getState, patchState }: StateContext<FlatTreeStateModel>, { node, checked }: SelectSection) {
		let _selectedSectionsIds = _.cloneDeep(getState().selectedSectionsIds);
		let _selectedTestsIds = _.cloneDeep(getState().selectedTestsIds);
		const index = _selectedSectionsIds.findIndex((id) => id === node.id);
		if (checked) {
			if (index < 0) {
				_selectedSectionsIds.push(node.id);
				_selectedTestsIds.push(...node.cases.map((c) => c.id));
			}
		} else {
			_selectedSectionsIds.splice(index, 1);
			const testsIds = node.cases.map((c) => c.id);
			_selectedTestsIds = _selectedTestsIds.filter((id) => !testsIds.includes(id));
		}
		patchState({
			selectedTestsIds: _selectedTestsIds,
			selectedSectionsIds: _selectedSectionsIds,
		});
	}

	@Action(SelectSectionContentEntity)
	selectNodeItemContent(
		{ getState, patchState }: StateContext<FlatTreeStateModel>,
		{ node, entity, checked }: SelectSectionContentEntity,
	) {
		const state = getState();
		let _selectedSectionsIds = _.clone(state.selectedSectionsIds);
		let _selectedTestsIds = _.clone(state.selectedTestsIds);
		let tree = _.clone(state.testTree);
		const sectionIndex = tree.findIndex((s) => s.id === node.id);
		const selectedSectionIndex = _selectedSectionsIds.findIndex((id) => id === node.id);
		const section = tree[sectionIndex];
		const testIndex = _selectedTestsIds.findIndex((id) => id === entity.id);
		if (checked) {
			_selectedTestsIds.push(entity.id);
			_selectedTestsIds = Array.from(new Set(_selectedTestsIds));
			const isSectionSelected =
				_selectedTestsIds.filter((id) => section.cases.map((c) => c.id).includes(id)).length ===
				section.cases.length;
			if (isSectionSelected) {
				_selectedSectionsIds.push(node.id);
				_selectedSectionsIds = Array.from(new Set(_selectedSectionsIds));
			}
		} else {
			_selectedTestsIds.splice(testIndex, 1);
			const isSectionSelected =
				_selectedTestsIds.filter((id) => section.cases.map((c) => c.id).includes(id)).length ===
				section.cases.length;
			if (!isSectionSelected && selectedSectionIndex >= 0) {
				_selectedSectionsIds.splice(selectedSectionIndex, 1);
			}
		}
		patchState({ selectedTestsIds: _selectedTestsIds, selectedSectionsIds: _selectedSectionsIds });
	}

	@Action(SetResult)
	addTestResult(
		{ getState, patchState, dispatch }: StateContext<FlatTreeStateModel>,
		{ projectId, result, test }: SetResult,
	) {
		patchState({
			savingResult: true,
		});
		let _test = _.cloneDeep(test);
		_test.status = result.status;
		_test.assignedTo = result.assignedTo;
		const testIds = [_test.id];

		const resultRequest = {
			projectId,
			testIds,
			status: result.status.id,
			notes: result.notes,
			assignedTo: result.assignedTo,
			attachmentIds: result.attachmentIds,
		};

		return this.testApiService.saveTestsResults(resultRequest).pipe(
			pluck('data'),
			tap((res: TestResult[]) => {
				const _sections = _.cloneDeep(getState().testTree);
				const _sectionIndex = _sections.findIndex((s) => s.id === test.testCase.sectionId);
				const testIndex = _sections[_sectionIndex].cases.findIndex((t) => t.id === test.id);
				_sections[_sectionIndex].cases[testIndex] = _test;
				patchState({
					testTree: _sections,
					savingResult: false,
					activeTest: _test,
					result: res[0],
				});
			}),
		);
	}

	@Action(SetTestsCount)
	setSetTestsCount({ patchState }: StateContext<FlatTreeStateModel>, { tests }: SetTestsCount) {
		patchState({
			testsCount: tests.length,
		});
	}

	@Action(SetActiveTestId)
	setActiveTestId({ getState, patchState }: StateContext<FlatTreeStateModel>, { testId }: SetActiveTestId) {
		const tests = cloneDeep(getState().testTree[0].cases);
		const testIds = testId ? [testId] : [tests[0].id];
		patchState({
			activeTestsIds: testIds,
			activeTest: tests[0],
		});
	}

	@Action(SetFilters)
	setFilters({ patchState }: StateContext<FlatTreeStateModel>, { filters, search }: SetFilters) {
		patchState({ filters, search });
	}

	@Action(RemoveFilterByKey)
	removeFilterByKey({ patchState, getState }: StateContext<FlatTreeStateModel>, { filterKey }: RemoveFilterByKey) {
		const filters = cloneDeep(getState().filters);
		delete filters[filterKey];
		patchState({ filters });
	}

	@Action(UpdateSearch)
	updateSearch({ patchState }: StateContext<FlatTreeStateModel>, { search }: UpdateSearch) {
		patchState({ search });
	}

	@Action(UncheckAll)
	uncheckAll({ patchState }: StateContext<FlatTreeStateModel>) {
		patchState({
			selectedSectionsIds: [],
			selectedTestsIds: [],
		});
	}

	private searchTest(tree, id): Test {
		return tree.reduce((prev, section) => {
			return prev || section.cases.find((test) => test.id === id);
		}, undefined);
	}

	private getFirstFulfilledSection(tree): Section {
		let section: Section = null;
		for (let s of tree) {
			if (s.cases.length) {
				section = s;
				break;
			}
		}
		return section;
	}
}
