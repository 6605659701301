import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

const TRANSITION = '{{duration}}ms ease-in-out';
const DURATION = { params: { duration: 25 } };
const STAGGER = 25;

export const eqaHeightCollapse = trigger('eqaHeightCollapse', [
	transition(':enter', [style({ height: 0 }), animate(TRANSITION, style({ height: '*' }))], DURATION),
	transition(':leave', [style({ height: '*' }), animate(TRANSITION, style({ height: 0 }))], DURATION),
]);

export const eqaHeightCollapseList = trigger('eqaHeightCollapseList', [
	transition(
		'* => *',
		[
			query(':enter', [style({ height: 0 }), stagger(STAGGER, [animate(TRANSITION, style({ height: '*' }))])], {
				optional: true,
			}),
			query(':leave', [style({ height: '*' }), stagger(STAGGER, [animate(TRANSITION, style({ height: 0 }))])], {
				optional: true,
			}),
		],
		DURATION,
	),
]);

export const eqaWidthCollapse = trigger('eqaWidthCollapse', [
	transition(':enter', [style({ width: 0 }), animate(TRANSITION, style({ width: '*' }))], DURATION),
	transition(':leave', [style({ width: '*' }), animate(TRANSITION, style({ width: 0 }))], DURATION),
]);

export const eqaWidthCollapseList = trigger('eqaWidthCollapseList', [
	transition(
		'* => *',
		[
			query(':enter', [style({ width: 0 }), stagger(STAGGER, [animate(TRANSITION, style({ width: '*' }))])], {
				optional: true,
			}),
			query(':leave', [style({ width: '*' }), stagger(STAGGER, [animate(TRANSITION, style({ width: 0 }))])], {
				optional: true,
			}),
		],
		DURATION,
	),
]);

export const eqaFadeIn = trigger('eqaFadeIn', [
	transition(':enter', [style({ opacity: 0 }), animate(TRANSITION, style({ opacity: 1 }))], DURATION),
	transition(':leave', [style({ opacity: 1 }), animate(TRANSITION, style({ opacity: 0 }))], DURATION),
]);

export const eqaFadeInList = trigger('eqaFadeInList', [
	transition(
		'* => *',
		[
			query(':enter', [style({ opacity: 0 }), stagger(STAGGER, [animate(TRANSITION, style({ opacity: 1 }))])], {
				optional: true,
			}),
			query(':leave', [style({ opacity: 1 }), stagger(STAGGER, [animate(TRANSITION, style({ opacity: 0 }))])], {
				optional: true,
			}),
		],
		DURATION,
	),
]);

export const eqaFadeInTop = trigger('eqaFadeInTop', [
	transition(
		':enter',
		[
			style({ transform: 'translateY(-10px)', opacity: 0 }),
			animate(TRANSITION, style({ transform: 'translateY(0)', opacity: 1 })),
		],
		DURATION,
	),
	transition(
		':leave',
		[
			style({ transform: 'translateY(0)', opacity: 1 }),
			animate(TRANSITION, style({ transform: 'translateY(-10px)', opacity: 0 })),
		],
		DURATION,
	),
]);

export const eqaFadeInBottom = trigger('eqaFadeInBottom', [
	transition(
		':enter',
		[
			style({ transform: 'translateY(10px)', opacity: 0 }),
			animate(TRANSITION, style({ transform: 'translateY(0)', opacity: 1 })),
		],
		DURATION,
	),
	transition(
		':leave',
		[
			style({ transform: 'translateY(0)', opacity: 1 }),
			animate(TRANSITION, style({ transform: 'translateY(10px)', opacity: 0 })),
		],
		DURATION,
	),
]);

export const eqaScaleIn = trigger('eqaScaleIn', [
	transition(
		':enter',
		[style({ transform: 'scale(0)' }), animate(TRANSITION, style({ transform: 'scale(1)' }))],
		DURATION,
	),
	transition(
		':leave',
		[style({ transform: 'scale(1)' }), animate(TRANSITION, style({ transform: 'scale(0)' }))],
		DURATION,
	),
]);

export const eqaScaleInList = trigger('eqaScaleInList', [
	transition(
		'* => *',
		[
			query(
				':enter',
				[
					style({ transform: 'scale(0)' }),
					stagger(STAGGER, [animate(TRANSITION, style({ transform: 'scale(1)' }))]),
				],
				{ optional: true },
			),
			query(
				':leave',
				[
					style({ transform: 'scale(1)' }),
					stagger(STAGGER, [animate(TRANSITION, style({ transform: 'scale(0)' }))]),
				],
				{ optional: true },
			),
		],
		DURATION,
	),
]);

export const eqaSlideIn = trigger('eqaSlideIn', [
	transition(
		`* => left`,
		[style({ transform: 'translateX(-100%)' }), animate(TRANSITION, style({ transform: 'translateX(0)' }))],
		DURATION,
	),
	transition(
		'left => *',
		[style({ transform: 'translateX(0)' }), animate(TRANSITION, style({ transform: 'translateX(-100%)' }))],
		DURATION,
	),
	transition(
		'* => right',
		[style({ transform: 'translateX(100%)' }), animate(TRANSITION, style({ transform: 'translateX(0)' }))],
		DURATION,
	),
	transition(
		'right => *',
		[style({ transform: 'translateX(0)' }), animate(TRANSITION, style({ transform: 'translateX(100%)' }))],
		DURATION,
	),
]);

export const eqaSlideInLeft = trigger('eqaSlideInLeft', [
	transition(
		':enter',
		[style({ transform: 'translateX(-100%)' }), animate(TRANSITION, style({ transform: 'translateX(0)' }))],
		DURATION,
	),
	transition(
		':leave',
		[style({ transform: 'translateX(0)' }), animate(TRANSITION, style({ transform: 'translateX(-100%)' }))],
		DURATION,
	),
]);

export const eqaSlideInLeftList = trigger('eqaSlideInLeftList', [
	transition(
		'* => *',
		[
			query(
				':enter',
				[
					style({ transform: 'translateX(-100%)' }),
					stagger(STAGGER, [animate(TRANSITION, style({ transform: 'translateX(0)' }))]),
				],
				{ optional: true },
			),
			query(
				':leave',
				[
					style({ transform: 'translateX(0)' }),
					stagger(STAGGER, [animate(TRANSITION, style({ transform: 'translateX(-100%)' }))]),
				],
				{ optional: true },
			),
		],
		DURATION,
	),
]);

export const eqaSlideInRight = trigger('eqaSlideInRight', [
	transition(
		':enter',
		[style({ transform: 'translateX(100%)' }), animate(TRANSITION, style({ transform: 'translateX(0)' }))],
		DURATION,
	),
	transition(
		':leave',
		[style({ transform: 'translateX(0)' }), animate(TRANSITION, style({ transform: 'translateX(100%)' }))],
		DURATION,
	),
]);

export const eqaSlideInRightList = trigger('eqaSlideInRightList', [
	transition(
		'* => *',
		[
			query(
				':enter',
				[
					style({ transform: 'translateX(100%)' }),
					stagger(STAGGER, [animate(TRANSITION, style({ transform: 'translateX(0)' }))]),
				],
				{ optional: true },
			),
			query(
				':leave',
				[
					style({ transform: 'translateX(0)' }),
					stagger(STAGGER, [animate(TRANSITION, style({ transform: 'translateX(100%)' }))]),
				],
				{ optional: true },
			),
		],
		DURATION,
	),
]);

export const eqaSlideInTop = trigger('eqaSlideInTop', [
	transition(
		':enter',
		[
			style({ transform: 'translate3d(0,{{start}},0)' }),
			animate(TRANSITION, style({ transform: 'translate3d(0,{{end}},0)' })),
		],
		{ params: { end: 0, start: '100%', duration: 300 } },
	),
	transition(
		':leave',
		[
			style({ transform: 'translate3d(0,{{end}},0)' }),
			animate(TRANSITION, style({ transform: 'translate3d(0,{{start}},0)' })),
		],
		{ params: { end: 0, start: '100%', duration: 300 } },
	),
]);

export const eqaSlideInTopList = trigger('eqaSlideInTopList', [
	transition(
		'* => *',
		[
			query(
				':enter',
				[
					style({ transform: 'translateY(100%)' }),
					stagger(STAGGER, [animate(TRANSITION, style({ transform: 'translateY(0)' }))]),
				],
				{ optional: true },
			),
			query(
				':leave',
				[
					style({ transform: 'translateY(0)' }),
					stagger(STAGGER, [animate(TRANSITION, style({ transform: 'translateY(100%)' }))]),
				],
				{ optional: true },
			),
		],
		DURATION,
	),
]);

export const eqaSlideInBottom = trigger('eqaSlideInBottom', [
	transition(
		':enter',
		[style({ transform: 'translateY(-100%)' }), animate(TRANSITION, style({ transform: 'translateY(0)' }))],
		DURATION,
	),
	transition(
		':leave',
		[style({ transform: 'translateY(0)' }), animate(TRANSITION, style({ transform: 'translateY(-100%)' }))],
		DURATION,
	),
]);

export const eqaSlideInBottomList = trigger('eqaSlideInBottomList', [
	transition(
		'* => *',
		[
			query(
				':enter',
				[
					style({ transform: 'translateY(-100%)' }),
					stagger(STAGGER, [animate(TRANSITION, style({ transform: 'translateY(0)' }))]),
				],
				{ optional: true },
			),
			query(
				':leave',
				[
					style({ transform: 'translateY(0)' }),
					stagger(STAGGER, [animate(TRANSITION, style({ transform: 'translateY(-100%)' }))]),
				],
				{ optional: true },
			),
		],
		DURATION,
	),
]);
