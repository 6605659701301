import { Injectable } from "@angular/core";
import { Section } from "@features/case/case-repository/domain/interfaces/section";
import { HttpSectionsService } from "@features/case/case-repository/domain/services/http-sections.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import * as _ from "lodash";
import { pluck, tap } from "rxjs/operators";
import { TreeUtils } from "../../../../../../store/tree/tree.utils";
import {
	AddChild,
	AddRootItem,
	CheckItem,
	ClearManageCasesState,
	FilterTree,
	GetTree,
	RemoveTreeNode,
	RemoveTreeNodeList,
	ResetFilters,
	SetActiveItem,
	SetMoveTarget,
	ToggleItem,
	UpdateSelectedItemName
} from "./manage.cases.actions";

export interface ManageCasesStateModel {
	tree: Array<Section>;
	sections: Section[];
	filteredSections: Section[];
	activeItem: Section;
	moveTargetItem: Section;
	parentIsValid: boolean;
	isEmpty: boolean;
	loading: boolean;
	contentLoading: boolean;
}

@State<ManageCasesStateModel>({
	name: 'manageCasesState',
	defaults: {
		tree: [],
		sections: [],
		filteredSections: [],
		activeItem: null,
		moveTargetItem: null,
		parentIsValid: true,
		loading: false,
		isEmpty: true,
		contentLoading: false,
	},
})
@Injectable()
export class ManageCasesState {
	@Selector()
	static tree(treeStateModel: ManageCasesStateModel): Section[] {
		return treeStateModel.tree;
	}

	@Selector()
	static sections(treeStateModel: ManageCasesStateModel): Section[] {
		return treeStateModel.sections;
	}

	@Selector()
	static loading(treeStateModel: ManageCasesStateModel): boolean {
		return treeStateModel.loading;
	}

	@Selector()
	static selectedItem(treeStateModel: ManageCasesStateModel): Section {
		return treeStateModel.activeItem;
	}

	@Selector()
	static moveTargetItem(treeStateModel: ManageCasesStateModel): Section {
		return treeStateModel.moveTargetItem;
	}

	@Selector()
	static isTreeEmpty(treeStateModel: ManageCasesStateModel): boolean {
		return treeStateModel.isEmpty;
	}

	@Selector()
	static contentLoading(treeStateModel: ManageCasesStateModel): boolean {
		return treeStateModel.contentLoading;
	}

	constructor(private sectionApiService: HttpSectionsService) {}

	@Action(GetTree)
	getTree({ getState, patchState }: StateContext<ManageCasesStateModel>, { projectId }: GetTree) {
		patchState({ loading: true });
		return this.sectionApiService.getSectionsWithCases(projectId).pipe(
			pluck('data'),
			tap((items: Section[]) => {
				const sections = _.cloneDeep(items);
				const tree = TreeUtils.buildTree(sections);
				const activeItem: Section = tree.length > 0 ? tree[0] : null;
				if (activeItem) {
					activeItem.active = true;
					activeItem.expanded = true;
				}
				const _sections = _.cloneDeep(sections);
				_sections.forEach((s) => (s.children = []));
				patchState({
					tree: tree,
					activeItem: activeItem,
					isEmpty: tree.length === 0,
					sections: _sections,
					loading: false,
				});
			}),
		);
	}

	@Action(AddRootItem)
	addRootItem(
		{ getState, patchState, dispatch }: StateContext<ManageCasesStateModel>,
		{ sectionModel }: AddRootItem,
	) {
		return this.sectionApiService.createSection(sectionModel).pipe(
			pluck('data'),
			tap((section: Section) => {
				const state = _.cloneDeep(getState());
				const tree = state.tree;
				const sections = state.sections;
				const previousActive = state.activeItem;
				const currentActive = section;

				// deactivate previous
				if (previousActive) {
					previousActive.active = false;
				}
				currentActive.active = true;
				currentActive.expanded = true;

				// add new root
				sections.push(section);
				tree.push(currentActive);

				patchState({
					tree: tree,
					activeItem: currentActive,
					sections: sections,
					isEmpty: false,
				});
			}),
		);
	}

	@Action(AddChild)
	addChild({ getState, patchState }: StateContext<ManageCasesStateModel>, { sectionModel }: AddChild) {
		return this.sectionApiService.createSection(sectionModel).pipe(
			pluck('data'),
			tap((section: Section) => {
				const _section = _.cloneDeep(section);
				const state = this.cloneState(getState());
				const sections = state.sections;
				const activeItem = state.activeItem;
				const tree = state.tree;
				const treeNode = TreeUtils.getNodeById(tree, _section.parent.id);
				_section.expanded = true;
				treeNode.expanded = true;
				treeNode.children = [...treeNode.children, _section];
				activeItem.children = treeNode.children;
				sections.push(_section);
				patchState({
					tree: [...tree],
					activeItem: activeItem,
					sections: sections,
					isEmpty: false,
				});
			}),
		);
	}

	@Action(UpdateSelectedItemName)
	updateSelectedItemName(
		{ getState, patchState }: StateContext<ManageCasesStateModel>,
		{ item, newName }: UpdateSelectedItemName,
	) {
		const state = this.cloneState(getState());
		const itemForUpdate = _.clone(item);
		itemForUpdate.name = newName;
		return this.sectionApiService.updateSection(itemForUpdate.id, itemForUpdate).pipe(
			pluck('data'),
			tap((updatedItem: Section) => {
				const tree = state.tree;
				const sections = state.sections;
				const sectionIndex = sections.findIndex((s) => s.id === updatedItem.id);
				sections[sectionIndex].name = updatedItem.name;
				const treeNode = TreeUtils.getNodeById(tree, updatedItem.id);
				treeNode.name = updatedItem.name;
				const activeItem = state.activeItem;
				activeItem.name = updatedItem.name;
				patchState({ tree: tree, sections: sections, activeItem: activeItem });
			}),
		);
	}

	@Action(RemoveTreeNode)
	removeNode(
		{ getState, setState, patchState, dispatch }: StateContext<ManageCasesStateModel>,
		{ item }: RemoveTreeNode,
	) {
		const state = this.cloneState(getState());
		const ids = TreeUtils.getNodeChildrenRecursive(item);
		const sections = state.sections;
		ids.push(item.id);
		return this.sectionApiService.removeSectionList(ids).pipe(
			tap(() => {
				let newActiveItem: Section;
				const tree = state.tree;
				if (item.hasParent) {
					const parentItem = TreeUtils.findParent(item, tree);
					const nextSibling = TreeUtils.findNextSibling(parentItem, item);
					if (nextSibling) {
						newActiveItem = _.cloneDeep(nextSibling) as Section;
					} else {
						const prevSibling = TreeUtils.findPreviousSibling(parentItem, item);
						if (prevSibling) {
							newActiveItem = _.cloneDeep(prevSibling) as Section;
						} else {
							newActiveItem = parentItem;
						}
					}
				} else {
					const nextSibling = TreeUtils.findNextRootSibling(item, tree);
					if (nextSibling) {
						newActiveItem = _.cloneDeep(nextSibling) as Section;
					} else {
						const prevSibling = TreeUtils.findPrevRootSibling(item, tree);
						if (prevSibling) {
							newActiveItem = _.cloneDeep(prevSibling) as Section;
						} else {
							newActiveItem = null;
						}
					}
				}
				TreeUtils.removeNodeFromTree(item, tree);
				const index = sections.findIndex((s) => s.id === item.id);
				sections.splice(index, 1);
				patchState({
					tree: tree,
					activeItem: newActiveItem,
					isEmpty: tree.length === 0,
					sections: sections,
				});
			}),
		);
	}

	@Action(RemoveTreeNodeList)
	removeNodeList({ getState, patchState }: StateContext<ManageCasesStateModel>) {
		const state = this.cloneState(getState());
		const activeItem = state.activeItem;
		const checkedItems = activeItem.children.filter((x) => x.checked === true);
		const itemsIdsToBeRemoved = TreeUtils.getScionsOfManySections(checkedItems);
		const treeCopy = _.cloneDeep(state.tree) as Array<Section>;
		return this.sectionApiService.removeSectionList(itemsIdsToBeRemoved).pipe(
			tap(() => {
				const sections = state.sections;
				activeItem.children = activeItem.children.filter((x) => x.checked === false);
				activeItem.checked = false;
				checkedItems.forEach((item) => {
					TreeUtils.removeNodeFromTree(item, treeCopy);
					const index = sections.findIndex((s) => s.id === item.id);
					sections.splice(index, 1);
				});

				patchState({
					tree: treeCopy,
					activeItem: activeItem,
					isEmpty: treeCopy.length === 0,
				});
			}),
		);
	}

	@Action(SetActiveItem)
	setActiveItem({ getState, patchState }: StateContext<ManageCasesStateModel>, { item }: SetActiveItem) {
		const state = this.cloneState(getState());
		const oldActive = state.activeItem;
		const tree = state.tree;
		const old = TreeUtils.getNodeById(tree, oldActive.id);
		const newActive = TreeUtils.getNodeById(tree, item.id);
		old.active = false;
		newActive.active = true;
		newActive.expanded = true;
		patchState({
			activeItem: newActive,
			tree: tree,
		});
	}

	@Action(ClearManageCasesState)
	clearManageCasesState({ patchState }: StateContext<ManageCasesStateModel>, {}: ClearManageCasesState) {
		patchState({
			tree: [],
			activeItem: null,
			moveTargetItem: null,
			loading: false,
			isEmpty: true,
			contentLoading: false,
		});
	}

	@Action(CheckItem)
	checkItem({ getState, setState }: StateContext<ManageCasesStateModel>, { item }: CheckItem) {
		const state = this.cloneState(getState());
		const activeItemCopy = state.activeItem;
		if (item.parent && item.parent.id === activeItemCopy.id) {
			const index = activeItemCopy.children.findIndex((x) => x.id === item.id);
			activeItemCopy.children[index].checked = item.checked;
			activeItemCopy.checked =
				activeItemCopy.children.filter((x) => x.checked === true).length === activeItemCopy.children.length &&
				activeItemCopy.children.length > 0;
		} else {
			activeItemCopy.checked = item.checked;
			TreeUtils.changeCheckedItemsFlag(activeItemCopy.children, item.checked);
		}
		setState(patch<ManageCasesStateModel>({ activeItem: activeItemCopy }));
	}

	@Action(ToggleItem)
	toggleItem({ getState, setState }: StateContext<ManageCasesStateModel>, { item }: ToggleItem) {
		const state = this.cloneState(getState());
		const tree = state.tree;
		const forActivate = TreeUtils.getNodeById(tree, item.id);
		forActivate.expanded = !forActivate.expanded;
		setState(patch<ManageCasesStateModel>({ tree: tree }));
	}

	@Action(SetMoveTarget)
	setMoveTarget({ getState, setState }: StateContext<ManageCasesStateModel>, { item }: SetMoveTarget) {
		const state = this.cloneState(getState());
		const _tree = state.tree;
		const _node = TreeUtils.getNodeById(_tree, item.id);
		_node.active = true;
		setState(
			patch<ManageCasesStateModel>({
				tree: _tree,
				moveTargetItem: item,
			}),
		);
	}

	@Action(FilterTree)
	filterTree({ getState, patchState }: StateContext<ManageCasesStateModel>, { filters }: FilterTree) {
		// SHIT ALL IS SHIT
		const state = this.cloneState(getState()) as ManageCasesStateModel;
		const sections = state.sections;
		const filteredSections = [];
		const paths = [];
		if (Object.keys(filters).length > 0) {
			sections.forEach((section) => {
				if (section.cases) {
					const _section = _.cloneDeep(section);
					// @ts-ignore
					_section.cases = this.nestedFilter(_section.cases, filters).flat();
					filteredSections.push(_section);
					if (_section.cases.length) {
						paths.push(..._section.path, _section.id);
					}
				}
			});

			const sectionsForBuild = _.cloneDeep(
				filteredSections.filter((s) => paths.findIndex((p) => p === s.id) >= 0),
			);
			const tree = TreeUtils.buildTree(sectionsForBuild);
			const activeItem = tree[0];
			if (activeItem) {
				activeItem.active = true;
			}
			patchState({
				tree: tree,
				activeItem: activeItem,
			});
		} else {
			const tree = TreeUtils.buildTree(sections);
			const activeItem = tree[0];
			if (activeItem) {
				activeItem.active = true;
			}
			patchState({
				tree: tree,
				activeItem: activeItem,
			});
		}
	}

	@Action(ResetFilters)
	resetFilters({ getState, patchState }: StateContext<ManageCasesStateModel>, {}: ResetFilters) {
		const state = this.cloneState(getState());
		const sections = state.sections;
		const tree = TreeUtils.buildTree(sections);
		const activeItem = tree[0];
		if (activeItem) {
			activeItem.active = true;
		}
		patchState({
			tree: tree,
			activeItem: activeItem,
		});
	}

	nestedFilter(cases, filters) {
		const resultArray = [];
		Object.keys(filters).forEach((filterKey) => {
			const currentFilter = filters[filterKey];
			const filteredCases = cases.filter((tCase) => {
				if (Array.isArray(tCase[filterKey])) {
					return tCase[filterKey].some((r) => currentFilter.values.findIndex((v) => v.id === r.id) >= 0);
				}
			});
			resultArray.push(...Array.from(new Set(filteredCases)));
		});
		return resultArray;
	}

	cloneState<T>(state: T): T {
		return _.cloneDeep(state);
	}
}
