import { NgModule } from '@angular/core';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';

import { EqaDropdownBoxComponent } from './dropdown-box.component';
import { ActiveZoneModule } from '@shared/ui/directives/active-zone/active-zone.module';
import { OverscrollModule } from '@shared/ui/directives/overscroll/overscroll.module';
import { ScrollbarModule } from '@shared/ui/elements/scrollbar';

@NgModule({
    imports: [ActiveZoneModule, PolymorpheusModule, OverscrollModule, ScrollbarModule],
    declarations: [EqaDropdownBoxComponent],
    exports: [EqaDropdownBoxComponent]
})
export class TuiDropdownBoxModule {}
