import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { Comment } from "@features/comments/domain/models/comment";

@Injectable({ providedIn: 'root' })
export class CommentsService {
	private baseUrl = ServerUrl.HOME_URL;
	private commentsUrl = this.baseUrl + '/comments';

	constructor(private http: HttpClient) {}

	get(entityId: string, entityType: string) {
		return this.http.get<Comment[]>(`${this.commentsUrl}/list/${entityType}/${entityId}`);
	}

	addComment({ entityId, text, parentId, networkId, entityType }: Comment) {
		return this.http.post<Comment>(this.commentsUrl, { entityId, text, parentId, networkId, entityType});
	}

	update({ id, text }: Comment) {
		return this.http.put<Comment>(this.commentsUrl + '/' + id, { text });
	}

	delete(id: string) {
		return this.http.delete(this.commentsUrl + '/' + id);
	}
}
