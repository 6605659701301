<ng-container *ngIf="context">
	<aside class="nav-sidebar" [class.sidebar-collapsed-desktop]="collapsed$ | async">
		<eqa-scrollbar class="scroll-area" [hidden]="true">
			<ul class="sidebar-top-level-items">
				<li
					class="context-header has-tooltip"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }"
				>
					<a class="eqa-nav-link" eqaWebLink [routerLink]="context.contextLink"
						><span class="project-avatar-container">
							<span class="avatar avatar-tile s32 identicon bg2">{{
								context?.contextEntity[context?.contextEntityFieldName]
									| firstLetter
									| titlecase
							}}</span>
						</span>
						<span class="sidebar-context-title">
							{{ context?.contextEntity[context?.contextEntityFieldName] }}
						</span>
					</a>
				</li>
				<li
					[class.disabled]="point.disabled"
					eqaFlyOut
					[sidebarCollapsed]="collapsed$ | async"
					*ngFor="let point of context?.menuItems"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLinkActive="active"
				>
					<a
						eqaWebLink
						eqaFeatureSubscription
						[eqaHasPermission]="point.permission"
						[class.has-sub-items]="context?.menuItems?.length > 0"
						[feature]="point.subscriptionFeature"
						[maxFeatureCount]="-1"
						[routerLink]="point | eqaComputeLinkUrl"
					>
						<span class="nav-icon-container">
							<eqa-svg [src]="point.icon"></eqa-svg>
						</span>
						<span class="nav-item-name">{{ point.title }}</span>
					</a>
					<ul *ngIf="point.children && point.children.length" class="sidebar-sub-level-items">
						<li class="fly-out-top-item">
							<span class="fly-out-top-item-container">
								<strong class="fly-out-top-item-name"> {{ point.title }} </strong>
							</span>
						</li>
						<li class="divider fly-out-top-item"></li>
						<li
							routerLinkActive="active"
							[routerLinkActiveOptions]="{ exact: true }"
							*ngFor="let child of point.children"
							data-track-label="general"
						>
							<a
								eqaWebLink
								[eqaHasPermission]="child.permission"
								eqaFeatureSubscription
								[feature]="child.subscriptionFeature"
								[maxFeatureCount]="-1"
								[routerLink]="child | eqaComputeLinkUrl"
								><span> {{ child.title }} </span>
							</a>
						</li>
					</ul>
				</li>
			</ul>
			<a eqaWebLink class="toggle-sidebar-button" (click)="collapse()">
				<eqa-svg src="eqaCollapseSidebar" class="mr-8 collapse-icon"></eqa-svg>
				<span class="collapse-text">Collapse sidebar</span>
			</a>
		</eqa-scrollbar>
	</aside>
</ng-container>
