import { Sprint } from "../interfaces/sprint";
import { SprintsSortConfig } from "./sprints.state";

export class CreateSprint {
	static readonly type = '[Sprint] Create Sprint';

	constructor(public payload: Sprint) {}
}

export class GetSprintsList {
	static readonly type = '[Sprints] Get List';

	constructor(public projectId: string, public limit: number, public offset: number) {}
}

export class GetActiveSprintsList {
	static readonly type = '[Sprints] Get Active Sprints List';

	constructor(public projectId: string, public limit: number, public offset: number) {}
}

export class GetUpcomingSprintsList {
	static readonly type = '[Sprints] Get Upcoming Sprints List';

	constructor(public projectId: string, public limit: number, public offset: number) {}
}

export class GetClosedSprintsList {
	static readonly type = '[Sprints] Get Closed Sprints List';

	constructor(public projectId: string, public limit: number, public offset: number) {}
}

export class GetSprintDetails {
	static readonly type = '[Sprints] Get Sprint Details';

	constructor(public id: string) {}
}

export class CloseSprint {
	static readonly type = '[Sprints] Close Sprint';

	constructor(public sprint: Sprint) {}
}

export class StartSprint {
	static readonly type = '[Sprints] Start Sprint';

	constructor(public sprint: Sprint) {}
}

export class UpdateSprint {
	static readonly type = '[Sprint] Update';

	constructor(public sprint: Sprint) {}
}

export class DeleteSprint {
	static readonly type = '[Sprint] Delete';

	constructor(public sprint: Sprint) {}
}

export class UpdateSortConfig {
	static readonly type = '[Sprint] Update sprints sort config';

	constructor(public config: Partial<SprintsSortConfig>) {}
}
