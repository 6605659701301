import { Component, HostBinding, Input } from '@angular/core';
import { UserModel } from '@core/interfaces/user/UserModel';

@Component({
	selector: 'eqa-user-avatar',
	templateUrl: './user-avatar.component.html',
	styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent {
	@Input() user: Pick<UserModel, 'email' | 'avatar'> | UserModel;

	@HostBinding('class.no-avatar')
	get noAvatar(): boolean {
		return !this.user?.avatar;
	}

	@HostBinding('title')
	get email() {
		return this.user?.email ?? 'None';
	}
}
