import { Injectable } from "@angular/core";
import { RunExecutionStatistics } from "@features/executions/run-execution/domain/interfaces";
import { HttpRunExecutionService } from "@features/executions/run-execution/domain/services/http";
import {
	loadRuneExecutionStateHandler
} from "@features/executions/run-execution/domain/store/run-execution-state.hanlder";
import {
	runExecutionStateDefaults,
	RunExecutionStateModel
} from "@features/executions/run-execution/domain/store/run-execution-state.model";
import { LoadRunExecution } from "@features/executions/run-execution/domain/store/run-execution.actions";
import { calculateStatistic } from "@features/executions/run-execution/domain/utils";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";

@State<RunExecutionStateModel>({
	name: 'runExecutionState',
	defaults: runExecutionStateDefaults,
})
@Injectable()
export class RunExecutionState {
	@Selector()
	public static statistic(model: RunExecutionStateModel): RunExecutionStatistics {
		const {
			run: { stats, testsCount },
		} = model;
		return calculateStatistic(stats, testsCount);
	}
	constructor(private httpRunExecutionService: HttpRunExecutionService) {}

	@Action(LoadRunExecution)
	public loadRunExecution(context: StateContext<RunExecutionStateModel>, { runId }: LoadRunExecution) {
		return this.httpRunExecutionService
			.loadRunExecutionById(runId)
			.pipe(tap((run) => loadRuneExecutionStateHandler(context, run)));
	}
}
