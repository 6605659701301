import { Injectable } from "@angular/core";
import { OrderDirections } from "@core/constants/order-directions";
import { ReportsApiService } from "@features/reports/domain/services/reports-api.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { append, patch, removeItem } from "@ngxs/store/operators";
import { addHours, format } from "date-fns";
import { orderBy } from "lodash";
import { filter, tap } from "rxjs/operators";
import { TimeOfDay } from "../constants/schedule-values";
import { Report } from "../models/report";
import { ReportsStateModel } from "../models/reports-state-model";
import { ReportActions } from "./reports.actions";

@Injectable()
@State<ReportsStateModel>({
	name: 'reportsState',
	defaults: {
		reports: [],
		freshReport: null,
		selectedReport: null,
		loading: true,
		orderObjects: [
			{
				property: 'createdAt',
				direction: 'desc',
			},
		],
	},
})
export class ReportsState {
	@Selector()
	static reports(m: ReportsStateModel): Report[] {
		const orderObjects = m.orderObjects;
		if (!orderObjects?.length) {
			return m.reports;
		}
		const config: [string[], OrderDirections[]] = m.orderObjects.reduce(
			(acc, { direction, property }) => [
				[...acc[0], property],
				[...acc[1], direction],
			],
			[[], []],
		);
		return orderBy(m.reports, ...config);
	}

	@Selector()
	static selectedReport(m: ReportsStateModel): Report {
		return m.selectedReport;
	}

	@Selector()
	static loading(m: ReportsStateModel): boolean {
		return m.loading;
	}

	constructor(private reportsApiService: ReportsApiService) {}

	@Action(ReportActions.GetAll)
	getAll({ patchState }: StateContext<ReportsStateModel>, { projectId }: ReportActions.GetAll) {
		patchState({ loading: true });
		return this.reportsApiService.getAll(projectId).pipe(
			tap(
				(reports) => patchState({ reports, loading: false }),
				() => patchState({ loading: false }),
			),
		);
	}

	@Action(ReportActions.GetOne)
	getOne({ patchState }: StateContext<ReportsStateModel>, { projectId, id }: ReportActions.GetOne) {
		patchState({ loading: true });
		return this.reportsApiService.getOne(projectId, id).pipe(
			tap(
				(selectedReport) => patchState({ selectedReport, loading: false }),
				() => patchState({ loading: false }),
			),
		);
	}

	@Action(ReportActions.Create)
	create({ patchState, setState }: StateContext<ReportsStateModel>, { dto, user }: ReportActions.Create) {
		patchState({ loading: true });
		const offset = new Date().getTimezoneOffset() / 60;
		if (dto.isScheduled) {
			const todayHours = new Date().setHours(+dto.schedule.time.split(':')[0], 0, 0);
			dto.schedule.time = format(addHours(todayHours, offset), 'hh:mm') as TimeOfDay;
		}
		return this.reportsApiService.create(dto).pipe(
			filter((r) => !!r),
			tap(
				(freshReport) => {
					patchState({ freshReport });
					if (freshReport.users?.some((u) => u.id === user.id)) {
						setState(
							patch({
								reports: append([freshReport]),
								loading: false,
							}),
						);
					}
				},
				() => patchState({ loading: false }),
			),
		);
	}

	@Action(ReportActions.DeleteOne)
	delete({ setState, patchState }: StateContext<ReportsStateModel>, { projectId, id }: ReportActions.DeleteOne) {
		return this.reportsApiService.delete(projectId, id).pipe(
			tap(() => {
				setState(
					patch<ReportsStateModel>({
						reports: removeItem((r) => r.id === id),
					}),
				);
			}),
		);
	}

	@Action(ReportActions.SetOrder)
	setOrder({ patchState }: StateContext<ReportsStateModel>, { orderObjects }: ReportActions.SetOrder) {
		patchState({ orderObjects });
	}
}
