import { merge, Observable } from 'rxjs';

import { typedFromEvent } from './typed-from-event';
import { EqaEventWith, EqaTypedEventTarget } from '@shared/domain/types';

export function mouseDragFinishFrom<T extends EqaTypedEventTarget<EqaEventWith<DragEvent, T>>>(
	target: Exclude<T, null>,
): Observable<EqaEventWith<MouseEvent, T>> {
	return merge(typedFromEvent(target, 'mouseup'), typedFromEvent(target, 'dragend'));
}
