import { Section } from "@features/case/case-repository/domain/interfaces/section";
import { TestCaseExecution } from "@features/executions/testcase-execution/domain/interfaces";
import {
	TestCaseExecutionStateModel
} from "@features/executions/testcase-execution/domain/store/test-execution-state.model";
import { StateContext } from "@ngxs/store";
import produce from "immer";

export function loadTestCaseExecutionsHandler(
	stateContext: StateContext<TestCaseExecutionStateModel>,
	payload: [Array<TestCaseExecution>, Array<Section>],
): void {
	const oldState = stateContext.getState();
	produce(oldState, (draft) => {
		const [executions, sections] = payload;
		executions.forEach((e) => {
			const {
				testCase: { section = null },
			} = e;
			e.testCase.section = sections.find(({ id = null }) => id === section?.id);
		});
		draft.executions = executions;
		stateContext.patchState(draft);
	});
}
