export enum RequirementGroupActionsList {
	GET_GROUPS = '[RequirementGroup] Get Requirement Groups By Project',
	SET_ACTIVE = '[RequirementGroup] Set Active Requirement Group',
	TOGGLE_GROUP = '[RequirementGroup] Toggle Requirement Group',
	COLLAPSE_ALL = '[RequirementGroup] Collapse ALl Groups',
	EXPAND_ALL = '[RequirementGroup] Expand ALl Groups',
	CREATE_REQUIREMENT = '[RequirementGroup] Create Requirement Group',
	REMOVE_GROUP = '[RequirementGroup] Remove Requirement Group',
	UPDATE_GROUP = '[RequirementGroup] Update Requirement Group',
	BUILD_GROUPS_TREE = '[RequirementGroup] Build Requirement Group Tree',
	TOGGLE_SHOW_REQUIREMENTS_COUNT = '[RequirementGroup] Toggle Show Requirements Count',
}
