import { Injectable } from "@angular/core";
import { Section } from "@features/case/case-repository/domain/interfaces/section";
import { HttpSectionsService } from "@features/case/case-repository/domain/services/http-sections.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { append, patch, updateItem } from "@ngxs/store/operators";
import * as _ from "lodash";
import { pluck, tap } from "rxjs/operators";
import { VIRTUAL_SECTION_ID } from "../../contants/section-constants";
import {
	CreateSection,
	FilterSections,
	FulfillSections,
	GetFulfilledSections,
	GetSections,
	MoveSections,
	RemoveSection,
	SelectSection,
	SetSections,
	UpdateSection
} from "./sections.actions";
import { SectionsStateModel } from "./sections.state.model";

@State<SectionsStateModel>({
	name: 'sectionsState',
	defaults: {
		sections: [],
		selectedSection: null,
		filteredSections: [],
		newSection: null,
		cutSection: null,
		cutSectionChildren: [],
		loading: false,
	},
})
@Injectable()
export class SectionsState {
	@Selector()
	static sections(treeStateModel: SectionsStateModel): Section[] {
		return treeStateModel.sections;
	}

	@Selector()
	static selectedSection(treeStateModel: SectionsStateModel): Section {
		return treeStateModel.selectedSection;
	}

	@Selector()
	static loading(sectionsStateModel: SectionsStateModel): boolean {
		return sectionsStateModel.loading;
	}

	@Selector()
	static newSection(treeStateModel: SectionsStateModel): Section {
		return treeStateModel.sections[treeStateModel.sections.length - 1];
	}

	@Selector()
	static filteredSections(treeStateModel: SectionsStateModel): Section[] {
		return treeStateModel.filteredSections;
	}

	@Selector()
	static cuttedSection(treeStateModel: SectionsStateModel): Section {
		return treeStateModel.cutSection;
	}

	constructor(private sectionApiService: HttpSectionsService) {}

	@Action(GetSections)
	getSections({ getState, setState, patchState }: StateContext<SectionsStateModel>, { projectId }: GetSections) {
		patchState({
			loading: true,
		});

		return this.sectionApiService.getSections(projectId).pipe(
			tap((sections: Section[]) => {
				patchState({
					sections: sections,
					loading: false,
				});
			}),
		);
	}

	@Action(SetSections)
	setSections({ getState, setState, patchState }: StateContext<SectionsStateModel>, { sections }: SetSections) {
		const _sections = _.cloneDeep(sections);
		const calculatedSections = this.calculateSectionsPath(_sections);
		patchState({
			sections: calculatedSections,
			loading: true,
		});
	}

	@Action(SelectSection)
	selectSection({ getState, setState, patchState }: StateContext<SectionsStateModel>, { section }: SelectSection) {
		patchState({
			selectedSection: section,
		});
	}

	@Action(FulfillSections)
	fulfillSections({ patchState }: StateContext<SectionsStateModel>, { sections }: FulfillSections) {
		const _sections = _.cloneDeep(sections);
		const calculatedSections = this.calculateSectionsPath(_sections);
		patchState({
			sections: calculatedSections,
			filteredSections: calculatedSections,
		});
	}

	@Action(GetFulfilledSections)
	getFulfilledSections(
		{ getState, setState, patchState }: StateContext<SectionsStateModel>,
		{ projectId }: GetFulfilledSections,
	) {
		patchState({
			loading: true,
		});
		return this.sectionApiService.getSectionsWithCases(projectId).pipe(
			pluck('data'),
			tap((sections: Section[]) => {
				const _sections = _.cloneDeep(sections);
				const calculated = this.calculateSectionsPath(_sections);
				patchState({
					sections: calculated,
					filteredSections: calculated,
				});
				patchState({
					loading: false,
				});
			}),
		);
	}

	@Action(FilterSections)
	filterSections({ getState, patchState }: StateContext<SectionsStateModel>, { filters }: FilterSections) {
		const state = this.cloneState(getState()) as SectionsStateModel;
		const sections = state.sections;
		if (Object.keys(filters).length > 0) {
			const filteredSections = [];
			const paths = [];
			sections.forEach((section) => {
				if (section.cases) {
					const _section = _.cloneDeep(section);
					// @ts-ignore
					_section.cases = this.nestedFilter(_section.cases, filters).flat();
					filteredSections.push(_section);
					if (_section.cases.length) {
						paths.push(..._section.path, _section.id);
					}
				}
			});

			const sectionsForBuild = _.cloneDeep(
				filteredSections.filter((s) => paths.findIndex((p) => p === s.id) >= 0),
			);
			patchState({
				filteredSections: sectionsForBuild,
			});
		} else {
			patchState({
				filteredSections: sections,
				sections: sections,
			});
		}
	}

	@Action(CreateSection)
	createSection({ getState, setState }: StateContext<SectionsStateModel>, { section }: CreateSection) {
		return this.sectionApiService.createSection(section).pipe(
			pluck('data'),
			tap((newSection: Section) => {
				const _section = _.cloneDeep(newSection);
				_section.path = _section.parent
					? getState().sections.find((s) => s.id === _section.parent?.id)?.path
					: [];
				setState(
					patch({
						sections: append([_section]),
						newSection: newSection,
					}),
				);
			}),
		);
	}

	@Action(UpdateSection)
	updateSection({ setState }: StateContext<SectionsStateModel>, { id, section }: UpdateSection) {
		return this.sectionApiService.updateSection(id, section).pipe(
			pluck('data'),
			tap((updated: Section) => {
				setState(
					patch({
						sections: updateItem((s) => s.id === updated.id, updated),
					}),
				);
			}),
		);
	}

	@Action(RemoveSection)
	removeSection({ getState, patchState }: StateContext<SectionsStateModel>, { idsForRemove }: RemoveSection) {
		const _state = this.cloneState(getState()) as SectionsStateModel;
		return this.sectionApiService.removeSectionList(idsForRemove).pipe(
			pluck('data'),
			tap((removedSection: Section) => {
				let _sections = _state.sections;
				_sections = _sections.filter((s) => !idsForRemove.includes(s.id));
				patchState({
					sections: _sections,
				});
			}),
		);
	}

	@Action(MoveSections)
	moveSections(
		{ getState, patchState }: StateContext<SectionsStateModel>,
		{ sectionsIds, newParentId }: MoveSections,
	) {
		const _state = this.cloneState(getState()) as SectionsStateModel;
		return this.sectionApiService
			.moveSections(sectionsIds, newParentId === VIRTUAL_SECTION_ID ? null : newParentId)
			.pipe(
				pluck('data'),
				tap((movedSections: Section[]) => {
					let _sections = _state.sections.filter((s) => movedSections.some((mv) => mv.id === s.id));
					_sections.forEach((s) => {
						s.parent = movedSections[0].parent;
					});
					const calculatedSections = this.calculateSectionsPath(_state.sections);

					patchState({
						sections: calculatedSections,
					});
				}),
			);
	}

	nestedFilter(cases, filters) {
		const resultArray = [];
		Object.keys(filters).forEach((filterKey) => {
			const currentFilter = filters[filterKey];
			const filteredCases = cases.filter((tCase) => {
				/*   if (Array.isArray(tCase[filterKey])) {
					return tCase[filterKey].some((r) => currentFilter.values.findIndex((v) => v.id === r.id) >= 0);
				} else {
					if (typeof tCase[filterKey] === 'object') {
						return currentFilter.values.findIndex((v) => v.id === tCase[filterKey].id) >= 0;
					}
				}*/
			});
			resultArray.push(...Array.from(new Set(filteredCases)));
		});
		return resultArray;
	}

	cloneState<T>(state: T): T {
		return _.cloneDeep(state);
	}

	calculateSectionsPath(sections: Section[]) {
		let calculated: Section[] = [];
		const _sections = _.cloneDeep(sections);
		_sections.forEach((section) => {
			section.path = [];
			if (section.parent) {
				const parent = _sections.find((x) => x.id === section.parent.id);
				if (parent?.path?.length) {
					section.path.push(...parent.path);
				}
				section.path.push(section.parent.id);
			}
			calculated.push(section);
		});
		return calculated;
	}
}
