import { RequestInvoiceModel } from '@features/network/components/billing/domain/interfaces/invoice.request';
import { RefundRequestModel } from '@features/network/components/billing/domain/models/redund.request.model';

export class GetPayments {
	static readonly type = '[Payment Api] Get Payments';

	constructor() {}
}

export class PayByYandex {
	static readonly type = '[Payment Api] Pay By Yandex';

	constructor(public amount: any, public currency: any) {}
}

export class RefundRequest {
	static readonly type = '[Payment Api] Refund Request';

	constructor(public refundRequestModel: RefundRequestModel) {}
}

export class RequestInvoice {
	static readonly type = '[Payment Api] Request Invoice';

	constructor(public requestInvoiceModel: RequestInvoiceModel) {}
}
