import { AccountService } from '@core/services/http/auth/account.service';
import { UserService } from '@core/services/http/user/user.service';
import { ConfigService } from '@core/services/config-service';
import { DestroyService } from '@shared/domain/services/destroy.service';
import { GetAccountData } from '@features/user-settings/store/account.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { EqaLogoComponent } from '@shared/ui/components/top-panel/logo/logo.component';
import { EqaProfileComponent } from '@shared/ui/components/top-panel/profile/profile.component';
import { WorkspaceSettingsComponent } from '@shared/ui/components/top-panel/wokspace-settings/wokspace-settings.component';

@Component({
	selector: 'eqa-top-panel',
	templateUrl: './top-panel.component.html',
	styleUrls: ['./top-panel.component.scss'],
	standalone: true,
	providers: [DestroyService],
	imports: [EqaLogoComponent, EqaProfileComponent, WorkspaceSettingsComponent],
})
export class EqaTopPanelComponent implements OnInit {
	public userId: string;
	public networkId: string;
	public showEditNetwork: boolean = true;
	public link: string = '/billing';
	public integrations: string = '/integrations';

	constructor(public authService: AccountService, private store: Store, private userService: UserService, public configService: ConfigService) {}

	ngOnInit(): void {
		this.getUserId();
		if (this.userId) {
			this.getUser();
		}

		if (this.userId) {
			this.checkUserPermissions();
		}
	}

	getUser(): void {
		if (this.configService.isDemoVersion) {
			return;
		}
		this.store.dispatch(new GetAccountData());
	}

	getUserId(): void {
		const user = this.authService.getUserData();
		this.userId = user?.id;
		this.networkId = user.network.id;
	}

	get team(): string {
		return this.configService.isDemoVersion ? '/team/roles' : '/team';
	}

	private checkUserPermissions(): void {
		if (
			!this.userService.hasPermission('INTEGRATIONS_MANAGE') &&
			!this.userService.hasPermission('NETWORK_USERS_LIST') &&
			!this.userService.hasPermission('NETWORK_ROLES_LIST')
		) {
			this.showEditNetwork = false;
		}

		if (!this.userService.hasPermission('INTEGRATIONS_MANAGE') && this.userService.hasPermission('NETWORK_USERS_LIST')) {
			this.link = '/team';
		}

		if (!this.userService.hasPermission('INTEGRATIONS_MANAGE') && this.userService.hasPermission('NETWORK_ROLES_LIST')) {
			this.link = '/team/roles';
		}
	}
}
