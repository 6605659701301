import { Action, Selector, State, StateContext } from '@ngxs/store';
import { mergeMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';

import {
	AssignUser,
	CreateRole,
	DeactivateUser,
	DeleteGroup,
	DeleteRole,
	FullUpdateRole,
	GetGroups,
	GetRole,
	GetRoles,
	GetTeamUsers,
	InviteUser,
	UpdateGroupRules,
	UpdateRole,
	UpdateRule,
} from './team.actions';
import { TeamStateModel } from './team-state.model';
import { UserService } from '@core/services/http/user/user.service';
import { AccessControlService } from '@core/services/http/access-control/access-control.service';
import { modRules } from './acl-rule.frontend.group';
import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { GroupsApiService } from '@features/network/components/team/domain/services/groups.api.service';
import { UserModel } from '@core/interfaces/user/UserModel';

@State<TeamStateModel>({
	name: 'teamState',
	defaults: {
		users: [],
		usersLoading: false,
		roles: [],
		rolesLoading: false,
		groupRules: [],
		role: null,
		groups: [],
		groupsLoading: false,
	},
})
@Injectable()
export class TeamState {
	constructor(
		private ngZone: NgZone,
		private router: Router,
		private usersService: UserService,
		private accessControlService: AccessControlService,
		private groupsService: GroupsApiService,
	) {}

	// tslint:disable-next-line: member-ordering
	@Selector()
	static teamUsers(teamStateModel: TeamStateModel) {
		return teamStateModel.users;
	}

	// tslint:disable-next-line: member-ordering
	@Selector()
	static loadingTeamUsers(teamStateModel: TeamStateModel) {
		return teamStateModel.usersLoading;
	}

	// tslint:disable-next-line: member-ordering
	@Selector()
	static groups(teamStateModel: TeamStateModel) {
		return teamStateModel.groups;
	}

	// tslint:disable-next-line: member-ordering
	@Selector()
	static loadingGroups(teamStateModel: TeamStateModel) {
		return teamStateModel.groupsLoading;
	}

	// tslint:disable-next-line: member-ordering
	@Selector()
	static roles(teamStateModel: TeamStateModel) {
		return teamStateModel.roles;
	}

	// tslint:disable-next-line: member-ordering
	@Selector()
	static role(teamStateModel: TeamStateModel) {
		return teamStateModel.role;
	}

	// tslint:disable-next-line: member-ordering
	@Selector()
	static groupRules(teamStateModel: TeamStateModel) {
		return teamStateModel.groupRules;
	}

	// tslint:disable-next-line: member-ordering
	@Selector()
	static loadingTeamRoles(teamStateModel: TeamStateModel) {
		return teamStateModel.rolesLoading;
	}

	@Action(GetTeamUsers, { cancelUncompleted: true })
	getTeamUsers({ getState, patchState, setState }: StateContext<TeamStateModel>) {
		patchState({
			usersLoading: true,
		});
		return this.usersService.getUsers().pipe(
			tap((users: UserModel[]) => {
				const state = getState();
				setState({
					...state,
					usersLoading: false,
					users,
				});
			}),
		);
	}

	@Action(InviteUser, { cancelUncompleted: true })
	inviteUser({ getState, setState }: StateContext<TeamStateModel>, { user }: InviteUser) {
		return this.usersService.inviteUser(user).pipe(
			mergeMap(() => this.usersService.getUsers()),
			tap((users: UserModel[]) => {
				const state = getState();
				setState({
					...state,
					users,
				});
			}),
		);
	}

	@Action(DeactivateUser, { cancelUncompleted: true })
	deactivateUser({ patchState, getState, setState }: StateContext<TeamStateModel>, { id }: DeactivateUser) {
		patchState({
			usersLoading: true,
		});
		return this.usersService.deactivateUser(id).pipe(
			mergeMap(() => this.usersService.getUsers()),
			tap((users: UserModel[]) => {
				const state = getState();
				setState({
					...state,
					users,
					usersLoading: false,
				});
			}),
		);
	}

	@Action(AssignUser, { cancelUncompleted: true })
	assignUser({ getState, setState }: StateContext<TeamStateModel>, { roleId, userId }: AssignUser) {
		return this.accessControlService.assignRole(roleId, userId).pipe(
			mergeMap(() => this.usersService.getUsers()),
			tap((users: UserModel[]) => {
				const state = getState();
				setState({
					...state,
					users,
				});
			}),
		);
	}

	@Action(GetGroups, { cancelUncompleted: true })
	getGroups({ getState, patchState, setState }: StateContext<TeamStateModel>) {
		patchState({
			groupsLoading: true,
		});
		return this.groupsService.getListByNetwork().pipe(
			tap((groups: any) => {
				const state = getState();
				setState({
					...state,
					groupsLoading: false,
					groups: groups.reverse(),
				});
			}),
		);
	}

	@Action(DeleteGroup, { cancelUncompleted: true })
	deleteGroup({ getState, dispatch, patchState, setState }: StateContext<TeamStateModel>, { id }: DeleteGroup) {
		patchState({
			groupsLoading: true,
		});
		return this.groupsService.delete(id).pipe(mergeMap(() => dispatch(new GetGroups())));
	}

	@Action(GetRoles, { cancelUncompleted: true })
	getRoles({ getState, patchState, setState }: StateContext<TeamStateModel>) {
		patchState({
			rolesLoading: true,
		});
		return this.accessControlService.getRoles().pipe(
			tap((roles) => {
				const state = getState();
				setState({
					...state,
					rolesLoading: false,
					roles: roles.data,
				});
			}),
		);
	}

	@Action(GetRole, { cancelUncompleted: true })
	getRole({ getState, patchState, setState }: StateContext<TeamStateModel>, { id }: GetRole) {
		patchState({
			rolesLoading: true,
		});
		return this.accessControlService.getRole(id).pipe(
			tap((role) => {
				const upgratedRules = this.groupRules(role.rules);

				const state = getState();
				setState({
					...state,
					role,
					groupRules: upgratedRules,
					rolesLoading: false,
				});
			}),
		);
	}

	groupRules(originalRolesArray: string[]) {
		const cloneRules = _.cloneDeep(modRules);
		cloneRules.sort((a, b) => a.name.localeCompare(b.name));
		for (let i = 0; i < cloneRules.length; i++) {
			let counter = 0;
			for (let j = 0; j < cloneRules[i].data.length; j++) {
				cloneRules[i].data.sort((a, b) => a.name.localeCompare(b.name));
				originalRolesArray.forEach((item) => {
					if (cloneRules[i].data[j].rule === item) {
						cloneRules[i].data[j].checked = true;
						counter = counter + 1;
					}
				});
				if (cloneRules[i].data.length === counter) {
					cloneRules[i].checked = true;
				}
			}
		}

		return cloneRules;
	}

	@Action(CreateRole, { cancelUncompleted: true })
	createRole({ getState, dispatch, setState }: StateContext<TeamStateModel>, { role }: CreateRole) {
		return this.accessControlService.createRole(role).pipe(
			mergeMap(() => this.accessControlService.getRoles()),
			tap((roles) => {
				const state = getState();
				setState({
					...state,
					roles: roles.data,
				});
			}),
		);
	}

	@Action(DeleteRole, { cancelUncompleted: true })
	deleteRole({ getState, patchState, setState }: StateContext<TeamStateModel>, { id }: DeleteRole) {
		patchState({
			rolesLoading: true,
		});
		return this.accessControlService.deleteRole(id).pipe(
			mergeMap(() => this.accessControlService.getRoles()),
			tap(
				(roles) => {
					const state = getState();
					setState({
						...state,
						roles: roles.data,
						rolesLoading: false,
					});
				},
				(e) => {
					patchState({
						rolesLoading: false,
					});
				},
			),
		);
	}

	@Action(UpdateRule)
	updateRules({ getState, patchState }: StateContext<TeamStateModel>, { rule }: UpdateRule) {
		const state = getState();
		const cloneRules = _.cloneDeep(state.groupRules);

		for (let i = 0; i < cloneRules.length; i++) {
			cloneRules[i].data.forEach((item, j, arr) => {
				if (item.rule === rule.rule) {
					item.checked = rule.checked;
				}
			});
		}

		for (let i = 0; i < cloneRules.length; i++) {
			const counter = Object.values(cloneRules[i].data).reduce((a, { checked }) => a + Number(checked), 0);
			if (cloneRules[i].data.length === counter) {
				cloneRules[i].checked = true;
			} else {
				cloneRules[i].checked = false;
			}
		}

		patchState({
			groupRules: cloneRules,
		});
	}

	@Action(UpdateGroupRules)
	updateGroupRules({ getState, patchState }: StateContext<TeamStateModel>, { group }: UpdateGroupRules) {
		const state = getState();
		const cloneGroupRules = _.cloneDeep(state.groupRules);

		for (let i = 0; i < cloneGroupRules.length; i++) {
			if (cloneGroupRules[i].name === group.name) {
				cloneGroupRules[i].checked = group.checked;
				if (cloneGroupRules[i].checked) {
					cloneGroupRules[i].data.forEach((item) => (item.checked = true));
				} else {
					cloneGroupRules[i].data.forEach((item) => (item.checked = false));
				}
			}
		}

		patchState({
			groupRules: cloneGroupRules,
		});
	}

	@Action(FullUpdateRole, { cancelUncompleted: true })
	fullUpdateRole({ getState, patchState }: StateContext<TeamStateModel>, { id }: FullUpdateRole) {
		patchState({
			rolesLoading: true,
		});
		const state = getState();
		let updatedRules: string[] = [];
		state.groupRules.forEach((item) => {
			const temp = item.data.filter((elem) => elem.checked === true).map((elem) => elem.rule);
			temp.forEach((elem) => updatedRules.push(elem));
		});
		const updatedRole = {
			...state.role,
			rules: updatedRules,
		};
		return this.accessControlService.updateRole(id, updatedRole).pipe(
			mergeMap(() => this.accessControlService.getRoles()),
			tap((roles) => {
				patchState({
					roles: roles.data,
					rolesLoading: false,
				});
			}),
		);
	}

	@Action(UpdateRole, { cancelUncompleted: true })
	updateRole({ patchState }: StateContext<TeamStateModel>, { id, role }: UpdateRole) {
		patchState({
			rolesLoading: true,
		});
		return this.accessControlService.updateRole(id, role).pipe(
			mergeMap(() => this.accessControlService.getRoles()),
			tap((roles) => {
				patchState({
					roles: roles.data,
					rolesLoading: false,
				});
			}),
		);
	}
}
