import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { ActivityModel } from "@features/activity/domain/interfaces/activity.model";

export interface Activity {
	activities: ActivityModel[];
	count: number;
}

@Injectable({ providedIn: 'root' })
export class ActivityService {
	private baseUrl = ServerUrl.HOME_URL;
	private projectsUrl = this.baseUrl + '/projects';

	constructor(private http: HttpClient) {}

	getActivityByProjectId(projectId: string, page = 1) {
		return this.http.get<Activity>(`${this.activityUrl(projectId)}?page=${page}`);
	}

	private activityUrl(projectId: string) {
		return `${this.projectsUrl}/${projectId}/activities`;
	}
}
