import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';

import { EqaHintService } from '@shared/domain/services/hint-service';
import { EqaAbstractHintOptions } from '@shared/domain/abstracts/abstract-hint-options';
import { EqaActiveZoneDirective } from '@shared/ui/directives/active-zone/active-zone.directive';

@Directive()
export abstract class AbstractEqaHint implements OnDestroy {
	@Input('eqaHintMode')
	mode: EqaAbstractHintOptions['mode'] = this.options.mode;

	@Input('eqaHintDirection')
	direction: EqaAbstractHintOptions['direction'] = this.options.direction;

	content: PolymorpheusContent = '';

	protected constructor(
		protected readonly elementRef: ElementRef<HTMLElement>,
		protected readonly hintService: EqaHintService,
		readonly activeZone: EqaActiveZoneDirective | null,
		protected readonly options: EqaAbstractHintOptions,
	) {}

	abstract getElementClientRect();

	ngOnDestroy() {
		this.hideTooltip();
	}

	protected showTooltip() {
		this.hintService.add(this);
	}

	protected hideTooltip() {
		this.hintService.remove(this);
	}
}
