import { CreateTrackerDescription } from "./create-tracker-description";

export class CreateTrelloDescriptionStrategy implements CreateTrackerDescription {
	create(data) {
		let descriptionString = '';
		if (data.preconditions) {
			descriptionString += `**Preconditions:**\n${data.preconditions}\n\n`;
		}
		if (data.steps?.length) {
			descriptionString += `**Steps and Expected results:**\n`;
			data.steps.forEach((step, index) => {
				descriptionString += `${index + 1}. **Step:** ${step.desc}.\n **Expected result:** ${step.expectedResult}\n`;
			});
			descriptionString += '\n';
		}
		descriptionString += `**Actual result:** \n ${data.actualResult}`;
		return descriptionString;
	}
}
