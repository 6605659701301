import { SortOrder } from '@shared/domain/interfaces/SortOrder';

export class LoadTeamEffort {
	static readonly type = '[TeamEffort] Load Team Effort';

	constructor(public runId: string) {}
}

export class CalculateTeamEffortBurndown {
	static readonly type = '[TeamEffort] Calculate Team Effort Burndown Chart Data';

	constructor() {}
}

export class ChangeSortDirection {
	static readonly type = '[TeamEffort] Change Team Effort Sort Direction';

	constructor(public sort: SortOrder) {}
}
