<div class="profile" *ngIf="userId">
	<ng-template [ngIf]="!isDemoVersion" [ngIfElse]="home">
		<div class="d-flex align-items-center" (eqaActiveZoneChange)="hide()">
			<eqa-user-avatar
				(click)="toggle()"
				[user]="user$ | async"
				[eqaManualHint]="profileOptions"
				[eqaManualHintShow]="profileOptionsVisible"
				eqaHintDirection="bottom-left"
			></eqa-user-avatar>
		</div>
	</ng-template>
</div>

<ng-template #home>
	<a href="https://everyqa.io" title="{{ 'txt_go_to_landing_title' | translate }}">
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6 11V20H10V14H15V20H19V11M4 12L12.5 3L21 12"
				stroke="#36373B"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	</a>
</ng-template>

<ng-template #profileOptions>
	<ul>
		<li class="current-user">
			{{ (user$ | async).email }}
		</li>
		<li class="divider"></li>
		<li class="point">
			<a eqaWebLink [routerLink]="['/', 'user', userId]">Manage profile</a>
		</li>
		<li class="point">
			<a eqaWebLink [routerLink]="['/', 'user', userId, 'credentials']">Credentials</a>
		</li>
		<li class="point">
			<a eqaWebLink [routerLink]="['/', 'user', userId, 'api-tokens']">Api tokens</a>
		</li>
		<li class="point">
			<a
				eqaWebLink
				eqaFeatureSubscription
				feature="INTEGRATIONS"
				[routerLink]="['/', 'user', userId, 'integrations']"
				eqaHasPermission="INTEGRATIONS_MANAGE"
				>Integrations settings</a
			>
		</li>
		<li class="divider"></li>
		<li class="logout"><a eqaWebLink (click)="logout()">Sign out</a></li>
	</ul>
</ng-template>
