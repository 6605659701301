import { RequirementGroupDto } from "@features/requirements/domain/interfaces/requirement-group/requirement.group.dto";
import { MatrixActionsList } from "./matrix.actions.list";

export class GetMatrix {
	static readonly type = MatrixActionsList.GET_MATRIX;

	constructor(public projectId: string) {}
}

export class FilterMatrixByGroup {
	static readonly type = MatrixActionsList.FILTER_MATRIX_BY_GROUP;

	constructor(public groups: RequirementGroupDto[]) {}
}
