import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local.storage.service';
import { CurrencyCode } from '../../interfaces/localisation';

@Injectable({ providedIn: 'root' })
export class CurrencyService {
	private currencies = [
		{
			code: 'RUB',
		},
	];

	constructor(private localStorageService: LocalStorageService) {}

	getCurrencies(): CurrencyCode[] {
		return this.currencies;
	}

	setCurrency(currency: string) {
		const currencyObject = this.currencies.find((x) => x.code === currency);
		this.localStorageService.set('currency', JSON.stringify(currencyObject));
		return currencyObject;
	}

	getCurrency(): CurrencyCode {
		// try to get from storage
		try {
			return (
				this.localStorageService.getParsedByKey('currency') ||
				this.currencies.find(c => c.code === this.localStorageService.getParsedByKey('user').network.currency)
			);
		} catch (e) {
			// return english by default
			return this.currencies[0];
		}
	}
}
