import { DisplayedIntegrationItem } from "../types/displayed-integration-item";
import { InstallIntegrationSteps } from "./install-integration-steps";
import { IntegrationName } from "./integration-name";
import { IntegrationProviders } from "./integration.providers";

export const AVAILABLE_INTEGRATIONS: DisplayedIntegrationItem[] = [
	{
		name: IntegrationName.JIRA_SOFTWARE,
		logoClass: 'jira-logo',
		isEnabled: false,
		description: 'Proprietary issue tracking product developed by Atlassian that allows bug tracking and agile project management.',
		externalLink: 'https://atlassian.com/software/jira',
		installed: false,
		installSteps: [
			InstallIntegrationSteps.HOST,
			InstallIntegrationSteps.AUTHORIZATION,
			InstallIntegrationSteps.MAPPING,
			InstallIntegrationSteps.SETTINGS,
		],
	},
	{
		name: IntegrationName.SLACK,
		logoClass: 'slack-logo',
		provider: IntegrationProviders.SLACK,
		isEnabled: false,
		description: 'Proprietary business communication platform.',
		externalLink: 'https://slack.com',
		installed: false,
		installSteps: [InstallIntegrationSteps.MAPPING, InstallIntegrationSteps.SETTINGS],
	},
	{
		provider: IntegrationProviders.TRELLO,
		name: IntegrationName.TRELLO,
		logoClass: 'trello-logo',
		isEnabled: false,
		description: 'Web-based, Kanban-style, list-making application.',
		externalLink: 'https://trello.com',
		installed: false,
		installSteps: [
			InstallIntegrationSteps.AUTHORIZATION,
			InstallIntegrationSteps.MAPPING,
			InstallIntegrationSteps.SETTINGS,
		],
	},
	{
		provider: IntegrationProviders.GITHUB,
		name: IntegrationName.GITHUB,
		logoClass: 'github-logo',
		isEnabled: false,
		description: 'Provider of Internet hosting for software development and version control using Git.',
		externalLink: 'https://github.com',
		installed: false,
		installSteps: [
			InstallIntegrationSteps.HOST,
			InstallIntegrationSteps.AUTHORIZATION,
			InstallIntegrationSteps.MAPPING,
			InstallIntegrationSteps.SETTINGS,
		],
	},
	{
		provider: IntegrationProviders.GITLAB,
		name: IntegrationName.GITLAB,
		logoClass: 'gitlab-logo',
		isEnabled: false,
		description: ' Web-based DevOps lifecycle tool.',
		externalLink: 'https://gitlab.com',
		installed: false,
		installSteps: [
			InstallIntegrationSteps.AUTHORIZATION,
			InstallIntegrationSteps.MAPPING,
			InstallIntegrationSteps.SETTINGS,
		],
	},
	{
		provider: IntegrationProviders.BITBUCKET_CLOUD,
		name: IntegrationName.BITBUCKET_CLOUD,
		logoClass: 'bitbucket-logo',
		isEnabled: false,
		description: 'Git-based source code repository hosting service owned by Atlassian.',
		externalLink: 'https://bitbucket.com',
		installed: false,
		installSteps: [
			InstallIntegrationSteps.HOST,
			InstallIntegrationSteps.AUTHORIZATION,
			InstallIntegrationSteps.MAPPING,
			InstallIntegrationSteps.SETTINGS,
		],
	},
	{
		provider: IntegrationProviders.TELEGRAM_BOT,
		name: IntegrationName.TELEGRAM,
		logoClass: 'telegram-logo',
		isEnabled: false,
		description: 'Free and open source, cross-platform, cloud-based instant messaging software. ',
		externalLink: 'https://telegram.org',
		installed: false,
		installSteps: [InstallIntegrationSteps.MAPPING, InstallIntegrationSteps.SETTINGS],
	},
];
