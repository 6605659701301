import { Component, OnInit, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { DestroyService } from '@shared/domain';
import { SidenavCollapseService } from '@shared/domain/services/sidenav-collapse.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SidenavContextService } from '@shared/domain/services/sidenav-context.service';
import { EMPTY } from 'rxjs';

const sideNavSizes = {
	empty: '0',
	collapsed: '48',
	expanded: '260',
};

@Component({
	selector: 'eqa-content',
	template: ` <ng-content></ng-content> `,
	styleUrls: ['./content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class EqaContentComponent {
	private _toggled: boolean = false;
	private _hasContext: boolean = false;

	@HostBinding('style.padding-left.px')
	get toggled() {
		if (!this._hasContext) {
			return sideNavSizes.empty;
		}
		return this._toggled ? sideNavSizes.collapsed : sideNavSizes.expanded;
	}

	constructor(
		private sideNavContext: SidenavContextService,
		private destroy$: DestroyService,
		private sidenavCollapseService: SidenavCollapseService,
	) {
		this.sideNavContext.sidenavContext
			.pipe(
				takeUntil(this.destroy$),
				switchMap((context) => {
					this._hasContext = context?.menuItems?.length > 0;
					return this.sidenavCollapseService.collapseObs$;
				}),
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe((toggle) => (this._toggled = toggle));
	}
}
