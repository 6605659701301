import { Utils } from './utils';

export class ArrayUtils {
	static groupByKey<T, R>(collection: Array<T>, keyFn: (p) => any): Object {
		return collection.reduce((acc, obj) => {
			const key = keyFn(obj);
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(obj);
			return acc;
		}, {});
	}

	static convertArrayOfObjectsToKeyValue<T, R>(
		collection: Array<T>,
		keyFn: (p) => any,
		valueTemplateFn: (v) => any,
	): Object {
		return collection.reduce((acc, obj) => {
			const key = keyFn(obj);
			if (!acc[key]) {
				acc[key] = valueTemplateFn(obj);
			}
			return acc;
		}, {});
	}

	static groupArrayByDate(array: any, property = 'completedAt') {
		return array.reduce((acc, obj) => {
			const key = Utils.parseStringToDate(obj[property], 'yyyy-MM-dd');
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(obj);
			return acc;
		}, {});
	}
}
