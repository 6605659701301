import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { pluck } from 'rxjs/operators';
import { ServerUrl } from '@core/constants/server-url';
import { ChatIntegrationProviders } from '@features/integrations/domain/constants';
import { ProjectConfig } from 'src/app/features/projects/interfaces';
import { Defect } from '@features/defects/domain/interfaces/defect';
import { DefectModel } from '@features/defects/domain/interfaces/defect.model';
import { QueryParams } from '@core/interfaces/http/QueryParams';
import { Section } from '@features/case/case-repository/domain/interfaces/section';
import { Utils } from '@core/utils/utils';

@Injectable({ providedIn: 'root' })
export class RunsApiService {
	private baseUrl = ServerUrl.HOME_URL;
	private testRunUrl = this.baseUrl + '/runs';

	constructor(private http: HttpClient) {}

	getTestRunList(projectId: string, params: QueryParams) {
		return this.http.get(this.testRunUrl + '/list/' + projectId, { params });
	}

	getDefectsByRun(runId: string, token?: string): Observable<DefectModel[]> {
		const tokenString = token ? '?token=' + token : '';
		return this.http.get<Defect[]>(`${this.testRunUrl}/${runId}/defects${tokenString}`).pipe(pluck('data'));
	}

	shareRun(runId: string) {
		return this.http.post(`${this.testRunUrl}/${runId}/share`, { ttl: 2880 });
	}

	getSharedRun(token: string, runId: string) {
		return this.http.get(`${this.testRunUrl}/${runId}/full?token=${token}`);
	}

	getFullTestRunById(id: string) {
		return this.http.get<Section[]>(this.testRunUrl + '/' + id);
	}

	getTestRunBysprintId(id: string) {
		return this.http.get(this.testRunUrl + '/runs/sprint/' + id);
	}

	getTestRunById(id: string) {
		return this.http.get(this.testRunUrl + '/' + id);
	}

	createTestRun(testRun) {
		return this.http.post(this.testRunUrl, testRun);
	}

	deleteTestRun(id: string) {
		return this.http.delete(this.testRunUrl + '/' + id);
	}

	updateTestRun(testRun, testRunId: string) {
		return this.http.put(this.testRunUrl + '/' + testRunId, testRun);
	}

	getRunProgress(testRunId: string, groupBy = 'dates', token?: string) {
		const tokenString = token ? '&token=' + token : '';
		return this.http.get(`${this.testRunUrl}/${testRunId}/progress/${groupBy}?limit=0&offset=0${tokenString}`);
	}

	sendToChat(provider: ChatIntegrationProviders, runId: string) {
		return this.http.post(
			`${this.testRunUrl}/${runId}/chats/${Utils.resolveChatUrlProviderParam(provider)}/send`,
			null,
		);
	}

	getRunsConfig(runId: string, token?: string) {
		const tokenString = token ? '?token=' + token : '';
		return this.http.get(`${this.testRunUrl}/${runId}/config${tokenString}`).pipe<ProjectConfig>(pluck('data'));
	}
}
