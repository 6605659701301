import { EqaStringHandler } from '@shared/types/handler';
import { DEFAULT_ICONS_PATH, SLASH_DELIMITER } from '@shared/domain/constants';

export function iconsPathFactory(staticPath: string): EqaStringHandler<string> {
	const base = staticPath[staticPath.length - 1] === SLASH_DELIMITER ? staticPath : staticPath + SLASH_DELIMITER;

	return (name) => {
		if (name.startsWith('eqa')) {
			return `${base}${name}.svg#${name}`;
		}

		return DEFAULT_ICONS_PATH(name);
	};
}
