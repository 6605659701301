import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverscrollModule } from '@shared/ui/directives/overscroll/overscroll.module';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { EqaDialogHostComponent } from './dialog-host.component';
import { LetModule } from '@shared/ui/directives/let';
import { FocusTrapModule } from '@shared/ui/directives/focus-trap/focus-trap.module';

@NgModule({
	imports: [CommonModule, PolymorpheusModule, OverscrollModule, LetModule, FocusTrapModule],
	declarations: [EqaDialogHostComponent],
	exports: [EqaDialogHostComponent],
})
export class EqaDialogHostModule {}
