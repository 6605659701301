import { Injectable } from "@angular/core";
import { SharedStep } from "@features/case/case-steps-shared/domain";
import { SharedStepApiService } from "@features/projects/services/shared.step.api.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import * as _ from "lodash";
import { sortBy } from "lodash";
import { pluck, tap } from "rxjs/operators";
import { CreateSharedStep, GetSharedSteps, RemoveSharedStep, UpdateSharedStep } from "./shared-steps.actions";

export interface SharedStepsStateModel {
	sharedSteps: Array<SharedStep>;
	loading: boolean;
}

@State<SharedStepsStateModel>({
	name: 'sharedStepsState',
	defaults: {
		sharedSteps: [],
		loading: false,
	},
})
@Injectable()
export class SharedStepsState {
	@Selector()
	static sharedStepsList(m: SharedStepsStateModel): Array<SharedStep> {
		return m.sharedSteps;
	}

	@Selector()
	static loading(m: SharedStepsStateModel): boolean {
		return m.loading;
	}

	constructor(private sharedStepsApiService: SharedStepApiService) {}

	@Action(GetSharedSteps)
	getSharedStep({ getState, patchState }: StateContext<SharedStepsStateModel>, { projectId }: GetSharedSteps) {
		patchState({ loading: true });
		return this.sharedStepsApiService.getSharedSteps(projectId).pipe(
			pluck('data'),
			tap((sharedStepsList: Array<SharedStep>) => {
				const sharedSteps = sortBy(sharedStepsList, ['name', 'desc']);
				patchState({
					sharedSteps: sharedSteps.map((c) => new SharedStep(c)),
					loading: false,
				});
			}),
		);
	}

	@Action(RemoveSharedStep)
	removeSharedStep(
		{ getState, patchState }: StateContext<SharedStepsStateModel>,
		{ projectId, stepId }: RemoveSharedStep,
	) {
		return this.sharedStepsApiService.deleteSharedStep(projectId, stepId).pipe(
			tap(() => {
				const sharedStepsList = _.cloneDeep(getState().sharedSteps);
				const index = sharedStepsList.findIndex((f) => f.id === stepId);
				sharedStepsList.splice(index, 1);
				patchState({
					sharedSteps: sharedStepsList.map((c) => new SharedStep(c)),
					loading: false,
				});
			}),
		);
	}

	@Action(UpdateSharedStep)
	updateSharedStep(
		{ getState, patchState }: StateContext<SharedStepsStateModel>,
		{ projectId, stepId, step }: UpdateSharedStep,
	) {
		return this.sharedStepsApiService.updateSharedStep(projectId, stepId, step).pipe(
			tap(() => {
				const sharedStepsList = _.cloneDeep(getState().sharedSteps);
				const index = sharedStepsList.findIndex((f) => f.id === stepId);
				const updatedStep = sharedStepsList[index];
				updatedStep.desc = step.desc;
				updatedStep.name = step.name;
				updatedStep.expectedResult = step.expectedResult;
				patchState({
					sharedSteps: sharedStepsList.map((c) => new SharedStep(c)),
					loading: false,
				});
			}),
		);
	}

	@Action(CreateSharedStep)
	createSharedStep(
		{ getState, patchState }: StateContext<SharedStepsStateModel>,
		{ projectId, step }: CreateSharedStep,
	) {
		patchState({ loading: true });
		return this.sharedStepsApiService.createSharedStep(projectId, step).pipe(
			pluck('data'),
			tap((createdSharedStep: SharedStep) => {
				const steps = _.cloneDeep(getState().sharedSteps);
				steps.push(createdSharedStep);
				patchState({
					sharedSteps: steps.map((c) => new SharedStep(c)),
					loading: false,
				});
			}),
		);
	}
}
