import { NgModule } from '@angular/core';
import { DragDropFileUploadDirective } from './file.drop.directive';



@NgModule({
  declarations: [DragDropFileUploadDirective],
	exports: [DragDropFileUploadDirective]
})
export class FileDropDirectiveModule { }
