import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	Inject,
	Input,
	NgZone,
	ViewChild,
} from '@angular/core';
import { ANIMATION_FRAME, WINDOW } from '@ng-web-apis/common';
import { EqaDirection } from '@shared/domain/types';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DestroyService } from '@shared/domain';
import { AbstractEqaHint } from '@shared/domain/abstracts';
import { eqaZonefree } from '@shared/domain/observables/zone-free';
import { px } from '@shared/domain/utils/format';
import { EqaHintsHostComponent } from '@shared/ui/components/hints-host';
import { EqaPointerHintDirective } from '@shared/ui/directives/pointer-hint';
import { EqaHintMode } from '@shared/domain/constants/hint-mode';

const SPACE = 8;
const BORDER_WIDTH = 1;
const LEFT_PADDING = 16;
const TOP_PADDING = 12;
const ARROW_SIZE = 8;
const ARROW_OFFSET = 14;
const ARROWHEAD_OFFSET = ARROW_OFFSET + (ARROW_SIZE * Math.sqrt(2)) / 2;
const reverseDirectionsVertical: { [key in EqaDirection]: EqaDirection } = {
	'top-left': 'bottom-left',
	'top-right': 'bottom-right',
	'bottom-left': 'top-left',
	'bottom-right': 'top-right',
	'left': 'right',
	'right': 'left',
};
const reverseDirectionsHorizontal: { [key in EqaDirection]: EqaDirection } = {
	'top-left': 'top-right',
	'top-right': 'top-left',
	'bottom-left': 'bottom-right',
	'bottom-right': 'bottom-left',
	'left': 'right',
	'right': 'left',
};

@Component({
	selector: 'eqa-hint-box[hint]',
	templateUrl: './hint-box.template.html',
	styleUrls: ['./hint-box.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class EqaHintBoxComponent {
	@ViewChild('arrow')
	private readonly arrow?: ElementRef<HTMLElement>;

	@Input()
	hint!: AbstractEqaHint;

	constructor(
		@Inject(ANIMATION_FRAME) animationFrame$: Observable<number>,
		@Inject(DestroyService) destroy$: Observable<void>,
		@Inject(NgZone) ngZone: NgZone,
		@Inject(ElementRef) private readonly elementRef: ElementRef<HTMLElement>,
		@Inject(WINDOW) private readonly windowRef: Window,
		@Inject(EqaHintsHostComponent)
		private readonly hintsHost: EqaHintsHostComponent,
	) {
		animationFrame$.pipe(eqaZonefree(ngZone), takeUntil(destroy$)).subscribe(() => this.calculatePosition());
	}

	@HostBinding('class._untouchable')
	get isUntouchable(): boolean {
		return this.hint instanceof EqaPointerHintDirective;
	}

	private calculatePosition() {
		if (this.mode !== 'overflow') {
			this.calculateCoordinates();
		} else {
			this.setOverflowStyles();
		}
	}

	@HostBinding('attr.data-mode')
	get mode(): EqaHintMode | null {
		return this.hint.mode;
	}

	private calculateCoordinates() {
		const hostRect = this.hint.getElementClientRect();
		const portalRect = this.hintsHost.clientRect;
		const tooltip = this.elementRef.nativeElement;
		const { style } = tooltip;
		const tooltipRect = tooltip.getBoundingClientRect();
		const isHostLong = hostRect.width > ARROWHEAD_OFFSET * 2;
		const directions: EqaDirection[] = ['left', 'right', 'bottom-left', 'bottom-right', 'top-left', 'top-right'];

		let top = 0;
		let left = 0;
		let { direction } = this.hint;

		const horizontalTop = hostRect.top + hostRect.height / 2 - tooltipRect.height / 2 - portalRect.top;
		const horizontalLeft = hostRect.left - tooltipRect.width - SPACE - portalRect.left;
		const horizontalRight = hostRect.left + hostRect.width + SPACE - portalRect.left;
		const verticalBottom = hostRect.bottom + SPACE - portalRect.top;
		const verticalTop = hostRect.top - tooltipRect.height - SPACE - portalRect.top;
		const verticalRight = isHostLong
			? hostRect.left - portalRect.left
			: hostRect.left + hostRect.width / 2 - ARROWHEAD_OFFSET - portalRect.left;
		const verticalLeft = isHostLong
			? hostRect.left - tooltipRect.width + hostRect.width - portalRect.left
			: hostRect.left - tooltipRect.width + hostRect.width / 2 + ARROWHEAD_OFFSET - portalRect.left;

		directions.splice(directions.indexOf(direction), 1);

		// eslint-disable-next-line no-constant-condition
		while (true) {
			switch (direction) {
				case 'left':
					top = horizontalTop;
					left = horizontalLeft;
					break;
				case 'right':
					top = horizontalTop;
					left = horizontalRight;
					break;
				case 'top-right':
					top = verticalTop;
					left = verticalRight;
					break;
				case 'top-left':
					top = verticalTop;
					left = verticalLeft;
					break;
				case 'bottom-right':
					top = verticalBottom;
					left = verticalRight;
					break;
				case 'bottom-left':
					top = verticalBottom;
					left = verticalLeft;
					break;
			}

			const verticalFit =
				top + portalRect.top > SPACE &&
				top + tooltipRect.height + SPACE + portalRect.top < this.windowRef.innerHeight;
			const horizontalFit = left > SPACE && left + tooltipRect.width + SPACE + portalRect.left < portalRect.width;

			if (directions.length === 0 || (verticalFit && horizontalFit)) {
				break;
			}

			direction = verticalFit ? reverseDirectionsHorizontal[direction] : reverseDirectionsVertical[direction];
			direction = directions.splice(directions.indexOf(direction), 1)[0] || direction;
		}

		style.top = px(top);
		style.left = px(left);

		tooltip.setAttribute('data-eqa-host-direction', direction);
	}

	private setOverflowStyles() {
		const hostRect = this.hint.getElementClientRect();
		const { style } = this.elementRef.nativeElement;

		style.top = px(hostRect.top - window.innerHeight - TOP_PADDING - BORDER_WIDTH);
		style.left = px(hostRect.left - LEFT_PADDING - BORDER_WIDTH);
		style.width = px(hostRect.width + LEFT_PADDING * 2 + BORDER_WIDTH * 2);
	}
}
