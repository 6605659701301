import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type EqaHintDirective = any;

type AbstractEqaHint = any;

@Injectable({
	providedIn: 'root',
})
export class EqaHintService extends BehaviorSubject<ReadonlyArray<AbstractEqaHint>> {
	private directives: ReadonlyArray<EqaHintDirective> = [];

	constructor() {
		super([]);
	}

	add(directive: AbstractEqaHint) {
		this.next(this.value.concat(directive));
	}

	remove(directive: AbstractEqaHint) {
		if (this.value.includes(directive)) {
			this.next(this.value.filter((hint) => hint !== directive));
		}
	}

	register(directive: EqaHintDirective) {
		this.directives = [...this.directives, directive];
	}

	unregister(directive: EqaHintDirective) {
		this.remove(directive);
		this.directives = this.directives.filter((dir) => dir !== directive);
	}

	showHintForId(id: string) {
		const directive = this.findDirectiveWithHintId(id);

		if (directive) {
			this.add(directive);
		}
	}

	hideHintForId(id: string) {
		const directive = this.findDirectiveWithHintId(id);

		if (directive) {
			this.remove(directive);
		}
	}

	private findDirectiveWithHintId(id: string): EqaHintDirective | undefined {
		return this.directives.find((directive) => directive.eqaHintId === id);
	}
}
