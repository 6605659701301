import { RunExecution } from "@features/executions/run-execution/domain/interfaces";
import { RunExecutionStateModel } from "@features/executions/run-execution/domain/store/run-execution-state.model";
import { StateContext } from "@ngxs/store";
import produce from "immer";

export function loadRuneExecutionStateHandler(
	stateContext: StateContext<RunExecutionStateModel>,
	payload: RunExecution,
): void {
	const oldState = stateContext.getState();
	produce(oldState, (draft) => {
		draft.run = payload;
		stateContext.patchState(draft);
	});
}
