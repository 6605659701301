import { Directive, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class EqaController implements OnChanges {
	readonly change$ = new Subject<void>();
	ngOnChanges() {
		this.change$.next();
	}
}
