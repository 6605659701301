import { Injectable } from '@angular/core';
import { Currency } from '@core/constants';
import { UserService } from '@core/services/http/user/user.service';
import { CurrencyService } from '@core/services/localisation/currency.service';
import { NetworkService } from '@features/network/services/network.service';
import { PeriodInterval } from '@features/network/components/billing/domain/constants/period-interval';
import { PricableFeature } from '@features/network/components/billing/domain/constants/pricable.feature';
import { SubscriptionModel } from '@features/network/components/billing/domain/models/subscription.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { finalize, map, pluck, tap } from 'rxjs/operators';
import { SubscriptionStateModel } from './subscription-state.model';
import { GetSubscription, UpgradeSubscription } from './subscrtiption.actions';

@State<SubscriptionStateModel>({
	name: 'subscriptionState',
	defaults: {
		subscription: null,
		loading: true,
	},
})
@Injectable()
export class SubscriptionState {
	constructor(private networkService: NetworkService, private usersService: UserService, private currencyService: CurrencyService) {}

	@Selector()
	static subscription(m: SubscriptionStateModel) {
		return m.subscription;
	}

	@Selector()
	static loading(m: SubscriptionStateModel): boolean {
		return m.loading;
	}

	@Action(GetSubscription)
	getSubscription({ patchState }: StateContext<SubscriptionStateModel>, {}: GetSubscription) {
		patchState({ loading: true });
		return this.networkService.getSubscription().pipe(
			pluck('data'),
			map((response: SubscriptionModel) => new SubscriptionModel(response)),
			tap((subscription) => {
				const currency = this.currencyService.getCurrency();
				const interval = subscription.period.interval;
				subscription.calculateSubscriptionPrice(<Currency>currency.code, interval);
				patchState({ subscription: subscription });
			}),
			finalize(() => patchState({ loading: false })),
		);
	}

	@Action(UpgradeSubscription)
	upgradeSubscription({ getState, patchState }: StateContext<SubscriptionStateModel>, { currency }: UpgradeSubscription) {
		patchState({ loading: true });
		const subscription = getState().subscription;
		const _subscription = _.cloneDeep(subscription);
		const _plan = subscription.plan;
		const feature = _plan.getFeaturesForPurchase(_subscription.plan.features, PricableFeature.USERS);
		// Get network users and set it as default minimum for users feature
		const selectedPeriod = _plan.getPeriodByInterval(_subscription.invoice.period.interval);
		feature.amount = subscription.features[PricableFeature.USERS].value;
		feature.prices = subscription.features[PricableFeature.USERS].prices;
		feature.getPriceByCurrency(currency, <PeriodInterval>selectedPeriod.interval);
		feature.calculatePrice();

		patchState({ loading: false });
	}
}
