import { eqaAssert } from '@shared/domain/classes/assert';

/**
 * Adds 'px' to the number and turns it into a string
 */
export function px(value: number): string {
	eqaAssert.assert(Number.isFinite(value), 'Value must be finite number');

	return `${value}px`;
}
