import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EqaScrollControlsComponent } from './scroll-controls.component';
import { EqaScrollbarDirective } from './scrollbar.directive';
import { EqaScrollbarWrapperDirective } from './scrollbar-wrapper.directive';
import { LetModule } from '@shared/ui/directives/let';

@NgModule({
	imports: [CommonModule, LetModule],
	declarations: [EqaScrollbarDirective, EqaScrollbarWrapperDirective, EqaScrollControlsComponent],
	exports: [EqaScrollControlsComponent, EqaScrollbarDirective, EqaScrollbarWrapperDirective],
})
export class TuiScrollControlsModule {}
