import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { CopyRequirementDto } from "../interfaces/requirement/copy.requirement.dto";
import { CreateRequirementDto } from "../interfaces/requirement/create.requirement.dto";
import { MoveRequirementsDto } from "../interfaces/requirement/move.requirement.dto";
import { RemoveRequirementsDto } from "../interfaces/requirement/remove.requirements.dto";
import { UpdateRequirementDto } from "../interfaces/requirement/update.requirement.dto";

@Injectable({ providedIn: 'root' })
export class RequirementApiService {
	private baseUrl = ServerUrl.HOME_URL;

	private groupsUrl = this.baseUrl + '/requirement';

	constructor(private http: HttpClient) {}

	getByGroup(projectId: string, requirementGroupId: string) {
		return this.http.get(`${this.requirementUrl(projectId)}/groups/${requirementGroupId}/items`);
	}

	getOneById(projectId: string, groupId: string, requirementId: string) {
		return this.http.get(`${this.requirementUrl(projectId)}/groups/${groupId}/items/${requirementId}`);
	}

	createRequirement(projectId: string, requirement: CreateRequirementDto) {
		return this.http.post(`${this.requirementUrl(projectId)}/groups/${requirement.groupId}/items`, requirement);
	}

	updateRequirement(projectId: string, requirement: UpdateRequirementDto, requirementId: string) {
		return this.http.put(
			`${this.requirementUrl(projectId)}/groups/${requirement.groupId}/items/${requirementId}`,
			requirement,
		);
	}

	removeRequirement(projectId: string, groupId: string, id: string) {
		return this.http.delete(`${this.requirementUrl(projectId)}/groups/${groupId}/items/${id}`);
	}

	moveRequirements(projectId: string, moveRequirementsDto: MoveRequirementsDto) {
		return this.http.post(`${this.requirementUrl(projectId)}/move`, moveRequirementsDto);
	}

	copyRequirements(projectId: string, copyRequirementsDto: CopyRequirementDto) {
		return this.http.post(`${this.requirementUrl(projectId)}/copy`, copyRequirementsDto);
	}

	removeRequirements(projectId: string, groupId: string, removeRequirementsDto: RemoveRequirementsDto) {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: removeRequirementsDto,
		};
		return this.http.delete(`${this.requirementUrl(projectId)}/groups/${groupId}/items`, options);
	}

	requirementUrl(projectId) {
		return `${this.baseUrl}/projects/${projectId}/requirements`;
	}

	linkCases(projectId: string, requirementGroupId: string, requirementId: string, caseIds: string[]) {
		return this.http.put(
			`${this.requirementUrl(projectId)}/groups/${requirementGroupId}/items/${requirementId}/cases`,
			{ caseIds },
		);
	}

	getLinkedCases(projectId: string, requirementGroupId: string, requirementId: string) {
		return this.http.get(`${this.requirementUrl(projectId)}/groups/${requirementGroupId}/items/${requirementId}/cases`);
	}

	getTraceabilityMatrix(projectId: string) {
		/*let params = new HttpParams();
		params = params.append('runs', runIds.join(', '));*/
		return this.http.get(`${this.requirementUrl(projectId)}/matrix`);
	}
}
