import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { EqaWrapperModule } from '../wrapper/wrapper.module';
import { LoaderModule } from '../../elements/loader/loader.module';
import { SvgModule } from '../../elements/svg/svg.module';
import { EqaButtonComponent } from './button.component';
import { EqaHintModule } from '../../directives/hint';
import { EqaTooltipModule } from '../tooltip';

@NgModule({
	declarations: [EqaButtonComponent],
	exports: [EqaButtonComponent],
	imports: [
		CommonModule,
		EqaWrapperModule,
		PolymorpheusModule,
		LoaderModule,
		SvgModule,
		EqaHintModule,
		EqaTooltipModule,
	],
})
export class ButtonModule {}
