import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { CreateRequirementGroupDto } from "../interfaces/requirement-group/create.requirement.group.dto";
import { UpdateRequirementGroupDto } from "../interfaces/requirement-group/update.requirement.group.dto";

@Injectable({ providedIn: 'root' })
export class RequirementGroupApiService {
	private baseUrl = ServerUrl.HOME_URL;

	constructor(private http: HttpClient) {}

	createGroup(projectId: string, group: CreateRequirementGroupDto) {
		return this.http.post(`${this.requirementsUrl(projectId)}/groups`, group);
	}

	updateGroup(projectId: string, groupId, group: UpdateRequirementGroupDto) {
		return this.http.put(`${this.requirementsUrl(projectId)}/groups/${groupId}`, group);
	}

	delete(projectId: string, groupId: string) {
		return this.http.delete(`${this.requirementsUrl(projectId)}/groups/${groupId}`);
	}

	getGroups(projectId) {
		return this.http.get(`${this.requirementsUrl(projectId)}/groups`);
	}

	requirementsUrl(projectId) {
		return `${this.baseUrl}/projects/${projectId}/requirements`;
	}
}
