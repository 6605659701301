import { AddTestContexts } from '@features/run/domain/constants/add-tests-contexts';
import { PartialResult } from '@features/run/domain/interfaces/test/partial.result';
import { Test } from '@features/run/domain/interfaces/test/test';
import { UserModel } from '@core/interfaces/user/UserModel';
import {PieStats} from "@shared/ui/components/charts/interfaces/pie.stats";

export class GetTests {
	static readonly type = '[Execution] Get Tests';

	constructor(public runId: string) {}
}

export class SetTests {
	static readonly type = '[Execution] Set Tests';

	constructor(public tests: Test[]) {}
}

export class GetTest {
	static readonly type = '[Execution] Get Test';

	constructor(public id: string) {}
}

export class SetActiveTest {
	static readonly type = '[Execution] Set Active Test';

	constructor(public test: Test) {}
}

export class SetLoadingStatus {
	static readonly type = '[Execution] Set Loading Status';

	constructor(public loading: boolean) {}
}

export class SetExecutionStatistics {
	static readonly type = '[Execution] Set Execution Stats';

	constructor(public stats: PieStats) {}
}

export class SetSelectedTests {
	static readonly type = '[Execution] Set Tests To Execute';

	constructor(public testCases: any[]) {}
}

export class AddTests {
	static readonly type = '[Execution] Add Tests';

	constructor(public runId: string, public caseIds: string[], public context: AddTestContexts) {}
}

export class AddTestResult {
	static readonly type = '[Result] Add Result';

	constructor(public projectId: string, public testIds: string[], public result: PartialResult) {}
}

export class AssignTests {
	static readonly type = '[Test] Assign To User';

	constructor(public runId: string, public testIds: string[], public assignTo: UserModel) {}
}

export class RemoveTests {
	static readonly type = '[Test] Remove Tests From Run';

	constructor(public runId: string, public testIds: string[], public assignTo: UserModel) {}
}

export class GetActiveTestActions {
	static readonly type = '[Test] Get Actions';

	constructor(public testId: string) {}
}
