import { Section } from '@features/case/case-repository/domain/interfaces/section';
import {
	ROOT_CASES_SECTION_NAME,
	VIRTUAL_SECTION_ID,
} from '@features/case/case-repository/domain/contants/section-constants';

export function processSections(sections: Section[]) {
	let calculated: Section[] = [];
	sections.forEach((section) => {
		if (section.id === null) {
			section.name = ROOT_CASES_SECTION_NAME;
			section.id = VIRTUAL_SECTION_ID;
		}
		section.path = [];
		if (section.parent) {
			const parent = sections.find((x) => x.id === section.parent.id);
			if (parent?.path?.length) {
				section.path.push(...parent.path);
			}
			section.path.push(section.parent.id);
		}
		calculated.push(section);
	});
	return calculated;
}
