import { TestAction } from '@features/run/domain/interfaces/test/test.action';
import { TeamEffort } from '../../interfaces/TeamEffort';
import { Status } from '@features/run/domain/interfaces/test/status';
import { Utils } from '@core/utils/utils';
import { ArrayUtils } from '@core/utils/array.utils';

export type StatusKeyVal = {
	[key: string]: {
		status: Status, count: number

	}
}

export function calculateTeamEffort(groupedProgressByUser: any, statuses: StatusKeyVal) {
	const teamEffort: TeamEffort[] = [];
	Object.keys(groupedProgressByUser).forEach((key, index) => {
		const currentActionsArray = <Array<TestAction>> groupedProgressByUser[key];
		let total = currentActionsArray.length;
		const calculatedByStatus = currentActionsArray.reduce((acc, curr) => {
			const key = curr.status.name;
			if (!acc[key]) {
				acc[key] = {
					count: 0,
					name: curr.status.name,
					color: curr.status.color,
					percentage: 0,
				};
			}
			acc[key].count += 1;
			acc[key].percentage = ((acc[key].count / total) * 100).toFixed(0);
			return acc;
		}, {});
		teamEffort[index] = {
			creator: currentActionsArray[0].creator,
			statuses: Object.values({ ...statuses, ...calculatedByStatus }),
			total: total,
		};
	});

	return teamEffort;
}

export function calculateTeamEffortChartStats(startDate: Date, testsInRunCount: number, actionList: TestAction[]) {
	// Create chart data with initial value.
	// First data elements always equal tests count in run.
	const lineChartData = [
		{
			backgroundColor: 'rgba(180, 236, 248, 0.2)',
			borderColor: '#119FBF',
			data: [testsInRunCount],
			label: 'Untested tests',
			pointBackgroundColor: '#ffffff',
			pointBorderColor: '#119FBF',
			pointHoverBackgroundColor: '#119FBF',
			pointHoverBorderColor: '#119FBF',
		},
	];
	// Add first label. First label equals test run start date
	const labels = [Utils.parseStringToDate(startDate, 'yyyy-MM-dd')];
	actionList
		.sort((a, b) => {
			let dateA = new Date(a.createdAt).getTime();
			let dateB = new Date(b.createdAt).getTime();
			return dateA - dateB;
		});

	const groupedActions = ArrayUtils.groupArrayByDate(actionList, 'createdAt');
	Object.keys(groupedActions).forEach(key => {
		labels.push(key);
		testsInRunCount -= groupedActions[key].filter(a => a.status.name !== 'not_tested').length;
		lineChartData[0].data.push(testsInRunCount);
	});

	return {
		data: lineChartData,
		labels: labels.map(l => Utils.formatDate(new Date(l), 'MM-dd').toString()),
	};

}
