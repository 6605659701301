import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerUrl } from '@core/constants/server-url';
import { GroupModel } from '@features/network/components/team/domain/interfaces/group.model';

@Injectable({ providedIn: 'root' })
export class GroupsApiService {
	private baseUrl = ServerUrl.HOME_URL;

	private groupsUrl = this.baseUrl + '/groups';

	constructor(private http: HttpClient) {}

	getListByNetwork() {
		return this.http.get(this.groupsUrl + '/network');
	}

	getOneById(groupId: string) {
		return this.http.get(this.groupsUrl + '/' + groupId);
	}

	createGroup(group: GroupModel) {
		return this.http.post(this.groupsUrl, group);
	}

	updateGroup(groupId, group: GroupModel) {
		return this.http.put(this.groupsUrl + '/' + groupId, group);
	}

	delete(id: string) {
		return this.http.delete(this.groupsUrl + '/' + id);
	}

	mangeGroupAccess(usersAndProjects) {
		return this.http.post(this.groupsUrl + '/relations', usersAndProjects);
	}
}
