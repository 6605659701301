import { ComponentRef, Injectable, Type } from '@angular/core';
import { DomService } from './dom.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ModalService {
	private modalElementId = 'roowix-modal';
	private modalBodyElementId = 'roowix-body-modal';
	private modalBodyElementCenterId = 'roowix-body-modal-center';
	private modalBodyElementId2 = 'roowix-body-modal-left';

	constructor(private domService: DomService, private router: Router) {}

	init<T>(component: Type<T>, inputs?: object, outputs?: object, position?: string, width?: string): ComponentRef<T> {
		const componentConfig = {
			inputs,
			outputs,
		};
		let ref: ComponentRef<T>;
		const doc = document.getElementById(this.modalBodyElementId);
		document.getElementById(this.modalElementId).classList.add('show');
		switch (position) {
			case 'left':
				ref = this.domService.appendComponentTo(this.modalBodyElementId2, component, componentConfig);
				const lElement = document.getElementById(this.modalBodyElementId2);
				if (lElement) {
					lElement.classList.add('show');
				}
				break;

			case 'center':
				ref = this.domService.appendComponentTo(this.modalBodyElementCenterId, component, componentConfig);
				const cElement = document.getElementById(this.modalBodyElementCenterId);
				if (cElement) {
					cElement.classList.add('show');
				}
				break;
			default:
				doc.style.width = width ? `${width}` : '35%';
				ref = this.domService.appendComponentTo(this.modalBodyElementId, component, componentConfig);
				const elem = document.getElementById(this.modalBodyElementId);
				if (elem) {
					elem.classList.add('show');
				}
		}

		this.router.events.subscribe(() => {
			if (ref) {
				this.destroy();
			}
		});
		return ref;
	}

	destroy() {
		this.domService.removeComponent();
		try {
			document.getElementById(this.modalElementId).classList.remove('show');
			document.getElementById(this.modalBodyElementId).classList.remove('show');
			document.getElementById(this.modalBodyElementCenterId).classList.remove('show');
			document.getElementById(this.modalBodyElementId2).classList.remove('show');
			document.getElementById(this.modalBodyElementId).classList.remove('w-60');
		} catch (e) {}
	}
}
