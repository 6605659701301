import { OrderObject } from "@core/interfaces/order-object";
import { UserModel } from "@core/interfaces/user/UserModel";
import { CreateReportDto } from "../models/create-report.dto";
import { Report } from "../models/report";

export namespace ReportActions {
	export class GetAll {
		static type = '[Reports] Get Reports List';

		constructor(public projectId: string) {}
	}

	export class GetOne {
		static type = '[Reports] Get Report';

		constructor(public projectId: string, public id: string) {}
	}

	export class DeleteOne {
		static type = '[Reports] Delete Report';

		constructor(public projectId: string, public id: string) {}
	}

	export class Create {
		static type = '[Reports] Create Report';

		constructor(public dto: CreateReportDto, public user: UserModel) {}
	}

	export class SetFilters {
		static type = '[Reports] Set Filters';

		// TODO: implement
		constructor(public filters: any) {}
	}

	export class SetOrder {
		static type = '[Reports] Order By';

		constructor(public orderObjects: OrderObject<Report>[]) {}
	}
}
