import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";

@Injectable({ providedIn: 'root' })
export class PlansApiService {
	private baseUrl = ServerUrl.HOME_URL;
	private billingUrl = this.baseUrl + '/billing/plans';

	constructor(private http: HttpClient) {}

	getPlans() {
		return this.http.get(this.billingUrl);
	}

	applyPlan(planId, applyPlan) {
		return this.http.post(`${this.billingUrl}/${planId}/apply`, applyPlan);
	}

	getCost(planId, applyPlan) {
		return this.http.post(`${this.billingUrl}/${planId}/cost`, applyPlan);
	}
}
