import { ChangeDetectionStrategy, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { SidenavCollapseService } from '@shared/domain/services/sidenav-collapse.service';
import { DestroyService } from '@shared/domain';
import { SidenavContextService } from '@shared/domain/services/sidenav-context.service';
import { Observable } from 'rxjs';
import { Context } from '@layout/domain/store/sidenav/context';
import { AccountService } from '@core/services/http/auth/account.service';

@Component({
	selector: 'eqa-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class SidenavComponent {
	@Input() context: Context;

	public collapsed$ = this.sidenavCollapseService.collapseObs$;

	constructor(private sidenavCollapseService: SidenavCollapseService) {}

	public collapse(): void {
		this.sidenavCollapseService.toggleSidenav();
	}
}
