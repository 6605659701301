import { Injectable } from "@angular/core";
import { Utils } from "@core/utils/utils";
import { CustomField } from "@features/case/case-fields/domain";

import { Testcase } from "@features/case/case-repository/domain/interfaces/testcase";
import { UpdateCaseDto } from "@features/case/case-repository/domain/interfaces/update-case.dto";
import { HttpSectionsService } from "@features/case/case-repository/domain/services/http-sections.service";
import { TestCaseApiService } from "@features/case/case-repository/domain/services/test.case.api.service";
import { DefaultFields } from "@features/case/case-review/domain/constants/default.fields";
import { TestApiService } from "@features/run/domain/services/api/test-execution/test.api.service";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { append, insertItem, patch, updateItem } from "@ngxs/store/operators";
import { cloneDeep, isEqual, last, pullAllBy, pullAllWith, sortBy } from "lodash";
import { of } from "rxjs";
import { finalize, pluck, switchMap, tap } from "rxjs/operators";
import { UpdateNodeContent } from "../../../../../../store/tree/tree.state.actions";
import { AttachmentsState } from "../../../../../attachments/domain/store/attachments.state";
import { CustomFieldsState } from "@features/projects/+state/settings/custom-fields/custom-fields.state";
import { CasesRoutesEndPoints, TestCaseStatus } from "../../contants/case-constants";
import { ContentOperations } from "../../contants/content.operations";
import { VIRTUAL_SECTION_ID } from "../../contants/section-constants";
import { CasesViewMode } from "../../interfaces/cases-view-mode";
import { EmptyCaseFactoryService } from "../../services/empty-case-factory.service";
import {
	ChangeCaseTags,
	ChangeTestCaseCheckedFlag,
	ClearActiveCase,
	ClearChangeLog,
	ClearHistory,
	CopyTestCasesList,
	CreateTestCase,
	GetTestCase,
	GetTestCaseChangelog,
	GetTestcaseRunHistory,
	GetTestCasesList,
	GetTestCasesListByProjectId,
	MoveTestCasesList,
	RemoveTestCase,
	RemoveTestCasesList,
	SelectTestCase,
	SetCaseList,
	SetCaseNgxsFormValue,
	SetCasesViewMode,
	SetLoadingStatus,
	SetSelectedCasesList,
	SubmitBulkCaseForm,
	SubmitCaseForm,
	ToggleShowCasesCount,
	UpdateTestCase,
	UpdateTestCases
} from "./testcase.actions";
import { ChangeLog } from "../../interfaces/change-log";

export interface TestCaseStateModel {
	testCaseList: Array<Testcase>;
	activeTestCase: Testcase;
	casesLoading: boolean;
	bulkCasesActionsEnabled: boolean;
	allCasesSelected: boolean;
	changelog: ChangeLog[];
	testHistory: any[];
	caseLoading: boolean;
	showCasesCount: boolean;
	selectedTestCases: Array<Testcase>;
	selectedCasesIds: Array<string>;
	caseForm: {
		model: UpdateCaseDto;
		dirty: boolean;
		status: string;
		errors: {};
	};
	viewMode: CasesViewMode;
}

@State<TestCaseStateModel>({
	name: 'testCasesState',
	defaults: {
		caseForm: {
			model: undefined,
			dirty: false,
			status: '',
			errors: {},
		},
		testCaseList: [],
		activeTestCase: null,
		casesLoading: false,
		allCasesSelected: false,
		bulkCasesActionsEnabled: false,
		changelog: [],
		testHistory: [],
		caseLoading: true,
		showCasesCount: false,
		selectedTestCases: [],
		selectedCasesIds: [],
		viewMode: CasesViewMode.LIST,
	},
})
@Injectable()
export class TestcaseState {
	@Selector()
	static viewMode(testCaseStateModel: TestCaseStateModel): CasesViewMode {
		return testCaseStateModel.viewMode;
	}

	@Selector()
	static casesFormValue(testCaseStateModel: TestCaseStateModel): UpdateCaseDto {
		return testCaseStateModel.caseForm.model;
	}

	@Selector()
	static casesFormValid(testCaseStateModel: TestCaseStateModel): boolean {
		return testCaseStateModel.caseForm.status === 'VALID';
	}

	@Selector()
	static casesList(testCaseStateModel: TestCaseStateModel): Array<Testcase> {
		return testCaseStateModel.testCaseList;
	}

	@Selector()
	static changelog(testCaseStateModel: TestCaseStateModel): Array<any> {
		return testCaseStateModel.changelog;
	}

	@Selector()
	static selectedCasesList(testCaseStateModel: TestCaseStateModel): Array<Testcase> {
		return testCaseStateModel.selectedTestCases;
	}

	@Selector()
	static allCasesSelected(testCaseStateModel: TestCaseStateModel): boolean {
		return testCaseStateModel.selectedTestCases.length === testCaseStateModel.selectedCasesIds.length;
	}

	@Selector()
	static selectedCasesIds(testCaseStateModel: TestCaseStateModel): Array<string> {
		return testCaseStateModel.selectedCasesIds;
	}

	@Selector()
	static rootCases(testCaseStateModel: TestCaseStateModel): Testcase[] {
		return testCaseStateModel.testCaseList.filter((c) => !c?.section?.id);
	}

	@Selector()
	static activeTestCase(testCaseStateModel: TestCaseStateModel): Testcase {
		return testCaseStateModel.activeTestCase;
	}

	@Selector()
	static testCase(testCaseStateModel: TestCaseStateModel): Testcase {
		return last(testCaseStateModel.testCaseList);
	}

	@Selector()
	static caseLoading(testCaseStateModel: TestCaseStateModel): boolean {
		return testCaseStateModel.caseLoading;
	}

	@Selector()
	static bulkCasesActionsEnabled(testCaseStateModel: TestCaseStateModel): boolean {
		return testCaseStateModel.bulkCasesActionsEnabled;
	}

	@Selector()
	static casesLoading(testCaseStateModel: TestCaseStateModel): boolean {
		return testCaseStateModel.casesLoading;
	}

	@Selector()
	static testHistory(testCaseStateModel: TestCaseStateModel): any[] {
		return testCaseStateModel.testHistory.filter((h) => h.actions?.length);
	}

	@Selector()
	static showCasesCount(testCaseStateModel: TestCaseStateModel): boolean {
		return testCaseStateModel.showCasesCount;
	}

	constructor(
		private sectionApiService: HttpSectionsService,
		private testCasesApiService: TestCaseApiService,
		private testApiService: TestApiService,
		private emptyCaseFactoryService: EmptyCaseFactoryService,
		private store: Store,
	) {}

	@Action(GetTestCasesList)
	getTestCasesList({ getState, patchState }: StateContext<TestCaseStateModel>, { section }: GetTestCasesList) {
		patchState({ casesLoading: true });
		const sectionId = section.id;
		return this.testCasesApiService.getCasesBySectionId(sectionId).pipe(
			pluck('data'),
			tap((casesList: any[]) => {
				patchState({
					testCaseList: casesList,
					allCasesSelected: false,
					bulkCasesActionsEnabled: false,
					casesLoading: false,
				});
			}),
		);
	}

	@Action(GetTestCasesListByProjectId)
	getTestCasesListByProjectId(
		{ getState, patchState }: StateContext<TestCaseStateModel>,
		{ projectId }: GetTestCasesListByProjectId,
	) {
		patchState({ casesLoading: true });
		return this.testCasesApiService.getCasesByProjectId(projectId).pipe(
			pluck('data'),
			tap((casesList: Testcase[]) => {
				patchState({
					testCaseList: casesList,
					casesLoading: false,
				});
			}),
		);
	}

	@Action(GetTestcaseRunHistory)
	getTestcaseRunHistory(
		{ getState, patchState }: StateContext<TestCaseStateModel>,
		{ testCaseId }: GetTestcaseRunHistory,
	) {
		patchState({ caseLoading: true });
		return this.testApiService.getHistoryByTestCaseId(testCaseId).pipe(
			pluck('data'),
			tap((history: any[]) => {
				patchState({
					testHistory: history,
					caseLoading: false,
				});
			}),
		);
	}

	@Action(GetTestCase)
	getTestCase({ patchState }: StateContext<TestCaseStateModel>, { testCaseId }: GetTestCase) {
		patchState({ caseLoading: true });
		if (testCaseId === CasesRoutesEndPoints.Create) {
			return of(
				patchState({
					activeTestCase: this.emptyCaseFactoryService.create(),
					caseLoading: false,
				}),
			);
		}
		return this.testCasesApiService.getTestCase(testCaseId).pipe(
			pluck('data'),
			tap((testCase: Testcase) => {
				patchState({ activeTestCase: testCase, caseLoading: false });
			}),
		);
	}

	@Action(ClearActiveCase)
	clearActiveCase({ patchState }: StateContext<TestCaseStateModel>) {
		patchState({ activeTestCase: null });
	}

	@Action(SetCasesViewMode)
	setViewMode({ patchState }: StateContext<TestCaseStateModel>, { viewMode }: SetCasesViewMode) {
		patchState({ viewMode });
	}

	@Action(SetLoadingStatus)
	setLoadingStatus({ patchState }: StateContext<TestCaseStateModel>, { status }: SetLoadingStatus) {
		patchState({ caseLoading: status });
	}

	@Action(GetTestCaseChangelog)
	getTestCaseChangelog({ patchState }: StateContext<TestCaseStateModel>, { testCaseId }: GetTestCaseChangelog) {
		patchState({ casesLoading: true });
		return this.testCasesApiService.getTestcaseChangelog(testCaseId).pipe(
			pluck('data'),
			tap((changes: ChangeLog[]) => {
				patchState({ changelog: changes, caseLoading: false });
			}),
		);
	}

	@Action(CreateTestCase)
	createTestCase({ setState }: StateContext<TestCaseStateModel>, { testCase }: CreateTestCase) {
		return this.testCasesApiService.createTestCase(this.parseOneTestcaseEstimate(testCase)).pipe(
			tap((testCaseCreateResult: Testcase) => {
				setState(
					patch({
						testCaseList: append([testCaseCreateResult]),
						casesLoading: false,
					}),
				);
			}),
		);
	}

	@Action(UpdateTestCase)
	updateTestCase({ setState }: StateContext<TestCaseStateModel>, { testCase }: UpdateTestCase) {
		return this.testCasesApiService.updateTestCase(testCase.id, this.parseOneTestcaseEstimate(testCase)).pipe(
			tap((testCaseCreateResult: Testcase) => {
				setState(
					patch({
						testCaseList: updateItem((i) => i.id === testCase.id, testCaseCreateResult),
					}),
				);
			}),
		);
	}

	@Action(UpdateTestCases)
	updateTestCases(ctx: StateContext<TestCaseStateModel>, { updateManyCasesDto }: UpdateTestCases) {
		const { setState } = ctx;
		const updateDtoCopy = cloneDeep(updateManyCasesDto);
		const estimate = updateDtoCopy.fields.find((f) => f.slug === DefaultFields.ESTIMATE);
		if (estimate) {
			estimate.value.value = Utils.parseEstimate(estimate.value.value);
		}
		return this.testCasesApiService.bulkUpdateCases(updateDtoCopy).pipe(
			pluck('data'),
			tap(() => {
				setState(
					patch<TestCaseStateModel>({
						selectedTestCases: [],
					}),
				);
			}),
			switchMap((cases) => this.getTestCasesListByProjectId(ctx, { projectId: cases[0].projectId })),
		);
	}

	@Action(SubmitCaseForm)
	submitForm({ getState, setState, patchState, dispatch }: StateContext<TestCaseStateModel>, { updateCaseDto }: SubmitCaseForm) {
		const formValue: UpdateCaseDto = cloneDeep(updateCaseDto);
		patchState({ caseLoading: true });
		const activeCase = cloneDeep(getState().activeTestCase);

		const dto = this.getDto(formValue, activeCase);
		const sub$ = formValue.id
			? this.testCasesApiService.updateTestCase(dto.id, dto)
			: this.testCasesApiService.createTestCase(dto);

		return sub$.pipe(
			finalize(() => patchState({ caseLoading: false })),
			tap((activeTestCase) => {
				setState(
					patch({
						activeTestCase,
						testCaseList: formValue.id ? updateItem((c) => c.id === activeTestCase.id, activeTestCase) : insertItem(activeTestCase)
					}),
				);
			}),
			switchMap(() => {
				const active = cloneDeep(getState().activeTestCase);
				return dispatch(new UpdateNodeContent([active], active.section, ContentOperations.UPDATE));
			}),
		);
	}

	@Action(SubmitBulkCaseForm)
	submitBulkForm(ctx: StateContext<TestCaseStateModel>, { ids }: SubmitBulkCaseForm) {
		const { setState, getState, patchState, dispatch } = ctx;
		patchState({ caseLoading: true });
		const formValue = cloneDeep(getState().caseForm.model);
		const activeCase = cloneDeep(getState().activeTestCase);
		const { fields, tags, sectionId, isApproved, featureId } = this.getDto(formValue, activeCase, true);

		return this.testCasesApiService.bulkUpdateCases({ ids, fields, tags, sectionId, isApproved, featureId }).pipe(
			finalize(() => patchState({ caseLoading: false })),
			tap((cases) => {
				if (activeCase) {
					const newActiveCase = cases.find((ca) => ca.id === activeCase.id);
					patchState({
						activeTestCase: newActiveCase,
					});
				}
				const casesList = [...getState().testCaseList.filter((c) => !ids.includes(c.id)), ...cases];
				patchState({
					testCaseList: sortBy(casesList, 'id'), // TODO change iteratee to "order" when cases reordering will be implemented
				});
			}),
		);
	}

	@Action(ChangeCaseTags)
	changeCaseTags({ getState, patchState }: StateContext<TestCaseStateModel>, { tags }: ChangeCaseTags) {
		const state = getState();
		const activeTestCase = cloneDeep(state.activeTestCase);
		activeTestCase.tags = tags;
		patchState({ activeTestCase });
		return activeTestCase;
	}

	@Action(ToggleShowCasesCount)
	toggleShowCasesCount({ getState, patchState }: StateContext<TestCaseStateModel>) {
		patchState({ showCasesCount: !getState().showCasesCount });
	}

	@Action(ChangeTestCaseCheckedFlag)
	changeTestCaseCheckedFlag(
		{ getState, patchState }: StateContext<TestCaseStateModel>,
		{ payload }: ChangeTestCaseCheckedFlag,
	) {
		const state = getState();
		let isAllSelectedFlag = state.allCasesSelected;
		let bulkCasesActionsEnabled = state.bulkCasesActionsEnabled;
		const casesList = cloneDeep(state.testCaseList) as Array<Testcase>;
		if (payload.tCase) {
			const index = casesList.findIndex((x) => x.id === payload.tCase.id);
			casesList[index].checked = payload.flag;
			isAllSelectedFlag =
				casesList.filter((x) => x.checked === true).length === casesList.length && casesList.length > 0;
			bulkCasesActionsEnabled = casesList.filter((x) => x.checked === true).length > 0;
		} else {
			let length = casesList.length;
			isAllSelectedFlag = payload.flag;
			bulkCasesActionsEnabled = payload.flag;
			while (length--) {
				casesList[length].checked = payload.flag;
			}
		}

		patchState({
			testCaseList: casesList,
			allCasesSelected: isAllSelectedFlag,
			bulkCasesActionsEnabled: bulkCasesActionsEnabled,
		});
	}

	@Action(SelectTestCase)
	selectTestCase({ getState, patchState }: StateContext<TestCaseStateModel>, { testCase, flag }: SelectTestCase) {
		let selectedCasesIds = cloneDeep(getState().selectedCasesIds);
		if (testCase) {
			const index = selectedCasesIds.findIndex((id) => id === testCase.id);
			if (index < 0 && flag) {
				selectedCasesIds.push(testCase.id);
			}
			if (index >= 0 && !flag) {
				selectedCasesIds.splice(index, 1);
			}
		} else {
			selectedCasesIds = flag ? getState().selectedTestCases.map((c) => c.id) : [];
		}

		patchState({
			selectedCasesIds: selectedCasesIds,
		});
	}

	@Action(SetSelectedCasesList)
	setSelectedCasesList(
		{ getState, patchState }: StateContext<TestCaseStateModel>,
		{ testCase }: SetSelectedCasesList,
	) {
		patchState({
			selectedCasesIds: testCase.map((c) => c.id),
			selectedTestCases: testCase,
		});
	}

	@Action(RemoveTestCasesList)
	removeTestCasesList(
		{ getState, patchState }: StateContext<TestCaseStateModel>,
		{ testCaseIds }: RemoveTestCasesList,
	) {
		return this.testCasesApiService.removeTestCaseList(testCaseIds).pipe(
			tap(() => {
				patchState({
					testCaseList: pullAllWith(cloneDeep(getState().testCaseList), testCaseIds, (c, id) => c.id === id),
					allCasesSelected: false,
					bulkCasesActionsEnabled: false,
				});
			}),
		);
	}

	@Action(CopyTestCasesList)
	copyTestCasesList({ getState, patchState }: StateContext<TestCaseStateModel>, { ids, section }: CopyTestCasesList) {
		const caseList = cloneDeep(getState().testCaseList);
		return this.testCasesApiService
			.copyTestCaseList(ids, section?.id === VIRTUAL_SECTION_ID ? null : section?.id)
			.pipe(
				pluck('data'),
				tap((cases: Testcase[]) => {
					patchState({
						testCaseList: caseList.concat(cases),
						allCasesSelected: false,
					});
				}),
			);
	}

	@Action(RemoveTestCase)
	removeTestCase(
		{ getState, patchState }: StateContext<TestCaseStateModel>,
		{ testCaseId }: RemoveTestCase,
	) {
		const state = getState();
		const casesListCopy = cloneDeep(state.testCaseList);
		const selectedTestCasesCopy = cloneDeep(state.selectedTestCases);
		const indexOfSelectedTestCaseForDelete = selectedTestCasesCopy.findIndex(
			(testcase) => testcase.id === testCaseId,
		);
		const indexOfTestCaseForDelete = casesListCopy.findIndex((testcase) => testcase.id === testCaseId);
		return this.testCasesApiService.removeTestCase(testCaseId).pipe(
			tap(() => {
				casesListCopy.splice(indexOfTestCaseForDelete, 1);
				selectedTestCasesCopy.splice(indexOfSelectedTestCaseForDelete, 1);
				patchState({
					testCaseList: casesListCopy,
					selectedTestCases: selectedTestCasesCopy,
				});
			}),
		);
	}

	@Action(MoveTestCasesList)
	moveTestCasesList(
		{ getState, setState }: StateContext<TestCaseStateModel>,
		{ ids, newSection }: MoveTestCasesList,
	) {
		const state = getState();
		const selectedCases = cloneDeep(state.testCaseList).filter(cs => ids.includes(cs.id));
		let casesListCopy = cloneDeep(state.testCaseList).filter((cs) => !ids.includes(cs.id))

		if (ids.length) {
			return this.testCasesApiService
				.moveTestCases(
					selectedCases.map(cs => ({ caseId: cs.id, initialSectionId: cs.sectionId })),
					newSection.id === VIRTUAL_SECTION_ID ? null : newSection.id
				)
				.pipe(
					pluck('data'),
					tap((cases: Testcase[]) => {
						setState(
							patch<TestCaseStateModel>({
								testCaseList: [...casesListCopy, ...cases],
								allCasesSelected: false,
								bulkCasesActionsEnabled: false,
							}),
						);
					}),
				);
		}
	}

	@Action(ClearChangeLog)
	clearChangeLog({ patchState }: StateContext<TestCaseStateModel>, { }: ClearChangeLog) {
		patchState({
			changelog: [],
			caseLoading: true,
		});
	}

	@Action(ClearHistory)
	clearHistory({ patchState }: StateContext<TestCaseStateModel>, { }: ClearHistory) {
		patchState({
			testHistory: [],
			caseLoading: true,
		});
	}

	@Action(SetCaseList)
	pushCases({ patchState }: StateContext<TestCaseStateModel>, { testCaseList }: SetCaseList) {
		patchState({ testCaseList });
	}

	@Action(SetCaseNgxsFormValue)
	setCaseNgxsFormValue({ getState, patchState }: StateContext<TestCaseStateModel>, { formValue }: SetCaseNgxsFormValue) {
		patchState({ caseForm: {
			...getState().caseForm,
				model: formValue
			}
		});
	}

	private getDto(formValue: UpdateCaseDto, activeCase: Testcase, isBulk = false): UpdateCaseDto {
		const defaultFields = cloneDeep(this.store.selectSnapshot(CustomFieldsState.customFieldsList));
		if (isBulk) {
			activeCase = {
				automated: false,
				attachments: [],
				estimate: 0,
				expectedResult: '',
				innerId: '',
				name: '',
				preconditions: '',
				projectId: '',
				featureId: '',
				steps: [],
				isApproved: activeCase?.isApproved === TestCaseStatus.APPROVED,
				fields: Object.values(DefaultFields)
					.filter((f) => formValue[f] !== undefined)
					.map((f) => {
						const field = defaultFields.find((df) => df.slug === f);
						if (field.slug === 'estimate') {
							field.value = { value: parseInt(formValue[f], 10) };
							return field;
						}
						field.value = { value: formValue[f] };
						return field;
					}),
			};
		}

		if (!activeCase.fields?.length) {
			activeCase.fields = formValue.fields;
		}
		if (formValue.section?.id === null) {
			formValue.section = null;
		}
		const fields = activeCase.fields ?? [];
		pullAllBy(fields, formValue.fields, 'slug');
		const updateDefaultField = (field: DefaultFields) => {
			const cField = fields.find((f) => f.slug === field);
			if (formValue[field] !== undefined) {
				if (cField) {
					if (cField.slug === 'estimate') {
						cField.value.value = parseInt(formValue[field], 10);
					}
					cField.value.value = formValue[field];
				}
			}
		};

		Object.values(DefaultFields).forEach(updateDefaultField);

		const newFields =
			fields?.length || formValue?.fields?.length ? [...(fields ?? []), ...(formValue.fields ?? [])] : undefined;
		const tags: string[] = formValue?.tags ?? activeCase.tags?.map((t) => t.id);
		const attachments = this.store.selectSnapshot(AttachmentsState.attachments);
		formValue.isApproved = formValue.isApproved === TestCaseStatus.APPROVED;

		const dto = { ...activeCase, ...formValue, fields: newFields, tags, attachments } as UpdateCaseDto;

		if (dto?.sectionId === VIRTUAL_SECTION_ID) {
			dto.sectionId = null;
		}

		if (isBulk) {
			delete dto.id;
		}

		return dto;
	}

	private parseTestcasesEstimate(testCases: Testcase[]) {
		return testCases.map(this.parseOneTestcaseEstimate);
	}

	private parseOneTestcaseEstimate(testCase: any & { fields: CustomField[] }) {
		const testCaseCopy = cloneDeep(testCase);
		const estimate = testCaseCopy.fields.find((f) => f.slug === DefaultFields.ESTIMATE);
		if (estimate) {
			estimate.value.value = Utils.parseEstimate(estimate.value.value);
		}
		return testCaseCopy;
	}
}
