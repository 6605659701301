import {
	closeIcon,
	collapseIcon,
	copyIcon,
	copyIconBlack,
	cutIcon,
	deleteIcon,
	deleteIconBlack,
	downArrowIconEmpty,
	expandIcon,
	filterIcon,
	filterIcons,
	filtersIconBlack,
	importIcon,
	importMenuIcon,
	listCollapsedIcon,
	listDeleteIcon,
	listEditIcon,
	listSettingIcon,
	lockIcon,
	minusIcon,
	minusIconBlack,
	moveIcon,
	moveIconBlack,
	optionsIcon,
	plusIcon,
	plusIconBlack,
	projectOverview,
	projectSettingsMenuIcon,
	requirementsMenuIcon,
	rightArrowIconBlack,
	runsMenuIcon,
	searchIcon,
	settingsButton,
	showCountIcon,
	slackIcon,
	sortIconBlack,
	sprintsMenuIcon,
	telegrammIcon,
	testCasesMenuIcon,
	toolbarBackIcon,
	toolbarDeleteButton,
	toolbarEditIcon,
	unlockIcon,
	documentIcon,
} from './everyqa.icons';

export const registeredIcons = [
	toolbarBackIcon,
	optionsIcon,
	filterIcons,
	searchIcon,
	settingsButton,
	toolbarDeleteButton,
	toolbarEditIcon,
	listCollapsedIcon,
	listSettingIcon,
	listDeleteIcon,
	listEditIcon,
	projectOverview,
	testCasesMenuIcon,
	sprintsMenuIcon,
	runsMenuIcon,
	requirementsMenuIcon,
	projectSettingsMenuIcon,
	importMenuIcon,
	filterIcon,
	plusIconBlack,
	deleteIcon,
	cutIcon,
	copyIcon,
	plusIcon,
	moveIconBlack,
	deleteIconBlack,
	minusIcon,
	moveIcon,
	rightArrowIconBlack,
	downArrowIconEmpty,
	sortIconBlack,
	filtersIconBlack,
	copyIconBlack,
	unlockIcon,
	telegrammIcon,
	slackIcon,
	lockIcon,
	closeIcon,
	minusIconBlack,
	importIcon,
	showCountIcon,
	collapseIcon,
	expandIcon,
	documentIcon,
];
