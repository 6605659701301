import { Injectable } from "@angular/core";
import { RequirementGroupDto } from "@features/requirements/domain/interfaces/requirement-group/requirement.group.dto";
import { RequirementGroupApiService } from "@features/requirements/domain/services/requirement.group.api.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import * as _ from "lodash";
import { cloneDeep } from "lodash";
import { pluck, tap } from "rxjs/operators";
import { TreeUtils } from "../../../../../store/tree/tree.utils";
import {
	CollapseAllGroups,
	CreateRequirementsGroup,
	ExpandAllGroups,
	GetRequirementsGroup,
	RemoveRequirementsGroup,
	SetActiveRequirementGroup,
	ToggleRequirementGroup,
	ToggleShowRequirementsCount,
	UpdateRequirementsGroup
} from "./requirement.group.actions";
import { RequirementGroupStateModel } from "./requirement.group.state.model";
import { RequirementTree } from "./requirement.tree";

@State<RequirementGroupStateModel>({
	name: 'requirementsGroupState',
	defaults: {
		newRequirementGroup: null,
		requirementsGroupTree: [],
		requirementGroupsList: [],
		requirementsTreeLoading: true,
		activeIds: [],
		expandedIds: [],
		checkedIds: [],
		showRequirementsCount: false,
	},
})
@Injectable()
export class RequirementGroupState {
	@Selector()
	static requirementsGroupsTree(m: RequirementGroupStateModel): RequirementGroupDto[] {
		return m.requirementsGroupTree;
	}

	@Selector()
	static requirementsGroupsList(m: RequirementGroupStateModel): RequirementGroupDto[] {
		return m.requirementGroupsList;
	}

	@Selector()
	static empty(m: RequirementGroupStateModel): boolean {
		return m.requirementGroupsList.length === 0;
	}

	@Selector()
	static activeRequirementGroupsIds(m: RequirementGroupStateModel): string[] {
		return m.activeIds;
	}

	@Selector()
	static requirementsGroupsLoading(m: RequirementGroupStateModel): boolean {
		return m.requirementsTreeLoading;
	}


	@Selector()
	static newRequirementGroup(m: RequirementGroupStateModel): RequirementGroupDto {
		return m.newRequirementGroup;
	}

	@Selector()
	static activeGroup(m: RequirementGroupStateModel): RequirementGroupDto {
		if (m.activeIds.length) {
			return m.requirementGroupsList.find((x) => x.id === m.activeIds[0]);
		} else {
			return null;
		}
	}

	@Selector()
	static expandedRequirementGroupsIds(m: RequirementGroupStateModel): string[] {
		return m.expandedIds;
	}

	@Selector()
	static showRequirementsCount(m: RequirementGroupStateModel): boolean {
		return m.showRequirementsCount;
	}

	constructor(
		private requirementsGroupApiService: RequirementGroupApiService,
		private requirementTree: RequirementTree,
	) {}

	@Action(GetRequirementsGroup)
	getRequirementGroups(
		{ getState, setState, patchState }: StateContext<RequirementGroupStateModel>,
		{ projectId }: GetRequirementsGroup,
	) {
		patchState({
			requirementsTreeLoading: true,
		});
		return this.requirementsGroupApiService.getGroups(projectId).pipe(
			pluck('data'),
			tap((requirementGroupDtos: RequirementGroupDto[]) => {
				const requirementsGroups = cloneDeep(requirementGroupDtos);
				requirementsGroups.forEach((g) => (g.children = []));
				const tree = this.requirementTree.buildRequirementTree(requirementsGroups);
				const activeIds = getState().activeIds;
				patchState({
					activeIds: activeIds.length ? activeIds : (tree.length > 0 ? [tree[0].id] : []),
					requirementsGroupTree: tree,
					requirementGroupsList: this.requirementTree.calculateNodesPath(requirementsGroups),
					requirementsTreeLoading: false,
				});
			}),
		);
	}

	@Action(CreateRequirementsGroup)
	createRequirementGroup(
		{ getState, setState, patchState }: StateContext<RequirementGroupStateModel>,
		{ projectId, group }: CreateRequirementsGroup,
	) {
		return this.requirementsGroupApiService.createGroup(projectId, group).pipe(
			pluck('data'),
			tap((requirementGroupDto: RequirementGroupDto) => {
				requirementGroupDto.children = [];
				const reqGroups = cloneDeep(getState().requirementGroupsList);
				reqGroups.push(requirementGroupDto);
				const expanded = cloneDeep(getState().expandedIds);
				expanded.push(requirementGroupDto.id);
				patchState({
					requirementGroupsList: this.requirementTree.calculateNodesPath(reqGroups),
					expandedIds: expanded,
					requirementsGroupTree: this.requirementTree.buildRequirementTree(reqGroups),
					requirementsTreeLoading: false,
					newRequirementGroup: requirementGroupDto,
				});
			}),
		);
	}

	@Action(SetActiveRequirementGroup)
	setActiveRequirementGroup(
		{ getState, setState, patchState }: StateContext<RequirementGroupStateModel>,
		{ groupId }: SetActiveRequirementGroup,
	) {
		const expanded = cloneDeep(getState().expandedIds);
		expanded.push(groupId);
		patchState({
			activeIds: [groupId],
			expandedIds: Array.from(new Set(expanded)),
			requirementsTreeLoading: false,
		});
	}

	@Action(ToggleRequirementGroup)
	toggleRequirementGroup(
		{ getState, setState, patchState }: StateContext<RequirementGroupStateModel>,
		{ groupId }: ToggleRequirementGroup,
	) {
		const state = getState();
		const expanded = _.cloneDeep(state.expandedIds);
		const index = expanded.findIndex((id) => id === groupId);
		if (index >= 0) {
			expanded.splice(index, 1);
		} else {
			expanded.push(groupId);
		}
		patchState({
			expandedIds: expanded,
		});
	}

	@Action(ExpandAllGroups)
	expandAll({ getState, patchState }: StateContext<RequirementGroupStateModel>) {
		const stack = cloneDeep(getState().requirementsGroupTree);
		const expandedIds: string[] = [];
		while (stack.length) {
			const node = stack.shift();
			expandedIds.push(node.id);
			if (node?.children?.length) {
				stack.push(...node.children);
			}
		}
		patchState({ expandedIds });
	}

	@Action(CollapseAllGroups)
	collapseAll({ patchState }: StateContext<RequirementGroupStateModel>) {
		patchState({ expandedIds: [] });
	}

	@Action(UpdateRequirementsGroup)
	updateRequirementsGroup(
		{ getState, patchState }: StateContext<RequirementGroupStateModel>,
		{ projectId, id, group }: UpdateRequirementsGroup,
	) {
		return this.requirementsGroupApiService.updateGroup(projectId, id, group).pipe(
			pluck('data'),
			tap((requirementGroupDto: RequirementGroupDto) => {
				const reqGroups = cloneDeep(getState().requirementGroupsList);
				const reqIndex = reqGroups.findIndex((rG) => rG.id === requirementGroupDto.id);
				reqGroups[reqIndex].name = requirementGroupDto.name;
				reqGroups[reqIndex].parentId = requirementGroupDto.parentId;
				patchState({
					requirementGroupsList: this.requirementTree.calculateNodesPath(reqGroups),
					requirementsGroupTree: this.requirementTree.buildRequirementTree(reqGroups),
					requirementsTreeLoading: false,
				});
			}),
		);
	}

	@Action(RemoveRequirementsGroup)
	removeRequirementsGroup(
		{ getState, patchState }: StateContext<RequirementGroupStateModel>,
		{ projectId, group }: RemoveRequirementsGroup,
	) {
		return this.requirementsGroupApiService.delete(projectId, group.id).pipe(
			pluck('data'),
			tap(() => {
				const state = getState();
				const tree = _.cloneDeep(state.requirementsGroupTree);
				const reqGroups = cloneDeep(getState().requirementGroupsList);
				const reqIndex = reqGroups.findIndex((rG) => rG.id === group.id);
				reqGroups.splice(reqIndex, 1);

				const _node = _.cloneDeep(group);
				if (!_node.parentId) {
					let rootNodeIndex = tree.findIndex((s) => s.id === group.id);
					tree.splice(rootNodeIndex, 1);
					rootNodeIndex = rootNodeIndex > 0 ? rootNodeIndex - 1 : rootNodeIndex;
					const newActiveNode = tree[rootNodeIndex] ? tree[rootNodeIndex] : null;
					patchState({
						requirementsGroupTree: tree,
						requirementGroupsList: reqGroups,
						activeIds: newActiveNode ? [newActiveNode.id] : [],
					});
				} else {
					const nodesParent = TreeUtils.getNodeById(tree, _node.parentId);
					let nodesIndex = nodesParent.children.findIndex((c) => c.id === _node.id);
					nodesParent.children.splice(nodesIndex, 1);
					nodesIndex = nodesIndex > 0 ? nodesIndex - 1 : nodesIndex;
					const newActiveNode = nodesParent.children[nodesIndex] ? nodesParent.children[nodesIndex] : nodesParent;
					patchState({
						requirementsGroupTree: tree,
						requirementGroupsList: reqGroups,
						activeIds: [newActiveNode.id],
					});
				}
			}),
		);
	}

	@Action(ToggleShowRequirementsCount)
	toggleShowRequirementsCount({ getState, patchState }: StateContext<RequirementGroupStateModel>) {
		patchState({ showRequirementsCount: !getState().showRequirementsCount });
	}
}
