import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { Attachment } from "@features/attachments/domain/interfaces/attachment";
import { AttachmentEntityTypes } from "@features/attachments/domain/interfaces/attachment-entity.types";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AttachmentApiService {
	private baseUrl = ServerUrl.HOME_URL;
	private attachUrls = this.baseUrl + '/attachments';

	constructor(private http: HttpClient) {}

	saveAttachment(body) {
		return this.http.post<Attachment[]>(this.attachUrls, body);
	}

	removeAttachment(id: string) {
		return this.http.delete(this.attachUrls + '/' + id);
	}

	updateAttachments(body) {
		return this.http.post(this.attachUrls + '/attach/update', body);
	}

	assignAttachmentToEntity(
		attachments: Attachment[],
		entityIds: string[],
		type: AttachmentEntityTypes,
	): Observable<any> {
		const body = {
			attachmentIds: attachments.map((a) => a.id),
			entityIds: entityIds,
			type,
		};
		return this.http.post<any>(this.attachUrls + '/assign', body);
	}

	getByEntity(id) {
		return this.http.get(this.attachUrls + '/list/' + id);
	}
}
