import { ProjectModel, ProjectSettingsModel } from "@features/projects/interfaces";

export class LoadProject {
	static readonly type = '[ProjectModel] Load';

	constructor(public projectId: string) {}
}

export class AddProject {
	static readonly type = '[ProjectModel] Add';

	constructor(public payload: ProjectModel) {}
}

export class GetProjects {
	static readonly type = '[ProjectModel] Get';
}

export class GetAllProjects {
	static readonly type = '[ProjectModel] Get All';
}

export class SetProjects {
	static readonly type = '[ProjectModel] Set Projects';

	constructor(public payload: ProjectModel[]) {}
}

export class SetProject {
	static readonly type = '[ProjectModel] Set Project';

	constructor(public payload: ProjectModel) {}
}

export class ClearProject {
	static readonly type = '[ProjectModel] Clear Project';

	constructor() {}
}

export class UpdateProject {
	static readonly type = '[ProjectModel] Update';

	constructor(public payload: ProjectModel, public id: string) {}
}

export class UpdateProjectSettings {
	static readonly type = '[ProjectModel] Update Settings';

	constructor(public payload: ProjectSettingsModel, public id: string) {}
}

export class DeleteProject {
	static readonly type = '[ProjectModel] Delete';

	constructor(public id: string) {}
}

export class GetProjectAndSetItAsSelected {
	static readonly type = '[ProjectModel] Set';

	constructor(public id: string) {}
}

export class ClearState {
	static readonly type = '[ProjectModel] Clear State';

	constructor() {}
}

export class GetProjectUsers {
	static readonly type = '[ProjectModel] Get Users';

	constructor(public projectId: string = null) {}
}

export class SaveProjectsUsers {
	static readonly type = '[ProjectModel] Save Users';

	constructor(public userIds: string[]) {}
}

export class ToggleProjectsIsFavorite {
	static readonly type = '[ProjectModel] Toggle Favorite';

	constructor(public id: string) {}
}

export class LoadProjectsStatistics {
	static readonly type = '[ProjectModel] Load Statistic';

	constructor() {}
}

export class LoadProjectStatistics {
	static readonly type = '[ProjectModel] Load Project Statistic';

	constructor() {}
}
