import { ElementRef, InjectionToken, Provider } from '@angular/core';
import { merge, Observable, timer } from 'rxjs';
import { distinctUntilChanged, filter, mapTo, startWith, switchMapTo, take, takeUntil } from 'rxjs/operators';
import { DestroyService, EqaFocusVisibleService } from '@shared/domain';
import { stopPropagation, typedFromEvent } from '@shared/domain/observables';

const DELAY = 500;

export const EQA_DESCRIBED_BY_SHOW = new InjectionToken<Observable<boolean>>('Accessible tooltip visibility stream');
export const EQA_DESCRIBED_BY_PROVIDERS: Provider[] = [
	DestroyService,
	EqaFocusVisibleService,
	{
		provide: EQA_DESCRIBED_BY_SHOW,
		deps: [EqaFocusVisibleService, ElementRef],
		useFactory: describedByFactory,
	},
];

export function describedByFactory(
	focusVisible$: Observable<boolean>,
	{ nativeElement }: ElementRef<HTMLElement>,
): Observable<boolean> {
	return focusVisible$
		.pipe(
			filter(Boolean),
			switchMapTo(
				timer(DELAY).pipe(
					mapTo(true),
					takeUntil(merge(typedFromEvent(nativeElement, 'keydown'), typedFromEvent(nativeElement, 'blur'))),
				),
			),
			switchMapTo(
				merge(
					typedFromEvent(nativeElement, 'keydown').pipe(
						filter(({ key }) => key === 'Escape'),
						take(1),
						stopPropagation(),
						mapTo(false),
						startWith(true),
					),
					typedFromEvent(nativeElement, 'blur').pipe(mapTo(false)),
				),
			),
		)
		.pipe(distinctUntilChanged());
}
