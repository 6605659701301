export function getFirstElement<T>(array: Array<T>): T {
	if (!array) {
		return;
	}
	if (!array[0]) {
		return;
	}

	return array[0];
}
