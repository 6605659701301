import { NgModule } from "@angular/core";
import { FilesizePipe } from "@features/attachments/ui/pipes/filesize.pipe";
import { EqaUploadFormView } from "@features/attachments/ui/pipes/upload-form-view.pipe";

@NgModule({
	imports: [],
	exports: [FilesizePipe, EqaUploadFormView],
	declarations: [FilesizePipe, EqaUploadFormView],
	providers: [],
})
export class UploadAttachmentsPipesModule {}
