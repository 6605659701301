import { Currency } from "@core/constants";
import { BalanceActionsEnum } from "./balance.actions.enum";

export class GetBalance {
	static readonly type = BalanceActionsEnum.GET_BALANCE;

	constructor() {}
}

export class ResetBalance {
	static readonly type = BalanceActionsEnum.RESET_BALANCE;

	constructor() {}
}

export class RecalculateBalance {
	static readonly type = BalanceActionsEnum.RECALCULATE_BALANCE;

	constructor(public amount: any) {}
}

export class SetBalanceForPayment {
	static readonly type = BalanceActionsEnum.SET_BALANCE_FOR_PAYMENT;

	constructor(public amount: any) {}
}

export class ChangeBalanceCurrency {
	static readonly type = BalanceActionsEnum.CHANGE_BALANCE_CURRENCY;

	constructor(public currency: Currency) {}
}

export class ValidateBalance {
	static readonly type = BalanceActionsEnum.CHECK_BALANCE;

	constructor(public price: number) {}
}
