export enum RequirementActionsList {
	GET_REQUIREMENTS = '[Requirement] Get Requirements By Group',
	REMOVE_REQUIREMENT = '[Requirement] Remove Requirement',
	UPDATE_REQUIREMENT = '[Requirement] Update Requirement',
	GET_REQUIREMENT = '[Requirement] Get Requirement',
	CREATE_REQUIREMENT = '[Requirement] Create Requirement',
	LINK_CASES = '[Requirement] Link Cases',
	GET_LINKED_CASES = '[Requirement] Get Linked Cases',
	SET_CURRENT_REQUIREMENT = '[Requirement] Set Current Requirement',
	SELECT_REQUIREMENT = '[Requirement] Select Requirement',
	SELECT_ALL_REQUIREMENTS = '[Requirement] Select All Requirements',
	MOVE_REQUIREMENTS = '[Requirement] Move Requirements',
	COPY_REQUIREMENTS = '[Requirement] Copy Requirements',
	REMOVE_REQUIREMENTS = '[Requirement] Remove Requirements',
}
