import { Injectable } from "@angular/core";
import { CustomField } from "@features/case/case-fields/domain";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import * as _ from "lodash";
import { pluck, tap } from "rxjs/operators";
import { CustomFieldApiService } from "@features/projects/services/custom.field.api.service";
import { CreateCustomField, LoadCustomFields, RemoveCustomField, UpdateCustomField } from "./custom-fields.actions";

export interface CustomFieldsStateModel {
	customFields: Array<CustomField>;
	loading: boolean;
}

@State<CustomFieldsStateModel>({
	name: 'customFieldsState',
	defaults: {
		customFields: [],
		loading: false,
	},
})
@Injectable()
export class CustomFieldsState {
	@Selector()
	static customFieldsList(m: CustomFieldsStateModel): Array<CustomField> {
		return m.customFields;
	}

	@Selector()
	static loading(m: CustomFieldsStateModel): boolean {
		return m.loading;
	}

	constructor(private customFieldsApiService: CustomFieldApiService) {}

	@Action(LoadCustomFields)
	getCustomField({ getState, patchState }: StateContext<CustomFieldsStateModel>, { projectId }: LoadCustomFields) {
		patchState({ loading: true });
		return this.customFieldsApiService.getCustomFields(projectId).pipe(
			pluck('data'),
			tap((customFieldsList: Array<CustomField>) => {
				customFieldsList.sort((a, b) => a.order - b.order);
				patchState({
					customFields: customFieldsList.map((c) => new CustomField(c)),
					loading: false,
				});
			}),
		);
	}

	@Action(RemoveCustomField)
	removeCustomField(
		{ getState, patchState }: StateContext<CustomFieldsStateModel>,
		{ projectId, fieldId }: RemoveCustomField,
	) {
		return this.customFieldsApiService.deleteCustomField(projectId, fieldId).pipe(
			tap(() => {
				const customFieldsList = _.cloneDeep(getState().customFields);
				const index = customFieldsList.findIndex((f) => f.slug === fieldId);
				customFieldsList.splice(index, 1);
				patchState({
					customFields: customFieldsList.map((c) => new CustomField(c)),
					loading: false,
				});
			}),
		);
	}

	@Action(UpdateCustomField)
	updateCustomField(
		{ getState, patchState }: StateContext<CustomFieldsStateModel>,
		{ projectId, fieldId, field }: UpdateCustomField,
	) {
		return this.customFieldsApiService.updateCustomField(projectId, fieldId, field).pipe(
			tap(() => {
				const customFieldsList = _.cloneDeep(getState().customFields);
				const defaultFieldsCount = customFieldsList.filter((f) => f.order === 0).length;
				const index = customFieldsList.findIndex((f) => f.slug === fieldId);
				const [updatedField] = customFieldsList.splice(index, 1);
				updatedField.name = field.name;
				updatedField.order = field.order;
				customFieldsList.splice(defaultFieldsCount + field.order - 1, 0, updatedField);
				patchState({
					customFields: customFieldsList.map((c) => new CustomField(c)),
					loading: false,
				});
			}),
		);
	}

	@Action(CreateCustomField)
	createCustomField(
		{ getState, patchState }: StateContext<CustomFieldsStateModel>,
		{ projectId, field }: CreateCustomField,
	) {
		patchState({ loading: true });
		return this.customFieldsApiService.createCustomField(projectId, field).pipe(
			pluck('data'),
			tap((createdCustomField: CustomField) => {
				const fields = _.cloneDeep(getState().customFields);
				fields.push(createdCustomField);
				patchState({
					customFields: fields.map((c) => new CustomField(c)),
					loading: false,
				});
			}),
		);
	}
}
