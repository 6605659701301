import {
	CreateRequirementGroupDto
} from "@features/requirements/domain/interfaces/requirement-group/create.requirement.group.dto";
import { RequirementGroupDto } from "@features/requirements/domain/interfaces/requirement-group/requirement.group.dto";
import {
	UpdateRequirementGroupDto
} from "@features/requirements/domain/interfaces/requirement-group/update.requirement.group.dto";
import { RequirementGroupActionsList } from "./requirement.group.actions.list";

export class GetRequirementsGroup {
	static readonly type = RequirementGroupActionsList.GET_GROUPS;

	constructor(public projectId: string) {}
}

export class CreateRequirementsGroup {
	static readonly type = RequirementGroupActionsList.CREATE_REQUIREMENT;

	constructor(public projectId: string, public group: CreateRequirementGroupDto) {}
}

export class SetActiveRequirementGroup {
	static readonly type = RequirementGroupActionsList.SET_ACTIVE;

	constructor(public groupId: string) {}
}

export class ToggleRequirementGroup {
	static readonly type = RequirementGroupActionsList.TOGGLE_GROUP;

	constructor(public groupId: string) {}
}

export class CollapseAllGroups {
	static readonly type = RequirementGroupActionsList.COLLAPSE_ALL;
}

export class ExpandAllGroups {
	static readonly type = RequirementGroupActionsList.EXPAND_ALL;
}

export class UpdateRequirementsGroup {
	static readonly type = RequirementGroupActionsList.UPDATE_GROUP;

	constructor(public projectId: string, public id: string, public group: UpdateRequirementGroupDto) {}
}

export class RemoveRequirementsGroup {
	static readonly type = RequirementGroupActionsList.REMOVE_GROUP;
	constructor(public projectId: string, public group: RequirementGroupDto) {}
}

export class ToggleShowRequirementsCount {
	static readonly type = RequirementGroupActionsList.TOGGLE_SHOW_REQUIREMENTS_COUNT;
}
