import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { CaseFeature } from "@features/case/case-features/domain/interfaces/CaseFeature";
import { ProjectsState } from "@features/projects/+state/project/project.state";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class CaseFeaturesApiService {
	private baseUrl = ServerUrl.HOME_URL;

	private projectId = null;

	private url(): string {
		return `${this.baseUrl}/projects/${this.projectId}/features`;
	}

	private projectIDSub(): void {
		this.store.select(ProjectsState.selectedProject).subscribe((project) => (this.projectId = project?.id));
	}

	constructor(private http: HttpClient, private store: Store) {
		this.projectIDSub();
	}

	public loadProjectCaseFeatures(): Observable<CaseFeature[]> {
		return this.http.get<CaseFeature[]>(this.url());
	}

	public createCaseFeature(caseFeature: Partial<CaseFeature | 'name'>): Observable<CaseFeature> {
		return this.http.post<CaseFeature>(this.url(), caseFeature);
	}

	public updateCaseFeature(caseFeature: CaseFeature): Observable<CaseFeature> {
		const updateUrl = `${this.url()}/${caseFeature.id}`;
		return this.http.put<CaseFeature>(updateUrl, caseFeature);
	}

	public removeCaseFeature(caseFeatureId: string) {
		const removeUrl = `${this.url()}/${caseFeatureId}`;
		return this.http.delete(removeUrl);
	}
}
