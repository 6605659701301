import { Priority } from "@features/case/case-review/domain/constants/tescase.priorities";
import { RadioButtonLabelValue } from "@shared/ui/forms/ui/components/radio-button/radio-label-value";

export const PriorityColors = {
	[Priority.BLOCKER]: '#FF6252',
	[Priority.CRITICAL]: '#FF6252',
	[Priority.HIGH]: '#FF6252',
	[Priority.MEDIUM]: '#FFC23C',
	[Priority.LOW]: '#119FBF',
};

export const PriorityDisplayLabels = {
	[Priority.BLOCKER]: 'Blocker',
	[Priority.CRITICAL]: 'Critical',
	[Priority.HIGH]: 'High',
	[Priority.MEDIUM]: 'Medium',
	[Priority.LOW]: 'Low',
};

export enum TestCaseStatus {
	APPROVED = 'APPROVED',
	DRAFT = 'DRAFT',
}

export const TestCaseStatusDescription = {
	[TestCaseStatus.APPROVED]: 'Approved',
	[TestCaseStatus.DRAFT]: 'Draft',
};

export const TestCaseStatusesList = [
	{
		id: TestCaseStatus.APPROVED,
		name: TestCaseStatusDescription[TestCaseStatus.APPROVED],
	},
	{
		id: TestCaseStatus.DRAFT,
		name: TestCaseStatusDescription[TestCaseStatus.DRAFT],
	},
];

export const PrioritiesRadioArray: RadioButtonLabelValue[] = Object.values(Priority).map((v) => ({
	color: PriorityColors[v],
	value: v,
	label: PriorityDisplayLabels[v],
}));

export enum CasesRoutesEndPoints {
	PrimaryInfo = 'primary-info',
	Changelog = 'changelog',
	TestHistory = 'test-history',
	Defects = 'defects',
	TestScript = 'test-script',
	BulkReview = 'review',
	Create = 'create',
	BulkForm = 'form',
	BulkSelect = 'review',
	BulkEdit = 'bulk-edit',
}

export const CASE_ID_ROUTE_PARAM = 'caseId';
export const CASE_FORM_STORE_PATH = 'testCasesState.caseForm';
