export class ApiToken {
	id: string;
	name: string;
	createdAt: Date;
	expiresAt: Date;

	constructor(data) {
		this.id = data.id;
		this.name = data.name;
		this.createdAt = data.createdAt;
		this.expiresAt = data.expiresAt;
	}
}
