import { Directive, ElementRef, Inject, Input, Optional } from '@angular/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';

import { AbstractEqaHint } from '@shared/domain/abstracts';
import { EqaHintService } from '@shared/domain/services/hint-service';
import {
	EQA_MANUAL_HINT_OPTIONS,
	EqaManualHintOptions,
} from '@shared/ui/directives/manual-hint/manual-hint-options';
import { EqaActiveZoneDirective } from '@shared/ui/directives/active-zone/active-zone.directive';

@Directive({
	selector: '[eqaManualHint]:not(ng-container)',
})
export class EqaManualHintDirective extends AbstractEqaHint {
	@Input('eqaManualHint')
	content: PolymorpheusContent = '';

	@Input()
	set eqaManualHintShow(show: boolean) {
		if (show) {
			this.showTooltip();
		} else {
			this.hideTooltip();
		}
	}

	constructor(
		@Inject(ElementRef) elementRef: ElementRef<HTMLElement>,
		@Inject(EqaHintService) hintService: EqaHintService,
		@Optional()
		@Inject(EqaActiveZoneDirective)
		activeZone: EqaActiveZoneDirective | null,
		@Inject(EQA_MANUAL_HINT_OPTIONS) protected readonly options: EqaManualHintOptions,
	) {
		super(elementRef, hintService, activeZone, options);
	}

	getElementClientRect(): ClientRect {
		return this.elementRef.nativeElement.getBoundingClientRect();
	}
}
