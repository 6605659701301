import { Injectable } from "@angular/core";
import { RequirementGroupDto } from "@features/requirements/domain/interfaces/requirement-group/requirement.group.dto";
import * as _ from "lodash";

@Injectable({ providedIn: 'root' })
export class RequirementTree {
	buildRequirementTree(nodes: RequirementGroupDto[]): RequirementGroupDto[] {
		const root = [];
		const _nodes = this.calculateNodesPath(_.cloneDeep(nodes));
		const nodesCount = _nodes.length;
		for (let nodeIndex = 0; nodeIndex < nodesCount; nodeIndex++) {
			const node = _nodes[nodeIndex];
			if (!node.parentId) {
				root.push(node);
			} else {
				const parentIndex = _nodes.findIndex((el) => el.id === node.parentId);
				const currentParent = _nodes[parentIndex];
				if (currentParent.children) {
					currentParent.children.push(node);
				} else {
					currentParent['children'] = [];
					currentParent.children.push(node);
				}
			}
		}
		return root;
	}

	calculateNodesPath(nodes: RequirementGroupDto[]): RequirementGroupDto[] {
		const _sections = _.cloneDeep(nodes);
		const sectionsWithPath = [];
		const sectionsCount = _sections.length;
		for (let sectionIndex = 0; sectionIndex < sectionsCount; sectionIndex++) {
			const node = _sections[sectionIndex];
			node['path'] = [];
			if (node.parentId) {
				const parentIndex = _sections.findIndex((x) => x.id === node.parentId);
				const parent = _sections[parentIndex];
				if (parent['path'] && parent['path'].length) {
					node['path'].push(...parent['path'], node.parentId);
				} else {
					node['path'].push(node.parentId);
				}
			}
			sectionsWithPath.push(node);
		}
		return sectionsWithPath;
	}
}
