export enum SectionsActionsEnum {
	GET_SECTIONS = '[Sections] Get Sections',
	SET_SECTIONS = '[Sections] Set Sections',
	SELECT_SECTION = '[Sections] Select Section',
	UPDATE_SECTION = '[Sections] Update Section',
	CREATE_SECTION = '[Sections] Create Section',
	DELETE_SECTION = '[Sections] Delete Sections',
	MOVE_SECTIONS = '[Sections] Move Sections',
	SET_CUT_SECTION = '[Sections] Set Cut Section',
	GET_FULL_SECTIONS = '[Sections] Get Fulfilled Sections',
	FILTER_SECTIONS = '[Sections] Filter Sections',
	FULFILL_SECTIONS = '[Sections] Fulfill Sections',
}
