import { IntegrationProviders } from "./integration.providers";

export enum IntegrationName {
	GITHUB = 'Github',
	GITLAB = 'Gitlab',
	BITBUCKET_CLOUD = 'Bitbucket Cloud',
	TRELLO = 'Trello',
	JENKINS = 'Jenkins',
	JIRA_SOFTWARE = 'Jira Software',
	SLACK = 'Slack',
	TELEGRAM = 'Telegram',
}

export const INTEGRATION_NAME_TO_PROVIDERS: { [key in IntegrationName]: IntegrationProviders[] } = {
	[IntegrationName.GITHUB]: [IntegrationProviders.GITHUB],
	[IntegrationName.GITLAB]: [IntegrationProviders.GITLAB],
	[IntegrationName.BITBUCKET_CLOUD]: [IntegrationProviders.BITBUCKET_CLOUD],
	[IntegrationName.TRELLO]: [IntegrationProviders.TRELLO],
	[IntegrationName.JENKINS]: [IntegrationProviders.JENKINS],
	[IntegrationName.TELEGRAM]: [IntegrationProviders.TELEGRAM_BOT],
	[IntegrationName.SLACK]: [IntegrationProviders.SLACK],
	[IntegrationName.JIRA_SOFTWARE]: [IntegrationProviders.JIRA_CLOUD, IntegrationProviders.JIRA_HOSTED],
};

export const INTEGRATION_PROVIDER_TO_NAME: {
	[key in IntegrationProviders]: IntegrationName;
} = Object.entries(INTEGRATION_NAME_TO_PROVIDERS)
	.reduce((acc, [name, providers]) => ({
		...acc,
		...providers.reduce((childAcc, provider) => ({
			...childAcc,
			[provider]: name,
		}), {}),
	}), {} as { [key in IntegrationProviders]: IntegrationName });
