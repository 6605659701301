import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AbstractHttpService } from "@core/abstracts";
import { API_URL } from "@core/tokens/api-url.token";
import { Section } from "@features/case/case-repository/domain/interfaces/section";
import { UpdateSectionModel } from "@features/case/case-repository/domain/interfaces/update.section.model";
import { EMPTY_ARRAY } from "@shared/domain/constants";
import { processSections } from "@shared/domain/utils/tree";
import { ToastrService } from "ngx-toastr";
import { from } from "rxjs";
import { catchError, map, pluck } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class HttpSectionsService extends AbstractHttpService {
	private sectionsUrl = this.baseUrl + '/sections';

	constructor(
		notificationsService: ToastrService,
		@Inject(API_URL) private baseUrl: string,
		private http: HttpClient,
	) {
		super(notificationsService);
	}

	public getSections(projectId: string) {
		return this.http.get<Section[]>(`${this.sectionsUrl}/list/${projectId}`).pipe(
			pluck('data'),
			map((sections: Section[]) => {
				return processSections(sections);
			}),
			catchError((err) => {
				this.handleError(err);
				return from(EMPTY_ARRAY);
			}),
		);
	}

	getSectionsWithCases(projectId: string) {
		return this.http.get(`${this.sectionsUrl}/list/full/cases/${projectId}`);
	}

	updateSection(id: string, section: UpdateSectionModel) {
		return this.http.put(this.sectionsUrl + '/' + id, section);
	}

	createSection(section) {
		return this.http.post(this.sectionsUrl, section);
	}

	fetchSectionsWithCases(projectId: string) {
		return this.http.get(this.sectionsUrl + '/list/full/cases/' + projectId);
	}

	removeSectionList(ids) {
		return this.http.post(this.sectionsUrl + '/delete/many', ids);
	}

	moveSections(sectionsIds: string[], newParentId: string) {
		return this.http.post(this.sectionsUrl + '/move', { sectionsIds, newParentId });
	}
}
