import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SidenavStateModel } from '@layout/domain/store/sidenav/sidenav-state.model';
import { Injectable } from '@angular/core';
import { SetSidenavContext } from '@layout/domain/store/sidenav/sidenav-state.actions';
import produce from 'immer';
import { Context } from '@layout/domain/store/sidenav/context';

@State<SidenavStateModel>({
	name: 'sidenavState',
	defaults: {
		loading: false,
		context: null,
	},
})
@Injectable()
export class SidenavState {
	@Selector()
	public static sidenavContext(m: SidenavStateModel): Context {
		return m.context;
	}

	@Selector()
	public static loading(m: SidenavStateModel): boolean {
		return m.loading;
	}

	@Action(SetSidenavContext)
	setSidenavContext({ getState, patchState }: StateContext<SidenavStateModel>, { context }: SetSidenavContext) {
		patchState({
			loading: true,
		});
		const oldState = getState();
		produce(oldState, (draft) => {
			draft.context = context;
			draft.loading = false;
			patchState(draft);
		});
	}
}
