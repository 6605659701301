import { Injectable } from "@angular/core";
import {
	ROOT_CASES_SECTION_NAME,
	VIRTUAL_SECTION_ID
} from "@features/case/case-repository/domain/contants/section-constants";
import { HttpSectionsService } from "@features/case/case-repository/domain/services/http-sections.service";
import { HttpTestcaseExecutionsService } from "@features/executions/testcase-execution/domain/services";
import {
	loadTestCaseExecutionsHandler
} from "@features/executions/testcase-execution/domain/store/test-execution-state.hanlder";
import {
	testCaseExecutionsStateDefaults,
	TestCaseExecutionStateModel
} from "@features/executions/testcase-execution/domain/store/test-execution-state.model";
import { LoadTestExecutionsByRunId } from "@features/executions/testcase-execution/domain/store/test-execution.actions";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { forkJoin } from "rxjs";
import { tap } from "rxjs/operators";

@State<TestCaseExecutionStateModel>({
	name: 'testExecutionState',
	defaults: testCaseExecutionsStateDefaults,
})
@Injectable()
export class TestCaseExecutionState {
	@Selector()
	public static executions(model: TestCaseExecutionStateModel): any {
		const { executions } = model;
		const groupedExecutions = executions.reduce((acc, curr) => {
			const {
				testCase: { section = { id: VIRTUAL_SECTION_ID, name: ROOT_CASES_SECTION_NAME } },
			} = curr;
			if (!acc[section.id]) {
				acc[section.id] = {
					section,
					tests: [curr],
				};
				return acc;
			}
			acc[section.id].tests.push(curr);
		}, {});
		return Object.values(groupedExecutions);
	}
	constructor(
		private httpTestCaseExecutionsService: HttpTestcaseExecutionsService,
		private httpSectionsService: HttpSectionsService,
	) {}

	@Action(LoadTestExecutionsByRunId)
	public loadTestExecutions(
		context: StateContext<TestCaseExecutionStateModel>,
		{ runId, projectId }: LoadTestExecutionsByRunId,
	) {
		const testExecutionsObs$ = forkJoin([
			this.httpSectionsService.getSections(projectId),
			this.httpTestCaseExecutionsService.getTestExecutionsByRunId(runId),
		]);

		return testExecutionsObs$.pipe(
			tap(([sections, executions]) => {
				loadTestCaseExecutionsHandler(context, [executions, sections]);
			}),
		);
	}
}
