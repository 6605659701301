import { InjectionToken } from '@angular/core';
import { EqaStringHandler } from '@shared/types/handler';
import { iconsPathFactory } from '@shared/domain/utils/miscellaneous/icons-path-factory';

export const EQA_ICONS_PATH: InjectionToken<EqaStringHandler<string>> = new InjectionToken<EqaStringHandler<string>>(
	'A handler to retrieve USE id for icon by name',
	{
		factory: () => iconsPathFactory('assets/icons/svg'),
	},
);
