import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { pluck, tap } from 'rxjs/operators';
import { ApiToken } from '@features/user-settings/interfaces/api.token';
import { ApiTokensService } from '@features/network/services/api-tokens.service';
import { CreateToken, GetTokens, RemoveToken } from './tokens.actions';

export interface TokensStateModel {
	tokens: Array<ApiToken>;
	loading: boolean;
}

@State<TokensStateModel>({
	name: 'tokensState',
	defaults: {
		tokens: [],
		loading: false,
	},
})
@Injectable()
export class TokensState {
	constructor(private apiTokensService: ApiTokensService) {}

	@Selector()
	static tokensList(m: TokensStateModel): Array<ApiToken> {
		return m.tokens;
	}

	@Selector()
	static loading(m: TokensStateModel): boolean {
		return m.loading;
	}

	@Action(GetTokens)
	getTokens({ patchState }: StateContext<TokensStateModel>) {
		patchState({ loading: true });
		return this.apiTokensService.getTokens().pipe(
			pluck('data'),
			tap((tokens: Array<ApiToken>) => {
				patchState({
					tokens: tokens.map((c) => new ApiToken(c)),
					loading: false,
				});
			}),
		);
	}

	@Action(CreateToken)
	createToken({ getState, patchState }: StateContext<TokensStateModel>, { token }: CreateToken) {
		patchState({ loading: true });
		return this.apiTokensService.createToken(token).pipe(
			pluck('data'),
			tap((createdToken: ApiToken) => {
				const tokens = _.cloneDeep(getState().tokens);
				tokens.push(createdToken);
				patchState({
					tokens: tokens.map((c) => new ApiToken(c)),
					loading: false,
				});
			}),
		);
	}

	@Action(RemoveToken)
	removeToken({ getState, patchState }: StateContext<TokensStateModel>, { tokenId }: RemoveToken) {
		return this.apiTokensService.deleteToken(tokenId).pipe(
			tap(() => {
				const tokens = _.cloneDeep(getState().tokens);
				const index = tokens.findIndex((f) => f.id === tokenId);
				tokens.splice(index, 1);
				patchState({
					tokens: tokens.map((c) => new ApiToken(c)),
					loading: false,
				});
			}),
		);
	}
}
