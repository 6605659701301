<div class="firstview-container">
	<div class="firstview-container__img-wrapper">
		<img
			src='../../../../../../assets/icons/first-view/expired.svg'
			class="firstview-container__img-wrapper__svg"
			alt="sprints"
		/>
	</div>
	<div class="firstview-container__form">
		<h2 class="firstview-container__form__title">{{ 'txt_expired_title' | translate }}</h2>
		<p class="firstview-container__form__text">
			Your subscription expired. Please, renew your subscription or contact us.
		</p>
		<div class="row">
			<div class="col-md-12 pt-10">
				<div class="d-flex justify-content-end">
					<button eqaButton appearance="primary" (click)="renew()">
						{{ 'txt_renew' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
