import { Injectable } from "@angular/core";
import { CommentsService } from "@features/comments/domain/services/comments.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import * as _ from "lodash";
import { tap } from "rxjs/operators";
import { COMMENT_ENTITY_TYPE_URL_MAPPINGS } from "../constants/entity-type-mappings";
import { Comment } from "../models/comment";
import { CommentsStateModel } from "./comments-state.model";
import { AddComment, GetComments, RemoveComment, UpdateComment } from "./comments.actions";

@State<CommentsStateModel>({
	name: 'commentsState',
	defaults: {
		comments: [],
	},
})
@Injectable()
export class CommentsState {
	constructor(private commentsApiService: CommentsService) {}

	@Selector()
	static comments(m: CommentsStateModel): Comment[] {
		return m.comments;
	}

	@Action(GetComments)
	getComments({ patchState }: StateContext<CommentsStateModel>, { entityId, entityType }: GetComments) {
		return this.commentsApiService.get(entityId, COMMENT_ENTITY_TYPE_URL_MAPPINGS[entityType]).pipe(
			tap((comments: any[]) => {
				patchState({
					comments,
				});
			}),
		);
	}

	@Action(AddComment)
	addComments({ getState, patchState }: StateContext<CommentsStateModel>, { comment }: AddComment) {
		return this.commentsApiService.addComment(comment).pipe(
			tap((newComment: Comment) => {
				const comments = _.cloneDeep(getState().comments);
				comments.push(newComment);
				patchState({
					comments,
				});
			}),
		);
	}

	@Action(RemoveComment)
	removeComment({ getState, patchState }: StateContext<CommentsStateModel>, { commentId }: RemoveComment) {
		return this.commentsApiService.delete(commentId).pipe(
			tap(() => {
				let comments = _.cloneDeep(getState().comments);
				comments = comments.filter((comment) => comment.id !== commentId);
				patchState({
					comments,
				});
			}),
		);
	}

	@Action(UpdateComment)
	updateComment({ getState, patchState }: StateContext<CommentsStateModel>, { comment }: UpdateComment) {
		return this.commentsApiService.update(comment).pipe(
			tap((res: Comment) => {
				let comments = _.cloneDeep(getState().comments);
				let commentIndex = comments.findIndex((c) => c.id === res.id);
				comments[commentIndex] = res;
				patchState({
					comments,
				});
			}),
		);
	}
}
