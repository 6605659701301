import { HttpClient } from "@angular/common/http";
import { ChatIntegrationProviders } from "@features/integrations/domain/constants";
import { ForeignProjectInfo, MapProjectDto } from "@features/integrations/domain/types";
import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";
import { CommonIntegrationService } from "../common-integration.service";

export class ChatIntegrationService extends CommonIntegrationService<any> {
	constructor(protected readonly provider: ChatIntegrationProviders, protected readonly http: HttpClient) {
		super(provider, http);
	}

	sendTrialMessage(projectId: string) {
		return this.http.post(`${this.providerUrl}/${projectId}/trial-message`, null);
	}

	mapProject(projectId: string, dto: MapProjectDto): Observable<ForeignProjectInfo> {
		return this.http.post(`${this.providerUrl}/chats/projects/${projectId}`, dto).pipe(pluck('data', 'data'));
	}
}
