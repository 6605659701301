import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Inject,
	Input,
} from '@angular/core';
import { CSS, USER_AGENT } from '@ng-web-apis/common';
import { EQA_SCROLL_REF } from '@shared/tokens/scroll-ref';
import { EQA_SCROLL_INTO_VIEW, EQA_SCROLLABLE } from '@shared/domain/constants/events';
import { getElementOffset } from '@shared/domain/utils';

export function isFirefox(userAgent: string): boolean {
	return userAgent.toLowerCase().includes('firefox');
}

export function scrollRefFactory({ browserScrollRef }: EqaScrollbarComponent): ElementRef<HTMLElement> {
	return browserScrollRef;
}

@Component({
	selector: 'eqa-scrollbar',
	templateUrl: './scrollbar.template.html',
	styleUrls: ['./scrollbar.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: EQA_SCROLL_REF,
			deps: [EqaScrollbarComponent],
			useFactory: scrollRefFactory,
		},
	],
})
export class EqaScrollbarComponent {
	private delegated = false;

	private readonly isLegacy: boolean =
		!this.cssRef.supports('position', 'sticky') ||
		(isFirefox(this.userAgent) && !this.cssRef.supports('scrollbar-width', 'none'));

	@Input()
	hidden = false;

	readonly browserScrollRef = new ElementRef(this.elementRef.nativeElement);

	constructor(
		@Inject(CSS) private readonly cssRef: any,
		@Inject(ElementRef) private readonly elementRef: ElementRef<HTMLElement>,
		@Inject(USER_AGENT) private readonly userAgent: string,
	) {}

	get showScrollbars(): boolean {
		return !this.hidden && (!this.isLegacy || this.delegated);
	}

	@HostBinding('class._legacy')
	get showNative(): boolean {
		return this.isLegacy && !this.hidden && !this.delegated;
	}

	@HostListener(`${EQA_SCROLLABLE}.stop`, ['$event.detail'])
	onScrollable(element: HTMLElement) {
		this.delegated = true;
		this.browserScrollRef.nativeElement = element;
	}

	@HostListener(`${EQA_SCROLL_INTO_VIEW}.stop`, ['$event.detail'])
	scrollIntoView(detail: HTMLElement) {
		if (this.delegated) {
			return;
		}

		const { nativeElement } = this.browserScrollRef;
		const { offsetTop, offsetLeft } = getElementOffset(nativeElement, detail);

		nativeElement.scrollTop = offsetTop + detail.offsetHeight / 2 - nativeElement.clientHeight / 2;
		nativeElement.scrollLeft = offsetLeft + detail.offsetWidth / 2 - nativeElement.clientWidth / 2;
	}
}
