import { NgModule } from '@angular/core';
import { EqaPreviewDialogComponent } from './preview-dialog.component';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [PolymorpheusModule, CommonModule],
	declarations: [EqaPreviewDialogComponent],
	exports: [EqaPreviewDialogComponent],
})
export class EqaPreviewDialogModule {}
