import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { ApiToken } from "@features/user-settings/interfaces/api.token";

@Injectable({ providedIn: 'root' })
export class ApiTokensService {
	private baseUrl = ServerUrl.HOME_URL;
	private tokensUrl = this.baseUrl + '/network/tokens';

	constructor(private http: HttpClient) {}

	getTokens() {
		return this.http.get(this.tokensUrl);
	}

	createToken(token: ApiToken) {
		return this.http.post(this.tokensUrl, token);
	}

	deleteToken(tokenId: string) {
		return this.http.delete<ApiToken[]>(this.tokensUrl + `/${tokenId}`);
	}
}
