import { Injectable, NgZone } from "@angular/core";
import { RequirementStatus } from "@features/requirements/domain/interfaces/requirement/requirement.status";
import { RequirementApiService } from "@features/requirements/domain/services/requirement.api.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { cloneDeep } from "lodash";
import { pluck, tap } from "rxjs/operators";
import { FilterMatrixByGroup, GetMatrix } from "./matrix.actions";
import { MatrixStateModel } from "./matrix.state.model";

@State<MatrixStateModel>({
	name: 'matrixState',
	defaults: {
		initialMatrix: null,
		matrix: null,
		statusStats: null,
		coverageStats: null,
		loading: true,
		matrixReloading: false,
	},
})
@Injectable()
export class MatrixState {
	constructor(private requirementApiService: RequirementApiService, private zone: NgZone) {}

	@Selector()
	static matrix(m: MatrixStateModel) {
		return m.matrix;
	}

	@Selector()
	static requirements(m: MatrixStateModel) {
		return m.matrix.requirements;
	}

	@Selector()
	static loading(m: MatrixStateModel) {
		return m.loading;
	}

	@Selector()
	static matrixRedrawInProgress(m: MatrixStateModel) {
		return m.matrixReloading;
	}

	@Selector()
	static stats(m: MatrixStateModel) {
		return { status: m.statusStats, coverageStats: m.coverageStats };
	}

	@Action(GetMatrix)
	loadMatrix({ getState, setState, patchState }: StateContext<MatrixStateModel>, { projectId }: GetMatrix) {
		patchState({ loading: true });
		return this.requirementApiService.getTraceabilityMatrix(projectId).pipe(
			pluck('data'),
			tap((matrix: any) => {
				const _matrix = cloneDeep(matrix);
				patchState({
					matrix: {
						requirements: _matrix.requirements,
						cases: _matrix.cases,
						normalisedMatrix: this.normalizeMatrix(_matrix),
						matrix: _matrix.matrix,
					},
					initialMatrix: {
						requirements: _matrix.requirements,
						cases: _matrix.cases,
						normalisedMatrix: this.normalizeMatrix(_matrix),
						matrix: _matrix.matrix,
					},
					statusStats: this.calculateStatistic(_matrix.requirements),
					coverageStats: this.calculateCovered(_matrix),
					loading: false,
				});
			}),
		);
	}

	@Action(FilterMatrixByGroup)
	filterMatrixByGroup(
		{ getState, setState, patchState }: StateContext<MatrixStateModel>,
		{ groups }: FilterMatrixByGroup,
	) {
		this.zone.runOutsideAngular(() => {
			patchState({
				matrixReloading: true,
			});
			let _matrix = cloneDeep(getState().initialMatrix);
			if (groups.length) {
				_matrix.requirements = _matrix.requirements.filter((r) => groups.findIndex((x) => x.id === r.groupId) >= 0);

				patchState({
					matrix: {
						requirements: _matrix.requirements,
						cases: _matrix.cases,
						normalisedMatrix: this.normalizeMatrix(_matrix),
						matrix: _matrix.matrix,
					},
					statusStats: this.calculateStatistic(_matrix.requirements),
					coverageStats: this.calculateCovered(_matrix),
					matrixReloading: false,
				});
			} else {
				patchState({
					matrix: {
						requirements: _matrix.requirements,
						cases: _matrix.cases,
						normalisedMatrix: this.normalizeMatrix(_matrix),
						matrix: _matrix.matrix,
					},
					statusStats: this.calculateStatistic(_matrix.requirements),
					coverageStats: this.calculateCovered(_matrix),
					matrixReloading: false,
				});
			}
		});
	}

	normalizeMatrix(matrix) {
		let indexedMatrix = '';
		const mtrx = matrix.matrix;
		const cases = matrix.cases;
		const reqs = matrix.requirements;

		mtrx.forEach((m) => {
			indexedMatrix += `${m.requirementId}:${m.caseId}`;
			indexedMatrix += ';';
		});
		const caseObject = {};
		cases.forEach((c) => {
			caseObject[c.id] = [];
			reqs.forEach((r) => {
				const key = `${r.id}:${c.id}`;
				if (indexedMatrix.includes(key)) {
					caseObject[c.id].push({ covered: true, color: this.getStatusColor(r.status) });
				} else {
					caseObject[c.id].push({ covered: true, color: '' });
				}
			});
		});

		return caseObject;
	}

	calculateStatistic(requirements) {
		let total = requirements.length;
		let statistic = requirements.reduce((acc, obj) => {
			const key = obj.status;
			if (!acc[key]) {
				acc[key] = {
					count: 1,
				};
			} else {
				acc[key].count += 1;
			}
			return acc;
		}, {});

		return { stats: this.calculatePercentage(statistic, total), total: total };
	}

	calculatePercentage(statistics, total) {
		for (let stat of Object.keys(statistics)) {
			statistics[stat].percentage = total > 0 ? ((statistics[stat].count / total) * 100).toFixed() : 0;
		}
		return statistics;
	}

	calculateCovered(matrix) {
		let covered = 0;
		const total = matrix.requirements.length;
		matrix.requirements.forEach((r) => {
			const cov = matrix.matrix.filter((m) => m.requirementId === r.id);
			if (cov.length) {
				covered++;
			}
		});
		const coveredPercentage = total > 0 ? ((covered / total) * 100).toFixed(0) : 0;
		const notCovered = total > 0 ? (((total - covered) / total) * 100).toFixed(0) : 0;
		const linkedCasesCount = this.calculateLinkedCases(matrix);
		return { covered: coveredPercentage, notCovered: notCovered, linkedCasesCount };
	}

	calculateLinkedCases(matrix) {
		const casesLinked = matrix.matrix;
		const casesObject = casesLinked.reduce((acc, obj) => {
			const key = obj.caseId;
			if (!acc[key]) {
				acc[key] = {
					count: 1,
				};
			} else {
				acc[key].count += 1;
			}
			return acc;
		}, {});
		return Object.keys(casesObject).length;
	}

	getStatusColor(status) {
		switch (status) {
			case RequirementStatus.APPROVED:
				return '#55BB00';
			case RequirementStatus.COMPLETED:
				return '#05A081';
			case RequirementStatus.PENDING:
				return '#F2C94C';
			case RequirementStatus.VERIFIED:
				return '#A4AAB0';
			case RequirementStatus.DEPLOYED:
				return '#36373B';
			case RequirementStatus.SUBMITTED:
				return '#FF6252';
			default:
				return '#36373B';
		}
	}
}
