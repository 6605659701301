import { Currency } from '@core/constants';
import { PeriodInterval } from '@features/network/components/billing/domain/constants/period-interval';
import { PriceModel } from './price.model';

export class PlanFeatureModel {
	id: string;
	slug: string;
	max: number;
	min: number;
	free: number;
	description: string;
	order: number;
	price: PriceModel;
	prices: PriceModel[];
	nonNativeMin: number;
	amount: number;
	calculatedPrice: number;

	constructor(model: PlanFeatureModel) {
		this.id = String(model.id);
		this.slug = model.slug;
		this.max = model.max;
		this.min = model.min;
		this.free = model.free;
		this.order = model.order;
		this.description = model.description;
		this.prices = model.prices.map((price) => new PriceModel(price));
		this.price = model.price ? model.price : null;
		this.nonNativeMin = model.nonNativeMin ? model.nonNativeMin : model.min;
		this.amount = model.amount ? model.amount : 0;
		this.calculatedPrice = model.calculatedPrice ? model.calculatedPrice : 0;
	}

	increaseAmount() {
		if (this.amount < this.max) {
			this.amount += 1;
		}
	}

	decreaseAmount() {
		if (this.amount > this.min && this.amount > this.nonNativeMin) {
			this.amount -= 1;
		}
	}

	setPriceByCurrencyAndInterval(currency: Currency, interval: PeriodInterval) {
		this.price = this.prices.find((p) => p.currency === currency && p.period.interval === interval);
	}

	calculatePrice() {
		this.calculatedPrice = this.amount * this.price.amount;
	}
}
