import { UserModel } from '@core/interfaces/user/UserModel';

export class GetUserFromStorage {
	static readonly type = '[USER] Get From Storage';

	constructor() {}
}

export class SetUser {
	static readonly type = '[USER] Set';

	constructor(public user: UserModel) {}
}
