import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from './svg.component';
import { LetModule } from '@shared/ui/directives/let';

@NgModule({
	declarations: [SvgComponent],
	imports: [CommonModule, LetModule],
	exports: [SvgComponent],
})
export class SvgModule {}
