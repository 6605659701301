import { NgModule } from '@angular/core';
import { UserAvatarComponent } from '@shared/ui/components/user-avatar/user-avatar.component';
import { StringPipesModule } from '@shared/ui/pipes/string-pipes/string-pipes.module';
import { CommonModule } from '@angular/common';
import { SvgModule } from '@shared/ui/elements/svg/svg.module';

@NgModule({
	imports: [StringPipesModule, CommonModule, SvgModule],
	exports: [UserAvatarComponent],
	declarations: [UserAvatarComponent],
	providers: [],
})
export class UserAvatarModule {}
