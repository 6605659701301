import { NgZone } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, pipe } from 'rxjs';

export function eqaZonefull<T>(ngZone: NgZone): MonoTypeOperatorFunction<T> {
	return (source) =>
		new Observable((subscriber) =>
			source.subscribe({
				next: (value) => ngZone.run(() => subscriber.next(value)),
				error: (error) => ngZone.run(() => subscriber.error(error)),
				complete: () => ngZone.run(() => subscriber.complete()),
			}),
		);
}

export function eqaZonefree<T>(ngZone: NgZone): MonoTypeOperatorFunction<T> {
	return (source) => new Observable((subscriber) => ngZone.runOutsideAngular(() => source.subscribe(subscriber)));
}

export function eqaZoneOptimized<T>(ngZone: NgZone): MonoTypeOperatorFunction<T> {
	return pipe(eqaZonefree(ngZone), eqaZonefull(ngZone));
}
