import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallbackPipe } from '@shared/ui/pipes/other-pipes/callback.pipe';

@NgModule({
  declarations: [CallbackPipe],
  exports: [CallbackPipe],
  imports: [CommonModule],
})
export class OtherPipesModule { }
