import { PlanFeatureModel } from "./plan.feature.model";
import { PlanPeriodModel } from "./plan.period.model";

export class SelectedPlanModel {
	id: string;
	name: string;
	period: PlanPeriodModel;
	periods: PlanPeriodModel[];
	features: PlanFeatureModel[];
	total: number;

	constructor(model: SelectedPlanModel) {
		this.id = model.id;
		this.name = model.name;
		this.period = model.period;
		this.periods = model.periods;
		this.features = model.features;
		this.total = model.total;
	}
}
