import { InjectionToken, ValueProvider } from '@angular/core';
import { EQA_ABSTRACT_HINT_DEFAULT_OPTIONS, EqaAbstractHintOptions } from '@shared/domain/abstracts';

export interface EqaPointerHintOptions extends EqaAbstractHintOptions {
	readonly eqaHintShowDelay: number;
	readonly eqaHintHideDelay: number;
}

/** Default values for pointer hint options */
export const EQA_POINTER_HINT_DEFAULT_OPTIONS: EqaPointerHintOptions = {
	...EQA_ABSTRACT_HINT_DEFAULT_OPTIONS,
	eqaHintShowDelay: 0,
	eqaHintHideDelay: 0,
};

export const EQA_POINTER_HINT_OPTIONS = new InjectionToken<EqaPointerHintOptions>(
	'Default parameters for pointer hint directive',
	{
		factory: () => EQA_POINTER_HINT_DEFAULT_OPTIONS,
	},
);

export const eqaPointerHintOptionsProvider: (options: Partial<EqaPointerHintOptions>) => ValueProvider = (
	options: Partial<EqaPointerHintOptions>,
) => ({
	provide: EQA_POINTER_HINT_OPTIONS,
	useValue: { ...EQA_POINTER_HINT_DEFAULT_OPTIONS, ...options },
});
