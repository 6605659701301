export class FormControlModel<T> {
	value: T;
	key: string;
	label: string;
	required: boolean;
	order: number;
	controlType: string;
	multi?: boolean;
	cssClass?: string;
	options: { id: string; name: string }[];

	constructor(
		options: {
			value?: T;
			key?: string;
			label?: string;
			required?: boolean;
			order?: number;
			multi?: boolean;
			controlType?: string;
			cssClass?: string;
			options?: { id: string; name: string }[];
		} = {},
	) {
		this.value = options.value;
		this.multi = options?.multi;
		this.key = options.key || '';
		this.label = options.label || '';
		this.required = !!options.required;
		this.order = options.order === undefined ? 1 : options.order;
		this.controlType = options.controlType || '';
		this.cssClass = options.cssClass ? options.cssClass : 'custom-input';
		this.options = options.options || [];
	}
}
