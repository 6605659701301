import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ProjectsState } from '@features/projects/+state/project/project.state';
import { ManageCasesState } from '@features/case/case-repository/domain/store/cases/manage.cases.state';
import { TestcaseState } from '@features/case/case-repository/domain/store/cases/testcase.state';
import { RunState } from '@features/run/domain/store/runs.state';
import { AccountState } from '@features/user-settings/store/account.state';
import { ActivityState } from '@features/activity/domain/store/activity.state';
import { SubscriptionState } from '@features/network/+state/subscription/subscrtiption.state';
import { PlanState } from '@features/network/+state/plan/plan.state';
import { BalanceState } from '@features/network/+state/balance/balance.state';
import { PaymentState } from '@features/network/+state/payment/payment.state';
import { LocalisationState } from '@core/store/localisation/localisation.state';
import { SprintsState } from '@features/sprint/domain/store/sprints.state';
import { IntegrationsState } from '@features/integrations/domain/store/integrations.state';
import { TeamState } from '@store/team/team.state';
import { UserState } from '@core/store/user/user.state';
import { AttachmentsState } from '@features/attachments/domain/store/attachments.state';
import { CommentsState } from '@features/comments/domain/store/comments.state';
import { SectionsState } from '@features/case/case-repository/domain/store/sections/sections.state';
import { ExecutionState } from '@features/run/domain/store/execution/exectuion.state';
import { TreeState } from '@store/tree/tree.state';
import { FilterState } from '@store/filter/filter.state';
import { CaseTagsState } from '@features/case/case-tags/domain/store/case-tags.state';
import { StatusState } from '@store/status/status.state';
import { FlatTreeState } from '@store/flat-tree/flat.tree.state';
import { CustomFieldsState } from '@features/projects/+state/settings/custom-fields/custom-fields.state';
import { SharedStepsState } from '@features/projects/+state/settings/shared-steps/shared-steps.state';
import { TokensState } from '@core/store/tokens/tokens.state';
import { RequirementGroupState } from '@features/requirements/domain/store/requirement-group/requirement.group.state';
import { RequirementState } from '@features/requirements/domain/store/requirement/requirement.state';
import { ProjectConfigsState } from '@store/project-configs/project-configs.state';
import { MatrixState } from '@features/requirements/domain/store/matrix/matrix.state';
import { DefectsState } from '@features/defects/domain/store/defects.state';
import { StepperState } from '@shared/ui/components/stepper/domain/store';
import { ReportsState } from '@features/project-reports/domain/store/reports.state';
import { CaseReviewState } from '@features/case/case-review/domain/store/case-review.state';
import { TeamEffortState } from '@features/run/domain/store/team-effort/team-effort.state';
import { EqaLoaderState } from '@shared/domain/store/eqa-loader/eqa-loader.state';
import { CaseFeaturesState } from '@features/case/case-features/domain/store/case-features.state';
import { RunExecutionState } from '@features/executions/run-execution/domain/store/run-execution.state';
import { TestCaseExecutionState } from '@features/executions/testcase-execution/domain/store';
import { environment } from '../../environments/environment';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { SidenavState } from '@layout/domain/store/sidenav/sidenav.state';
import { TestPlansState } from '@features/test-plans/domain/store/test-plans.state';

@NgModule({
	imports: [
		NgxsModule.forRoot(
			[
				ProjectsState,
				ManageCasesState,
				TestcaseState,
				RunState,
				AccountState,
				ActivityState,
				SubscriptionState,
				PlanState,
				BalanceState,
				PaymentState,
				LocalisationState,
				SprintsState,
				IntegrationsState,
				TeamState,
				UserState,
				AttachmentsState,
				CommentsState,
				SectionsState,
				ExecutionState,
				TreeState,
				FilterState,
				CaseTagsState,
				StatusState,
				FlatTreeState,
				CustomFieldsState,
				SharedStepsState,
				TokensState,
				RequirementGroupState,
				RequirementState,
				ProjectConfigsState,
				MatrixState,
				DefectsState,
				StepperState,
				ReportsState,
				CaseReviewState,
				TeamEffortState,
				EqaLoaderState,
				CaseFeaturesState,
				RunExecutionState,
				TestCaseExecutionState,
				SidenavState,
				TestPlansState,
			],
			{ developmentMode: !environment.production },
		),
		NgxsResetPluginModule.forRoot(),
		NgxsStoragePluginModule.forRoot({
			key: [
				'projectsState.selectedProject',
				'subscriptionState',
				'planState',
				'balanceState',
				'treeState.selectedNodesEntitiesIds',
				'treeState.activeSectionIds',
				'treeState.expandedNodesIds',
				'c.stats',
				'userState.user',
				'flatTreeState.testsCount',
				'testCasesState.selectedCasesIds',
				'testCasesState.showCasesCount',
				'testCasesState.viewMode',
				'requirementsGroupState.activeIds',
				'requirementsGroupState.expandedIds',
				'requirementsGroupState.checkedIds',
				'requirementsGroupState.showRequirementsCount',
			],
		}),
		NgxsRouterPluginModule.forRoot(),
		NgxsLoggerPluginModule.forRoot({ disabled: true }),
		NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production, maxAge: 30 }),
	],
	exports: [],
	declarations: [],
	providers: [],
})
export class StoreModule {}
