import { Attachment } from "@features/attachments/domain/interfaces/attachment";
import { AttachmentEntityTypes } from "@features/attachments/domain/interfaces/attachment-entity.types";

export class PushAttachments {
	static readonly type = '[Attachment] Push Attachments';
	public attachments: Attachment[];

	constructor(...attachments: Attachment[]) {
		this.attachments = attachments;
	}
}

export class SetAttachments {
	static readonly type = '[Attachment] Set Attachments';

	constructor(public attachments: Attachment[]) {}
}

export class SelectAttachments {
	static readonly type = '[Attachment] Select Attachments';

	constructor(public attachments: Attachment[], public selected: boolean) {}
}

export class LoadAttachmentsByNetwork {
	static readonly type = '[Attachment] Load Attachments By Network';

	constructor() {}
}

export class SaveAttachments {
	static readonly type = '[Attachment] Save Attachments';

	constructor(public entityIds: string[], public type: AttachmentEntityTypes) {}
}

export class UploadAttachments {
	static readonly type = '[Attachment] Upload Attachments';

	constructor() {}
}

export class GetAttachments {
	static readonly type = '[Attachment] Get Attachments';

	constructor(public entityId: string) {}
}

export class RemoveAttachment {
	static readonly type = '[Attachment] Remove Attachment';

	constructor(public attachment: Attachment, public index?: number) {}
}

export class RemoveAttachmentsList {
	static readonly type = '[Attachment] Remove Attachments List';

	constructor(public attachments: Attachment[] = null) {}
}

export class SortAttachmentsList {
	static readonly type = '[Attachment] Sort Attachments List';

	constructor(public sort) {}
}

export class TrashAttachment {
	static readonly type = '[Attachment] Trash Attachment';

	constructor(public attachment: Attachment) {}
}

export class RemoveTrashAttachments {
	static readonly type = '[Attachment] Remove Trash';

	constructor() {}
}

export class ClearTrashAttachments {
	static readonly type = '[Attachment] Clear Trash';

	constructor() {}
}

export class ClearSelectedAttachments {
	static readonly type = '[Attachment] Clear Selected Attachments';

	constructor() {}
}

export class ClearAttachmentState {
	static readonly type = '[Clear] Clear Attachments State';

	constructor() {}
}
