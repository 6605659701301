import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverscrollDirective } from './overscroll.directive';

@NgModule({
	declarations: [OverscrollDirective],
	exports: [OverscrollDirective],
	imports: [CommonModule],
})
export class OverscrollModule {}
