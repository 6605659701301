import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigService {
	env = environment?.environmentName ?? 'dev';

	get isDemoVersion() {
		return this.env === 'demo';
	}
}
