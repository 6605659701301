import { SubscriptionState } from '@features/network/+state/subscription/subscrtiption.state';
import { ConfigService } from '../services/config-service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';

@Injectable()
export class SubscriptionGuard implements CanActivate {
	constructor(private router: Router, private store: Store, private configService: ConfigService) {}

	canActivate(): boolean {
		const subscription = this.store.selectSnapshot(SubscriptionState.subscription);
		if (subscription && !subscription.isExpired) {
			return true;
		}
		if (this.configService.isDemoVersion) {
			return true;
		}
		this.router.navigateByUrl('/expired');
		return false;
	}
}
