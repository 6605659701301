import { SelectedFilter } from "@shared/ui/components/filters/domain/interfaces/selected.filter";
import { ReviewStatus } from "../constants/review-status";
import { CreateCaseReviewDto } from "../interfaces/create-case-review.dto";
import { UpdateCaseReviewDto } from "../interfaces/update-case-review.dto";

export class LoadCaseReviews {
	static readonly type = '[Case Reviews] Load Case Reviews';
	constructor(public projectId: string, public status?: ReviewStatus) {}
}

export class LoadCaseReview {
	static readonly type = '[Case Reviews] Load Case Review';
	constructor(public caseReviewId: string) {}
}

export class CreateCaseReview {
	static readonly type = '[Case Reviews] Create Case Review';
	constructor(public caseReview: Partial<CreateCaseReviewDto>) {}
}

export class UpdateCaseReview {
	static readonly type = '[Case Reviews] Update Case Review';
	constructor(public caseReview: Partial<UpdateCaseReviewDto>) {}
}

export class ChangeCaseReviewStatus {
	static readonly type = '[Case Reviews] Change Case Review Status';
	constructor(public caseReviewId: string, public status: ReviewStatus) {}
}

export class RemoveCaseReview {
	static readonly type = '[Case Reviews] Delete Case Review';
	constructor(caseReviewId: string) {}
}

export class SortReviews {
	static readonly type = '[Case Reviews] Sort Reviews List';
	constructor(public sortExpression) {}
}

export class FilterReviews {
	static readonly type = '[Case Reviews] Filter Reviews List';
	constructor(public filters: SelectedFilter) {}
}
