import { fromEvent, Observable } from 'rxjs';
import { EqaEventWith, EqaTypedEventTarget } from '@shared/domain/types';

export function typedFromEvent<E extends keyof WindowEventMap>(
	target: Window,
	event: E,
	options?: AddEventListenerOptions,
): Observable<EqaEventWith<WindowEventMap[E], typeof target>>;

export function typedFromEvent<E extends keyof DocumentEventMap>(
	target: Document,
	event: E,
	options?: AddEventListenerOptions,
): Observable<EqaEventWith<DocumentEventMap[E], typeof target>>;

export function typedFromEvent<T extends Element, E extends keyof HTMLElementEventMap>(
	target: T,
	event: E,
	options?: AddEventListenerOptions,
): Observable<EqaEventWith<HTMLElementEventMap[E], typeof target>>;

export function typedFromEvent<E extends Event, T extends EqaTypedEventTarget<EqaEventWith<E, T>>>(
	target: T,
	event: string,
	options?: AddEventListenerOptions,
): Observable<EqaEventWith<E, T>>;

export function typedFromEvent<E extends Event>(
	target: EqaTypedEventTarget<E>,
	event: string,
	options?: AddEventListenerOptions,
): Observable<E>;

export function typedFromEvent<E extends Event>(
	target: EqaTypedEventTarget<E>,
	event: string,
	options: AddEventListenerOptions = {},
): Observable<E> {
	return fromEvent(target, event, options);
}
