import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EqaLinkActivePipe } from '@shared/ui/pipes/link/link-active.pipe';
import { EqaComputeLinkUrlPipe } from '@shared/ui/pipes/link/compute-link-url.pipe';

@NgModule({
	declarations: [EqaLinkActivePipe, EqaComputeLinkUrlPipe],
	exports: [EqaLinkActivePipe, EqaComputeLinkUrlPipe],
	imports: [CommonModule],
})
export class EqaLinkPipesModule {}
