import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { StepperData, StepperMeta, StepperStateModel } from './stepper-interfaces';
import { PatchData, PatchMeta, SetSelectedStep, SetUrl } from './stepper.actions';

@State<StepperStateModel>({
	name: 'stepperState',
	defaults: {
		meta: {
			url: null,
			currentIndex: null,
			name: null,
		},
		data: {},
	},
})
@Injectable()
export class StepperState {

	@Selector()
	static meta(m: StepperStateModel): StepperMeta {
		return m.meta;
	}

	@Selector()
	static data(m: StepperStateModel): StepperData {
		return m.data;
	}

	static property(property: string) {
		return createSelector([StepperState], (m: StepperStateModel) => m.data[property]);
	}

	@Action(PatchData)
	patch({ patchState, getState }: StateContext<StepperStateModel>, { obj }: PatchData) {
		patchState({
			data: {
				...getState().data,
				...obj,
			},
		});
	}

	@Action(SetSelectedStep)
	selectStep({ patchState, getState }: StateContext<StepperStateModel>, { index }: SetSelectedStep) {
		patchState({
			meta: { ...getState().meta, currentIndex: index },
		});
	}

	@Action(SetUrl)
	setUrl({ patchState, getState }: StateContext<StepperStateModel>, { url }: SetUrl) {
		patchState({
			meta: { ...getState().meta, url },
		});
	}

	@Action(PatchMeta)
	patchMeta({ patchState, getState }: StateContext<StepperStateModel>, { meta }: PatchMeta) {
		patchState({
			meta: { ...getState().meta, ...meta },
		});
	}
}
