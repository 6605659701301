import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'firstLetter',
})
export class FirstLetterPipe implements PipeTransform {
	transform(str: string, inUpperCase = false): string {
		if (!str) {
			return '?';
		}
		for (let letter of str) {
			if (letter.match(/[a-z]/i)) {
				return inUpperCase ? letter.toUpperCase() : letter.toLowerCase();
			}
		}
		return '?';
	}
}
