import { StepperMeta } from './stepper-interfaces';

export class PatchData {
	static type = '[Stepper] Patch Data';

	constructor(public obj: { [key: string]: any }) {}
}

export class PatchMeta {
	static type = '[Stepper] Patch Meta';

	constructor(public meta: Partial<StepperMeta>) {}
}

export class SetSelectedStep {
	static type = '[Stepper] Select Step';

	constructor(public index: number) {}
}

export class SetUrl {
	static type = '[Stepper] Set Url';

	constructor(public url: string) {}
}
