import { NgModule } from '@angular/core';
import { EqaWrapperComponent } from '@shared/ui/components/wrapper/wrapper.component';

@NgModule({
	imports: [],
	exports: [EqaWrapperComponent],
	declarations: [EqaWrapperComponent],
	providers: [],
})
export class EqaWrapperModule {}
