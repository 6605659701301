import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { EqaLinkModule } from '@shared/ui/elements/link';
import { RouterLinkWithHref } from '@angular/router';

@Component({
	selector: 'eqa-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [EqaLinkModule, RouterLinkWithHref],
})
export class EqaLogoComponent {}
