import { Injectable } from "@angular/core";
import { TestPlan } from "@features/test-plans/domain/interfaces";
import { HttpTestPlansService } from "@features/test-plans/domain/services/http-test-plans.service";
import { LoadTestPlans } from "@features/test-plans/domain/store/test-plans.actions";
import { TestPlansStateModel } from "@features/test-plans/domain/store/test-plans.state.model";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import produce from "immer";
import { tap } from "rxjs/operators";

@State<TestPlansStateModel>({
	name: 'testPlansState',
	defaults: {
		selectedTestPlan: null,
		selectedTestPlanId: 0,
		testPlans: [],
		loading: false,
	},
})
@Injectable()
export class TestPlansState {
	constructor(private httpTestPlansService: HttpTestPlansService) {}
	@Selector()
	public static loading(m: TestPlansStateModel): boolean {
		return m.loading;
	}
	@Action(LoadTestPlans)
	public load({ getState, patchState }: StateContext<TestPlansStateModel>, { projectId }: LoadTestPlans) {
		const oldState = getState();
		return this.httpTestPlansService.getList(projectId).pipe(
			tap((testPlansList: TestPlan[]) => {
				const newState = produce(oldState, (draft: TestPlansStateModel) => {
					draft.loading = false;
					draft.testPlans = testPlansList;
				});
				patchState(newState);
			}),
		);
	}
}
