import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

export abstract class AbstractHttpService {
	protected constructor(private notificationService: ToastrService) {}

	public handleError(error: HttpErrorResponse) {
		this.notificationService.error(error.message, 'An error happened');
	}

	public handleSuccess(title: string, message: string) {
		this.notificationService.success(message, title);
	}
}
