export enum FilterActionsEnum {
	FULFILL_FILTERS = '[Filters] Fulfill filters',
	SAVE_FILTERS_FORM_STATE = '[Filters] Save filters form state',
	SET_FILTERING_IN_PROGRESS = '[Filters] Filter in progress',
	SELECT_FILTER = '[Filters] Select filter',
	SET_FILTER = '[Filters] Set Filter',
	SET_SEARCH = '[Filters] Set Search Phrase',
	SET_EXPANDED = '[Filters] Set Expanded',
	SET_FILTERS_CONTROLS = '[Filters] Set Filters Controls',
	REMOVE_FILTER = '[Filters] Remove Filter',
	CLEAR_FILTERS = '[Filters] Clear Filters',
}
