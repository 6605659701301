import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as _ from 'lodash';
import { pluck, switchMap, tap } from 'rxjs/operators';
import { TestrunModel } from '../../interfaces/testrun.model';
import { AttachmentApiService } from '@features/attachments/domain/services/http/attachment.service';
import { RunsApiService } from '../../services/api/runs-api.service';
import { HttpSectionsService } from '@features/case/case-repository/domain/services/http-sections.service';
import { TestActionsApiService } from '@features/run/domain/services/api/test-execution/test.actions.api.service';
import { TestApiService } from '@features/run/domain/services/api/test-execution/test.api.service';
import { Section } from '@features/case/case-repository/domain/interfaces/section';
import { Test } from '@features/run/domain/interfaces/test/test';
import { TestAction } from '@features/run/domain/interfaces/test/test.action';
import {
	AddTestResult,
	AddTests,
	AssignTests,
	GetActiveTestActions,
	GetTest,
	GetTests,
	SetActiveTest,
	SetExecutionStatistics,
	SetLoadingStatus,
	SetSelectedTests,
	SetTests,
} from './execution.actions';
import { ExecutionStateModel } from './execution.state.model';
import {PieStats} from "@shared/ui/components/charts/interfaces/pie.stats";

@State<ExecutionStateModel>({
	name: 'c',
	defaults: {
		testTree: [],
		tests: [],
		actionList: [],
		activeTest: null,
		activeSection: null,
		currentTestRun: null,
		isBulkOperationsAllowed: false,
		loading: false,
		totalTestsSelected: 0,
		selectedTests: [],
		result: [],
		saving: false,
		stats: null,
	},
})
@Injectable()
export class ExecutionState {
	constructor(
		private sectionApiService: HttpSectionsService,
		private testRunApiService: RunsApiService,
		private attachmentApiService: AttachmentApiService,
		private testApiService: TestApiService,
		private testActionsApiService: TestActionsApiService,
	) {}

	@Selector()
	static testTree(executionStateModel: ExecutionStateModel): Section[] {
		return executionStateModel.testTree;
	}

	@Selector()
	static stats(executionStateModel: ExecutionStateModel): PieStats {
		return executionStateModel.stats;
	}

	@Selector()
	static tests(executionStateModel: ExecutionStateModel) {
		return executionStateModel.tests;
	}

	@Selector()
	static rootTests(executionStateModel: ExecutionStateModel) {
		return executionStateModel.tests.filter((t) => !t?.sectionId);
	}

	@Selector()
	static activeTest(executionStateModel: ExecutionStateModel) {
		return executionStateModel.activeTest;
	}

	@Selector()
	static actionList(executionStateModel: ExecutionStateModel) {
		return executionStateModel.actionList;
	}

	@Selector()
	static bulkOperationsEnabled(executionStateModel: ExecutionStateModel): boolean {
		return executionStateModel.isBulkOperationsAllowed;
	}

	@Selector()
	static totalSelected(executionStateModel: ExecutionStateModel): number {
		return executionStateModel.totalTestsSelected;
	}

	@Selector()
	static selectedTests(executionStateModel: ExecutionStateModel) {
		return executionStateModel.selectedTests;
	}

	@Selector()
	static activeItem(executionStateModel: ExecutionStateModel): Section {
		return executionStateModel.activeSection;
	}

	@Selector()
	static currentTestRun(executionStateModel: ExecutionStateModel): TestrunModel {
		return executionStateModel.currentTestRun;
	}

	@Selector()
	static loading(executionStateModel: ExecutionStateModel): boolean {
		return executionStateModel.loading;
	}

	@Action(GetTests)
	getTests({ patchState }: StateContext<ExecutionStateModel>, { runId }: GetTests) {
		return this.testApiService.getMappedTests(runId).pipe(
			pluck('data'),
			tap((tests: Array<Test>) => {
				patchState({
					tests: tests,
					loading: false,
				});
			}),
		);
	}

	@Action(GetTest)
	getTest({ patchState }: StateContext<ExecutionStateModel>, { id }: GetTest) {
		return this.testApiService.getTestById(id).pipe(
			pluck('data'),
			tap((test: Test) => {
				patchState({
					activeTest: test,
					loading: false,
				});
			}),
		);
	}

	@Action(SetTests)
	setTests({ patchState }: StateContext<ExecutionStateModel>, { tests }: SetTests) {
		patchState({
			tests,
			loading: false,
		});
	}

	@Action(SetActiveTest)
	setActiveTest({ patchState }: StateContext<ExecutionStateModel>, { test }: SetActiveTest) {
		patchState({
			activeTest: test,
		});
	}

	@Action(AddTests)
	addTests({ getState, patchState }: StateContext<ExecutionStateModel>, { runId, caseIds, context }: AddTests) {
		patchState({ saving: true });
		return this.testApiService.createTest(runId, caseIds).pipe(
			tap(() => {
				const patch: Partial<ExecutionStateModel> = { saving: false };
				patch.tests = getState().tests.filter((t) => caseIds.includes(t.testCase.id));
				patchState(patch);
			}),
		);
	}

	@Action(SetExecutionStatistics)
	setExecutionStatistics(
		{ getState, patchState }: StateContext<ExecutionStateModel>,
		{ stats }: SetExecutionStatistics,
	) {
		patchState({
			stats: _.cloneDeep(stats),
		});
	}

	@Action(SetLoadingStatus)
	setLoadingStatus({ patchState }: StateContext<ExecutionStateModel>, { loading }: SetLoadingStatus) {
		patchState({
			loading: loading,
		});
	}

	@Action(AssignTests)
	assignTests(
		{ getState, patchState, dispatch }: StateContext<ExecutionStateModel>,
		{ runId, testIds, assignTo }: AssignTests,
	) {
		return this.testApiService.assignTests(runId, testIds, assignTo.id).pipe(
			tap((res) => {
				patchState({
					totalTestsSelected: 0,
					isBulkOperationsAllowed: false,
				});
			}),
			switchMap(() => dispatch(new SetSelectedTests([]))),
		);
	}

	@Action(AddTestResult)
	addTestResult(
		{ getState, setState }: StateContext<ExecutionStateModel>,
		{ projectId, testIds, result }: AddTestResult,
	) {
		const state = getState();
		const tests = _.cloneDeep(state.tests);
		let testIdsForSave = testIds;
		tests.forEach((t) => {
			const index = testIdsForSave.findIndex((id) => id === t.id);
			if (index > -1) {
				t.status = result.status;
				t.assignedTo = result.assignedTo;
			}
		});
		const resultRequest = {
			projectId,
			testIds,
			status: result.status.id,
			notes: result.notes,
			assignedTo: result.assignedTo,
			attachmentIds: result.attachmentIds,
		};

		return this.testApiService.saveTestsResults(resultRequest).pipe(
			tap((res) => {
				setState(
					patch<ExecutionStateModel>({
						tests,
						totalTestsSelected: 0,
						selectedTests: [],
						isBulkOperationsAllowed: false,
					}),
				);
			}),
		);
	}

	@Action(SetSelectedTests)
	setSelectedTests({ getState, patchState }: StateContext<ExecutionStateModel>, { testCases }: SetSelectedTests) {
		const currentSelectedTests = _.cloneDeep(getState().selectedTests);
		currentSelectedTests.push(...testCases);
		patchState({
			selectedTests: currentSelectedTests,
			totalTestsSelected: currentSelectedTests.length,
			isBulkOperationsAllowed: currentSelectedTests.length > 0,
		});
	}

	@Action(GetActiveTestActions)
	getActions({ getState, patchState }: StateContext<ExecutionStateModel>, { testId }: GetActiveTestActions) {
		return this.testActionsApiService.getActionsByTest(testId).pipe(
			pluck('data'),
			tap((actions: TestAction[]) => {
				patchState({
					actionList: actions,
				});
			}),
		);
	}
}
