import { Component, Input, OnInit } from '@angular/core';
import { AccountService } from '@core/services/http/auth/account.service';
import { Store } from '@ngxs/store';
import { SetLanguage } from '@core/store/localisation/localisation.actions';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'eqa-root',
	templateUrl: './app.component.html',
	styles: [
		`
			:host {
				display: block;
				width: 100%;
				height: 100vh;
			}
		`,
	],
})
export class AppComponent implements OnInit {
	title = 'everyQa';

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private authService: AccountService,
		public store: Store,
	) {
		this.store.dispatch(new SetLanguage('en-Us'));
	}

	ngOnInit() {
		this.authService.refreshDataUser();
	}
}
