import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { CustomFieldType } from "@features/case/case-fields/domain";
import { SharedStep } from "@features/case/case-steps-shared/domain";

@Injectable({ providedIn: 'root' })
export class SharedStepApiService {
	types = CustomFieldType;
	private baseUrl = ServerUrl.HOME_URL;

	constructor(private http: HttpClient) {}

	url(projectId: string) {
		return `${this.baseUrl}/projects/${projectId}/templates/steps`;
	}

	getSharedSteps(projectId) {
		return this.http.get<SharedStep[]>(this.url(projectId) + '?limit=0');
	}

	deleteSharedStep(projectId: string, stepId: string) {
		return this.http.delete<SharedStep[]>(`${this.url(projectId)}/${stepId}`);
	}

	updateSharedStep(projectId: string, stepId: string, step) {
		return this.http.put<SharedStep[]>(`${this.url(projectId)}/${stepId}`, step);
	}

	createSharedStep(projectId: string, step: SharedStep) {
		return this.http.post(this.url(projectId), step);
	}
}
