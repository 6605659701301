import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: 'eqaBillableFeatures',
	styleUrls: ['./billable-features.component.scss'],
	templateUrl: 'billable-features.component.html',
})
export class BillableFeaturesComponent implements OnInit {
	@Output() buttonClick = new EventEmitter();

	constructor(private router: Router) {}

	ngOnInit() {}

	public openBilling() {
		this.buttonClick.emit(true);
	}

	public cancel() {
		this.buttonClick.emit();
	}
}
