import { Injectable } from "@angular/core";
import { ActivityService } from "@features/activity/domain/services/activity.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { fromPairs, groupBy, sortBy, toPairs } from "lodash";
import { tap } from "rxjs/operators";
import { ActivityStateModel } from "./activity-state.model";
import { GetActivity, GetNewActivity, InitActivity, SortActivity } from "./activity.actions";

@State<ActivityStateModel>({
	name: 'activityState',
	defaults: {
		activities: {},
		count: 0,
		loading: false,
	},
})
@Injectable()
export class ActivityState {
	constructor(private activityService: ActivityService) {}

	@Selector()
	static activities(activityStateModel: ActivityStateModel) {
		return activityStateModel.activities;
	}

	@Selector()
	static count(activityStateModel: ActivityStateModel) {
		return activityStateModel.count;
	}

	@Selector()
	static loading(activityStateModel: ActivityStateModel) {
		return activityStateModel.loading;
	}

	@Action(InitActivity)
	initActivity(ctx: StateContext<ActivityStateModel>, { projectId }: InitActivity) {
		ctx.patchState({
			loading: true,
			activities: {},
			count: 0,
		});
		return this.activityService.getActivityByProjectId(projectId, 0).pipe(
			tap((activity) => {
				activity.activities.forEach((a) => {
					//description: "Created --Sprint 2021-05-14 - 2021-05-28-- by flinn@flinn.com"
					const parsed = a.description.split('--');
					a.userEmail = parsed[parsed.length - 1].trim().replace('by','');
					a.description = parsed[1];
				});
				const options = { year: 'numeric', month: 'short', day: 'numeric' } as Intl.DateTimeFormatOptions;
				const grouped = fromPairs(
					sortBy(
						toPairs(groupBy(activity.activities, (a) => new Date(a.createdAt).toLocaleDateString('en-US', options))),
						([date, runs]) => -new Date(date).valueOf(),
					),
				);
				const state = ctx.getState();
				ctx.setState({
					...state,
					activities: grouped,
					count: activity.count,
					loading: false,
				});
			}),
		);
	}

	@Action(GetNewActivity)
	getNewActivity(ctx: StateContext<ActivityStateModel>, { projectId, page }: GetNewActivity) {
		ctx.patchState({
			loading: true,
		});
		return this.activityService.getActivityByProjectId(projectId, page).pipe(
			tap((activity) => {
				const state = ctx.getState();
				ctx.setState({
					...state,
					count: activity.count,
					loading: false,
				});
			}),
		);
	}

	@Action(GetActivity)
	getActivity(ctx: StateContext<ActivityStateModel>) {
		return ctx.getState();
	}

	@Action(SortActivity)
	sortActivity(ctx: StateContext<ActivityStateModel>) {
		const state = ctx.getState();
		ctx.setState({
			...state,
		});
	}
}
