import { Injectable } from '@angular/core';
import { UserService } from '@core/services/http/user/user.service';
import { PricableFeature } from '@features/network/components/billing/domain/constants/pricable.feature';
import { PlanFeatureModel } from '@features/network/components/billing/domain/models/plan.feature.model';
import { PlanModel } from '@features/network/components/billing/domain/models/plan.model';
import { SelectedPlanModel } from '@features/network/components/billing/domain/models/selected.plan.model';
import { SubscriptionModel } from '@features/network/components/billing/domain/models/subscription.model';
import { PlansApiService } from '@features/network/components/billing/domain/services/plans/plans.api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { map, pluck, switchMap, tap } from 'rxjs/operators';
import { ValidateBalance } from '../balance/balance.actions';
import { PlanStateModel } from './plan-state.model';
import {
	ApplyPlan,
	CalculatePlanPrice,
	DecreaseFeatureCount,
	GetPlanCost,
	GetPlans,
	IncreaseFeatureCount,
	SaveSelectedPlan,
	SelectPlan,
	SetPeriod,
	UpgradePlan,
} from './plan.actions';

@State<PlanStateModel>({
	name: 'planState',
	defaults: {
		selectedPlan: null,
		plans: [],
		currency: null,
		period: null,
		savedPlan: null,
		charge: null,
		isCalculating: false,
	},
})
@Injectable()
export class PlanState {
	@Selector()
	static charge(m: PlanStateModel): number {
		return m.charge;
	}

	@Selector()
	static plans(m: PlanStateModel) {
		return m.plans;
	}

	@Selector()
	static selectedPlan(m: PlanStateModel) {
		return m.selectedPlan;
	}

	@Selector()
	static savedPlan(m: PlanStateModel) {
		return m.savedPlan;
	}

	@Selector()
	static selectedCurrency(m: PlanStateModel) {
		return m.currency;
	}

	@Selector()
	static selectedPeriod(m: PlanStateModel) {
		return m.period;
	}

	@Selector()
	static isCalculating(m: PlanStateModel) {
		return m.isCalculating;
	}

	constructor(private plansService: PlansApiService, private usersService: UserService) {}

	@Action(GetPlans)
	getPlans({ getState, patchState }: StateContext<PlanStateModel>, { period, currency }: GetPlans) {
		return this.plansService.getPlans().pipe(
			pluck('data'),
			map((p: any[]) => p.map((pm) => new PlanModel(pm))),
			tap((plans: PlanModel[]) => {
				const paidPlans = plans.filter((p) => !p.isTrial);
				patchState({
					plans: <any[]>paidPlans.map((plan) => plan.normalisePlan(period, currency)),
				});
			}),
		);
	}

	@Action(SelectPlan)
	selectPlan({ getState, patchState }: StateContext<PlanStateModel>, { plan, currency, period }: SelectPlan) {
		return this.usersService.getUsersByNetwork().pipe(
			tap((users: any[]) => {
				const _plan = new PlanModel(plan);
				const usersCount = users.length;
				const feature = _plan.getPurchasableFeatures(PricableFeature.USERS);
				const selectedPeriod = _plan.getPeriodByInterval(period);
				feature.setPriceByCurrencyAndInterval(currency, period);
				feature.calculatePrice();
				feature.min = feature.min < usersCount ? usersCount : feature.min;
				const selectedPlan = new SelectedPlanModel({
					id: plan.id,
					name: _plan.name,
					period: selectedPeriod,
					periods: _plan.periods,
					features: [feature],
					total: feature.calculatedPrice,
				});

				patchState({
					selectedPlan: selectedPlan,
					currency: currency,
					period: selectedPeriod.interval,
				});
			}),
		);
	}

	@Action(IncreaseFeatureCount)
	increaseFeatureCount({ getState, patchState }: StateContext<PlanStateModel>, {}: IncreaseFeatureCount) {
		const state = getState();
		const plan = _.cloneDeep(state.selectedPlan);
		const feature = new PlanFeatureModel(plan.features.shift());
		feature.increaseAmount();
		feature.calculatePrice();
		plan.features.push(feature);
		plan.total = feature.calculatedPrice;
		patchState({
			selectedPlan: plan,
		});
	}

	@Action(CalculatePlanPrice)
	calculatePlanPrice({ getState, patchState }: StateContext<PlanStateModel>, { currency, period, currentValue }: CalculatePlanPrice) {
		const state = getState();
		const _plan = _.cloneDeep(state.selectedPlan);
		const featureForPurchase = new PlanFeatureModel(_plan.features[0]);
		if (currentValue) {
			featureForPurchase.amount = currentValue;
		}
		featureForPurchase.setPriceByCurrencyAndInterval(currency, period);
		featureForPurchase.calculatePrice();
		_plan.features = [featureForPurchase];
		_plan.period = _plan.periods.find((p) => p.interval === period);
		_plan.total = featureForPurchase.calculatedPrice;
		patchState({
			selectedPlan: _plan,
			currency: currency,
			period: period,
		});
	}

	@Action(DecreaseFeatureCount)
	decreaseFeatureCount({ getState, patchState }: StateContext<PlanStateModel>, {}: DecreaseFeatureCount) {
		const state = getState();
		const plan = _.cloneDeep(state.selectedPlan);
		const feature = new PlanFeatureModel(plan.features.shift());
		feature.decreaseAmount();
		feature.calculatePrice();
		plan.features.push(feature);
		plan.total = feature.calculatedPrice;
		patchState({
			selectedPlan: plan,
		});
	}

	@Action(SaveSelectedPlan)
	saveSelectedPlan({ getState, patchState }: StateContext<PlanStateModel>, { savedPlan }: SaveSelectedPlan) {
		patchState({
			savedPlan: savedPlan,
		});
	}

	@Action(ApplyPlan)
	applyFeatures({ getState, patchState }: StateContext<PlanStateModel>, {}: ApplyPlan) {
		const state = getState();
		let _plan = _.cloneDeep(state.selectedPlan);
		const feature = _plan.features[0];
		const applyPlan = {
			period: null,
			features: {},
		};
		applyPlan.features[feature.slug] = feature.amount;
		applyPlan.period = _plan.period;
		return this.plansService.applyPlan(_plan.id, applyPlan);
	}

	@Action(GetPlanCost)
	getCost({ getState, patchState, dispatch }: StateContext<PlanStateModel>, {}: GetPlanCost) {
		patchState({ isCalculating: true });
		const state = getState();
		let _plan = _.cloneDeep(state.selectedPlan);
		const feature = _plan.features[0];
		const applyPlan = {
			period: null,
			features: {},
		};
		applyPlan.features[feature.slug] = feature.amount;
		applyPlan.period = _plan.period;
		return this.plansService.getCost(_plan.id, applyPlan).pipe(
			tap((n: number) => patchState({ charge: n > 0 ? n / 100 : 0, isCalculating: false })),
			switchMap((n: number) => dispatch(new ValidateBalance(n / 100))),
		);
	}

	@Action(UpgradePlan)
	upgradePlan({ getState, patchState }: StateContext<PlanStateModel>, { plan, period, subscription, currency }: UpgradePlan) {
		return this.usersService.getUsersByNetwork().pipe(
			tap((users: any[]) => {
				const usersCount = users.length;
				const _subscription = new SubscriptionModel(subscription);
				const newPlan = new PlanModel(plan);
				const feature = newPlan.getPurchasableFeatures(PricableFeature.USERS);
				feature.amount = _subscription.features[PricableFeature.USERS];
				feature.min = feature.min < usersCount ? usersCount : feature.min;
				feature.setPriceByCurrencyAndInterval(currency, period);
				feature.calculatePrice();
				const selectedPeriod = newPlan.periods.find((p) => p.interval === period);

				const selectedPlan = new SelectedPlanModel({
					id: newPlan.id,
					name: newPlan.name,
					period: selectedPeriod,
					periods: newPlan.periods,
					features: [feature],
					total: feature.calculatedPrice,
				});

				patchState({
					selectedPlan: selectedPlan,
					period: selectedPeriod.interval,
					currency: currency,
				});
			}),
		);
	}

	@Action(SetPeriod)
	setPeriod({ patchState }: StateContext<PlanStateModel>, { period }: SetPeriod) {
		patchState({
			period,
		});
	}
}
