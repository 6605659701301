import { IntegrationProviders } from "@features/integrations/domain/constants";
import { IntegrationUserCredentials } from "@features/integrations/domain/types";
import ACCOUNT_MODELS from "./account-state.model";

export class GetAccountData {
	static readonly type = '[Account API] GetAccountData';
}

export class SaveAccountData {
	static readonly type = '[Jira API] SaveAccountData';

	constructor(public payload: Partial<ACCOUNT_MODELS.UserModel>) {}
}

export class SetAccountLanguage {
	static readonly type = '[Language] SetAccountLanguage';

	constructor(public payload: string) {}
}

export class LoadAvatar {
	static readonly type = '[Profile] Load Avatar';

	constructor(public formData: FormData) {}
}

export class GetUsersIntegrations {
	static readonly type = '[Profile] Load Integrations';
}

export class SetUserIntegration {
	static readonly type = '[Profile] Set Integration';

	constructor(public provider: IntegrationProviders, public data: IntegrationUserCredentials) {}
}

export class DeleteUserIntegration {
	static readonly type = '[Profile] Delete Integration';

	constructor(public provider: IntegrationProviders) {}
}
