import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, NgZone, Optional } from '@angular/core';
import { ANIMATION_FRAME } from '@ng-web-apis/common';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, throttleTime } from 'rxjs/operators';
import { eqaZoneOptimized } from '@shared/domain/observables/zone-free';
import { EQA_SCROLL_REF } from '@shared/tokens/scroll-ref';

// @dynamic
@Component({
	selector: 'eqa-scroll-controls',
	templateUrl: './scroll-controls.template.html',
	styleUrls: ['./scroll-controls.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [],
})
export class EqaScrollControlsComponent {
	readonly refresh$ = this.animationFrame$.pipe(
		throttleTime(300),
		map(() => this.scrollbars),
		startWith([false, false]),
		distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1]),
		eqaZoneOptimized(this.ngZone),
	);

	constructor(
		@Inject(NgZone) private readonly ngZone: NgZone,
		@Inject(DOCUMENT) private readonly documentRef: Document,
		@Optional()
		@Inject(EQA_SCROLL_REF)
		private readonly scrollRef: ElementRef<HTMLElement> | null,
		@Inject(ANIMATION_FRAME) private readonly animationFrame$: Observable<number>,
	) {}

	private get scrollbars(): [boolean, boolean] {
		const { clientHeight, scrollHeight, clientWidth, scrollWidth } = this.scrollRef
			? this.scrollRef.nativeElement
			: this.documentRef.documentElement;

		return [
			Math.ceil((clientHeight / scrollHeight) * 100) < 100,
			Math.ceil((clientWidth / scrollWidth) * 100) < 100,
		];
	}
}
