const defaults = {
	currency: {
		code: 'USD',
	},
	locale: {
		code: 'en-Us',
		shortCode: 'en',
		languageName: 'English',
	},
};

export default defaults;
