export enum FlatTreeActionsEnum {
	BUILD_TREE = '[Flat Tree] Build Flat Tree ',
	SET_RESULT = '[Flat Tree] Set Test Result',
	ACTIVATE_SECTION = '[Flat Tree] Activate Section',
	ACTIVATE_TEST = '[Flat Tree] Activate Test',
	SET_ACTIVE_IDS = '[Flat Tree] Set Active Ids',
	TOGGLE = '[Flat Tree] Toggle',
	SELECT = '[Flat Tree] Select Node',
	SELECT_CONTENT = '[Flat Tree] Select Section Content Entity',
	LOAD_NEXT = '[Flat Tree] Load Next',
	LOAD_PREVIOUS = '[Flat Tree] Load Previous',
	SET_STEP_STATUS = '[Flat Tree] Set Step Status',
	SET_LOADING_STATUS = '[Flat Tree] Set Loading Status',
	ASSIGN_TEST = '[Flat Tree] Assign Test',
	ASSIGN_TESTS = '[Flat Tree] Assign Tests',
	SET_TESTS_COUNT = '[Flat Tree] Set Tests Count',
	SET_SHOWED = '[Flat Tree] Set Showed',
	SET_FILTERS = '[Flat Tree] Set Filters',
	REMOVE_FILTER = '[Flat Tree] Remove Filter',
	SEARCH_UPDATED = '[Flat Tree] Update Search',
	UNCHECK_ALL = '[Flat Tree] Uncheck All',
}
