import { RunExecutionStatistics, RunExecutionStatItem } from "@features/executions/run-execution/domain/interfaces";
import { percents } from "@shared/domain/utils/math";

export function calculateStatistic(stats: Array<Partial<RunExecutionStatItem>>, testsCount): RunExecutionStatistics {
	return stats.reduce(
		(acc, curr) => {
			if (!acc.stats) {
				acc.stats = {};
			}
			acc.stats[curr.status.name] = {
				...curr,
				percentage: percents(curr.count, acc.total),
			};
			return acc;
		},
		{
			stats: null,
			total: testsCount,
		},
	);
}
