import { Injectable } from "@angular/core";
import { UserModel } from "@core/interfaces/user/UserModel";
import { UserService } from "@core/services/http/user/user.service";
import { ProjectModel } from "@features/projects/interfaces";
import { CommonStats } from "@features/projects/interfaces/common.stats";
import { TestStatistic } from "@features/run/domain/interfaces/test/test.statistic";
import { TestApiService } from "@features/run/domain/services/api/test-execution/test.api.service";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { patch, updateItem } from "@ngxs/store/operators";
import { eachDayOfInterval } from "date-fns";
import { cloneDeep, range, sortBy } from "lodash";
import { Observable } from "rxjs";
import { mergeMap, pluck, tap } from "rxjs/operators";
import { StatusState } from "@store/status/status.state";
import { ProjectApiService } from "@features/projects/services/projects-api.service";
import {
	AddProject,
	ClearProject,
	DeleteProject,
	GetAllProjects,
	GetProjectAndSetItAsSelected,
	GetProjects,
	GetProjectUsers,
	LoadProject,
	LoadProjectsStatistics,
	LoadProjectStatistics,
	SaveProjectsUsers,
	SetProject,
	SetProjects,
	ToggleProjectsIsFavorite,
	UpdateProject,
	UpdateProjectSettings
} from "./project.actions";

export class ProjectsStateModel {
	projects: ProjectModel[];
	networkProjects: ProjectModel[];
	selectedProject: ProjectModel;
	currentProjectStatistics: any; // пока не описал
	projectsRange: ProjectModel[];
	projectsCount: number;
	hasProjects: boolean;
	loading: boolean;
	stats: CommonStats;
}

@State<ProjectsStateModel>({
	name: 'projectsState',
	defaults: {
		projects: [],
		networkProjects: [],
		selectedProject: null,
		projectsRange: [],
		projectsCount: null,
		hasProjects: false,
		loading: false,
		currentProjectStatistics: [],
		stats: {
			testRuns: 0,
			testCases: 0,
			sprints: 0,
			tests: 0,
		},
	},
})
@Injectable()
export class ProjectsState {
	@Selector()
	static projects(projectsStateModel: ProjectsStateModel): ProjectModel[] {
		return projectsStateModel.projects;
	}

	@Selector()
	static currentProjectStatistics(projectsStateModel: ProjectsStateModel): TestStatistic[] {
		return projectsStateModel.currentProjectStatistics;
	}

	@Selector()
	static networkProjects(projectsStateModel: ProjectsStateModel): ProjectModel[] {
		return projectsStateModel.networkProjects;
	}

	@Selector()
	static stats(projectsStateModel: ProjectsStateModel): CommonStats {
		return projectsStateModel.stats;
	}

	@Selector()
	static selectedProject(projectsStateModel: ProjectsStateModel): ProjectModel {
		return projectsStateModel.selectedProject;
	}

	@Selector()
	static selectedProjectId(projectsStateModel: ProjectsStateModel): string {
		return projectsStateModel.selectedProject?.id;
	}

	@Selector()
	static projectUsers(projectsStateModel: ProjectsStateModel): UserModel[] {
		return projectsStateModel.selectedProject.projectUsers;
	}

	@Selector()
	static loading(projectsStateModel: ProjectsStateModel): boolean {
		return projectsStateModel.loading;
	}

	@Selector()
	static hasProjects(projectsStateModel: ProjectsStateModel): boolean {
		return projectsStateModel.hasProjects;
	}

	@Selector()
	static count(projectsStateModel: ProjectsStateModel): number {
		return projectsStateModel.projectsCount;
	}

	constructor(
		private testCaseExecutionService: TestApiService,
		private projectApiService: ProjectApiService,
		private store: Store,
		private userService: UserService,
	) {}

	@Action(LoadProject)
	loadProject({ patchState }: StateContext<ProjectsStateModel>, { projectId }: LoadProject) {
		patchState({
			loading: true,
		});
		return this.projectApiService.fetchProject(projectId).pipe(
			tap((project: ProjectModel) => {
				patchState({
					loading: false,
					selectedProject: project,
				});
			}),
		);
	}

	@Action(GetProjects)
	getProjects({ patchState }: StateContext<ProjectsStateModel>) {
		patchState({
			loading: true,
		});
		return this.projectApiService.getProjectsByUser().pipe(
			tap((projects: ProjectModel[]) => {
				patchState({
					projects,
					projectsCount: projects.length,
					hasProjects: projects.length > 0,
					stats: this.calculateStats(projects),
					loading: false,
				});
			}),
		);
	}

	@Action(GetAllProjects)
	getAllProjects({ patchState }: StateContext<ProjectsStateModel>) {
		patchState({
			loading: true,
		});
		return this.projectApiService.fetchProjectByNetwork().pipe(
			tap((networkProjects: ProjectModel[]) => {
				patchState({
					networkProjects,
					projectsCount: networkProjects.length,
					hasProjects: networkProjects.length > 0,
					stats: this.calculateStats(networkProjects),
					loading: false,
				});
			}),
		);
	}

	@Action(SetProjects)
	setProjects({ getState, setState }: StateContext<ProjectsStateModel>, { payload }: SetProjects) {
		const state = getState();
		setState({
			...state,
			projects: payload,
		});
	}

	@Action(SetProject)
	setProject({ getState, patchState }: StateContext<ProjectsStateModel>, { payload }: SetProject) {
		patchState({
			selectedProject: payload,
		});
	}

	@Action(ClearProject)
	clearProject({ getState, patchState }: StateContext<ProjectsStateModel>, {}: ClearProject) {
		patchState({
			selectedProject: null,
		});
	}

	@Action(AddProject, { cancelUncompleted: true })
	addProject({ getState, setState }: StateContext<ProjectsStateModel>, { payload }: AddProject) {
		return this.projectApiService.addProject(payload).pipe(
			tap((createdProjectResponse) => {
				const state = getState();
				const projects = cloneDeep(state.projects);
				createdProjectResponse.runsCount = '0';
				createdProjectResponse.sprintsCount = '0';
				createdProjectResponse.casesCount = '0';
				createdProjectResponse.testsCount = '0';
				createdProjectResponse.statistic = this.createEmptyStatistic();
				createdProjectResponse['users'] = [createdProjectResponse['creator']];

				const index = projects.findIndex((item) => item.isFavorite === false);
				projects.splice(index, 0, createdProjectResponse);

				setState(
					patch<ProjectsStateModel>({
						projects: projects,
						projectsCount: projects.length,
						stats: this.calculateStats(projects),
						hasProjects: true,
					}),
				);
			}),
		);
	}

	@Action(UpdateProject, { cancelUncompleted: true })
	updateProject({ getState, setState, dispatch }: StateContext<ProjectsStateModel>, { payload, id }: UpdateProject) {
		return dispatch(new GetProjects()).pipe(
			mergeMap(() => this.projectApiService.updateProject(id, payload)),
			tap((updatedProjectResponse) => {
				const state = getState();
				const newState = cloneDeep(state);
				const projectList = newState.projects;
				const projectIndex = projectList.findIndex((item) => item.id === id);
				const project = projectList[projectIndex];
				project.name = updatedProjectResponse.name;
				project.type = updatedProjectResponse.type;
				project.sprintType = updatedProjectResponse.sprintType;

				setState(
					patch<ProjectsStateModel>({
						projects: projectList,
						selectedProject: project,
					}),
				);
			}),
		);
	}

	@Action(UpdateProjectSettings, { cancelUncompleted: true })
	updateProjectSettings(
		{ getState, setState, dispatch }: StateContext<ProjectsStateModel>,
		{ payload, id }: UpdateProjectSettings,
	) {
		return this.projectApiService.updateProjectSettings(id, payload).pipe(
			pluck('data'),
			tap((updatedProjectResponse) => {
				setState(
					patch<ProjectsStateModel>({
						projects: updateItem((item) => item.id === updatedProjectResponse.id, updatedProjectResponse),
						selectedProject: updatedProjectResponse,
					}),
				);
			}),
		);
	}

	@Action(DeleteProject, { cancelUncompleted: true })
	deleteProject({ getState, setState }: StateContext<ProjectsStateModel>, { id }: DeleteProject) {
		return this.projectApiService.deleteProject(id).pipe(
			tap(() => {
				const state = getState();
				const projects = state.projects.filter((item) => item.id !== id);
				setState({
					...state,
					projects,
					projectsCount: projects.length,
					hasProjects: projects.length > 0,
					stats: this.calculateStats(projects),
				});
			}),
		);
	}

	@Action(GetProjectAndSetItAsSelected)
	setSelectedProject({ patchState }: StateContext<ProjectsStateModel>, { id }: GetProjectAndSetItAsSelected) {
		return this.projectApiService.fetchProject(id).pipe(
			tap((selectedProject) => {
				patchState({
					selectedProject,
				});
			}),
		);
	}

	@Action(GetProjectUsers)
	getProjectsUsers({ getState, patchState }: StateContext<ProjectsStateModel>, { projectId }: GetProjectUsers) {
		const selectedProject = cloneDeep(getState().selectedProject);
		const pId = projectId ? projectId : selectedProject.id;
		return this.userService.fetchUsersByProject(pId).pipe(
			tap((projectUsers) => {
				selectedProject.projectUsers = projectUsers;
				patchState({
					selectedProject,
				});
			}),
		);
	}

	@Action(SaveProjectsUsers)
	saveProjectsUsers({ getState, patchState }: StateContext<ProjectsStateModel>, { userIds }: SaveProjectsUsers) {
		const selectedProject = cloneDeep(getState().selectedProject);
		return this.projectApiService.saveProjectUsers(selectedProject.id, userIds).pipe(
			tap(({ projectUsers }: ProjectModel) => {
				selectedProject.projectUsers = projectUsers;
				patchState({
					selectedProject,
				});
			}),
		);
	}

	@Action(ToggleProjectsIsFavorite)
	toggleProjectsIsFavorites(
		{ getState, patchState }: StateContext<ProjectsStateModel>,
		{ id }: ToggleProjectsIsFavorite,
	) {
		const state = cloneDeep(getState());
		const projects = state.projects;
		const project = projects.find((item) => item.id === id);
		let obs$: Observable<void>;

		if (project.isFavorite) {
			obs$ = this.projectApiService.deleteProjectFromFavorites(id);
			project.isFavorite = false;
		} else {
			obs$ = this.projectApiService.addProjectToFavorites(id);
			project.isFavorite = true;
		}
		return obs$.pipe(
			tap(() => {
				patchState({
					projects: [
						...sortBy(
							projects.filter((p) => p.isFavorite),
							'createdAt',
						).reverse(),
						...sortBy(
							projects.filter((p) => !p.isFavorite),
							'createdAt',
						).reverse(),
					],
				});
			}),
		);
	}

	@Action(LoadProjectsStatistics)
	setProjectsStatistic({ getState, patchState }: StateContext<ProjectsStateModel>, {}: LoadProjectsStatistics) {
		const state = cloneDeep(getState());
		const projects = state.projects;

		return this.projectApiService.getProjectsStatistic().pipe(
			pluck('data'),
			tap((data: { projectId: string; label: string; allTestsCount: number; badTestsCount: number }[]) => {
				for (const project of projects) {
					project.statistic = [];
					const projectStat = data.filter((stat) => stat.projectId === project.id);
					for (const s of projectStat) {
						project.statistic.push({
							day: new Date(s.label),
							allTestsCount: s.allTestsCount,
							badTestsCount: s.badTestsCount,
							badTestsPercentage: s.allTestsCount ? s.badTestsCount / s.allTestsCount : 0,
						});
					}
				}

				patchState({ projects });
			}),
		);
	}

	@Action(LoadProjectStatistics)
	loadProjectStatistics({ getState, patchState }: StateContext<ProjectsStateModel>, {}: LoadProjectStatistics) {
		const state = cloneDeep(getState());
		return this.testCaseExecutionService.getTestStatisticsByProjectId(state.selectedProject.id).pipe(
			pluck('data'),
			tap((stats: TestStatistic[]) => {
				const statistics = stats.length > 0 ? stats : this.createInitialProjectStatistic();
				patchState({
					currentProjectStatistics: statistics,
				});
			}),
		);
	}

	calculateStats(projects: ProjectModel[]): CommonStats {
		let stats = {
			testRuns: 0,
			testCases: 0,
			sprints: 0,
			tests: 0,
		};
		projects.forEach((project) => {
			stats.sprints += parseInt(project.sprintsCount, 0);
			stats.testRuns += parseInt(project.runsCount, 0);
			stats.testCases += parseInt(project.casesCount, 0);
			stats.tests += parseInt(project.testsCount, 0);
		});
		return stats;
	}

	createInitialProjectStatistic(): TestStatistic[] {
		const statuses = this.store.selectSnapshot(StatusState.statuses);
		const dates = eachDayOfInterval({
			start: Date.now() - 3600000 * 24 * 60,
			end: Date.now(),
		});
		const stats: TestStatistic[] = [];
		statuses.forEach((status) => {
			stats.push(
				...dates.map((d) => {
					return {
						label: d.toString(),
						count: 0,
						status,
					};
				}),
			);
		});
		return stats;
	}

	createEmptyStatistic() {
		const stat = [];
		const date = new Date();
		range(30).forEach((num) => {
			const day = new Date().setDate(date.getDate() - num);
			stat.push({
				day,
				allTestsCount: 0,
				badTestsCount: 0,
				badTestsPercentage: 0,
			});
		});

		return stat;
	}
}
