import { HttpClient } from "@angular/common/http";
import { TrackerIntegrationProviders } from "@features/integrations/domain/constants";
import {
	CreateForeignDefectDto,
	ForeignPriorityInfo,
	ForeignUserInfo,
	TrackerIntegrationData
} from "@features/integrations/domain/types";
import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";
import { CommonIntegrationService } from "../common-integration.service";
import { CreateTrackerDescription } from "./strategies/create-tracker-description";

export class TrackerIntegrationService extends CommonIntegrationService<TrackerIntegrationData> {
	readonly mappedProjectsUrl = `${this.providerUrl}/mappings/projects`;

	constructor(
		protected readonly provider: TrackerIntegrationProviders,
		protected readonly http: HttpClient,
		public readonly createDescriptionStrategy: CreateTrackerDescription,
	) {
		super(provider, http);
	}

	getStatuses(projectId: string) {
		return this.http.get(`${this.mappedProjectsUrl}/${projectId}/statuses`).pipe(pluck('data'));
	}

	getIssueTypes(projectId: string) {
		return this.http.get(`${this.mappedProjectsUrl}/${projectId}/issue-types`).pipe(pluck('data'));
	}

	getUsers(projectId: string): Observable<ForeignUserInfo[]> {
		return this.http.get(`${this.mappedProjectsUrl}/${projectId}/members`).pipe(pluck('data'));
	}

	getPriorities(projectId: string): Observable<ForeignPriorityInfo[]> {
		return this.http.get(`${this.mappedProjectsUrl}/${projectId}/priorities`).pipe(pluck('data'));
	}

	createDefect(projectId: string, defect: CreateForeignDefectDto): Observable<any> {
		return this.http.post(`${this.mappedProjectsUrl}/${projectId}/defects`, defect).pipe(pluck('data'));
	}
}
