import { Directive, ElementRef, Inject, Input, NgZone, OnDestroy, Optional, Output, SkipSelf } from '@angular/core';
import { distinctUntilChanged, map, skip, startWith } from 'rxjs/operators';
import { eqaZoneOptimized } from '@shared/domain/observables/zone-free';
import { Observable } from 'rxjs';
import { EQA_ACTIVE_ELEMENT } from '@shared/tokens/active-element';

@Directive({
	selector:
		'[eqaActiveZone]:not(ng-container), [eqaActiveZoneChange]:not(ng-container), [eqaActiveZoneParent]:not(ng-container)',
	exportAs: 'eqaActiveZone',
})
export class EqaActiveZoneDirective implements OnDestroy {
	private subActiveZones: ReadonlyArray<EqaActiveZoneDirective> = [];

	private eqaActiveZoneParent: EqaActiveZoneDirective | null = null;

	@Output()
	readonly eqaActiveZoneChange = this.active$.pipe(
		map((element) => !!element && this.contains(element)),
		startWith(false),
		distinctUntilChanged(),
		skip(1),
		eqaZoneOptimized(this.ngZone),
	);

	@Input('eqaActiveZoneParent')
	set eqaActiveZoneParentSetter(zone: EqaActiveZoneDirective | null) {
		this.setZone(zone);
	}

	constructor(
		@Inject(EQA_ACTIVE_ELEMENT)
		private readonly active$: Observable<Element | null>,
		@Inject(NgZone) private readonly ngZone: NgZone,
		@Inject(ElementRef) private readonly elementRef: ElementRef<Element>,
		@Optional()
		@SkipSelf()
		@Inject(EqaActiveZoneDirective)
		private readonly directParentActiveZone: EqaActiveZoneDirective | null,
	) {
		if (this.directParentActiveZone) {
			this.directParentActiveZone.addSubActiveZone(this);
		}
	}

	ngOnDestroy() {
		if (this.directParentActiveZone) {
			this.directParentActiveZone.removeSubActiveZone(this);
		}

		if (this.eqaActiveZoneParent) {
			this.eqaActiveZoneParent.removeSubActiveZone(this);
		}
	}

	contains(node: Node): boolean {
		return (
			this.elementRef.nativeElement.contains(node) ||
			this.subActiveZones.some((item, index, array) => array.indexOf(item) === index && item.contains(node))
		);
	}

	private setZone(zone: EqaActiveZoneDirective | null) {
		if (this.eqaActiveZoneParent) {
			this.eqaActiveZoneParent.removeSubActiveZone(this);
		}

		if (zone) {
			zone.addSubActiveZone(this);
		}

		this.eqaActiveZoneParent = zone;
	}

	private addSubActiveZone(activeZone: EqaActiveZoneDirective) {
		this.subActiveZones = [...this.subActiveZones, activeZone];
	}

	private removeSubActiveZone(activeZone: EqaActiveZoneDirective) {
		const index = this.subActiveZones.findIndex((item) => item === activeZone);

		this.subActiveZones = [...this.subActiveZones.slice(0, index), ...this.subActiveZones.slice(index + 1)];
	}
}
