import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { EqaLetContext } from '@shared/ui/directives/let/let-context';

@Directive({
	selector: '[eqaLet]',
})
export class EqaLetDirective<T> {
	@Input()
	eqaLet!: T;

	static ngTemplateContextGuard<T>(_dir: EqaLetDirective<T>, _ctx: any): _ctx is EqaLetDirective<T> {
		return true;
	}

	constructor(
		@Inject(ViewContainerRef) viewContainer: ViewContainerRef,
		@Inject(TemplateRef) templateRef: TemplateRef<EqaLetContext<T>>,
	) {
		viewContainer.createEmbeddedView(templateRef, new EqaLetContext<T>(this));
	}
}
