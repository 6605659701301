import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from '@core/services/http/user/user.service';

@Directive({
	selector: '[eqaHasPermission]',
})
export class HasPermissionDirective implements OnChanges {
	@Input('eqaHasPermission') permissions: string | string[]; // Required permission passed in

	constructor(private el: ElementRef, private userService: UserService) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.permissions?.currentValue) {

			let condition: boolean;
			if (Array.isArray(this.permissions)) {
				condition = this.permissions.some(p => !this.userService.hasPermission(p));
			} else {
				condition = !this.userService.hasPermission(this.permissions);
			}
			if (condition) {
				setTimeout(() => this.el?.nativeElement?.remove?.());
			}
		}
	}
}
