import { Injectable, Provider } from '@angular/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { EqaPreviewDialogComponent } from './preview-dialog.component';
import { EQA_DIALOGS } from '@shared/tokens/dialogs';
import { AbstractEqaDialogService } from '@shared/domain/abstracts';

@Injectable({
	providedIn: 'root',
})
export class PreviewDialogService extends AbstractEqaDialogService<{}> {
	readonly defaultOptions = {};
	readonly component = new PolymorpheusComponent(EqaPreviewDialogComponent);
}

export const PREVIEW_DIALOG_PROVIDER: Provider = {
	provide: EQA_DIALOGS,
	useExisting: PreviewDialogService,
	multi: true,
};
