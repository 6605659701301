import { Currency } from "@core/constants";
import { SubscriptionActionsEnum } from "./subscription.actions.enum";

export class GetSubscription {
	static readonly type = SubscriptionActionsEnum.GET_SUBSCRIPTION;

	constructor() {}
}

export class UpgradeSubscription {
	static readonly type = SubscriptionActionsEnum.UPGRADE_SUBSCRIPTION;

	constructor(public currency: Currency) {}
}
