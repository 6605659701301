import * as _ from 'lodash';
import { Section } from '@features/case/case-repository/domain/interfaces/section';
import {
	ROOT_CASES_SECTION_NAME,
	VIRTUAL_SECTION_ID,
} from '@features/case/case-repository/domain/contants/section-constants';
import { TestCaseStatus } from '@features/case/case-repository/domain/contants/case-constants';
import { Utils } from '../../core/utils/utils';

export class TreeUtils {
	static buildTree(nodes: Array<Section>): Array<Section> {
		const root = [];
		const _nodes = this.calculateSectionsPath(_.cloneDeep(nodes));
		const nodesCount = _nodes.length;
		for (let nodeIndex = 0; nodeIndex < nodesCount; nodeIndex++) {
			const node = _nodes[nodeIndex];
			node.cases = Utils.sortCasesByOrder(node.cases, node.casesOrder);
			if (!node.id) {
				node.id = VIRTUAL_SECTION_ID;
				node.name = ROOT_CASES_SECTION_NAME;
				node.children = [];
			}

			if (!node.parent) {
				root.push(node);
			} else {
				const parentIndex = _nodes.findIndex((el) => el.id === node.parent.id);
				const currentParent = _nodes[parentIndex];
				if (parentIndex === -1) {
					continue;
				}
				currentParent.children.push(node);
			}
		}
		return root;
	}

	static getNodeChildrenRecursive(item: Section) {
		const children = [];
		if (item?.children?.length > 0) {
			for (const treeItem of item.children) {
				children.push(treeItem);
				if (treeItem?.children?.length > 0) {
					children.push(...this.getNodeChildrenRecursive(treeItem));
				}
			}
		}
		return children;
	}

	// TODO Mutation - refactor it
	static recalculatePathInNode(node: Section) {
		if (node.children.length > 0) {
			for (const child of node.children) {
				child.path = [...node.path, node.id];
				if (child.children.length > 0) {
					this.recalculatePathInNode(child);
				}
			}
		}
	}

	static treeToFlatList(tree: Section[]) {
		if (!tree.length) {
			return [];
		}
		const stack: Section[] = [];
		const aInnerTree = _.cloneDeep(tree);

		while (aInnerTree.length) {
			const node = aInnerTree.shift();
			if (node?.children?.length) {
				aInnerTree.push(...node.children);
				node.children = [];
			}
			stack.push(node);
		}

		// let innerTreeCount = aInnerTree.length;
		// while (innerTreeCount--) {
		// 	let node = aInnerTree.shift();
		// 	stack.push(node);
		// 	if (node?.children?.length) {
		// 		stack.push(...this.treeToFlatList(node.children));
		// 		node.children = [];
		// 	}
		// }
		return stack;
	}

	static treeToNestedFlat(tree) {
		let ii;
		const stack: Section[] = [];
		const aInnerTree = _.clone(tree);
		let innerTreeCount = aInnerTree.length;
		while (innerTreeCount--) {
			let node = aInnerTree.pop();
			if (node.children && node.children.length) {
				for (ii = 0; ii < node.children.length; ii += 1) {
					aInnerTree.push(node.children[ii]);
					innerTreeCount++;
				}
			}
			stack.push(node);
		}
		return stack;
	}

	static getScionsOfManySections(sections: Section[]) {
		return sections.concat(sections.reduce((acc, section) => acc.concat(this.getNodeChildrenRecursive(section)), []));
	}

	static findParent(item, tree) {
		let it = null;
		for (const trItem of tree) {
			if (trItem.id === item.parent.id) {
				return trItem;
			} else {
				it = this.findParent(item, trItem.children);
			}
		}

		return it;
	}

	static validateNewParent(sectionFrom: Section, sectionTo: Section) {
		const children = this.getScionsOfManySections(sectionFrom.children.filter((child) => child.checked));
		const childrenIds = children.map((c) => c.id);
		return childrenIds.findIndex((x) => x === sectionTo.id) < 0 && sectionFrom.id !== sectionTo.id;
	}

	static getNodeById<T>(stack: T[], id: string, path: string[] = []): T {
		let ii, node;
		const aInnerTree = _.clone(stack);
		let innerTreeCount = aInnerTree.length;
		while (innerTreeCount--) {
			node = aInnerTree.pop();
			if (node.id === (id ?? VIRTUAL_SECTION_ID)) {
				return node;
			} else if (node.children && node.children.length) {
				for (ii = 0; ii < node.children.length; ii += 1) {
					aInnerTree.push(node.children[ii]);
					innerTreeCount++;
				}
			}
		}
		return null;
	}

	static getNodeByIds(stack, ids) {
		let ii, node;
		let nodes = [];
		const aInnerTree = _.clone(stack);
		let innerTreeCount = aInnerTree.length;
		while (innerTreeCount--) {
			node = aInnerTree.pop();
			if (ids.findIndex((id) => id === node.id) > -1) {
				nodes.push(node);
			}
			if (node.children && node.children.length) {
				for (ii = 0; ii < node.children.length; ii += 1) {
					aInnerTree.push(node.children[ii]);
					innerTreeCount++;
				}
			}
		}
		return nodes;
	}

	static getNodesByCasesIds(stack: Section[], ids: string[]) {
		const aInnerTree = _.clone(stack);
		const clonedIds = [...ids];
		let nodes = [];
		for (const section of aInnerTree) {
			if (section?.children?.length) {
				aInnerTree.push(...section.children);
			}
			let needed = false;
			const casesIds = section.cases?.map((c) => c.id);
			if (!casesIds?.length) {
				continue;
			}
			for (let i = 0, j = clonedIds.length; i < j; i++) {
				if (casesIds.includes(clonedIds[i])) {
					needed = true;
					clonedIds.splice(i, 1);
					i--;
					j--;
				}
			}
			if (needed) {
				nodes.push(section);
			}
		}
		return nodes;
	}

	static findNextSibling(parent, item) {
		return this.findAdjacentSibling(+1, parent, item);
	}

	static findPreviousSibling(parent, item) {
		return this.findAdjacentSibling(-1, parent, item);
	}

	static findAdjacentSibling(steps, parent, item) {
		const siblings = this.getParentsChildren(parent);
		const index = siblings.findIndex((sb) => sb.id === item.id);
		return siblings.length > index + steps ? siblings[index + steps] : null;
	}

	static getRootSibling(item, steps, tree) {
		const index = tree.findIndex((x) => x.id === item.id);
		return tree.length > index + steps ? tree[index + steps] : null;
	}

	static findNextRootSibling(item, tree) {
		return this.getRootSibling(item, +1, tree);
	}

	static findPrevRootSibling(item, tree) {
		return this.getRootSibling(item, -1, tree);
	}

	static getParentsChildren(item: Section): any[] {
		return item.children;
	}

	static removeNodeFromTree(element, tree, currentTreeParent = null) {
		for (const treeItem of tree) {
			if (treeItem.id === element.id) {
				const index = tree.findIndex((i) => i.id === element.id);
				if (index > -1) {
					tree.splice(index, 1);
				}
			} else {
				this.removeNodeFromTree(element, treeItem.children, treeItem);
			}
		}
	}

	static getTestsFromTree(tree, testCasesIds) {
		let ii, node: Section;
		const aInnerTree = _.clone(tree);
		let list = [];
		let length = aInnerTree.length;
		while (length > 0) {
			length--;
			node = aInnerTree.pop();
			node.checked = false;
			list.push(...this.searchInTestCases(node, testCasesIds));
			if (node.children && node.children.length > 0) {
				for (ii = 0; ii < node.children.length; ii += 1) {
					aInnerTree.push(node.children[ii]);
					length++;
				}
			}
		}
		return list;
	}

	static searchInTestCases(section: Section, testCases) {
		let length = section.cases.length;
		let list = [];
		while (length--) {
			if (testCases.findIndex((id) => section.cases[length].id === id) >= 0) {
				section.cases[length].checked = false;
				list.push(section.cases[length]);
			}
		}
		return list;
	}

	static changeCheckedItemsFlag(items: Array<Section>, flag) {
		items.forEach((item) => {
			item.checked = flag;
		});
		return items;
	}

	static calculateSectionsPath(sections: Section[]) {
		const _sections = _.cloneDeep(sections);
		const sectionsWithPath = [];
		const sectionsCount = _sections.length;
		for (let sectionIndex = 0; sectionIndex < sectionsCount; sectionIndex++) {
			const section = _sections[sectionIndex];
			section.path = [];
			if (section.parent) {
				const parentIndex = _sections.findIndex((x) => x.id === section.parent.id);
				const parent = _sections[parentIndex];
				if (parent?.path?.length) {
					section.path.push(...parent.path);
				}
				section.path.push(section.parent.id);
			}
			sectionsWithPath.push(section);
		}
		return sectionsWithPath;
	}

	static filterTreeByContentFilters(flatTree: Section[], filters: any, searchPhrase: string) {
		const _flatTree = _.cloneDeep(flatTree);
		const paths = [];
		_flatTree.forEach((s) => {
			s.cases.forEach((c) => {
				if (c['testCase']) {
					c['testCase'].fields.forEach((f) => {
						c[f.slug] = f.value.value;
					});
					c['tags'] = c['testCase'].tags;
				} else {
					c.fields.forEach((f) => {
						c[f.slug] = f.value.value;
					});
				}
			});
			s.cases = s.cases.filter(
				(entity) => this.search.call(filters, entity) && this.searchByText.call(searchPhrase, entity),
			);
			if (s.cases.length > 0) {
				paths.push(...s.path, s.id);
			}
		});

		return { filtered: _flatTree, paths: paths };
	}

	static searchInTreeByText(flatTree: Section[], searchPhrase: string) {
		const _flatTree = _.cloneDeep(flatTree);
		const paths = [];
		_flatTree.forEach((s) => {
			s.cases.forEach((c) => {
				if (c['testCase']) {
					c['testCase'].fields.forEach((f) => {
						c[f.slug] = f.value.value;
					});
					c['tags'] = c['testCase'].tags;
				} else {
					c.fields.forEach((f) => {
						c[f.slug] = f.value.value;
					});
				}
			});
			s.cases = s.cases.filter(this.searchByText, searchPhrase);
			if (s.cases.length > 0) {
				paths.push(...s.path, s.id);
			}
		});

		return { filtered: _flatTree, paths: paths };
	}

	static search(entity) {
		if (!Object.keys(this).length) {
			return true;
		}
		return Object.keys(this).every((key) => {
			if (entity[key] !== undefined) {
				if (Array.isArray(this[key].value)) {
					if (Array.isArray(entity[key])) {
						return this[key].value.some((r) => {
							if (typeof r === 'object') {
								return entity[key].findIndex((x) => x.id === r.id) >= 0;
							} else {
								return entity[key].indexOf(r) >= 0;
							}
						});
					}
					return (
						this[key].value?.findIndex((i) => i.name.trim().toLowerCase() === entity[key].trim().toLowerCase()) >= 0
					);
				}
				if (typeof this[key].value === 'object') {
					if (this[key].value.id !== null) {
						return entity[key].toString().toLowerCase() === this[key].value.id.toString().toLowerCase();
					} else {
						return true;
					}
				}
				if (key === 'isApproved') {
					const filterValue = this[key].value.id === TestCaseStatus.APPROVED;
					return entity[key] == filterValue;
				}
				return entity[key].toString().toLowerCase().includes(this[key].value.toString().toLowerCase());
			} else {
				return false;
			}
		});
	}

	static searchByText(entity) {
		if (!this) {
			return true;
		}
		let entityAsString = JSON.stringify(Object.values(entity));
		return entityAsString.toUpperCase().includes(this.toString().toUpperCase());
	}

	static getPathFromFlatTree(sections: Section[], path: string[]): Section[] {
		const pathCopy = [...path];
		const resultPath: Section[] = [];
		const obj = sections.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
		while (pathCopy.length) {
			let node = obj[pathCopy.pop()];
			if (!node) {
				return resultPath;
			}
			resultPath.push(node);
		}
		return resultPath.reverse();
	}
}
