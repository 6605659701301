import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { delay, last, skipUntil, skipWhile, take, takeUntil, takeWhile } from 'rxjs/operators';
import { first } from 'lodash';
import { hasCssClass } from '@shared/domain/utils';
import { DestroyService } from '@shared/domain';
import { typedFromEvent } from '@shared/domain/observables';
import { px } from '@shared/domain/utils/format';
import { SidenavCollapseService } from '@shared/domain/services/sidenav-collapse.service';
import { EMPTY_STRING } from '@shared/domain/constants';

const HIDE_DELAY = 10;
const COLLAPSED_WIDTH = 48;
const EXPANDED_WIDTH = 260;

const classes = {
	IS_OVER: 'is-over',
	IS_SHOWING_FLY_OUT: 'is-showing-fly-out',
	SIDEBAR_SUB_LEVEL_ITEMS: 'sidebar-sub-level-items',
	FLY_OUT_LIST: 'fly-out-list',
	ACTIVE: 'active',
	HAS_SUB_ITEMS: 'has-sub-items',
};

@Directive({ selector: '[eqaFlyOut]' })
export class EqaFlyOutDirective implements OnInit {
	@Input() sidebarCollapsed: boolean = false;
	private _translateXValue: number = 0;

	private elementMouseEnter$ = typedFromEvent(this.elementRef.nativeElement, 'mouseenter').pipe(
		takeUntil(this.destroy$),
	);
	private elementMouseLeave$ = typedFromEvent(this.elementRef.nativeElement, 'mouseleave').pipe(
		takeUntil(this.destroy$),
		delay(HIDE_DELAY),
	);

	constructor(
		private sidenavCollapseService: SidenavCollapseService,
		private destroy$: DestroyService,
		private renderer2: Renderer2,
		private elementRef: ElementRef,
	) {}

	ngOnInit() {
		this.subscribeElementMouseEnter();
		this.subscribeElementMouseLeave();
		this.subscribeSideNavState();
	}

	private subscribeSideNavState(): void {
		this.sidenavCollapseService.collapseObs$.pipe(takeUntil(this.destroy$)).subscribe((collapsed) => {
			this._translateXValue = collapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH;
		});
	}

	private subscribeElementMouseEnter(): void {
		this.elementMouseEnter$.subscribe((event: MouseEvent) => {
			if (!event) {
				return;
			}
			const listLiItem = event.target as HTMLLIElement;
			const listLiIemLink: HTMLElement = first(listLiItem.getElementsByTagName('a'));
			if (hasCssClass(listLiItem, classes.ACTIVE) && !this.sidebarCollapsed) {
				return;
			}
			if (hasCssClass(listLiIemLink, classes.HAS_SUB_ITEMS)) {
				this.showFlyOutMenu(listLiItem);
			}
		});
	}

	private subscribeElementMouseLeave(): void {
		this.elementMouseLeave$.subscribe((event: MouseEvent) => {
			if (!event) {
				return;
			}
			const listLiItem = event.target as HTMLLIElement;
			if (hasCssClass(listLiItem, classes.IS_OVER) || hasCssClass(listLiItem, classes.IS_SHOWING_FLY_OUT)) {
				this.hideFyOutMenu(listLiItem);
			}
		});
	}

	private hideFyOutMenu(listLiItem: HTMLLIElement): void {
		const sublevelUlList = this.getSublevelUlList(listLiItem);
		if (!sublevelUlList) {
			return;
		}
		if (!sublevelUlList.matches(':hover')) {
			this.renderer2.removeClass(listLiItem, classes.IS_OVER);
			this.renderer2.removeClass(listLiItem, classes.IS_SHOWING_FLY_OUT);
			this.renderer2.removeClass(sublevelUlList, classes.FLY_OUT_LIST);
			this.renderer2.setAttribute(sublevelUlList, 'style', EMPTY_STRING);
		}
	}

	private showFlyOutMenu(listLiItem: HTMLLIElement): void {
		const sublevelUlList = this.getSublevelUlList(listLiItem);
		if (!sublevelUlList) {
			return;
		}
		if (!hasCssClass(listLiItem, classes.ACTIVE) || this.sidebarCollapsed) {
			this.renderer2.addClass(listLiItem, `${classes.IS_OVER}`);
			this.renderer2.addClass(listLiItem, `${classes.IS_SHOWING_FLY_OUT}`);
			this.renderer2.addClass(sublevelUlList, classes.FLY_OUT_LIST);
			this.renderer2.setAttribute(
				sublevelUlList,
				'style',
				`display: block; ${this.calculateTransform(listLiItem)}`,
			);
		}
	}

	private calculateTransform(listItem: HTMLElement): string {
		return `transform: translate3d(${px(this._translateXValue)}, ${px(listItem.offsetTop)}, 0px)`;
	}

	private getSublevelUlList(parentItem: HTMLLIElement): HTMLUListElement {
		return first(parentItem.getElementsByTagName('ul'));
	}
}
