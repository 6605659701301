import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";

@Injectable({ providedIn: 'root' })
export class NetworkService {
	private baseUrl = ServerUrl.HOME_URL;
	private networkUrl = this.baseUrl + '/network';

	constructor(private http: HttpClient) {}

	getStats() {
		return this.http.get(this.networkUrl + '/statistics');
	}

	getSubscription() {
		return this.http.get(this.networkUrl + '/subscription');
	}
}
