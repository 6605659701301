import { IntegrationProviders } from "./integration.providers";

export const PROVIDER_URL_MAPPINGS = {
	[IntegrationProviders.JIRA_HOSTED]: 'jira-hosted',
	[IntegrationProviders.JIRA_CLOUD]: 'jira-cloud',
	[IntegrationProviders.TRELLO]: 'trello',
	[IntegrationProviders.GITHUB]: 'github',
	[IntegrationProviders.GITLAB]: 'gitlab',
	[IntegrationProviders.BITBUCKET_CLOUD]: 'bitbucket-cloud',
	[IntegrationProviders.SLACK]: 'slack',
	[IntegrationProviders.TELEGRAM_BOT]: 'telegram-bot',
};

export const URL_PROVIDER_MAPPINGS = {
	'jira-hosted': IntegrationProviders.JIRA_HOSTED,
	'jira-cloud': IntegrationProviders.JIRA_CLOUD,
	'trello': IntegrationProviders.TRELLO,
	'github': IntegrationProviders.GITHUB,
	'gitlab': IntegrationProviders.GITLAB,
	'bitbucket-cloud': IntegrationProviders.BITBUCKET_CLOUD,
	'slack': IntegrationProviders.SLACK,
	'telegram-bot': IntegrationProviders.TELEGRAM_BOT,
};
