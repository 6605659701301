import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EqaContentComponent } from './content/content.component';
import { PaddedContainerComponent } from './padded-container/padded-container.component';
import {ScrollbarModule} from "@shared/ui/elements/scrollbar";

@NgModule({
	declarations: [EqaContentComponent, PaddedContainerComponent],
	imports: [CommonModule, ScrollbarModule],
	exports: [EqaContentComponent, PaddedContainerComponent],
})
export class ContentModule {}
