import { CaseFeature } from "@features/case/case-features/domain/interfaces/CaseFeature";

enum CaseFeaturesActions {
	getProjectCaseFeatures = '[CaseFeature] Get Case Features List By Project',
	getCaseFeature = '[CaseFeature] Get Case Feature',
	createCaseFeature = '[CaseFeature] Create Case Feature',
	updateCaseFeature = '[CaseFeature] Update Case Feature',
	removeCaseFeature = '[CaseFeature] Remove Case Feature',
}

export class LoadProjectCaseFeatures {
	static readonly type = CaseFeaturesActions.getProjectCaseFeatures;

	constructor() {}
}

export class GetCaseFeature {
	static readonly type = CaseFeaturesActions.getCaseFeature;

	constructor(public caseTagId: string) {}
}

export class CreateCaseFeature {
	static readonly type = CaseFeaturesActions.createCaseFeature;

	constructor(public feature: Partial<CaseFeature>) {}
}

export class UpdateCaseFeature {
	static readonly type = CaseFeaturesActions.updateCaseFeature;

	constructor(public feature: CaseFeature) {}
}

export class RemoveCaseFeature {
	static readonly type = CaseFeaturesActions.removeCaseFeature;

	constructor(public featureId: string) {}
}
