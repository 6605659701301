import { Currency } from '@core/constants';
import { Utils } from '@core/utils/utils';
import { PeriodInterval } from '@features/network/components/billing/domain/constants/period-interval';
import { PricableFeature } from '@features/network/components/billing/domain/constants/pricable.feature';
import { PlanFeatureModel } from './plan.feature.model';
import { PlanPeriodModel } from './plan.period.model';

export class PlanModel {
	id: string;

	name: string;

	periods: PlanPeriodModel[];

	discount: number;

	isTrial: boolean;

	features: PlanFeatureModel[];

	constructor(plan: PlanModel) {
		this.id = plan.id;
		this.name = plan.name;
		this.isTrial = plan.isTrial;
		this.features = plan.features.map((f) => new PlanFeatureModel(f));
		this.periods = plan.periods ? plan.periods.map((period) => new PlanPeriodModel(period)) : [];
	}

	getPeriodByInterval(interval: PeriodInterval): PlanPeriodModel {
		return this.periods.find((p) => p.interval === interval);
	}

	getPurchasableFeatures(feature: PricableFeature) {
		return this.features.find((f: PlanFeatureModel) => f.slug === feature);
	}

	getDisplayedFeatures() {
		const planFeaturesForDisplay = this.features.filter((f) => f.description);
		return planFeaturesForDisplay.sort((a, b) => a.order - b.order);
	}

	calculatePlanPrice(planFeatures: PlanFeatureModel[], currency: Currency, period: PeriodInterval) {
		const feature = planFeatures.find((f) => f.slug === PricableFeature.USERS);
		if (feature && feature.prices && feature.prices.length > 0) {
			return feature.prices.find((p) => p.currency === currency && p.period.interval === period);
		} else {
			return Utils.getZeroPrice(currency);
		}
	}

	normalisePlan(period, currency) {
		return {
			name: this.name,
			id: this.id,
			features: this.getDisplayedFeatures(),
			price: this.calculatePlanPrice(this.features, currency, this.getPeriodByInterval(period).interval),
			periods: this.periods,
		};
	}
}
