import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewComponent } from './preview.component';
import { PREVIEW_DIALOG_PROVIDER } from '@shared/ui/components/preview-dialog/preview-dialog.service';
import { EqaPreviewTitleComponent } from '@shared/ui/components/preview/title/preview-title.component';
import { MutationObserverModule } from '@ng-web-apis/mutation-observer';
import { EqaPreviewPaginationComponent } from '@shared/ui/components/preview/pagination/preview-pagination.component';
import { EqaPreviewActionModule } from '@shared/ui/components/preview/preview-action/preview-action.module';
import { ButtonModule } from '@shared/ui';

@NgModule({
	declarations: [EqaPreviewPaginationComponent, PreviewComponent, EqaPreviewTitleComponent],
	exports: [EqaPreviewPaginationComponent, PreviewComponent, EqaPreviewTitleComponent],
	imports: [CommonModule, MutationObserverModule, EqaPreviewActionModule, ButtonModule],
	providers: [PREVIEW_DIALOG_PROVIDER],
})
export class PreviewModule {}
