import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { IntegrationServicesContainer } from "@features/integrations/domain/services";
import { Action, createSelector, Selector, State, StateContext, StateOperator } from "@ngxs/store";
import { cloneDeep } from "lodash";
import { forkJoin, of } from "rxjs";
import { catchError, pluck, switchMap, tap } from "rxjs/operators";
import { DeleteUserIntegration, SetUserIntegration } from "@features/user-settings/store/account.actions";
import { ChatIntegrationProviders, HookStates, IntegrationProviders } from "../constants";
import { CommonIntegration, IntegrationsSummary } from "../types";
import { Integrations } from "./integrations.actions";

export interface IntegrationsStateModel {
	integrationsLoaded: boolean;
	hookLoaded: boolean;
	integrations: IntegrationsSummary;
}

@State<IntegrationsStateModel>({
	name: 'integrationsState',
	defaults: {
		integrationsLoaded: false,
		hookLoaded: true,
		integrations: {},
	},
})
@Injectable()
export class IntegrationsState {
	url = `${ServerUrl.HOME_URL}/integrations`;

	@Selector()
	static integrationsLoaded(m: IntegrationsStateModel) {
		return m.integrationsLoaded;
	}

	@Selector()
	static hookLoaded(m: IntegrationsStateModel) {
		return m.hookLoaded;
	}

	static integration(provider: IntegrationProviders) {
		return createSelector([IntegrationsState], (m: IntegrationsStateModel) => m.integrations[provider]);
	}

	static currentIntegration(provider: IntegrationProviders, projectId: string) {
		return createSelector([IntegrationsState], (m: IntegrationsStateModel) =>
			m.integrations[provider].mappedProjects?.find((mp) => mp.internal.id === projectId),
		);
	}

	@Selector()
	static integrations(m: IntegrationsStateModel) {
		return m.integrations;
	}

	@Selector()
	static currentJira(m: IntegrationsStateModel) {
		return (
			[m.integrations[IntegrationProviders.JIRA_CLOUD], m.integrations[IntegrationProviders.JIRA_HOSTED]].find(
				(i) => i.enabled,
			) ?? null
		);
	}

	static chats(projectId: string) {
		return createSelector([IntegrationsState], (m: IntegrationsStateModel) =>
			Object.values(ChatIntegrationProviders)
				.map((p) => m.integrations[p].mappedProjects?.find((mp) => mp?.internal?.id === projectId))
				.filter((p) => p?.data?.settings?.isEnabled),
		);
	}

	constructor(private readonly http: HttpClient, private readonly servicesContainer: IntegrationServicesContainer) {}

	@Action(Integrations.Summary)
	getIntegrationsInfo({ patchState }: StateContext<IntegrationsStateModel>) {
		patchState({ integrationsLoaded: false });
		return this.http
			.get(this.url, {
				headers: {
					'Cache-Control': 'max-age=5',
				},
			})
			.pipe(
				pluck('data'),
				tap(
					(data: CommonIntegration<any>[]) => {
						patchState({
							integrations: data.reduce(
								(acc, i) => ({ ...acc, [i.provider]: new CommonIntegration(i) }),
								{},
							),
							integrationsLoaded: true,
						});
					},
					() => {
						patchState({ integrationsLoaded: true });
					},
				),
			);
	}

	@Action(Integrations.GetOne)
	getIntegration({ patchState, getState }: StateContext<IntegrationsStateModel>, { provider }: Integrations.GetOne) {
		patchState({ integrationsLoaded: false });
		const service = this.getService(provider);
		return service.getIntegration().pipe(
			tap(
				(data) => {
					const integrations = cloneDeep(getState().integrations);
					integrations[provider] = data;
					patchState({ integrations });
				},
				() => {
					patchState({ integrationsLoaded: true });
				},
			),
		);
	}

	@Action(Integrations.Upsert)
	upsertIntegration(
		{ patchState, getState }: StateContext<IntegrationsStateModel>,
		{ provider, dto }: Integrations.Upsert,
	) {
		patchState({ integrationsLoaded: false });
		const service = this.getService(provider);
		return service.upsertIntegration(dto).pipe(
			tap(
				(data) => {
					const integrations = cloneDeep(getState().integrations);
					integrations[provider] = data;
					patchState({
						integrations,
						integrationsLoaded: true,
					});
				},
				() => {
					patchState({ integrationsLoaded: true });
				},
			),
		);
	}

	@Action(Integrations.Delete)
	deleteIntegration(
		{ patchState, getState }: StateContext<IntegrationsStateModel>,
		{ provider }: Integrations.Delete,
	) {
		patchState({ integrationsLoaded: false });
		if (!getState().integrations[provider]?.data) {
			return;
		}
		const service = this.getService(provider);
		return service.deleteIntegration().pipe(
			tap(
				() => {
					const integrations = cloneDeep(getState().integrations);
					integrations[provider] = new CommonIntegration<any>({
						provider,
						data: undefined,
						enabled: false,
						automatic: false,
					});
					patchState({
						integrations,
						integrationsLoaded: true,
					});
				},
				() => {
					patchState({ integrationsLoaded: true });
				},
			),
		);
	}

	@Action(Integrations.Enable)
	enableIntegration(
		{ patchState, getState }: StateContext<IntegrationsStateModel>,
		{ provider }: Integrations.Enable,
	) {
		if (getState().integrations[provider].enabled) {
			return of(null);
		}
		patchState({ integrationsLoaded: false });
		const service = this.getService(provider);
		return service.enableIntegration().pipe(
			tap(
				() => {
					const integrations = cloneDeep(getState().integrations);
					integrations[provider].enabled = true;
					patchState({
						integrations,
						integrationsLoaded: true,
					});
				},
				() => {
					patchState({ integrationsLoaded: true });
				},
			),
		);
	}

	@Action(Integrations.Disable)
	disableIntegrations(
		{ patchState, getState }: StateContext<IntegrationsStateModel>,
		{ providers }: Integrations.Disable,
	) {
		patchState({ integrationsLoaded: false });
		const enabledProviders = providers.filter((p) => getState().integrations[p].enabled);
		if (!enabledProviders?.length) {
			patchState({ integrationsLoaded: true });
			return of(null);
		}
		return forkJoin(enabledProviders.map((provider) => this.getService(provider).disableIntegration())).pipe(
			tap(
				() => {
					const integrations = cloneDeep(getState().integrations);
					enabledProviders.forEach((p) => {
						integrations[p].enabled = false;
					});
					patchState({
						integrations,
						integrationsLoaded: true,
					});
				},
				() => {
					patchState({ integrationsLoaded: true });
				},
			),
		);
	}

	@Action(Integrations.GetForeignProjects)
	getIntegrationForeignProjects(
		{ patchState, getState }: StateContext<IntegrationsStateModel>,
		{ provider }: Integrations.GetForeignProjects,
	) {
		patchState({ integrationsLoaded: false });
		return this.getService(provider)
			.getIntegrationForeignProjects()
			.pipe(
				tap(
					(r) => {
						const integrations = cloneDeep(getState().integrations);
						integrations[provider].foreignProjects = r;
						patchState({
							integrations,
							integrationsLoaded: true,
						});
					},
					() => {
						patchState({ integrationsLoaded: true });
					},
				),
			);
	}

	@Action(Integrations.GetMappedProjectById)
	getMappedProjectById(
		{ patchState, getState }: StateContext<IntegrationsStateModel>,
		{ provider, projectId }: Integrations.GetMappedProjectById,
	) {
		patchState({ integrationsLoaded: false });
		const service = this.getService(provider);
		return service.getMappedProjectById(projectId).pipe(
			tap(
				(r) => {
					const integrations = cloneDeep(getState().integrations);
					integrations[provider].currentMappedProject = r;
					patchState({ integrations });
				},
				() => {
					patchState({ integrationsLoaded: true });
				},
			),
			switchMap(() => service.getWebhook(projectId)),
			tap((r) => {
				const hook = r?.state === HookStates.ENABLED;
				const integrations = cloneDeep(getState().integrations);
				integrations[provider].currentMappedProject.hook = hook;
				const mp = integrations[provider]?.mappedProjects?.find((p) => p.internal.id === projectId);
				if (mp) {
					mp.hook = hook;
				}
				patchState({
					integrations,
					integrationsLoaded: true,
				});
			}),
			catchError(() => of(null)),
		);
	}

	@Action(Integrations.GetMappedProjects)
	getIntegrationMappedProjects(
		{ patchState, getState }: StateContext<IntegrationsStateModel>,
		{ provider }: Integrations.GetMappedProjects,
	) {
		patchState({ integrationsLoaded: false });
		return this.getService(provider)
			.getIntegrationMappedProjects()
			.pipe(
				tap(
					(r) => {
						const integrations = cloneDeep(getState().integrations);
						integrations[provider].mappedProjects = r;
						patchState({
							integrations,
							integrationsLoaded: true,
						});
					},
					() => {
						patchState({ integrationsLoaded: true });
					},
				),
			);
	}

	@Action(Integrations.MapProject)
	mapProject(
		{ patchState, getState }: StateContext<IntegrationsStateModel>,
		{ provider, dto, project }: Integrations.MapProject,
	) {
		patchState({ integrationsLoaded: false });
		return this.getService(provider)
			.mapProject(project.id, dto)
			.pipe(
				tap(
					(r) => {
						const integrations = cloneDeep(getState().integrations);
						const mapping = {
							external: r.id,
							internal: project,
							data: r,
						};
						const existent = integrations[provider].mappedProjects.findIndex(
							(p) => p.internal.id === project.id,
						);
						if (existent > -1) {
							integrations[provider].mappedProjects.splice(existent, 1, mapping);
						} else {
							integrations[provider].mappedProjects.push(mapping);
						}
						patchState({
							integrations,
							integrationsLoaded: true,
						});
					},
					() => {
						patchState({ integrationsLoaded: true });
					},
				),
			);
	}

	@Action(Integrations.AddWebHook)
	addWebhook(
		{ patchState, getState }: StateContext<IntegrationsStateModel>,
		{ provider, projectId }: Integrations.AddWebHook,
	) {
		patchState({ integrationsLoaded: false, hookLoaded: false });
		return this.getService(provider)
			.addWebhook(projectId)
			.pipe(
				tap(
					() => {
						const integrations = cloneDeep(getState().integrations);
						const existent = integrations[provider].mappedProjects.find((p) => p.internal.id === projectId);
						existent.hook = true;
						patchState({
							integrations,
							integrationsLoaded: true,
							hookLoaded: true,
						});
					},
					() => {
						patchState({ integrationsLoaded: true, hookLoaded: true });
					},
				),
			);
	}

	@Action(Integrations.DeleteWebHook)
	deleteWebhook(
		{ patchState, getState }: StateContext<IntegrationsStateModel>,
		{ provider, projectId }: Integrations.DeleteWebHook,
	) {
		patchState({ integrationsLoaded: false, hookLoaded: false });
		return this.getService(provider)
			.deleteWebhook(projectId)
			.pipe(
				tap(
					() => {
						const integrations = cloneDeep(getState().integrations);
						const existent = integrations[provider].mappedProjects.find((p) => p.internal.id === projectId);
						existent.hook = false;
						patchState({
							integrations,
							integrationsLoaded: true,
							hookLoaded: true,
						});
					},
					() => {
						patchState({ integrationsLoaded: true, hookLoaded: true });
					},
				),
			);
	}

	@Action(Integrations.UnmapProject)
	unMapProject(
		{ patchState, getState }: StateContext<IntegrationsStateModel>,
		{ provider, projectId }: Integrations.UnmapProject,
	) {
		patchState({ integrationsLoaded: false });
		return this.getService(provider)
			.unmapProject(projectId)
			.pipe(
				tap(
					() => {
						const integrations = cloneDeep(getState().integrations);
						const index = integrations[provider].mappedProjects.findIndex(
							(p) => p.internal.id === projectId,
						);
						integrations[provider].mappedProjects.splice(index, 1);
						patchState({
							integrations,
							integrationsLoaded: true,
						});
					},
					() => {
						patchState({ integrationsLoaded: true });
					},
				),
			);
	}

	@Action(Integrations.MapUser)
	mapUser(
		{ patchState, getState, dispatch }: StateContext<IntegrationsStateModel>,
		{ provider, dto }: Integrations.MapUser,
	) {
		return this.getService(provider)
			.mapUser(dto)
			.pipe(
				tap((user) => {
					dispatch(new SetUserIntegration(provider, user.data));
				}),
			);
	}

	@Action(Integrations.UnmapUser)
	unMapUser(
		{ patchState, getState, dispatch }: StateContext<IntegrationsStateModel>,
		{ provider }: Integrations.UnmapUser,
	) {
		return this.getService(provider)
			.unmapUser()
			.pipe(
				tap(() => {
					dispatch(new DeleteUserIntegration(provider));
				}),
			);
	}

	@Action(Integrations.CompleteOAuth)
	completeOAuth(
		{ patchState, getState, dispatch }: StateContext<IntegrationsStateModel>,
		{ params }: Integrations.CompleteOAuth,
	) {
		const provider = params.provider;
		delete params.provider;
		return this.getService(provider)
			.completeOAuth(params)
			.pipe(
				tap((user) => {
					dispatch(new SetUserIntegration(provider, user.data));
				}),
			);
	}

	@Action(Integrations.StoreDefect)
	storeDefect(
		{ getState, setState }: StateContext<IntegrationsStateModel>,
		{ provider, value }: Integrations.StoreDefect,
	) {
		setState(this.updateIntegration({ ...getState().integrations[provider], defectFormValue: value }));
	}

	@Action(Integrations.GetPriorities)
	getPriorities(
		{ getState, patchState }: StateContext<IntegrationsStateModel>,
		{ provider, projectId }: Integrations.GetPriorities,
	) {
		return this.servicesContainer
			.getTracker(provider)
			.getPriorities(projectId)
			.pipe(
				tap((priorities) => {
					const integration = cloneDeep(getState().integrations[provider]);
					integration.currentMappedProject.priorities = priorities;
					patchState({
						integrations: {
							...getState().integrations,
							[provider]: integration,
						},
					});
				}),
			);
	}

	@Action(Integrations.GetUsers)
	getUsers(
		{ getState, patchState }: StateContext<IntegrationsStateModel>,
		{ provider, projectId }: Integrations.GetUsers,
	) {
		return this.servicesContainer
			.getTracker(provider)
			.getUsers(projectId)
			.pipe(
				tap((users) => {
					const integration = cloneDeep(getState().integrations[provider]);
					integration.currentMappedProject.users = users;
					patchState({
						integrations: {
							...getState().integrations,
							[provider]: integration,
						},
					});
				}),
			);
	}

	@Action(Integrations.CreateIssue)
	createDefect(
		{ getState, patchState }: StateContext<IntegrationsStateModel>,
		{ provider, projectId, defect, formValue }: Integrations.CreateIssue,
	) {
		const service = this.servicesContainer.getTracker(provider);
		const { assignee, priority } = formValue;
		const dto = {
			assignee,
			priority,
			title: defect.name,
			description: service.createDescriptionStrategy.create(defect),
			runId: defect.run.id,
			defectId: defect.id,
		};
		return service.createDefect(projectId, dto);
	}

	@Action(Integrations.ClearDefectFormValues)
	clearDefectFormValues({ getState, setState }: StateContext<IntegrationsStateModel>) {
		for (const v of Object.values(getState().integrations)) {
			setState(this.updateIntegration({ ...v, defectFormValue: null }));
		}
	}

	updateIntegration(integration: CommonIntegration<any>): StateOperator<IntegrationsStateModel> {
		return (state: IntegrationsStateModel) => {
			return {
				...state,
				integrations: { ...state.integrations, [integration.provider]: integration },
			};
		};
	}

	getService(provider: IntegrationProviders) {
		return this.servicesContainer.getCommon(provider);
	}

	getChat(provider: ChatIntegrationProviders) {
		return this.servicesContainer.getChat(provider);
	}
}
