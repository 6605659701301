import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { ProjectConfig } from "@features/projects/interfaces";
import { TestrunModel } from "@features/run/domain/interfaces/testrun.model";
import { pluck } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ProjectTestProjectConfigsApiService {
	private baseUrl = ServerUrl.HOME_URL;

	constructor(private http: HttpClient) {}

	url(projectId: string) {
		return `${this.baseUrl}/projects/${projectId}/run-configs`;
	}

	getProjectConfigs(projectId: string) {
		return this.http.get(this.url(projectId)).pipe<ProjectConfig[]>(pluck('data'));
	}

	getOne(projectId: string, configId: string) {
		return this.http.get(`${this.url(projectId)}/${configId}`).pipe<ProjectConfig>(pluck('data'));
	}

	getRunsByConfig(projectId: string, configId: string) {
		return this.http.get(`${this.url(projectId)}/${configId}/runs`).pipe<TestrunModel[]>(pluck('data'));
	}

	deleteProjectConfig(projectId: string, configId: string) {
		return this.http.delete<void>(`${this.url(projectId)}/${configId}`);
	}

	updateProjectConfig(projectId: string, configId: string, config: ProjectConfig) {
		return this.http.put(`${this.url(projectId)}/${configId}`, config).pipe<ProjectConfig>(pluck('data'));
	}

	createProjectConfig(projectId: string, field: ProjectConfig) {
		return this.http.post(this.url(projectId), field).pipe<ProjectConfig>(pluck('data'));
	}
}
