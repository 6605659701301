import { CreateSectionDto } from "@features/case/case-repository/domain/interfaces/create-section.dto";
import { Section } from "@features/case/case-repository/domain/interfaces/section";
import { UpdateSectionModel } from "@features/case/case-repository/domain/interfaces/update.section.model";
import { SelectedFilter } from "@shared/ui/components/filters/domain/interfaces/selected.filter";
import { SectionsActionsEnum } from "./sections.actions.enum";

export class GetSections {
	static readonly type = SectionsActionsEnum.GET_SECTIONS;

	constructor(public projectId: string) {}
}

export class SetSections {
	static readonly type = SectionsActionsEnum.SET_SECTIONS;

	constructor(public sections: Section[]) {}
}

export class SelectSection {
	static readonly type = SectionsActionsEnum.SELECT_SECTION;

	constructor(public section: Section) {}
}

export class FulfillSections {
	static readonly type = SectionsActionsEnum.FULFILL_SECTIONS;

	constructor(public sections: Section[]) {}
}

export class GetFulfilledSections {
	static readonly type = SectionsActionsEnum.GET_FULL_SECTIONS;

	constructor(public projectId: string) {}
}

export class FilterSections {
	static readonly type = SectionsActionsEnum.FILTER_SECTIONS;

	constructor(public filters: SelectedFilter) {}
}

export class CreateSection {
	static readonly type = SectionsActionsEnum.CREATE_SECTION;

	constructor(public section: CreateSectionDto) {}
}

export class UpdateSection {
	static readonly type = SectionsActionsEnum.UPDATE_SECTION;

	constructor(public id: string, public section: UpdateSectionModel) {}
}

export class RemoveSection {
	static readonly type = SectionsActionsEnum.DELETE_SECTION;

	constructor(public idsForRemove: string[]) {}
}

export class MoveSections {
	static readonly type = SectionsActionsEnum.MOVE_SECTIONS;

	constructor(public sectionsIds: string[], public newParentId: string) {}
}
