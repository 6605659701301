<ng-container *ngIf="dialogs$ | async as dialogs">
	<section
		*ngFor="let item of dialogs"
		eqaScrollRef
		eqaFocusTrap
		eqaOverscroll="all"
		role="dialog"
		aria-modal="true"
		class="dialog"
		[attr.aria-labelledby]="item.id"
	>
		<ng-container *polymorpheusOutlet="item.component; context: item"></ng-container>
	</section>
	<div
		*eqaLet="isDialogClosesOnBack$ | async as isDialogClosesOnBack"
		class="dialog-overlay"
		[class.dialog-overlay_visible]="dialogs.length"
		(window:popstate)="closeLast(dialogs, isDialogClosesOnBack)"
		(transitionend)="onDialog($event, !!dialogs.length, isDialogClosesOnBack)"
	></div>
</ng-container>
