import { Section } from "@features/case/case-repository/domain/interfaces/section";
import { Testcase } from "@features/case/case-repository/domain/interfaces/testcase";
import { UpdateCaseDto } from "@features/case/case-repository/domain/interfaces/update-case.dto";
import { UpdateManyCasesDto } from "@features/case/case-repository/domain/interfaces/updateManyCasesDto";
import { TestCaseTag } from "@features/case/case-tags/domain/interfaces/TestCaseTag";
import { CasesViewMode } from "../../interfaces/cases-view-mode";

enum TestCaseActions {
	getTestCasesListByProject = '[TestCaseModel] Get Test Cases List By Project',
	getTestCasesList = '[TestCaseModel] Get Test Cases List',
	getTestCase = '[TestCaseModel] Get Test Case',
	setLoadingStatus = '[TestCaseModel] Set loading status',
	getTestCaseChangelog = '[TestCaseModel] Get Test Case Changelog',
	removeTestCasesList = '[TestCaseModel] Remove Test Cases List',
	copyTestCasesList = '[TestCaseModel] Copy Test Cases List',
	pushCases = '[TestCaseModel] Add Cases To List',
	setCaseList = '[TestCaseModel] Set Case List',
	changeTestCasesSection = '[TestCaseModel] Change Test Cases Section',
	createTestCase = '[TestCaseModel] Create Test Case',
	getHistory = '[TestCaseModel] Get test case run history',
	updateTestCase = '[TestCaseModel] Update Test Case',
	updateTestCases = '[TestCaseModel] Bulk Update Test Cases',
	changeTags = '[TestCaseModel] Change Test Case Tags',
	removeTestCase = '[TestCaseModel] Remove Test Case',
	changeTestCaseCheckedFlag = '[TestCaseModel] Change Test Case Checked Flag',
	selectTestCase = '[TestCaseModel] Select Test Case',
	setSelectedCasesList = '[TestCaseModel] Set Selected Test Cases List',
	addSelectedCase = '[TestCaseModel] Add Selected Case',
	submitForm = '[TestCaseModel] Submit Case Form',
	submitBulkForm = '[TestCaseModel] Submit Bulk Case Form',
	clearActiveCase = '[TestCaseModel] Clear Active Case',
	setIsTreeView = '[TestCaseModel] Set Is Tree View',
	toggleShowCasesCount = '[TestCaseModel] Toggle Show Cases Count',
	setCaseNgxsFormValue = '[TestCaseModel] SetCaseNgxsFormValue',
}

export class GetTestCasesList {
	static readonly type = TestCaseActions.getTestCasesList;

	constructor(public section: Section) {}
}

export class GetTestCasesListByProjectId {
	static readonly type = TestCaseActions.getTestCasesListByProject;

	constructor(public projectId: string) {}
}

export class GetTestCase {
	static readonly type = TestCaseActions.getTestCase;

	constructor(public testCaseId: string) {}
}

export class SetLoadingStatus {
	static readonly type = TestCaseActions.setLoadingStatus;

	constructor(public status: boolean) {}
}

export class GetTestcaseRunHistory {
	static readonly type = TestCaseActions.getHistory;

	constructor(public testCaseId: string) {}
}

export class RemoveTestCasesList {
	static readonly type = TestCaseActions.removeTestCasesList;

	constructor(public testCaseIds: string[]) {}
}

export class CopyTestCasesList {
	static readonly type = TestCaseActions.copyTestCasesList;

	constructor(public ids: string[], public section: Section) {}
}

export class MoveTestCasesList {
	static readonly type = TestCaseActions.changeTestCasesSection;

	constructor(public ids: string[], public newSection: Section) {}
}

export class CreateTestCase {
	static readonly type = TestCaseActions.createTestCase;

	constructor(public testCase: Testcase) {}
}

export class UpdateTestCase {
	static readonly type = TestCaseActions.updateTestCase;

	constructor(public testCase: UpdateCaseDto) {}
}

export class UpdateTestCases {
	static readonly type = TestCaseActions.updateTestCases;

	constructor(public updateManyCasesDto: UpdateManyCasesDto) {}
}

export class PushCases {
	static readonly type = TestCaseActions.pushCases;

	constructor(public cases: Testcase[]) {}
}

export class SetCaseList {
	static readonly type = TestCaseActions.setCaseList;

	constructor(public testCaseList: Testcase[]) {}
}

export class ChangeCaseTags {
	static readonly type = TestCaseActions.changeTags;

	constructor(public tags: TestCaseTag[]) {}
}

export class RemoveTestCase {
	static readonly type = TestCaseActions.removeTestCase;

	constructor(public testCaseId: string) { }
}

export class ChangeTestCaseCheckedFlag {
	static readonly type = TestCaseActions.changeTestCaseCheckedFlag;

	constructor(public payload) {}
}

export class SelectTestCase {
	static readonly type = TestCaseActions.selectTestCase;

	constructor(public testCase: Testcase, public flag: boolean) {}
}

export class SetSelectedCasesList {
	static readonly type = TestCaseActions.setSelectedCasesList;

	constructor(public testCase: Testcase[]) {}
}

export class GetTestCaseChangelog {
	static readonly type = TestCaseActions.getTestCaseChangelog;

	constructor(public testCaseId) {}
}

export class ClearHistory {
	static readonly type = '[TESTCASE] Clear History';

	constructor() {}
}

export class ClearChangeLog {
	static readonly type = '[TESTCASE] Clear Changelog';

	constructor() {}
}

export class SubmitCaseForm {
	static readonly type = TestCaseActions.submitForm;
	constructor(public updateCaseDto: UpdateCaseDto) {
	}
}

export class SubmitBulkCaseForm {
	static readonly type = TestCaseActions.submitBulkForm;

	constructor(public ids: string[]) {}
}

export class ClearActiveCase {
	static readonly type = TestCaseActions.clearActiveCase;
}

export class SetCasesViewMode {
	static readonly type = TestCaseActions.setIsTreeView;

	constructor(public viewMode: CasesViewMode) {}
}

export class ToggleShowCasesCount {
	static readonly type = TestCaseActions.toggleShowCasesCount;
}

export class SetCaseNgxsFormValue {
	static readonly type = TestCaseActions.setCaseNgxsFormValue;
	constructor(public formValue: UpdateCaseDto) {
	}
}
