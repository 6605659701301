export class TrackerIntegrationOauth1Data {
	host: string;
	consumerKey: string;
	consumerSecret: string;

	constructor(data: TrackerIntegrationOauth1Data) {
		this.host = data?.host;
		this.consumerKey = data?.consumerKey;
		this.consumerSecret = data?.consumerSecret;
	}
}
