import { HttpClient } from "@angular/common/http";
import { ServerUrl } from "@core/constants/server-url";
import { UserIntegration } from "@core/interfaces/user/UserModel";
import { IntegrationProviders } from "@features/integrations/domain/constants";
import { PROVIDER_URL_MAPPINGS } from "@features/integrations/domain/constants/provider-url-mappings";
import {
	CommonIntegration,
	CompleteOauthParams,
	ForeignProjectInfo,
	IntegrationUserCredentials,
	MapProjectDto,
	ProjectMapping,
	UpsertIntegrationDto,
	UserMapping
} from "@features/integrations/domain/types";
import { Observable } from "rxjs";
import { map, pluck } from "rxjs/operators";

interface IntegrationResponse<T> {
	provider: IntegrationProviders;
	isAutomatic: boolean;
	isEnabled: boolean;
	data: T;
}

export class CommonIntegrationService<T> {
	protected baseUrl = `${ServerUrl.HOME_URL}/integrations`;

	constructor(protected readonly provider: IntegrationProviders, protected readonly http: HttpClient) {}

	protected get providerUrl() {
		return `${this.baseUrl}/${PROVIDER_URL_MAPPINGS[this.provider]}`;
	}

	protected toIntegration() {
		return map(
			({ provider, data, isAutomatic, isEnabled }: IntegrationResponse<T>) =>
				new CommonIntegration<T>({
					provider,
					data,
					automatic: isAutomatic,
					enabled: isEnabled,
				}),
		);
	}

	getSummary() {
		return this.http.get<CommonIntegration<any>[]>(this.baseUrl).pipe(pluck('data'));
	}

	getIntegration(): Observable<CommonIntegration<T>> {
		return this.http.get(this.providerUrl).pipe(pluck('data'), this.toIntegration());
	}

	upsertIntegration(dto: UpsertIntegrationDto): Observable<CommonIntegration<T>> {
		return this.http.put(this.providerUrl, dto).pipe(pluck('data'), this.toIntegration());
	}

	deleteIntegration() {
		return this.http.delete<void>(this.providerUrl);
	}

	enableIntegration() {
		return this.http.put(`${this.providerUrl}/enabled`, null);
	}

	disableIntegration() {
		return this.http.delete<void>(`${this.providerUrl}/enabled`);
	}

	getIntegrationForeignProjects(): Observable<ForeignProjectInfo[]> {
		return this.http.get<any>(`${this.providerUrl}/projects`).pipe(pluck('data'));
	}

	getIntegrationMappedProjects(): Observable<ProjectMapping[]> {
		return this.http.get<any>(`${this.providerUrl}/mappings/projects`).pipe(pluck('data'));
	}

	getMappedProjectById(id: string): Observable<ProjectMapping> {
		return this.http.get<any>(`${this.providerUrl}/mappings/projects/${id}`).pipe(pluck('data'));
	}

	mapProject(projectId: string, dto: MapProjectDto): Observable<ForeignProjectInfo> {
		return this.http
			.put<any>(`${this.providerUrl}/mappings/projects/${projectId}`, dto)
			.pipe(pluck('data', 'data'));
	}

	unmapProject(projectId: string): Observable<void> {
		return this.http.delete<void>(`${this.providerUrl}/mappings/projects/${projectId}`);
	}

	mapUser(dto: IntegrationUserCredentials): Observable<UserMapping> {
		return this.http.put<any>(`${this.providerUrl}/mappings/members/self`, dto).pipe(pluck('data'));
	}

	unmapUser(): Observable<void> {
		return this.http.delete<void>(`${this.providerUrl}/mappings/members/self`);
	}

	getOAuth1RedirectionLink(): Observable<string> {
		return this.http.get(`${this.providerUrl}/accounts/redirect`).pipe(pluck('data', 'url'));
	}

	completeOAuth(params: CompleteOauthParams): Observable<UserIntegration> {
		return this.http.get(`${this.providerUrl}/accounts/complete`, { params }).pipe(pluck('data'));
	}

	checkCanCreateHook(projectId: string): Observable<any> {
		return this.http.get(`${this.providerUrl}/mappings/hooks/${projectId}/can-create`);
	}

	addWebhook(projectId: string): Observable<any> {
		return this.http.put(`${this.providerUrl}/mappings/hooks/${projectId}`, {});
	}

	getWebhook(projectId: string): Observable<any> {
		return this.http.get(`${this.providerUrl}/mappings/hooks/${projectId}`);
	}

	deleteWebhook(projectId: string): Observable<any> {
		return this.http.delete(`${this.providerUrl}/mappings/hooks/${projectId}`);
	}
}
