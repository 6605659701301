import { CreateRequirementDto } from "@features/requirements/domain/interfaces/requirement/create.requirement.dto";
import { RequirementDto } from "@features/requirements/domain/interfaces/requirement/requirement.dto";
import { UpdateRequirementDto } from "@features/requirements/domain/interfaces/requirement/update.requirement.dto";
import { RequirementActionsList } from "./requirement.actions.list";

export class GetRequirementsByGroup {
	static readonly type = RequirementActionsList.GET_REQUIREMENTS;

	constructor(public projectId: string, public groupId: string) {}
}

export class GetRequirement {
	static readonly type = RequirementActionsList.GET_REQUIREMENT;

	constructor(public projectId: string, public groupId: string, public requirementId: string) {}
}

export class SetCurrentRequirement {
	static readonly type = RequirementActionsList.SET_CURRENT_REQUIREMENT;

	constructor(public requirementDto: RequirementDto) {}
}

export class CreateRequirement {
	static readonly type = RequirementActionsList.CREATE_REQUIREMENT;

	constructor(public projectId: string, public requirement: CreateRequirementDto) {}
}

export class UpdateRequirement {
	static readonly type = RequirementActionsList.UPDATE_REQUIREMENT;

	constructor(public projectId: string, public requirement: UpdateRequirementDto, public requirementId: string) {}
}

export class RemoveRequirement {
	static readonly type = RequirementActionsList.REMOVE_REQUIREMENT;

	constructor(public projectId: string, public requirement: RequirementDto) {}
}

export class SelectRequirement {
	static readonly type = RequirementActionsList.SELECT_REQUIREMENT;

	constructor(public requirement: RequirementDto, public flag: boolean) {}
}

export class SelectAllRequirements {
	static readonly type = RequirementActionsList.SELECT_ALL_REQUIREMENTS;

	constructor(public flag: boolean) {}
}

export class LinkCases {
	static readonly type = RequirementActionsList.LINK_CASES;

	constructor(public projectId: string, public requirement: RequirementDto, public casesIds: string[]) {}
}

export class GetLinkedCases {
	static readonly type = RequirementActionsList.GET_LINKED_CASES;

	constructor(public projectId: string, public groupId: string, public requirementId: string) {}
}

export class MoveRequirements {
	static readonly type = RequirementActionsList.MOVE_REQUIREMENTS;

	constructor(public projectId: string, public groupId: string) {}
}

export class CopyRequirements {
	static readonly type = RequirementActionsList.COPY_REQUIREMENTS;

	constructor(public projectId: string, public groupId: string) {}
}

export class RemoveRequirements {
	static readonly type = RequirementActionsList.REMOVE_REQUIREMENTS;

	constructor(public projectId: string, public groupId: string) {}
}
