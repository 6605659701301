import { IntegrationProviders } from "../constants";
import { ChatIntegrationData } from "./chats";
import { TrackerIntegrationDefaultData, TrackerIntegrationOauth1Data } from "./trackers";

type TrackerIntegrationData = TrackerIntegrationDefaultData | TrackerIntegrationOauth1Data;

export class UpsertIntegrationDto {
	isEnabled: boolean;
	isAutomatic: boolean;
	provider: IntegrationProviders;
	data: TrackerIntegrationData | ChatIntegrationData;

	constructor(data: UpsertIntegrationDto) {
		this.isAutomatic = data.isAutomatic;
		this.provider = data.provider;
		this.isEnabled = data.isEnabled;
		switch (this.provider) {
			case IntegrationProviders.JIRA_HOSTED:
				this.data = new TrackerIntegrationOauth1Data(<TrackerIntegrationOauth1Data>data.data);
				break;
			case IntegrationProviders.JIRA_CLOUD:
			case IntegrationProviders.TRELLO:
			case IntegrationProviders.GITHUB:
			case IntegrationProviders.GITLAB:
			case IntegrationProviders.BITBUCKET_CLOUD:
			case IntegrationProviders.SLACK:
			case IntegrationProviders.TELEGRAM_BOT:
				this.data = new TrackerIntegrationDefaultData(<TrackerIntegrationDefaultData>data.data);
		}
	}
}
