import { Injectable } from "@angular/core";
import { Utils } from "@core/utils/utils";
import { SprintApiService } from "@features/sprint/domain/services/sprint.api.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import * as _ from "lodash";
import { map, pluck, tap } from "rxjs/operators";
import { SprintStatusEnum } from "../constants/sprint.status.enum";
import { Sprint } from "../interfaces/sprint";
import {
	CloseSprint,
	CreateSprint,
	DeleteSprint,
	GetActiveSprintsList,
	GetClosedSprintsList,
	GetSprintDetails,
	GetSprintsList,
	GetUpcomingSprintsList,
	StartSprint,
	UpdateSortConfig,
	UpdateSprint
} from "./sprints.actions";

export type SortConfig = {
	property: string;
	isAscending: boolean;
};

export type ActiveSprintSortProps = 'createdAt' | 'startAt' | 'endAt';

export type SprintsSortConfig = SortConfig & {
	property: ActiveSprintSortProps;
};

export class SprintsStateModel {
	completedSprints: Sprint[];
	upcomingSprints: Sprint[];
	activeSprints: Sprint[];
	openSprintsList: Sprint[];
	currentSprint: Sprint;
	activeSprintsCount: number;
	upcomingSprintsCount: number;
	completedSprintsCount: number;
	totalOpenSprintsCount: number;
	totalSprintCount: number;
	loading: boolean;
	activeSortConfig: SprintsSortConfig;
	upcomingSorting: boolean;
}

@State<SprintsStateModel>({
	name: 'sprintsState',
	defaults: {
		completedSprints: [],
		upcomingSprints: [],
		activeSprints: [],
		openSprintsList: [],
		currentSprint: null,
		activeSprintsCount: 0,
		upcomingSprintsCount: 0,
		completedSprintsCount: 0,
		totalOpenSprintsCount: 0,
		totalSprintCount: 0,
		loading: false,
		activeSortConfig: {
			property: 'createdAt',
			isAscending: true,
		},
		upcomingSorting: true,
	},
})
@Injectable()
export class SprintsState {
	@Selector()
	static openSprints(sprintsStateModel: SprintsStateModel): Sprint[] {
		return sprintsStateModel.openSprintsList;
	}

	@Selector()
	static activeSprintsCount(sprintsStateModel: SprintsStateModel): number {
		return sprintsStateModel.activeSprintsCount;
	}

	@Selector()
	static sprintsColumns(sprintsStateModel: SprintsStateModel): Array<{ status: SprintStatusEnum; list: Sprint[] }> {
		const sort = sprintsStateModel.activeSortConfig;
		const order = sprintsStateModel.activeSortConfig.isAscending ? 'asc' : 'desc';
		const upcoming = _.orderBy(sprintsStateModel.upcomingSprints, [(obj) => new Date(obj[sort.property])], [order]);
		const active = _.orderBy(sprintsStateModel.activeSprints, [(obj) => new Date(obj[sort.property])], [order]);
		const completed = _.orderBy(sprintsStateModel.completedSprints, [(obj) => new Date(obj[sort.property])], [order]);

		return [
			{ status: SprintStatusEnum.UPCOMING, list: upcoming },
			{ status: SprintStatusEnum.ACTIVE, list: active },
			{ status: SprintStatusEnum.CLOSED, list: completed },
		];
	}

	@Selector()
	static completedSprintsCount(sprintsStateModel: SprintsStateModel): number {
		return sprintsStateModel.completedSprintsCount;
	}

	@Selector()
	static totalOpenSprintsCount(sprintsStateModel: SprintsStateModel): number {
		return sprintsStateModel.totalOpenSprintsCount;
	}

	@Selector()
	static totalSprintsCount(sprintsStateModel: SprintsStateModel): number {
		return sprintsStateModel.totalSprintCount;
	}

	@Selector()
	static upcomingSprintsCount(sprintsStateModel: SprintsStateModel): number {
		return sprintsStateModel.upcomingSprintsCount;
	}

	@Selector()
	static completedSprints(sprintsStateModel: SprintsStateModel): {} {
		return sprintsStateModel.completedSprints;
	}

	@Selector()
	static upcomingSprints(sprintsStateModel: SprintsStateModel): {} {
		if (sprintsStateModel.upcomingSorting) {
			return sprintsStateModel.upcomingSprints;
		}
		return _.fromPairs(Object.entries(sprintsStateModel.upcomingSprints).reverse());
	}

	@Selector()
	static activeSprints(sprintsStateModel: SprintsStateModel): Sprint[] {
		const { property, isAscending } = sprintsStateModel.activeSortConfig;
		const ascendingArray = _.sortBy(sprintsStateModel.activeSprints, property);
		if (!isAscending) {
			return ascendingArray.reverse();
		}
		return ascendingArray;
	}

	@Selector()
	static loading(sprintsStateModel: SprintsStateModel): boolean {
		return sprintsStateModel.loading;
	}

	@Selector()
	static activeSprintsSortConfig(sprintsStateModel: SprintsStateModel): SprintsSortConfig {
		return sprintsStateModel.activeSortConfig;
	}

	@Selector()
	static upcomingSorting(sprintsStateModel: SprintsStateModel): boolean {
		return sprintsStateModel.upcomingSorting;
	}

	constructor(private sprintApiService: SprintApiService) {}

	@Action(GetSprintsList)
	getSprintsList(
		{ getState, setState }: StateContext<SprintsStateModel>,
		{ projectId, limit, offset }: GetSprintsList,
	) {
		const state = getState();
		setState({
			...state,
			loading: true,
		});

		return this.sprintApiService.getSprintsList(projectId, limit, offset).pipe(
			pluck('data'),
			map((sprints: Sprint[]) => {
				sprints.forEach((sprint) => (sprint.calculatedStats = this.calculateSprintStats(sprint)));
				return { total: sprints.length, groupedSprints: this.groupSprintsByStatus(sprints) };
			}),
			tap(({ total, groupedSprints }) => {
				const { active, upcoming, completed } = groupedSprints;
				const openSprints = [...active, ...upcoming];
				setState(
					patch<SprintsStateModel>({
						activeSprints: active,
						upcomingSprints: upcoming,
						completedSprints: completed,
						activeSprintsCount: active.length,
						upcomingSprintsCount: upcoming.length,
						completedSprintsCount: completed.length,
						openSprintsList: openSprints,
						totalOpenSprintsCount: active.length + upcoming.length,
						totalSprintCount: total,
						loading: false,
					}),
				);
			}),
		);
	}

	@Action(GetSprintDetails)
	getSprintDetails({ getState, setState }: StateContext<SprintsStateModel>, { id }: GetSprintDetails) {}

	@Action(CreateSprint)
	createSprint({ getState, setState }: StateContext<SprintsStateModel>, { payload }: CreateSprint) {
		const status = this.setSprintStatus(payload.startAt, Date.now());
		payload.status = status;
		return this.sprintApiService.createSprint(payload).pipe(
			pluck('data'),
			tap((sprint: Sprint) => {
				const state = getState();
				const upcomingSprints = _.cloneDeep(state.upcomingSprints);
				const activeSprints = _.cloneDeep(state.activeSprints);
				sprint.stats = [{ count: 0 }];
				sprint.calculatedStats = this.calculateSprintStats(sprint);
				if (status === SprintStatusEnum.ACTIVE) {
					activeSprints.push(sprint);
					setState(
						patch<SprintsStateModel>({
							activeSprints: activeSprints,
							activeSprintsCount: state.activeSprintsCount + 1,
							totalSprintCount: state.totalSprintCount + 1,
						}),
					);
				} else {
					upcomingSprints.push(sprint);
					setState(
						patch<SprintsStateModel>({
							upcomingSprints: upcomingSprints,
							upcomingSprintsCount: state.upcomingSprintsCount + 1,
							totalSprintCount: state.totalSprintCount + 1,
						}),
					);
				}
			}),
		);
	}

	@Action(UpdateSprint)
	updateSprint({ getState, patchState }: StateContext<SprintsStateModel>, { sprint }: UpdateSprint) {
		sprint.status = this.setSprintStatus(sprint.startAt, Date.now());
		return this.sprintApiService.updateSprint(sprint).pipe(
			pluck('data'),
			tap((updatedSprint: Sprint) => {
				const state = getState();
				const flatUpcoming = _.cloneDeep(state.upcomingSprints);
				const _activeSprints = _.cloneDeep(state.activeSprints);
				const allOpenedSprints = [...flatUpcoming, ..._activeSprints] as any;
				const index = allOpenedSprints.findIndex((s) => s.id === updatedSprint.id);
				let oldSprint = allOpenedSprints[index];
				oldSprint.status = updatedSprint.status;
				oldSprint.endAt = updatedSprint.endAt;
				oldSprint.name = updatedSprint.name;
				oldSprint.description = updatedSprint.description;
				oldSprint.startAt = updatedSprint.startAt;
				const groupedSprints = this.groupSprintsByStatus(allOpenedSprints);
				patchState({
					activeSprints: groupedSprints.active,
					upcomingSprints: groupedSprints.upcoming,
					activeSprintsCount: groupedSprints.active.length,
					upcomingSprintsCount: groupedSprints.upcoming.length,
				});
			}),
		);
	}

	@Action(StartSprint)
	startSprint({ getState, setState }: StateContext<SprintsStateModel>, { sprint }: StartSprint) {
		const state = getState();
		return this.sprintApiService.startSprint(sprint.id).pipe(
			pluck('data'),
			tap((startedSprint: Sprint) => {
				const activeSprints = _.cloneDeep(state.activeSprints);
				const upcomingSprints = _.cloneDeep(state.upcomingSprints).filter((x) => x.id !== startedSprint.id);
				startedSprint.stats = [{ count: 0 }];
				startedSprint.calculatedStats = this.calculateSprintStats(startedSprint);
				activeSprints.push(startedSprint);
				setState(
					patch<SprintsStateModel>({
						currentSprint: startedSprint,
						upcomingSprints: upcomingSprints,
						activeSprints: activeSprints,
						activeSprintsCount: state.activeSprintsCount + 1,
						upcomingSprintsCount: state.upcomingSprintsCount - 1,
					}),
				);
			}),
		);
	}

	@Action(CloseSprint)
	closeSprint({ getState, setState }: StateContext<SprintsStateModel>, { sprint }: CloseSprint) {
		const state = getState();
		return this.sprintApiService.closeSprint(sprint.id).pipe(
			pluck('data'),
			tap((closedSprint: Sprint) => {
				const _completedSprints = _.cloneDeep(state.completedSprints);
				const _activeSprints = _.cloneDeep(state.activeSprints);
				const indexOfSprint = _activeSprints.findIndex((s) => s.id === sprint.id);
				_activeSprints.splice(indexOfSprint, 1);
				closedSprint.calculatedStats = sprint.calculatedStats;
				_completedSprints.push(closedSprint);
				setState(
					patch<SprintsStateModel>({
						activeSprints: _activeSprints,
						completedSprints: _completedSprints,
						activeSprintsCount: state.activeSprintsCount - 1,
						completedSprintsCount: state.completedSprintsCount + 1,
					}),
				);
			}),
		);
	}

	@Action(DeleteSprint)
	deleteSprint({ getState, setState }: StateContext<SprintsStateModel>, { sprint }: DeleteSprint) {
		return this.sprintApiService.deleteSprint(sprint.id).pipe(
			tap(() => {
				const state = getState();
				if (sprint.status === SprintStatusEnum.ACTIVE) {
					const _activeSprints = _.cloneDeep(state.activeSprints);
					const index = _activeSprints.findIndex((s) => s.id === sprint.id);
					_activeSprints.splice(index, 1);
					setState(
						patch<SprintsStateModel>({
							activeSprints: [..._activeSprints],
							activeSprintsCount: state.activeSprintsCount - 1,
							totalOpenSprintsCount: state.totalOpenSprintsCount - 1,
							totalSprintCount: state.totalSprintCount - 1,
						}),
					);
				} else {
					const _upcomingSprints = _.cloneDeep(state.upcomingSprints);
					const index = _upcomingSprints.findIndex((s) => s.id === sprint.id);
					_upcomingSprints.splice(index, 1);
					setState(
						patch<SprintsStateModel>({
							upcomingSprints: _upcomingSprints,
							upcomingSprintsCount: state.upcomingSprintsCount - 1,
							totalOpenSprintsCount: state.totalOpenSprintsCount - 1,
							totalSprintCount: state.totalSprintCount - 1,
						}),
					);
				}
			}),
		);
	}

	@Action(GetActiveSprintsList)
	getActiveSprintsList(
		{ getState, setState }: StateContext<SprintsStateModel>,
		{ projectId, limit, offset }: GetActiveSprintsList,
	) {
		this.startLoading();
		return this.sprintApiService.getPaginatedSprintsList(projectId, limit, offset, SprintStatusEnum.ACTIVE).pipe(
			pluck('data'),
			tap((sprints: Sprint[]) => {
				sprints.forEach((sprint: Sprint) => {
					sprint.calculatedStats = this.calculateSprintStats(sprint);
				});
				setState(
					patch<SprintsStateModel>({
						activeSprints: sprints,
					}),
				);
				this.stopLoading();
			}),
		);
	}

	@Action(GetUpcomingSprintsList)
	getUpcomingSprintsList(
		{ getState, setState }: StateContext<SprintsStateModel>,
		{ projectId, limit, offset }: GetUpcomingSprintsList,
	) {
		this.startLoading();
		return this.sprintApiService.getPaginatedSprintsList(projectId, limit, offset, SprintStatusEnum.UPCOMING).pipe(
			pluck('data'),
			tap((sprints: []) => {
				const groupedSprints = this.groupSprintsByStatus(sprints);
				setState(
					patch<SprintsStateModel>({
						upcomingSprints: groupedSprints.upcoming,
					}),
				);
				this.stopLoading();
			}),
		);
	}

	@Action(GetClosedSprintsList)
	getClosedSprintsList(
		{ getState, setState }: StateContext<SprintsStateModel>,
		{ projectId, limit, offset }: GetClosedSprintsList,
	) {
		this.startLoading();
		return this.sprintApiService.getPaginatedSprintsList(projectId, limit, offset, SprintStatusEnum.CLOSED).pipe(
			pluck('data'),
			tap((sprints: []) => {
				const groupedSprints = this.groupSprintsByStatus(sprints);
				setState(
					patch<SprintsStateModel>({
						completedSprints: groupedSprints.completed,
					}),
				);
				this.stopLoading();
			}),
		);
	}

	@Action(UpdateSortConfig)
	updateSortConfig({ patchState, getState }: StateContext<SprintsStateModel>, { config }: UpdateSortConfig) {
		patchState({ activeSortConfig: { ...getState().activeSortConfig, ...config } });
	}

	setLoading(newValue: boolean) {
		return <T extends { loading: boolean }>(state: Readonly<T>) => {
			if (state && state.loading === newValue) {
				return state;
			}
			return { ...state, loading: newValue };
		};
	}

	startLoading() {
		return this.setLoading(true);
	}

	stopLoading() {
		return this.setLoading(false);
	}

	setSprintStatus(dateStart, dateNow) {
		const compareResult = Utils.compareDates(
			Utils.parseStringToDate(dateStart, 'yyyy-MM-dd'),
			Utils.formatDate(dateNow, 'yyyy-MM-dd'),
		);

		return compareResult <= 0 ? SprintStatusEnum.ACTIVE : SprintStatusEnum.UPCOMING;
	}

	groupSprintsByStatus(sprints: Sprint[]) {
		const grouped = _.groupBy(sprints, (item) => {
			return item.status;
		});
		const defaultObject: {
			active: Sprint[];
			upcoming: Sprint[];
			completed: Sprint[];
		} = {
			active: [],
			upcoming: [],
			completed: [],
		};
		for (let key of Object.keys(grouped)) {
			switch (key) {
				case SprintStatusEnum.ACTIVE:
					defaultObject.active = grouped[key];
					break;
				case SprintStatusEnum.UPCOMING:
					defaultObject.upcoming = grouped[key];
					break;
				case SprintStatusEnum.CLOSED:
					defaultObject.completed = grouped[key];
					break;
				default:
					break;
			}
		}
		return defaultObject;
	}

	private calculateSprintStats(sprint): any {
		const stats = {};
		sprint.stats.forEach((stat) => {
			const key = stat?.status?.name || 'no_tests';
			if (!stats[key]) {
				stats[key] = {
					color: stat?.status?.color || '#F2F2F2',
					name: stat?.status?.name || 'no tests',
					count: 1,
				};
			}
			stats[key].count += parseInt(stat.count.toString(), 10);
		});
		return stats;
	}

	private sortByDate(
		array: Array<any>,
		sortExpression: SprintsSortConfig = { property: 'startAt', isAscending: false },
	): Array<any> {
		const sortDirection = sortExpression.isAscending ? 'asc' : 'desc';
		return _.orderBy(array, [(obj) => new Date(obj[sortExpression.property])], [sortDirection]);
	}
}
