import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AbstractHttpService } from "@core/abstracts";
import { API_URL } from "@core/tokens/api-url.token";
import { CreateTestPlan, TestPlan } from "@features/test-plans/domain/interfaces";
import { ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import { catchError, pluck } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class HttpTestPlansService extends AbstractHttpService {
	private testPlansUrl = `${this.apiUrl}/test-plans`;
	constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string, notificationService: ToastrService) {
		super(notificationService);
	}

	public getList(projectId: string): Observable<Array<TestPlan>> {
		return this.http.get(this.testPlansUrl).pipe(
			pluck('data'),
			catchError((error) => {
				this.handleError(error);
				return of(error);
			}),
		);
	}

	public create(testPlan: CreateTestPlan): Observable<TestPlan> {
		return this.http.post<TestPlan>(this.testPlansUrl, testPlan).pipe(
			pluck('data'),
			catchError((error) => {
				this.handleError(error);
				return of(error);
			}),
		);
	}
}
