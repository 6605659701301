import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, Input } from '@angular/core';
import { EQA_LOADER_OPTIONS, EqaLoaderOptions } from '@shared/ui/elements/loader/loader-options';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';

@Component({
	selector: 'eqa-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
	@Input()
	inheritColor = this.options.inheritColor;

	@Input()
	overlay = this.options.overlay;

	@Input()
	textContent: PolymorpheusContent | null = null;

	@HostBinding('class._loading')
	loading = true;

	@Input()
	set showLoader(value: boolean) {
		this.loading = value;
	}

	constructor(
		@Inject(DOCUMENT) private readonly documentRef: Document,
		@Inject(ElementRef) private readonly elementRef: ElementRef<HTMLElement>,
		@Inject(EQA_LOADER_OPTIONS) private readonly options: EqaLoaderOptions,
	) {}

	get hasOverlay(): boolean {
		return this.overlay && this.loading;
	}

	get hasText(): boolean {
		return !!this.textContent;
	}
}
