import { FormControlModel } from '@shared/ui/forms/ui/components/dynaminc-fields/form.control.model';
import { Filter } from '@shared/ui/components/filters/domain/interfaces/new.filter';
import { SelectedFilter } from '@shared/ui/components/filters/domain/interfaces/selected.filter';
import { FilterActionsEnum } from './filter.actions.enum';

export class FulfillFilters {
	static readonly type = FilterActionsEnum.FULFILL_FILTERS;

	constructor(public filters: FormControlModel<any>[]) {}
}

export class SaveFiltersFormState {
	static readonly type = FilterActionsEnum.FULFILL_FILTERS;

	constructor(public filters: Filter<any>) {}
}

export class SetFilterInProgress {
	static readonly type = FilterActionsEnum.SET_FILTERING_IN_PROGRESS;

	constructor(public flag: boolean) {}
}

export class SelectFilter {
	static readonly type = FilterActionsEnum.SELECT_FILTER;

	constructor(public filter: SelectedFilter) {}
}

export class SetFilter {
	static readonly type = FilterActionsEnum.SET_FILTER;

	constructor(public filters: any, public searchPhrase: string) {}
}

export class SetExpanded {
	static readonly type = FilterActionsEnum.SET_EXPANDED;

	constructor(public expandedFilters: string[]) {}
}

export class SetFiltersControls {
	static readonly type = FilterActionsEnum.SET_FILTERS_CONTROLS;

	constructor(public controls: FormControlModel<any>[]) {}
}

export class RemoveFilter {
	static readonly type = FilterActionsEnum.REMOVE_FILTER;

	constructor(public filter: any) {}
}

export class ClearFilters {
	static readonly type = FilterActionsEnum.CLEAR_FILTERS;

	constructor() {}
}
