import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SidenavState } from '@layout/domain/store/sidenav/sidenav.state';
import { Observable } from 'rxjs';
import { Context } from '@layout/domain/store/sidenav/context';
import { SetSidenavContext } from '@layout/domain/store/sidenav/sidenav-state.actions';
import { MenuItem } from '@layout/domain/interfaces/MenuItem';
import { SprintsViewMode } from '@features/sprint/domain/constants/sprints.view.mode';
import { RunsViewMode } from '@features/run/domain/constants/runs.view.mode';
import { CasesRoutesEndPoints } from '@features/case/case-repository/domain/contants/case-constants';
import { UserModel } from '@core/interfaces/user/UserModel';
import { Testcase } from '@features/case/case-repository/domain/interfaces/testcase';
import { TestrunModel } from '@features/run/domain/interfaces/testrun.model';
import { SidenavContextTypes } from '@shared/domain/constants/sidenav-context-types';
import { ProjectModel } from 'src/app/features/projects/interfaces';

@Injectable({ providedIn: 'root' })
export class SidenavContextService {
	@Select(SidenavState.sidenavContext) sidenavContext: Observable<Context>;
	@Select(SidenavState.loading) loading$: Observable<boolean>;

	constructor(private store: Store) {}

	public setSidenavContext<T>(contextType: SidenavContextTypes, entity?: any): void {
		let context: Context = null;
		switch (contextType) {
			case SidenavContextTypes.CASE:
				context = this._getService().caseContext(entity);
				break;
			case SidenavContextTypes.PROJECT:
				context = this._getService().projectContext(entity);
				break;
			case SidenavContextTypes.WORKSPACE:
				context = this._getService().workspaceContext(entity);
				break;
			case SidenavContextTypes.USER:
				context = this._getService().userContext(entity);
				break;
			case SidenavContextTypes.RUN:
				context = this._getService().runContext(entity);
				break;
			default:
				context = this._getService().emptyContext();
				break;
		}
		this.store.dispatch(new SetSidenavContext(context));
	}

	public getSidenavContext<T>(contextType: SidenavContextTypes, entity?: any): Context {
		let context: Context = null;

		switch (contextType) {
			case SidenavContextTypes.CASE:
				context = this._getService().caseContext(entity);
				break;
			case SidenavContextTypes.PROJECT:
				context = this._getService().projectContext(entity);
				break;
			case SidenavContextTypes.WORKSPACE:
				context = this._getService().workspaceContext(entity);
				break;
			case SidenavContextTypes.USER:
				context = this._getService().userContext(entity);
				break;
			case SidenavContextTypes.RUN:
				context = this._getService().runContext(entity);
				break;
			default:
				context = this._getService().emptyContext();
				break;
		}

		return context;
	}

	private static projectContext(project: ProjectModel): Context {
		const { id: projectId } = project;
		const menuItems = [
			{
				id: 1,
				title: 'Project Information',
				icon: 'eqaProjectInfo',
				slug: 'project_details',
				link: {
					url: ['/', 'projects', projectId, 'activity'],
				},
				subscriptionFeature: 'ANY',
				children: [
					{
						id: 1,
						slug: 'project_activity',
						link: {
							url: ['/', 'projects', projectId, 'activity'],
						},
						title: 'Activity Stream',
					},
					{
						id: 2,
						slug: 'project_defects',
						link: {
							url: ['/', 'projects', projectId, 'defects'],
						},
						title: 'Defects',
					},
				],
			},
			{
				id: 4,
				title: 'Test Cases',
				slug: 'test_cases',
				icon: 'eqaDatabase',
				link: {
					url: ['/', 'projects', projectId, 'cases'],
				},
				subscriptionFeature: 'ANY',
				permission: [],
			},
			{
				id: 4,
				title: 'Case Reviews',
				slug: 'case_reviews',
				icon: 'eqaCaseReview',
				link: {
					url: ['/', 'projects', projectId, 'reviews'],
				},
				subscriptionFeature: 'ANY',
				permission: [],
			},
			{
				id: 2,
				title: 'Sprints',
				slug: 'sprints',
				icon: 'eqaSprints',
				subscriptionFeature: 'ANY',
				link: {
					url: ['/', 'projects', projectId, 'sprints'],
					params: {
						queryParams: { show: SprintsViewMode.ALL },
					},
				},
			},
			{
				id: 3,
				title: 'Test Runs',
				subscriptionFeature: 'ANY',
				slug: 'test_runs',
				icon: 'eqaTestruns',
				link: {
					url: ['/', 'projects', projectId, 'runs'],
					params: {
						queryParams: { show: RunsViewMode.ALL },
					},
				},
			},
			{
				id: 5,
				title: 'Requirements',
				subscriptionFeature: 'CUSTOM_FIELDS',
				slug: 'requirements',
				icon: 'eqaRequirements',
				link: {
					url: ['/', 'projects', projectId, 'requirements'],
				},
			},
			{
				id: 6,
				title: 'Reports',
				slug: 'reports',
				subscriptionFeature: 'CUSTOM_FIELDS',
				icon: 'eqaReports',
				link: {
					url: ['/', 'projects', projectId, 'reports'],
				},
			},
			{
				id: 7,
				title: 'Settings',
				slug: 'settings',
				subscriptionFeature: 'ANY',
				icon: 'eqaSettings',
				link: {
					url: ['/', 'projects', projectId, 'settings', '/', 'general'],
				},
				children: [
					{
						id: 1,
						link: {
							url: ['/', 'projects', projectId, 'settings', '/', 'general'],
						},
						title: 'General',
						subscriptionFeature: 'ANY',
					},
					{
						id: 2,
						link: {
							url: ['/', 'projects', projectId, 'settings', '/', 'users'],
						},
						title: 'Members',
						permission: 'PROJECTS_USERS_MANAGE',
						subscriptionFeature: 'ANY',
					},
					{
						id: 3,
						link: {
							url: ['/', 'projects', projectId, 'settings', '/', 'statuses'],
						},
						title: 'Statuses',
						subscriptionFeature: 'ANY',
					},
					{
						id: 4,
						link: {
							url: ['/', 'projects', projectId, 'settings', '/', 'tags'],
						},
						title: 'Tags',
						subscriptionFeature: 'ANY',
					},
					{
						id: 4,
						link: {
							url: ['/', 'projects', projectId, 'settings', '/', 'features'],
						},
						title: 'Features',
						subscriptionFeature: 'ANY',
					},
					{
						id: 5,
						link: {
							url: ['/', 'projects', projectId, 'settings', '/', 'fields'],
						},
						title: 'Test case fields',
						subscriptionFeature: 'CUSTOM_FIELDS',
					},
					{
						id: 6,
						link: {
							url: ['/', 'projects', projectId, 'settings', '/', 'shared-steps'],
						},
						title: 'Shared steps',
						subscriptionFeature: 'SHARED',
					},
					{
						id: 7,
						link: {
							url: ['/', 'projects', projectId, 'settings', '/', 'configs'],
						},
						title: 'Configurations',
						permission: 'PROJECT_CONFIGS_LIST',
					},
					/*	{
							id: 8,
							link: {
								url: ['/', 'projects', projectId,'/projects', projectId, 'settings', 'settings'],
							},
							title: 'Settings',
						},*/
				],
			},
		] as Array<MenuItem>;
		return {
			menuItems,
			contextEntity: project,
			contextLink: `/projects/${projectId}/overview`,
			contextEntityFieldName: 'name',
		};
	}

	private static caseContext(testCase: Testcase): Context {
		const { projectId, id: caseId } = testCase;
		const menuItems = [
			{
				id: 1,
				icon: 'eqaDefects',
				link: {
					url: ['/', '/', 'projects', projectId, 'cases', caseId, CasesRoutesEndPoints.Defects],
				},
				title: 'Defects',
			},
			{
				id: 2,
				icon: 'eqaChangelog',
				link: {
					url: ['/', '/', 'projects', projectId, 'cases', caseId, CasesRoutesEndPoints.Changelog],
				},
				title: 'Changelog',
			},
			{
				icon: 'eqaTestArchieve',
				id: 3,
				link: {
					url: ['/', '/', 'projects', projectId, 'cases', caseId, CasesRoutesEndPoints.TestHistory],
				},
				title: 'Test history',
			},
		];

		return {
			menuItems,
			contextEntity: testCase,
			contextEntityFieldName: 'name',
			contextLink: `/projects/${projectId}/cases/${testCase.id}/${CasesRoutesEndPoints.PrimaryInfo}`,
		};
	}

	private static workspaceContext(network: any): Context {
		const { id: networkId } = network;
		const menuItems = [
			{
				id: 1,
				link: {
					url: ['/', 'workspace', networkId, 'team', 'members'],
				},
				title: 'Team',
				icon: 'eqaMembers',
				children: [
					{
						id: 2,
						title: 'Members',
						link: {
							url: ['/', 'workspace', networkId, 'team', 'members'],
						},
					},
					{
						id: 3,
						title: 'Roles',
						link: {
							url: ['/', 'workspace', networkId, 'team', 'roles'],
						},
					},
				],
			},
			{
				id: 4,
				icon: 'eqaBilling',
				link: {
					url: ['/', 'workspace', networkId, 'billing'],
				},
				title: 'Billing Information',
			},

			{
				id: 5,
				icon: 'eqaIntegrationSettings',
				link: {
					url: ['/', 'workspace', networkId, 'integrations'],
				},
				title: 'Integrations',
				subscriptionFeature: 'INTEGRATIONS',
				permission: 'INTEGRATIONS_MANAGE',
			},
			{
				id: 6,
				icon: 'eqaAttachments',
				link: {
					url: ['/', 'workspace', networkId, 'attachments'],
				},
				title: 'Attachments',
			},
		];
		return {
			menuItems,
			contextEntity: network,
			contextEntityFieldName: 'name',
			contextLink: `/projects`,
		};
	}

	private static userContext(user: UserModel): Context {
		const { id: userId } = user;
		const menuItems = [
			{
				id: 2,
				title: 'Credentials',
				icon: 'eqaApiTokens',
				link: {
					url: ['/', 'user', userId, 'credentials'],
				},
			},
			{
				id: 3,
				title: 'API Tokens',
				icon: 'eqaCredentials',
				link: {
					url: ['/', 'user', userId, 'api-tokens'],
				},
			},
			{
				id: 4,
				title: 'Integrations Settings',
				icon: 'eqaIntegrationSettings',
				link: {
					url: ['/', 'user', userId, 'integrations'],
				},
				subscriptionFeature: 'INTEGRATIONS',
			},
		];

		return {
			menuItems,
			contextEntity: user,
			contextEntityFieldName: 'email',
			contextLink: `/user/${user.id}`,
		};
	}

	private static runContext(testRun: TestrunModel): Context {
		const { projectId, id: runId } = testRun;
		return {
			contextEntity: testRun,
			contextLink: `/projects/${testRun.projectId}/runs/${testRun.id}`,
			menuItems: [
				{
					id: 1,
					icon: 'eqaReports',
					link: {
						url: ['/projects', projectId, 'runs', runId, 'report'],
					},
					title: 'Report',
				},
				{
					id: 2,
					icon: 'eqaDefects',
					link: {
						url: ['/projects', projectId, 'runs', runId, 'defects'],
					},
					title: 'Defects',
				},
				{
					id: 3,
					icon: 'eqaProgress',
					link: {
						url: ['/projects', projectId, 'runs', runId, 'progress'],
					},
					title: 'Progress',
				},
				{
					id: 4,
					icon: 'eqaConfiguration',
					link: {
						url: ['/projects', projectId, 'runs', runId, 'configuration'],
					},
					title: 'Configuration',
				},
				{
					id: 5,
					icon: 'eqaTeamstats',
					link: {
						url: ['/projects', projectId, 'runs', runId, 'team'],
					},
					title: 'Team stats',
				},
			],
			contextEntityFieldName: 'name',
		};
	}

	private static emptyContext(): null {
		return null;
	}

	private _getService() {
		return SidenavContextService;
	}
}
