import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngxs/store';
import { ServerUrl } from '@core/constants/server-url';
import { ILogin } from '@core/interfaces/user/Login';
import { SetUser } from '@core/store/user/user.actions';
import { UserState } from '@core/store/user/user.state';
import { UserModel } from '@core/interfaces/user/UserModel';
import { ConfigService } from '../../config-service';

@Injectable({ providedIn: 'root' })
export class AccountService {
	private header: HttpHeaders;
	private url = ServerUrl.HOME_URL;

	constructor(
		private http: HttpClient,
		private jwtHelperService: JwtHelperService,
		private router: Router,
		private ngZone: NgZone,
		private configService: ConfigService,
		private store: Store,
	) {
		this.header = new HttpHeaders();
		this.header.append('Content-Type', 'application/json');
	}

	authenticateUser(user: ILogin) {
		return this.http.post(this.url + '/auth/signin', user, { headers: this.header });
	}

	impersonate(token: string) {
		return this.http.get(this.url + '/auth/impersonate', { params: { token } });
	}

	refreshDataUser() {
		if (this.isUserLoggedIn()) {
			const accessToken: string = this.jwtHelperService.tokenGetter();
			const observe = this.http.post(this.url + '/auth/refresh', { accessToken }, { headers: this.header });
			observe.subscribe((res: any) => {
				this.storeUserData(res.user, res.token['accessToken']);
			});
		}
	}

	storeUserData(user: UserModel, token: string) {
		this.store.dispatch(new SetUser(user));
		localStorage.setItem('token', token);
		if (this.configService.isDemoVersion) {
			localStorage.setItem('demo_token', user?.email ?? '');
		}
	}

	getUserData() {
		return this.store.selectSnapshot(UserState.user);
	}

	forgotPassword(email: string) {
		return this.http.post(this.url + '/user/forgot-password', email);
	}

	resetPassword(resetToken: string, password: string) {
		return this.http.post(this.url + '/user/reset-password?token=' + resetToken, password);
	}

	confirmAccount(token: string) {
		return this.http.post(this.url + '/user/confirm', token);
	}

	logout() {
		localStorage.clear();
		this.router.navigate(['/', 'signin']);
	}

	redirectIfForbidden() {
		this.ngZone.run(() => this.router.navigate(['/403'])).then();
	}

	redirectIfServerError() {
		this.ngZone.run(() => this.router.navigate(['/500'])).then();
	}

	isUserLoggedIn() {
		const token: string = this.jwtHelperService.tokenGetter();
		return !this.jwtHelperService.isTokenExpired(token);
	}
}
