import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EqaScrollRefDirective } from '@shared/ui/elements/scrollbar/scroll-ref.directive';
import { EqaScrollableDirective } from '@shared/ui/elements/scrollbar/scrollable.directive';
import { EqaScrollbarComponent } from '@shared/ui/elements/scrollbar/scrollbar.component';
import { TuiScrollControlsModule } from '@shared/ui/elements/scroll-controls';

@NgModule({
	imports: [CommonModule, TuiScrollControlsModule],
	declarations: [EqaScrollRefDirective, EqaScrollableDirective, EqaScrollbarComponent],
	exports: [EqaScrollRefDirective, EqaScrollableDirective, EqaScrollbarComponent],
})
export class ScrollbarModule {}
