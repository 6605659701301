import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CurrencyCode, AppLocale } from '@core/interfaces/localisation';
import { LocalisationStateModel } from './localisation.state.model';
import { LocalisationService } from '@core/services/localisation/localisation.service';
import { CurrencyService } from '@core/services/localisation/currency.service';
import defaults from './default.values';
import {
	GetCurrency,
	GetCurrencyList,
	GetLanguage,
	GetLanguageList,
	LoadLanguages,
	SetCurrency,
	SetLanguage,
} from './localisation.actions';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, NgZone } from '@angular/core';

@State<LocalisationStateModel>({
	name: 'localisationState',
	defaults: {
		currency: defaults.currency,
		currencies: [],
		locales: [],
		locale: defaults.locale,
		localeCodes: [],
	},
})
@Injectable()
export class LocalisationState {
	constructor(
		private localisationService: LocalisationService,
		private currencyService: CurrencyService,
		private ngZone: NgZone,
		private translationService: TranslateService,
	) {}

	@Selector()
	static currencies(m: LocalisationStateModel): CurrencyCode[] {
		return m.currencies;
	}

	@Selector()
	static currency(m: LocalisationStateModel): CurrencyCode {
		return m.currency;
	}

	@Selector()
	static localeCodes(m: LocalisationStateModel): string[] {
		return m.localeCodes;
	}

	@Selector()
	static locales(m: LocalisationStateModel): AppLocale[] {
		return m.locales;
	}

	@Selector()
	static locale(m: LocalisationStateModel): AppLocale {
		return m.locale;
	}

	@Action(SetLanguage)
	setLanguage({ getState, patchState }: StateContext<LocalisationStateModel>, { payload }: SetLanguage) {
		const newLocale = this.localisationService.setLocale(payload);
		this.ngZone.run(() => this.translationService.use(newLocale.code));
		patchState({
			locale: newLocale,
		});
	}

	@Action(GetLanguage)
	getLanguage({ patchState }: StateContext<LocalisationStateModel>, {}: GetLanguage) {
		let locale = this.localisationService.getCurrentLocale();
		if (!locale) {
			locale = this.localisationService.getDefaultLocale();
			this.localisationService.setLocale(locale.code);
		}
		patchState({
			locale: locale,
		});
	}

	@Action(LoadLanguages)
	loadLanguageCodesToTranslate({ patchState }: StateContext<LocalisationStateModel>, {}: LoadLanguages) {
		const codes = this.localisationService.getLocaleCodes();
		this.ngZone.run(() => this.translationService.addLangs(codes));
		patchState({
			localeCodes: codes,
		});
	}

	@Action(GetLanguageList)
	getLanguageList({ patchState }: StateContext<LocalisationStateModel>, {}: GetLanguageList) {
		const locales = this.localisationService.getLocales();
		patchState({
			locales: locales,
		});
	}

	@Action(SetCurrency)
	setCurrency({ getState, patchState }: StateContext<LocalisationStateModel>, { payload }: SetCurrency) {
		const currency = this.currencyService.setCurrency(payload);
		patchState({
			currency: currency,
		});
	}

	@Action(GetCurrency)
	getCurrency({ patchState }: StateContext<LocalisationStateModel>, {}: GetCurrency) {
		const currency = this.currencyService.getCurrency();
		patchState({
			currency: currency,
		});
	}

	@Action(GetCurrencyList)
	getCurrencyList({ patchState }: StateContext<LocalisationStateModel>, {}: GetCurrencyList) {
		const currencies = this.currencyService.getCurrencies();
		patchState({
			currencies: currencies,
		});
	}
}
