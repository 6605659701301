import { Inject, Injectable } from '@angular/core';
import { EqaDialog } from '@shared/types/dialog';
import { PolymorpheusComponent, PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { BehaviorSubject, Observable } from 'rxjs';
import { EqaIdService } from '@shared/domain/services/id.service';
import { EqaBaseDialogContext } from '@shared/domain/interfaces/base-dialog-context';

@Injectable()
export abstract class AbstractEqaDialogService<T extends {}> extends Observable<ReadonlyArray<EqaDialog<T, any>>> {
	protected abstract readonly component: PolymorpheusComponent<any, EqaDialog<T, any>>;

	protected abstract readonly defaultOptions: T;

	protected readonly dialogs$ = new BehaviorSubject<ReadonlyArray<EqaDialog<T, any>>>([]);

	protected constructor(@Inject(EqaIdService) private readonly idService: EqaIdService) {
		super((observer) => this.dialogs$.subscribe(observer));
	}

	open<G>(content: PolymorpheusContent<EqaBaseDialogContext<G> & T>, options: Partial<T> = {}): Observable<G> {
		return new Observable((observer) => {
			const completeWith = (result: G) => {
				observer.next(result);
				observer.complete();
			};
			const dialog = {
				...this.defaultOptions,
				...options,
				content,
				completeWith,
				$implicit: observer,
				component: this.component,
				createdAt: Date.now(),
				id: this.idService.generate(),
			};
			this.dialogs$.next([...this.dialogs$.value, dialog]);

			return () => {
				this.dialogs$.next(this.dialogs$.value.filter((item) => item !== dialog));
			};
		});
	}
}
