import { Injectable } from '@angular/core';
import { Currency } from '@core/constants';
import { Balance } from '@features/network/components/billing/domain/interfaces/balance';
import { PaymentApiService } from '@features/network/components/billing/domain/services/payment/payment.api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { pluck, tap } from 'rxjs/operators';
import { BalanceStateModel } from './balance-state.model';
import { ChangeBalanceCurrency, GetBalance, RecalculateBalance, ResetBalance, SetBalanceForPayment, ValidateBalance } from './balance.actions';

@State<BalanceStateModel>({
	name: 'balanceState',
	defaults: {
		currentBalance: null,
		nextBalance: null,
		needChargeBalance: false,
	},
})
@Injectable()
export class BalanceState {
	constructor(private paymentsService: PaymentApiService) {}

	@Selector()
	static balance(m: BalanceStateModel): Balance {
		return m.currentBalance;
	}

	@Selector()
	static nextBalance(m: BalanceStateModel): Balance {
		return m.nextBalance;
	}

	@Selector()
	static needChargeBalance(m: BalanceStateModel): boolean {
		return m.needChargeBalance;
	}

	@Action(GetBalance)
	getBalance({ patchState }: StateContext<BalanceStateModel>, {}: GetBalance) {
		return this.paymentsService.getBalance().pipe(
			pluck('data'),
			tap((balance: Balance) => {
				balance.amount = Number((balance.amount / 100).toFixed(2));
				patchState({
					currentBalance: balance,
					nextBalance: balance,
				});
			}),
		);
	}

	@Action(ResetBalance)
	setBalance({ getState, patchState }: StateContext<BalanceStateModel>, {}: ResetBalance) {
		patchState({
			currentBalance: getState().currentBalance,
			nextBalance: getState().currentBalance,
		});
	}

	@Action(ValidateBalance)
	validateBalance({ getState, patchState }: StateContext<BalanceStateModel>, { price }: ValidateBalance) {
		const currentBalanceAmount = getState().currentBalance.amount;
		patchState({
			needChargeBalance: currentBalanceAmount < price,
		});
	}

	@Action(RecalculateBalance)
	calculateNextBalance({ getState, patchState }: StateContext<BalanceStateModel>, { amount }: RecalculateBalance) {
		const currentBalanceAmount = getState().currentBalance.amount;
		const nextBalance = _.cloneDeep(getState().nextBalance);
		nextBalance.amount = Math.round((currentBalanceAmount + Number(amount)) * 100) / 100;
		patchState({
			nextBalance,
		});
	}

	@Action(SetBalanceForPayment)
	setBalanceForPayment({ getState, patchState }: StateContext<BalanceStateModel>, { amount }: SetBalanceForPayment) {
		const nextBalance = _.cloneDeep(getState().nextBalance);
		nextBalance.amount = Number(amount);
		nextBalance.currency = Currency.RUB;
		patchState({
			nextBalance: nextBalance,
		});
	}

	@Action(ChangeBalanceCurrency)
	changeBalanceCurrency({ getState, patchState }: StateContext<BalanceStateModel>, { currency }: ChangeBalanceCurrency) {
		const nextBalance = _.cloneDeep(getState().nextBalance);
		nextBalance.currency = currency;
		patchState({
			nextBalance: nextBalance,
		});
	}
}
