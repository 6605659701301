import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidenavCollapseService {
	private readonly _collapse$ = new BehaviorSubject<boolean>(false);
	public collapseObs$ = this._collapse$.asObservable();

	public toggleSidenav(): void {
		const currentSidenavToggleState = this._collapse$.getValue();
		this._collapse$.next(!currentSidenavToggleState);
	}
}
