export enum ReviewStatus {
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
}
export enum ReviewStatusMenuItems {
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
	ALL = 'ALL'
}

export const ReviewStatusDescription = {
	[ReviewStatus.IN_PROGRESS]: 'In Progress',
	[ReviewStatus.COMPLETED]: 'Completed',
	[ReviewStatus.FAILED]: 'Failed',
}
