import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerUrl } from "@core/constants/server-url";
import { Attachment } from "@features/attachments/domain/interfaces/attachment";
import { AttachmentEntityTypes } from "@features/attachments/domain/interfaces/attachment-entity.types";
import { Observable } from "rxjs";

@Injectable({
	providedIn: 'root',
})
export class AttachmentsHttpService {
	private baseUrl = ServerUrl.HOME_URL;
	private attachmentsApiUrl = this.baseUrl + '/attachments';

	constructor(private http: HttpClient) {}

	public loadAttachmentsByNetwork() {
		return this.http.get(this.attachmentsApiUrl + '/all');
	}

	public loadAttachmentsByEntityId(id) {
		return this.http.get(this.attachmentsApiUrl + '/list/' + id);
	}

	public createAttachment(body) {
		return this.http.post<Attachment[]>(this.attachmentsApiUrl, body);
	}

	public removeAttachment(id: string) {
		return this.http.delete(this.attachmentsApiUrl + '/' + id);
	}

	public removeAttachmentsList(attachmentsIds: string[]) {
		return this.http.post(this.attachmentsApiUrl + '/delete/list', { attachmentsIds });
	}

	public updateAttachments(body) {
		return this.http.post(this.attachmentsApiUrl + '/attach/update', body);
	}

	public assignAttachment(
		attachments: Attachment[],
		entityIds: string[],
		type: AttachmentEntityTypes,
	): Observable<any> {
		const body = {
			attachmentIds: attachments.map((a) => a.id),
			entityIds: entityIds,
			type,
		};
		return this.http.post<any>(this.attachmentsApiUrl + '/assign', body);
	}
}
