import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
	AttachmentsDropAreaComponent
} from "@features/attachments/ui/components/upload-attachments/attachments-drop-area/attachments-drop-area.component";
import {
	AttachmentsListItemComponent
} from "@features/attachments/ui/components/upload-attachments/attachments-list-item/attachments-list-item.component";
import {
	AttachmentsListComponent
} from "@features/attachments/ui/components/upload-attachments/attachments-list/attachments-list.component";
import {
	AttachmentsUploadButtonAreaComponent
} from "@features/attachments/ui/components/upload-attachments/attachments-upload-button-area/attachments-upload-button-area.component";
import {
	AttachmentsUploadFormComponent
} from "@features/attachments/ui/components/upload-attachments/attachments-upload-form/attachments-upload-form.component";
import { UploadAttachmentsPipesModule } from "@features/attachments/ui/pipes/upload-pipes.module";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "@shared/ui";
import { EqaPreviewDialogModule } from "@shared/ui/components/preview-dialog/preview-dialog.module";
import { PreviewModule } from "@shared/ui/components/preview/preview.module";
import { EqaWrapperModule } from "@shared/ui/components/wrapper/wrapper.module";
import { FileDropDirectiveModule } from "@shared/ui/directives/file-drop-directive/file-drop-directive.module";
import { LetModule } from "@shared/ui/directives/let";
import { SvgModule } from "@shared/ui/elements/svg/svg.module";
import { PolymorpheusModule } from "@tinkoff/ng-polymorpheus";
import { ModalWindowModule } from "@shared/ui/components/modal/modal-window.module";
import { AttachmentsUploadErrorComponent } from "@features/attachments/ui/components/upload-attachments/attachments-upload-error/attachments-upload-error.component";
import { OtherPipesModule } from "@shared/ui/pipes/other-pipes/other-pipes.module";

@NgModule({
	imports: [
		OtherPipesModule,
		EqaPreviewDialogModule,
		ModalWindowModule,
		UploadAttachmentsPipesModule,
		PreviewModule,
		CommonModule,
		ButtonModule,
		PolymorpheusModule,
		FileDropDirectiveModule,
		TranslateModule,
		ButtonModule,
		EqaWrapperModule,
		SvgModule,
		LetModule,
	],
	exports: [
		AttachmentsUploadFormComponent,
		AttachmentsUploadButtonAreaComponent,
		AttachmentsUploadFormComponent,
		AttachmentsDropAreaComponent,
		AttachmentsListItemComponent,
		AttachmentsListComponent,
	],
	declarations: [
		AttachmentsUploadFormComponent,
		AttachmentsUploadErrorComponent,
		AttachmentsUploadButtonAreaComponent,
		AttachmentsUploadFormComponent,
		AttachmentsDropAreaComponent,
		AttachmentsListItemComponent,
		AttachmentsListComponent,
	],
	providers: [],
})
export class UploadAttachmentsModule { }
