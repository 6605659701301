import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';

import { EqaTooltipComponent } from './tooltip.component';
import { SvgModule } from '@shared/ui/elements/svg/svg.module';
import { EqaHintModule } from '@shared/ui/directives/hint';

@NgModule({
	imports: [CommonModule, SvgModule, EqaHintModule, PolymorpheusModule, SvgModule],
	declarations: [EqaTooltipComponent],
	exports: [EqaTooltipComponent],
})
export class EqaTooltipModule {}
