import { Directive, ElementRef, Inject, Input, OnDestroy, Optional, Renderer2, Self } from '@angular/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { combineLatest, of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { EQA_HINT_OPTIONS, EqaHintOptions } from './hint-options';
import { AbstractEqaHint } from '@shared/domain/abstracts';
import { EqaObscuredService } from '@shared/domain/services/obscured.service';
import { DestroyService, EqaHoveredService, EqaParentsScrollService } from '@shared/domain';
import { EqaHintService } from '@shared/domain/services/hint-service';
import { EqaActiveZoneDirective } from '@shared/ui/directives/active-zone/active-zone.directive';
import { DESCRIBED_BY } from '@shared/ui/directives/described-by';

export const HINT_HOVERED_CLASS = '_hint_hovered';

@Directive({
	selector: '[eqaHint]:not(ng-container)',
	providers: [EqaObscuredService, EqaParentsScrollService, DestroyService],
})
export class EqaHintDirective extends AbstractEqaHint implements OnDestroy {
	@Input()
	eqaHintId?: string;

	@Input()
	eqaHintShowDelay: EqaHintOptions['eqaHintShowDelay'] = this.options.eqaHintShowDelay;

	@Input()
	eqaHintHideDelay: EqaHintOptions['eqaHintHideDelay'] = this.options.eqaHintHideDelay;

	@Input()
	eqaHintHost: HTMLElement | null = null;

	@Input()
	set eqaHint(value: PolymorpheusContent | null) {
		if (!value) {
			this.hideTooltip();
			this.content = '';

			return;
		}

		this.content = value;
	}

	readonly componentHovered$ = new Subject<boolean>();

	constructor(
		@Inject(Renderer2) private readonly renderer: Renderer2,
		@Inject(ElementRef) elementRef: ElementRef<HTMLElement>,
		@Inject(EqaHintService) hintService: EqaHintService,
		@Inject(DestroyService)
		destroy$: DestroyService,
		@Inject(EqaObscuredService)
		@Self()
		obscured$: EqaObscuredService,
		@Inject(EqaHoveredService) hoveredService: EqaHoveredService,
		@Optional()
		@Inject(EqaActiveZoneDirective)
		activeZone: EqaActiveZoneDirective | null,
		@Inject(EQA_HINT_OPTIONS) protected readonly options: EqaHintOptions,
	) {
		super(elementRef, hintService, activeZone, options);

		combineLatest(
			hoveredService.createHovered$(elementRef.nativeElement),
			this.componentHovered$.pipe(startWith(false)),
		)
			.pipe(
				map(([directiveHovered, componentHovered]) => directiveHovered || componentHovered),
				switchMap((visible) => {
					this.toggleClass(visible);
					return of(visible).pipe(delay(visible ? this.eqaHintShowDelay : this.eqaHintHideDelay));
				}),
				switchMap((visible) => of(visible)),
				distinctUntilChanged(),
				takeUntil(destroy$),
			)
			.subscribe((visible) => {
				if (visible) {
					this.showTooltip();
				} else {
					this.hideTooltip();
				}
			});

		this.hintService.register(this);
	}

	get id(): string | null {
		return this.eqaHintId ? this.eqaHintId + DESCRIBED_BY : null;
	}

	get host(): HTMLElement {
		return this.eqaHintHost ? this.eqaHintHost : this.elementRef.nativeElement;
	}

	getElementClientRect(): ClientRect {
		return this.host.getBoundingClientRect();
	}

	ngOnDestroy() {
		this.hintService.unregister(this);
	}

	protected showTooltip() {
		if (this.content === '') {
			return;
		}

		this.toggleClass(true);
		this.hintService.add(this);
	}

	protected hideTooltip() {
		this.toggleClass(false);
		this.hintService.remove(this);
	}

	private toggleClass(add: boolean) {
		if (add) {
			this.renderer.addClass(this.elementRef.nativeElement, HINT_HOVERED_CLASS);
		} else {
			this.renderer.removeClass(this.elementRef.nativeElement, HINT_HOVERED_CLASS);
		}
	}
}
